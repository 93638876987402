import React, { useState } from 'react';
import './PopHover.css';

const PopHover = ({ text, children }) => {
  const [showHover, setShowHover] = useState(false);

  const handleMouseEnter = () => {
    setShowHover(true);
  };

  const handleMouseLeave = () => {
    setShowHover(false);
  };

  return (
    <div
      className="pop-hover-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showHover && <div className="pop-hover-text">{text}</div>}
    </div>
  );
};

export default PopHover;
