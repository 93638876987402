import React from 'react';

const Logo = ({ logoAlt, logoSrc, ...props }) => (
  <img
    alt={logoAlt}
    src={logoSrc}
    {...props}
  />
);

export default Logo;
