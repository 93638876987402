import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PSC_BY_ID } from "./apolloQueries";
import PscProfile from '../../common/components/PscProfile/PscProfile';
import LearningTopics from '../../common/components/LearningTopics/LearningTopics';
import CourtesyPage from './CourtesyPage';
import gamsLogo from '../../assets/images/logo/new-gams-logo.png';
import headPscImg from '../../assets/images/psc/head-psc.jpg';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Container, Box, Grid, Link, IconButton, Icon, Button, Collapse } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import { apolloConfig } from "../../settings/apollo";
import { ApolloClient } from '@apollo/client/core';
import PartnersBlock from '../../common/components/PartnersBlock/PartnersBlock';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const client = new ApolloClient(apolloConfig);

const useStyles = makeStyles((theme) => ({
  rootContent: {
    backgroundImage: `url(${headPscImg})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: theme.palette.common.white,
    minHeight: 'calc(100vh - 409px)',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      //minHeight: 'calc(100vh - 210px)',
      minHeight: 'auto',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  container: {
    padding:70,
    backgroundColor: theme.palette.common.bgAuth,
    border: `solid 4px ${theme.palette.common.gamsDarkBlue}`,
    [theme.breakpoints.down(1920)]: {
      padding:40,
    },
    [theme.breakpoints.down('sm')]: {
      padding:12,
    },
  },
  containerXl: {
    maxWidth:1740,
  },
  toolbar: {
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom:25,
  },
  logoBox: {
    maxWidth:200,
    margin: 'auto',
    paddingTop:10,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  pscTitle: {
    maxWidth:600,
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    textAlign: 'center',
    lineHeight:1.4,
    marginBottom: 20
  },
  pscSubTitle: {
    maxWidth:600,
    margin: '0 auto',
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.6,
    textAlign: 'center',
    '& p': {
      marginBottom: 16
    }
  },
  readMoreBtn: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    display: 'block',
    textAlign: 'center',
    width: 'auto',
    margin: '0 auto',
    '&:focus': {
      outline: 'none',
      //color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
  },
  anchorBtn: {
    color: theme.palette.common.gamsDarkBlue,
    margin: theme.spacing(0),
    padding: 0,
    marginTop:3,
    marginBottom:3,
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.gamsDarkBlue,
    },
    '&:hover': {
      color: theme.palette.common.gamsDarkBlue,
    }
  },
  footer: {
    height: 112,
    padding: theme.spacing(2.5, 0),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    webkitPrintColorAdjust: 'exact',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  footerBox: {
    maxWidth:600,
    margin: 'auto',
    color: theme.palette.common.white,
    '& p': {
      margin: theme.spacing(2, 0),
    }
  },
  copyText: {
    padding:theme.spacing(0,4),
    textAlign: 'left',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop:15,
      paddingBottom:15,
    },
  },
  btnText: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.common.white,
    marginBottom: 5,
  },
  btnLink: {
    border: `solid 1px ${theme.palette.common.white}`,
    margin:'auto',
    textAlign: 'center',
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gamsDarkBlue,
    },
    '& a': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 15,
    },
  },
  socialBox: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  iconBtn: {
    color: theme.palette.common.white,
    margin: theme.spacing(0),
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
    },
    '& svg': {
      width: 24,
      height: 24,
    }
  }
}));

const PublicProfileScoreCard = props => {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(false);

  const {
    loading,
    error,
    data
  } = useQuery(GET_PSC_BY_ID, {
    variables: {
      id: props.match.params.id,
    },
    fetchPolicy: "cache-and-network",
    client
  });

  const psc = data?.profileScoreCardById;

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const pscDateCheck = (firstDate, secondDate) => {
    if(firstDate.setHours(0,0,0,0) <= secondDate.setHours(0,0,0,0)) {
      return true;
    }
    return false;
  }

  const pscDate = new Date(psc?.expirationDate);
  const today = new Date();
  if (pscDateCheck(pscDate, today)) return <CourtesyPage/>;

  return (
    <>
      <div className={classes.rootContent}>

        <Toolbar className={classes.toolbar}>
          <Box className={classes.logoBox}>
            <Link
              href="https://gamsplatform.com"
              rel="noopener noreferrer"
              target="_blank">
            <img src={gamsLogo} alt="GAMS Logo" />
            </Link>
          </Box>
        </Toolbar>

        <Box>
          <Box>
            <Typography className={classes.pscTitle} variant="h1">
              Profile Scorecard:<br/> Digital Meta-Skills Dynamic Certification
            </Typography>
            <Typography className={classes.pscSubTitle} variant="h2" component="div">
              <div>
                <p>
                The person solved the assigned Challenges of the Learning Topics in GAMS Platform, that via simulated environments, it collects the main digital platforms, including CRM, Excel and Google Analytics, Jira, Facebook Ads etc
                </p>
              </div>
              <Collapse in={isReadMore}>
                <p>Challenges and Career Paths are built by a network of instructors and professionals in digital skills, to provide constantly updated tasks.</p>
                <p>The Profile Scorecard is useful to recruiters for having aggregated insights about candidate’s real competencies in using software and working methods, tested in practice rather than being based on auto-declared CVs.</p>
              </Collapse>
              <Link
                className={classes.readMoreBtn}
                component="button"
                variant="body1"
                onClick={toggleReadMore}
              >{isReadMore ? 'Read less' : 'Read more'}
              </Link>
            </Typography>
          </Box>

          <Box textAlign="center">
            <div className={classes.anchorBtn}>
              <KeyboardArrowDownIcon style={{ fontSize: 64 }} />
            </div>
          </Box>
        </Box>

        {loading ? (
          <LoadingSpinner styles={{ paddingTop: 65 }} />
        ) : error ? (
          <div>Error! {error.message}</div>
        ) : (
          <Container
            maxWidth="xl"
            disableGutters
            className={classes.container}
            classes={{ maxWidthXl: classes.containerXl }}
          >
            <main>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <PscProfile
                    expirationDate={psc.expirationDate}
                    serialNumber={psc.serialNumber}
                    username={psc.username}
                    badges={psc.badges}
                    photo={psc.photo}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                  <LearningTopics careerPaths={psc.careerPaths} urlPath={props.match.path}/>
                </Grid>
              </Grid>
            </main>
          </Container>
        )}

      </div>

      <PartnersBlock pscPublic={true} />

      <footer className={classes.footer}>
        <Grid
          className={classes.footerGrid}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.copyText} variant="body1">
              {'Copyright © '}
              {new Date().getFullYear()}
              {' GAMS'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box textAlign="center">
              <Typography variant="body1" className={classes.btnText}>Discover more about the Profile Scorecard at</Typography>
              <Button
                className={classes.btnLink}
                variant="outlined"
                color="default"
                href="https://gamsplatform.com/profile-scorecard/"
                rel="noopener noreferrer"
                target="_blank"
                size="large">
                gamsplatform.com
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" className={classes.socialBox}>
              <IconButton
                className={classes.iconBtn}
                hreExcel and Googlef="https://www.linkedin.com/company/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-linkedin" style={{ color: '#fff' }}></Icon>
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.facebook.com/GAMSPlatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-facebook-f" style={{ color: '#fff' }} />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.instagram.com/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <InstagramIcon />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.youtube.com/channel/UC3yjGdmVYQktS8LmW_RW0Rg"
                rel="noopener noreferrer"
                target="_blank">
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default PublicProfileScoreCard;
