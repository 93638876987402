import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CAREER_BY_SLUG, GET_USER_DATA, GET_CAREERSINGLE_PAGE, GET_GLOBALCONTENT } from "./graphql/queries";
import { client } from "../../settings/apollo";
import {
  getChallenge,
  saveChallengeInstance
} from "../challenges/AdServer/simulation";
import ChallengeDialog from "../../common/components/ChallengeDialog";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Layout from '../../common/Layout';
import CareerHeader from '../../common/components/CareerHeader/CareerHeader';
import CareerDetails from '../../common/components/CareerDetails/CareerDetails';
import CareerInfos from '../../common/components/CareerInfos/CareerInfos';
import CareerLearningTopics from '../../common/components/CareerLearningTopics/CareerLearningTopics';
import SubHeader from '../../common/components/SubHeader/SubHeader';
import SubscriptionAlert from '../../common/components/SubscriptionAlert/SubscriptionAlert';
import SubscriptionDialog from '../../common/components/Dialogs/SubscriptionDialog/SubscriptionDialog';
import DefaultDialog from "../../common/components/Dialogs/DefaultDialog/DefaultDialog";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    marginBottom:32,
  },
  ctaBox: {
    display: "flex",
    flexDirection:'row',
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
      justifyContent: "center",
      alignItems: "flex-end",
      textAlign:'center'
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
    },
  },
  ctaText: {
    marginRight:6,
    color: theme.palette.text.secondary,
    marginBottom:4,
    [theme.breakpoints.down('sm')]: {
      marginRight:0,
      marginBottom:4,
    },
  },
  ctaBtn: {
    width:165,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
  defaultDialogIcon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.warning.main,
  },
  defaultDialogTextBox: {
    maxWidth: 440,
    margin: '0 auto',
    paddingTop: 12,
    paddingBottom: 8,
    textAlign: 'center',
    '& h1': {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom:30,
    },
    '& p': {
      textAlign:'left',
      marginBottom:12,
    }
  },
}));

const CareerSingle = props => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hideViewDialogOpen, setHideViewDialogOpen] = useState(false);
  const [state, setState] = useState({
    challengesToDo: [],
    selectedChallengeDescription: "",
    selectedChallengeCoach:null,
    selectedChallengeType: "",
    selectedChallengeTopic: "",
    selectedChallengeInitialLocation: "",
    selectedChallengeTitle: null,
    selectedChallengeIsValidation: null,
    selectedChallengeDifficulty: null,
    selectedChallengeAverageTime: null,
    open: false
  });

  const {
    // loading: meQueryLoading,
    // error: meQueryError,
    data: meQuery
  } = useQuery(GET_USER_DATA);

  const {
    loading: cpQueryLoading,
    error: cpQueryError,
    data: cpQuery
  } = useQuery(GET_CAREER_BY_SLUG, {
    variables: {
      slug:  props.match.params.slug,
    },
    fetchPolicy: "cache-and-network"
  });

  const {
    loading: cspLoading,
    error: cspError,
    data: cspData
  } = useQuery(GET_CAREERSINGLE_PAGE);

  const {
    loading: gcLoading,
    error: gcError,
    data: gcData
  } = useQuery(GET_GLOBALCONTENT);

  const globalContent = gcData?.globalContent?.data?.attributes;
  const career = cpQuery?.careerPathBySlug;
  const careerSinglePage = cspData?.careerSinglePage?.data?.attributes;
  const me = meQuery?.me;
  const isTrial = me ? me.userType === 'b2c-trial' : null;
  const enablePrice = me ? (me.userType === 'b2c-trial' || me.userType === 'b2b-expired') : null;

  async function handleOpen(id, restart) {
    const selectedChallenge = await getChallenge(id, client);
    const selectedChallengeDescription = selectedChallenge.challengeDescription;
    const selectedChallengeCoach = selectedChallenge.coach
    const selectedChallengeTitle = selectedChallenge.challengeTitle
    const selectedChallengeIsValidation = selectedChallenge.isValidation
    const selectedChallengeDifficulty = selectedChallenge.challengeDifficulty
    const selectedChallengeAverageTime = selectedChallenge.averageTime
    const selectedChallengeInitialLocation = selectedChallenge?.steps[0]?.initialLocationSimulation;
    const selectedChallengeLearningTopic = selectedChallenge.challengeLearningTopic;
    setState({
      selectedChallengeDescription,
      selectedChallengeInitialLocation,
      selectedChallengeType: id,
      selectedChallengeLearningTopic,
      selectedChallengeCoach,
      selectedChallengeTitle,
      selectedChallengeIsValidation,
      selectedChallengeDifficulty,
      selectedChallengeAverageTime,
      restart: restart || false,
      open: true
    });
  }

  const [deviceType, setDeviceType] = useState('');
  let device = '';
useEffect(() => {
  const userAgent = window.navigator.userAgent;
  if (/mobile|tablet/i.test(userAgent)) {
    setDeviceType('mobile');
    device = 'moblie';
  } else {
    setDeviceType('desktop');
    device = 'desktop';
  }
}, []);

  const handleClose = () => {
    setState({ open: false });
  };

  async function handleSave() {
    const {
      selectedChallengeType,
      selectedChallengeInitialLocation,
      restart
    } = state;
    const id = await saveChallengeInstance(selectedChallengeType, client, restart);
    props.setChallengeOrigineRoute(props.location.pathname) // set data in ChallengeContext
    props.history.push(`${selectedChallengeInitialLocation}/${id}`)
  }

  const subscriptionAlertAction = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleHideViewDialogOpen = () => {
      setHideViewDialogOpen(true);
  };

  const handleHideViewDialogClose = () => {
    setHideViewDialogOpen(false);
  };

  const careerProgress = (career?.totalFinishedChallenges / career?.totalChallenges) * 100;
  const coverImage = career?.cover?.data?.attributes?.url;

  return (
    <Layout>
      {cpQueryLoading || cspLoading || gcLoading? (
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      ) : cpQueryError || cspError || gcError ? (
        <div>Error! {cpQueryError && cpQueryError.message} {cspError && cspError.message} {gcError && gcError.message}</div>
      ) : (
        <>
          {isTrial ?
            <>
              <SubscriptionAlert
                alertTitle={careerSinglePage?.subscriptionAlert?.title}
                alertSubtitle={careerSinglePage?.subscriptionAlert?.subTitle}
                alertBtnAction={subscriptionAlertAction}
                alertBtnText={careerSinglePage?.subscriptionAlert?.textButton}
              />
              <SubscriptionDialog
                dialogClose={handleDialogClose}
                dialogOpen={dialogOpen}
                enablePrice={enablePrice}
                title={globalContent.subscriptionDialog.title}
                subTitle={globalContent.subscriptionDialog.description}
                pricingPlan={globalContent.pricingPlan}
                ctaFaqs={globalContent.subscriptionDialog.ctaFaqs}
              />
            </>
          : null}
          <CareerHeader
            title={career.name}
            description={career.description}
            fullImage={coverImage}
            videoLink={career.videoLink}
          />
          <Grid container spacing={3} className={classes.gridRoot}>
            <Grid item xs={12} md={12} lg={8} xl={8}>
              <CareerDetails
                aboutCareer={career.aboutCareer.content}
                keySkills={career.keySkills.content}
                toolsUsed={career.toolsUsed}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <CareerInfos
                careerProgress={careerProgress}
                careerObtainableScore={career.obtainableScore}
                careerScore={career.score}
                totalFinishedLearningTopics={career.totalFinishedLearningTopics}
                totalLearningTopics={career.totalLearningTopics}
                careerTags={career.tags}
                careerBadges={career.badges}
              />
            </Grid>
          </Grid>
          <SubHeader
            title={careerSinglePage?.subPageHeader?.title}
            subTitle={careerSinglePage?.subPageHeader?.subTitle}
            cta={
              <Box
                className={classes.ctaBox}
                id="learningTopics"
              >
                <Typography
                  className={classes.ctaText}
                  variant="body2"
                  component="div"
                >Check your progress in the</Typography>
                <Button component={RouterLink} className={classes.ctaBtn} to="/psc" target="_blank">
                  Profile Scorecard
                </Button>
              </Box>
            }
          />
          <CareerLearningTopics
            careerStartLevel={career.startLevel}
            careerStatus={career.status}
            learningTopics={career.learningTopics}
            challengeAction={handleOpen}
          />
          <ChallengeDialog
            //title="Start Challenge"
            handleUndo={handleClose}
            handleClose={handleClose}
            handleSave={handleSave}
            open={state.open}
            saveName="START CHALLENGE"
            button={true}
            coach={state.selectedChallengeCoach}
            challengeDashboard={true}
            brief={state.selectedChallengeDescription}
            challengeTitle={state.selectedChallengeTitle}
            challengeIsValidation={state.selectedChallengeIsValidation}
            challengeDifficulty={state.selectedChallengeDifficulty}
            challengeAverageTime={state.selectedChallengeAverageTime}
          />
        </>
      )}
    </Layout>
  )
}

export default CareerSingle;
