import React from "react";
import {campaignsColumns} from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignReport from './ReportCampaignReport';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep3 = ({ _add, type }) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        CustomComponent={ReportCampaignReport}
        currentStep = {3}
        customComponentProps={{
          handleBackToCampaign: _add,
          timeFrame: 72,
          lineItems: [
            {
              "name": "LI10002",
              "values":
              {
                "impressionsDelivered": [385640, 461755, 442600],
                "budgetDelivered": [193, 231, 221],
                "clicks": [1990, 2476, 1984],
                "conversions": [156, 236, 188]
              }

            },
            {
              "name": "LI10003",
              "values":
              {
                "impressionsDelivered": [232186, 255164, 222650],
                "budgetDelivered": [51, 56, 49],
                "clicks": [469, 471, 502],
                "conversions": [49, 64, 57]
              }

            },
          ]
        }}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "back-to-campaign"
};

export default withChallengeInstance(ReportAdServerStep3, action);
