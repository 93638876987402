import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Message from '../../common/components/Message/Message';
import { ApolloClient, gql } from '@apollo/client/core';
import { apolloConfig } from '../../settings/apollo';
import { useMutation } from '@apollo/client';

const client = new ApolloClient(apolloConfig);

const VERIFY_MAIL = gql`
  mutation verifyMail($link: String!) {
    verifyMail(link: $link)
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {},
  successIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.error.dark
  },
  customerBox: {
    maxWidth:600,
    margin:'auto',
    textAlign:'center',
    marginTop:28,
    marginBottom:30,
  },
  customerMsg: {
    marginBottom:10,
  },
  customerBtn: {
    color: theme.palette.common.gamsDarkBlue,
    lineHeight:1.2,
    textTransform:'none',
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const EmailConfirmed = (props) => {
  const classes = useStyles();
  const [verifyMail] = useMutation(VERIFY_MAIL, {client});
  const [hasError, setHasError] = useState();
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    verifyMail({ variables: {
      link: window.location.origin + props.location.pathname
    }})
    .then(({ data }) => {
      setHasError(false);
    })
    .catch(error => {
      setHasError(true);
      setErrorMsg(error.toString());
    });
  }, [verifyMail, props.location.pathname]);

  useEffect(() => {
    if (hasError === false) {
      const delay = setTimeout(() => {
        props.history.push("/login");
      }, 8000);
      return () => clearTimeout(delay);
    }
  }, [hasError, props.history]);

  let icon = null;
  if (hasError === true) {
    icon = (
      <Box className={classes.errorIcon}>
        <ErrorOutlineIcon style={{ fontSize: 280}}/>
      </Box>
    )
  } else if (hasError === false) {
    icon = (
      <Box className={classes.successIcon}>
        <DoneIcon style={{ fontSize: 280}}/>
      </Box>
    )
  }

  let title = null;
  if (hasError === true) {
    title = errorMsg;
  } else {
    title = 'Thank you for registering!'
  }

  let subTitle = null;
  if (hasError === true) {
    subTitle = (
      <Box className={classes.customerBox}>
        <Typography className={classes.customerMsg} variant="body1" color="textPrimary">
          If you're having trouble logging in, please get in touch with our knowledgeable staff who are always happy to help.
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Email us at:
          <Link
            variant="body1"
            className={classes.customerBtn}
            href="mailto:learn@gamsplatform.com"
            rel="noopener noreferrer"
            target="_blank"
          > learn@gamsplatform.com
          </Link>
        </Typography>
      </Box>
    );
  } else {
    subTitle = null;
  }

  let description = null;
  if (hasError === true) {
    description = null;
  } else {
    description = 'You will be redirected to the login page in a few seconds.';
  }

  return(
    <div className={classes.root}>
      <Message
        icon={icon}
        title={title}
        subTitle={subTitle}
        description={description}
        btnLink="/login"
        btnText="Back To Login"
      />
    </div>
  );
}

export default EmailConfirmed;
