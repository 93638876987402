import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Paper, Avatar, Button, Link} from '@material-ui/core';
import {  Link as RouterLink } from 'react-router-dom';
import pscImg from '../../../assets/images/dashboard/psc-cta.png';
import BadgesDialog from './BadgesDialog';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: theme.shadows[1],
    height: 230,
    //background: 'linear-gradient(90deg, rgba(7,136,164,1) 0%, rgba(3,80,107,1) 100%)',
    backgroundImage: 'linear-gradient(270deg, #03506B 0%, #0788A4 89%)',
    borderRadius: 6,
    marginBottom:150,
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      marginBottom:50,
    },
  },

  badgesBox: {
    paddingTop: 6,
    '& div': {
      width: 45,
      height: 45,
      margin: '0 4px',
      '& img': {
        width: '100%',
        height: 'auto',
      }
    }
  },

  showBadges: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: theme.typography.pxToRem(12),
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    
  },
  badgeimg: {
    width: 80,
    height: 80
  },
  userBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    },
  },
  user: {
    display: 'flex',
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  userAvatar: {
    width:116,
    height:116,
    marginRight:12,
    border:`solid 2px ${theme.palette.common.white}`,
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    }
  },
  userTextBox: {
    color: theme.palette.common.white,
    paddingTop: 6
  },
  userScore: {
    fontSize: theme.typography.pxToRem(30),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight:1,
    marginBottom:6
  },
  userLabel: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight:1,
    opacity: 0.7,
    marginBottom:16
  },
  userTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight:1.2,
  },
  cta: {
    width:400,
    height: '100%',
    position:'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
      marginTop: '-10%',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        paddingTop: 50,
        paddingBottom: 50,
      },
      '& p': {
        maxWidth: 240,
        margin: '0 auto',
        padding: 0,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.common.white,
        textAlign: 'center',
        lineHeight: 1.3,
        marginBottom: 6
      }
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0.1,
      backgroundImage: `url(${pscImg})`,
      backgroundPosition: '50% 9%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },
  gridRoot: {
    position: 'relative',
    marginTop: -80,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  scoreBox: {
    height: 145,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 8,
    '& div': {
      width: 140,
      margin: '0 auto'
    },
    '& div:first-child': {
      fontSize: theme.typography.pxToRem(30),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.1,
      marginBottom: 4
    },
    '& div:nth-child(2)': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.2
    },
  },

  
  ctaBtn: {
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const GridWithXXL = ({ xxl, ...other }) => { // Used to add extra breakpoint to Grid
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...other} />;
};

const UserHeader = ({ name, photo, overall, totalChallenges, totalLearningTopics, totalCareers, badgeEarned}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
  
    <Box className={classes.root}>
      <Box className={classes.userBox}>
        <Box className={classes.user}>
          <Avatar
            className={classes.userAvatar}
            src={photo ? photo : null}
          />
          <Box className={classes.userTextBox}>
            <Typography className={classes.userScore} variant="body1" component="div">{overall}</Typography>
            <Typography className={classes.userLabel} variant="body1" component="div">Overall Score</Typography>
            <Typography className={classes.userTitle} variant="body1" component="div">Welcome back {name}, keep challenging yourself.</Typography>
          </Box>
        </Box>
        <Box className={classes.cta}>
          <Box>
            <Typography
              variant="body1"
              component="p"
            >Check your meta-skills and progress in the dynamic certification</Typography>
            <Button
              size="medium"
              variant="outlined"
              component={RouterLink}
              className={classes.ctaBtn}
              to="/psc"
              target="_blank">
              Profile Scorecard
            </Button>
          </Box>
        </Box>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid className={classes.gridRoot} container spacing={3}>
          <GridWithXXL item xs={12} sm={6} lg={3}>
            <Paper className={classes.scoreBox}>
              <Typography variant="body1" component="div">{totalChallenges}</Typography>
              <Typography variant="body1" component="div">Challenge completed</Typography>
            </Paper>
          </GridWithXXL>
          <GridWithXXL item xs={12} sm={6} lg={3}>
            <Paper className={classes.scoreBox}>
              <Typography variant="body1" component="div">{totalLearningTopics}</Typography>
              <Typography variant="body1" component="div">Learning Topics completed</Typography>
            </Paper>
          </GridWithXXL>
          <GridWithXXL item xs={12} sm={6} lg={3}>
            <Paper className={classes.scoreBox}>
              <Typography variant="body1" component="div">{totalCareers}</Typography>
              <Typography variant="body1" component="div">Digital Career completed</Typography>
            </Paper>
          </GridWithXXL>
          <GridWithXXL item xs={12} sm={6} lg={3}>
            <Paper className={classes.scoreBox}>
              <Typography style={{marginTop: '5px'}}  variant="body1" component="div">{badgeEarned}</Typography>
              <Typography variant="body1" component="div">Badges earned</Typography>
              <Link className={classes.showBadges} style={{ textDecoration: 'underline'}} onClick={handleClickOpen}>Show Badges</Link>
            </Paper>
            
          </GridWithXXL>
        </Grid>
      </div>
      <BadgesDialog
        open={open}
        onClose={handleClose}
        badgeEarned={badgeEarned}
        />
    </Box>
  )

}

export default UserHeader;
