import React, { Component } from 'react';
import Layout from '../../common/Layout';
import PropTypes from 'prop-types';
import { Query } from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
import AddItemButton from '../../common/components/base/AddItemButton';
import SimpleSearch from "../../common/components/data-table/SimpleSearch";
import { SecureQuery } from "../../settings/graphqlHelper";
import { DELETE_USER_GROUP, GET_USER_GROUPS } from "./queries";
import AdminSettingsNavigation from '../adminSettings/AdminSettingsNavigation';
import TransferUsersToGroup from '../users/TransferUsersToGroup/TransferUsersToGroup';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { Redirect } from 'react-router';

const columns = ["name", "referral link", "actions"];
const columnsToHide = ["groupChallenges"];

class UserGroups extends Component {
  static propTypes = {
    page: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = { name: '', page: 0, open: false, userGroupId:null, userList:[] };
    this._first = this._first.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._keyPress = this._keyPress.bind(this);
    this._add = this._add.bind(this);
    this.handleCustomActionClick = this.handleCustomActionClick.bind(this);
    //this.handleClose = this.handleClose.bind(this);
  }

  _previousPage(e) {
    e.preventDefault();
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0 });
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
  }

  _deleteRow(event) {
    SecureQuery.mutate({ mutation: DELETE_USER_GROUP, variables: { input: event.id.id }, })
      .catch(error => console.log(error)).then(() => this.forceUpdate());
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({
        name: e.target.value,
        page: 0
      });
    }
  }

  _add() {
    this.props.history.push("/admin-settings/userGroup")
  }

  handleCustomActionClick(data){
    this.setState({userGroupId: data.id, open: true})
  }

  handleClose = () => {
    this.setState({userGroupId: null, open: false})

  };
  render() {
    if(this.props.location.pathname.includes('update') && !this.props.match.params.id)
    return (
      <Redirect
      to={{
        pathname: "../userGroups",
      }}
    />
  )
    return (
      <Layout name="User Group">
        <div className="wizard-container">
          <AddItemButton OnClick={this._add} />
          <div className="row">
            <div className="col-sm-12 col-md-10" />
            <div className="col-sm-12 col-md-2">
              <SimpleSearch keyPress={this._keyPress} />
            </div>
          </div>
          <AdminSettingsNavigation path={this.props.match.path} />
          <Query query={GET_USER_GROUPS} fetchPolicy="network-only"
            variables={{
              name: this.state.name,
              page: this.state.page
            }}>
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 15, marginBottom: 30 }} />;
              if (error) return `Error! ${error.message}`;
              const userGroups = {...data.userGroups, records: data.userGroups.records.map( item => (
                {...item, link: window.location.origin + '/register/'+ item._id } 
              ))};

              return (
                <DataTable header='User Groups'
                  editUrl="/admin-settings/userGroups/update/"
                  ages={data.userGroups.pages}
                  columns={columns}
                  records={userGroups}
                  deleteRow={this._deleteRow}
                  keyColumn='_id'
                  page={this.state.page}
                  first={this._first}
                  last={this._last.bind(this, data.userGroups.pages)}
                  next={this._nextPage}
                  previous={this._previousPage}
                  columnsToHide={columnsToHide}
                  handleCustomActionClick = {this.handleCustomActionClick.bind(this)}
                  hasCustomAction = {true} >
                </DataTable>
              );
            }}
          </Query>
              {this.state.userGroupId!==null && <TransferUsersToGroup userList={this.state.userList} userGroupId={this.state.userGroupId} open={this.state.open} handleClose={this.handleClose}></TransferUsersToGroup>}
            </div>
      </Layout>
    );
  }
}

export default UserGroups;
