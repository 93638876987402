import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PSC } from "./apolloQueries";
import { Divider, Grid } from '@material-ui/core';
import Layout from '../../common/Layout';
import PageHeader from '../../common/components/PageHeader/PageHeader';
import PscProfile from '../../common/components/PscProfile/PscProfile';
import LearningTopics from '../../common/components/LearningTopics/LearningTopics';
import PartnersBlock from '../../common/components/PartnersBlock/PartnersBlock';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const ProfileScoreCard = props => {
  const {
    loading,
    error,
    data
  } = useQuery(GET_PSC, {fetchPolicy: "cache-and-network"});

  const psc = data?.profileScoreCard;

  return (
    <Layout>
      {loading ? (
        <LoadingSpinner styles={{ paddingTop: 65 }} />
      ) : error ? (
        <div>Error! {error.message}</div>
      ) : (
        <>
          <PageHeader
            pscTitle="Profile Scorecard: Digital Meta-Skills Dynamic Certification"
            pscSubTitle="The Profile Scorecard shows your aggregated operational competencies to attach to your CV or share it on any Job Platform. Browse your results for every Career Path. Check your skill progress while completing the Challenges in the Learning Topics."
            publicLink={window.location.origin + '/psc/' + psc.serialNumber}
            pdfLink={window.location.origin + '/psc/pdf/' + psc.serialNumber}
            imgLink={window.location.origin + '/psc/img/' + psc.serialNumber}
            psc={psc}
          />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
              <PscProfile
                expirationDate={psc.expirationDate}
                serialNumber={psc.serialNumber}
                username={psc.username}
                badges={psc.badges}
                photo={psc.photo}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
              <LearningTopics careerPaths={psc.careerPaths} />
            </Grid>
          </Grid>
          <Divider style={{ textAlign:'center', maxWidth: 960, margin:'0 auto', marginTop:110, marginBottom:50 }} />
          <PartnersBlock />
        </>
      )}
    </Layout>
  )

}

export default ProfileScoreCard
