import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 824,
    margin:'auto',
    marginBottom:10,
    [theme.breakpoints.down('md')]: {
      paddingLeft:12,
      paddingRight:12,
    },
  },
  icon: {
    marginLeft: 11,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 11,
    },
  },
  blueBox: {
    background: 'var(--light-info-shades-190-p, linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #0288D1)',
    padding: 14,
    alignItems: 'flex-start',
    gap: 12,
    marginBottom: 32
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom:12,
  },
  description: {
    color: theme.palette.text.secondary,
    marginBottom:22,
  },
  btn: {
    display:'block',
    width:300,
    margin:'0 auto',
    textAlign:'center',
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    [theme.breakpoints.down('xs')]: {
      width:'100%',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.blue[600],
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const ResultSolution = ({ description, src }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.blueBox}>
        <div className="row">
          <div className="col-1">
            <svg className={classes.icon} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.0833 6.41671H11.9167V8.25004H10.0833V6.41671ZM10.0833 10.0834H11.9167V15.5834H10.0833V10.0834ZM11 1.83337C5.94001 1.83337 1.83334 5.94004 1.83334 11C1.83334 16.06 5.94001 20.1667 11 20.1667C16.06 20.1667 20.1667 16.06 20.1667 11C20.1667 5.94004 16.06 1.83337 11 1.83337ZM11 18.3334C6.95751 18.3334 3.66668 15.0425 3.66668 11C3.66668 6.95754 6.95751 3.66671 11 3.66671C15.0425 3.66671 18.3333 6.95754 18.3333 11C18.3333 15.0425 15.0425 18.3334 11 18.3334Z" fill="#0288D1"/>
            </svg> 
          </div>
          <div className="col-11">
            <Typography className={classes.title} variant="body1" component="h2">Challenge solution</Typography>
            <Typography className={classes.description} variant="body1" component="p">
              {description}
            </Typography>
          </div>
        </div>
      </Box>
      {src && (
        <Button
          variant="contained"
          size="large"
          color="default"
          className={classes.btn}
          href={src}
          rel="noopener noreferrer"
          target="_blank"
        >Check challenge debrief
        </Button>
      )}
    </Box>
  );
};

export default ResultSolution;
