import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    padding: theme.spacing(2.5, 0, 3.5, 0),
    borderRadius: 8,
  },
  dialogTitle: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1.2),
    '& h2': {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3,
    }
  },
  dialogContent: {
    padding: theme.spacing(0, 2, 0, 2)
  },
  dialogActions: {
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(3),
  },
  icon: {
    fontSize: theme.typography.pxToRem(58),
    color: theme.palette.warning.main
  }
}));

const DefaultDialog = ({ dialogOpen, dialogClose, dialogTitle, dialogContent, dialogActions, id, maxWidth, icon, iconStyles, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth ? maxWidth : 'sm'}
      id={id}
      open={dialogOpen}
      onClose={dialogClose}
      classes={{paper: classes.dialogRoot}}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box>
            {icon && <Icon className={`${classes.icon}`} style={iconStyles}>{icon}</Icon>}
          </Box>
          <Typography variant="h2" component="h2">{dialogTitle}</Typography>
        </Box>
      </DialogTitle>
      {children}
      {dialogContent &&
      <DialogContent className={classes.dialogContent}>
        {dialogContent}
      </DialogContent>}
      {dialogActions &&
      <DialogActions className={classes.dialogActions}>
        {dialogActions}
      </DialogActions>}
    </Dialog>
  );
}

export default DefaultDialog;
