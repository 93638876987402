import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, ButtonGroup, Popover, Typography, Box } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import DownloadPdf from './DowloadPdf'
import LinkedinAuthorization from '../../../components/linkedin/LinkedinAuthorization';
import LinkedinShare from '../../../components/linkedin/LinkedinShare';
import DownloadImage from './DownloadImage';
import Swal from 'sweetalert2';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  btn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      borderRight: `solid 1px rgba(0,0,0,0.39)`,
    }
  },
  copyBtnText: {
    [theme.breakpoints.up('md')]: {
      width: 140
    }
  },
  popover: {
    margin:2
  },
  popoverBox: {
    padding: theme.spacing(1.4),
  },
  popoverIcon: {
    color: theme.palette.success.main
  },
  popoverText: {
    lineHeight: 'unset',
    marginLeft:4
  }
}));

const ShareButtonGroup = ({ publicLink, pdfLink, imgLink, psc }) => {
  const [code, setCode] = useState('');
  const [, setAccessToken] = useState('');
  const [isLinkedinShare, setIsLinkedinShare] = useState(false);
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const [isDownloadPdf, setIsDownloadPdf] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const mediaQueryMd = useMediaQuery(theme.breakpoints.down('md'));
  const mediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const redirectUri=window.location.protocol+'//'+window.location.host+'/linkedin/authorization';

  const copyLinkToClipboard = (event) => {
    navigator.clipboard.writeText(publicLink);
    setAnchorEl(event.currentTarget);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  useEffect(() => {
    if (anchorEl) {
      const popoverDelayClose = setTimeout(() => {
        setAnchorEl(null);
      }, 6000);
      return () => clearTimeout(popoverDelayClose);
    }
  }, [anchorEl]);

  function downloadImage(){
    setIsDownloadImage(true)
  }

  function downloadPdf(){
    setIsDownloadPdf(true)
  }

  function onLinkedinLogin(code){
     console.log("linkedin login code: "+code);
     setCode(code);
  }

  function onLinkedinAuthorization(response){
     console.log("authorized");
     setAccessToken(response.accessToken);
     setIsLinkedinShare(true);
  }

  function onLinkedinShare(){
    Swal.fire({
      type: 'success',
      title: 'Profile Scorecard Shared',
      showConfirmButton: false,
      timer: 1500
    })
     setIsLinkedinShare(false);
     setCode('');
  }
  function onLinkedinShareError(message){
    Swal.fire({
      type: 'error',
      text: message,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: 'Close'
    })
     setIsLinkedinShare(false);
     setCode('');
  }
  function onLinkedinShareLoading(){
    return <LoadingSpinner styles={{ paddingTop: 60 }} /> ;
  }

  function onDownloadImage(){
    setIsDownloadImage(false);
  }

  function onDownloadPdf(){
    setIsDownloadPdf(false);
  }

  const linkedinCertificationBtn = 
      <Button
      href={"https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=GAMS%20Profile%20Scorecard&organizationId=19045448&expirationYear="+moment.utc(psc.expirationDate).year()+"&expirationMonth="+(moment.utc(psc.expirationDate).month()+1)+"&certUrl="+publicLink+"&certId="+psc.serialNumber}
      key="linkedinCertificationBtn"
      startIcon={<LinkedInIcon />}
      style={{}}
      className={classes.btn}
    >Share</Button>
;
  const copylinkBtn =
    <Button
      key="copylinkBtn"
      startIcon={<LinkIcon />}
      aria-describedby={popoverId}
      onClick={copyLinkToClipboard}
    ><span className={classes.copyBtnText}>Copy public Link</span>
    </Button>
  ;

  const downloadImageBtn =
    <Button
      key="downloadImageBtn"
      onClick={downloadImage}
      startIcon={<ImageIcon />}
    >Image
    </Button>
  ;

  const downloadPdfBtn =
    (true) ?
      <Button
        key="downloadPdfBtn"
        onClick={downloadPdf}
        startIcon={<PictureAsPdfIcon />}
      >Download
      </Button>
    : null
  ;

  const linkedinBtn =
    <LinkedIn
      clientId="77ia0jbfkyzcou"
      redirectUri={redirectUri}
      scope="w_member_social r_liteprofile"
      onSuccess={(code) => {
        onLinkedinLogin(code);
      }}
      onError={(error) => {
        console.log(error);
      }}
    >
      {({ linkedInLogin }) => (
        <Button
         onClick={linkedInLogin}
         key="linkedinBtn"
         startIcon={<LinkedInIcon />}
         style={{}}
         className={classes.btn}
       >Share</Button>
      )}
    </LinkedIn>
    ;

  let arrayButtons = [copylinkBtn, downloadImageBtn, downloadPdfBtn, linkedinCertificationBtn];

  return (
    <>
      <ButtonGroup
        orientation={mediaQueryXs ? "vertical" : "horizontal"}
        variant="contained"
        aria-label="contained primary button group"
        size={ mediaQueryMd ? "medium" : "large" }
        color="inherit"
        classes={{
          groupedContained: classes.btn
        }}
      >
        {arrayButtons}
      </ButtonGroup>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          className={classes.popoverBox}
        >
          <CheckCircleIcon className={classes.popoverIcon}/>
          <Typography className={classes.popoverText} variant="body2" component="div">Link copied to clipboard</Typography>
        </Box>
      </Popover>

      {(code!=='') ? <LinkedinAuthorization code={code} redirectUri={redirectUri} onSuccess={onLinkedinAuthorization} /> : null}
      {(isLinkedinShare) ?
        <LinkedinShare
          url={publicLink}
          text="I validated my skills in digital marketing with GAMS Platform. Check my results! #dontjustlearnsimulate #gamsplatform"
          description=""
          title="Profile Scorecard: Digital Meta-Skills Dynamic Certification"
          onSuccess={onLinkedinShare}
          onError={onLinkedinShareError}
          onLoading={onLinkedinShareLoading}
        />
      : null}
      {(isDownloadPdf) ? <DownloadPdf link={pdfLink} onSuccess={onDownloadPdf}/> : null}
      {(isDownloadImage) ? <DownloadImage link={imgLink} onSuccess={onDownloadImage}/> : null}
    </>
  )
}

export default ShareButtonGroup;
