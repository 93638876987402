import React from 'react';
import { Form, ErrorMessage } from 'formik';
import { movingTab, width100 } from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";
import Select from 'react-select';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { FormControlLabel, Input, Radio, RadioGroup } from '@material-ui/core';

const GET_CHALLENGE_DEFINITION_NAMES = gql`
query challengeDefinitionNames {
  challengeDefinitionNames{
    id
    name
  }
}
`;

const UserGroupForm = (props) => {
  const nameText = 'User Group Name (required)';
  const [isCareerPath, setIsCareerPath] = React.useState('false');

  const handleChange = (event) => {
    setIsCareerPath(event.target.value);
    props.setFieldValue("isCareerPath",event.target.value)
  };
  const { loading, error, data } = useQuery(GET_CHALLENGE_DEFINITION_NAMES);
  const { values } = props;
  if(loading) return null;
  if(error) return null;
  if(data) 
    return (
      <Form>
        <div className="card-header text-center">
          <h3 className="card-title">
            User Group
          </h3>
        </div>
        <div className="wizard-navigation">
          <div className="moving-tab" style={movingTab}>
            Info
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="input-group form-control-lg">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">face</i>
                </span>
              </div>
              <div className="form-group bmd-form-group">
                <TextField
                  required
                  label={nameText}
                  name="name"
                  value={values.name}
                  onChange={props.handleChange}

                  margin="normal"
                  style={width100}
                />
                <ErrorMessage name="name" component="div" />
              </div>

              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">fact_check</i>
                </span>
              </div>
              <div className="form-group bmd-form-group">
                {/* <label>Challenges: </label> */}
                <Select
                  name="groupChallenges"
                  placeholder="Select Challenges"
                  options={data.challengeDefinitionNames.map((item) => ({ value: item.id, label: item.name }))}
                  onChange={(values) => props.setFieldValue("groupChallenges", values.map((item) => item.value.toString()))}
                  isMulti={true}
                  defaultValue={props.groupChallengesSelect}
                  padding={20}
                />
              </div>
              <div className="input-group-prepend">
                Career Path:
              </div>
              <div className="form-group bmd-form-group">
              <RadioGroup aria-label="isCareerPath" name="isCareerPath" value={values.isCareerPath.toString()} onChange={handleChange}>
                <FormControlLabel value="true" control={<Radio />} label="true" />
                <FormControlLabel value="false" control={<Radio />} label="false" />
              </RadioGroup>

              </div>
              <div className="container">

              </div>
              <div className="form-group bmd-form-group ml-auto mr-auto">
                <button type="submit"
                  className="btn btn-next btn-fill btn-rose btn-wd" name="save" value="save">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    )
}

export default UserGroupForm;
