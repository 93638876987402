import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, SvgIcon } from '@material-ui/core';
import { ReactComponent as trophyIcon } from '../../../../assets/images/challenge-results/trophy.svg';
import placeholderimg from '../../../../assets/images/challenge-results/trophy-grats.png';

const useStyles = makeStyles(theme => ({
  bgIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    backgroundImage: `url(${placeholderimg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  svgIcon: {
    fontSize: '120px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '60px',
    },
  },
  cardBtn: {
    minWidth: theme.typography.pxToRem(200),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  }, 
  scoreTitle: {
    fontSize: theme.typography.pxToRem(15),
    marginBottom: theme.typography.pxToRem(16)
  },
  dialogContent: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      margin: '0 auto',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0
    },
    '&>*': {
      '&:first-child': {
        marginBottom: theme.typography.pxToRem(12)
      }
    }
  },
  dialogRoot: {
    padding: theme.spacing(2.5, 0, 3.5, 0),
    borderRadius: 8,
    minWidth: '600px',
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
    },
  },
  dialogHeading: {
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'center',
    '& img': {
      width: theme.typography.pxToRem(120),
      height: theme.typography.pxToRem(120),
    }
  },
  dialogTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
  },
  dialogActions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'center',
  },
}));

const LearningTopicCompletedDialog = ({ finishedLearningTopic, dialogOpen, dialogClose, id }) => {
  const classes = useStyles();

  const hasBadge = !!finishedLearningTopic.badge;
  const learningTopicName = finishedLearningTopic.name;

  return (
    <Dialog
      id={id}
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{
        paper: classes.dialogRoot
      }}
    >
      <Box className={classes.bgIcon}></Box>
      <DialogTitle className={classes.dialogHeading}>
        {!hasBadge ? <SvgIcon className={classes.svgIcon} component={trophyIcon} viewBox="0 0 120 120"/> : <img src={finishedLearningTopic.badge} alt="Gams Badge" />}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box textAlign="center">
          <Typography variant="h3" component="h3" className={classes.dialogTitle}>{hasBadge ? `Congratulations you have concluded the ${learningTopicName} and you have gained the badge!` : `Congratulations you have concluded the ${learningTopicName}!`}</Typography>
          <Typography variant="h4" component="h4" className={classes.scoreTitle}>{`You scored ${finishedLearningTopic.score} in your Learning Topic! Go on with the other Challenges and completed the other Learning Topics!`}</Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.cardBtn}
            color="default"
            variant="contained"
            size="large"
            onClick={dialogClose}>
            CONTINUE
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LearningTopicCompletedDialog;
