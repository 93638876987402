import React from "react";
import { useMutation, gql } from '@apollo/client';
import FacebookLogin from 'react-facebook-login';
import { Cookies } from 'react-cookie';
import { ApolloClient } from '@apollo/client';
import { apolloConfig } from '../../settings/apollo';

const client = new ApolloClient(apolloConfig);

const LOGIN_WITH_FACEBOOK = gql`
  mutation loginWithFacebook($facebookLoginResponse: FacebookLoginResponseInput!) {
    loginWithFacebook(facebookLoginResponse: $facebookLoginResponse) {
      _id
      userRole
      token
    }
  }
`;

const LOGGED_USER = gql`
  query loggedUser {
    user {
      username
      role
    }
  }
`;

const Facebook = () => {
  const [loginWithFacebook] = useMutation(LOGIN_WITH_FACEBOOK, { client });

  const handleFacebookLogin = (facebookLoginResponse) => {
    // Perform the login with Facebook
    loginWithFacebook({
      variables: {
        facebookLoginResponse: facebookLoginResponse,
      },
    })
      .then(async (result) => {
        // Handle the login success
        const user = result.data.loginWithFacebook;
        await afterLogin(
          { username: user._id, role: user.userRole },
          user.token
        );
      })
      .catch((error) => {
        // Handle the login error
        console.error('Login with Facebook error:', error);
      });
  };

  const afterLogin = async function ({ username, role }, token) {
    const cookies = new Cookies();
    cookies.set('jwt', token);
    client.writeQuery({
      query: LOGGED_USER,
      data: {
        user: {
          username,
          role,
        },
      },
    });
    window.location.assign('/');
  };

    return (
        <FacebookLogin
            appId="729052885691118"
            fields="name, email, picture"
            scope="public_profile,email"
            callback={handleFacebookLogin}
            icon="fa-facebook"
            buttonStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '32px',
                padding: '0 12px',
                fontSize: '14px',
                textTransform: 'none',
                lineHeight: '1',
              }}
            textButton='Sign in with Facebook'
        />
    )
};

export default Facebook;
