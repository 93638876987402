import React from "react";

import { campaignsColumns } from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignUpdate from './ReportCampaignForm';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep1 = ({ _add, type, _skip }) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add} _skip={_skip}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        currentStep = {9}
        timeframe
        CustomComponent={ReportCampaignUpdate}
        customComponentProps={{
          handleLineItems: _skip
        }}
        handleTimeframeChange = { _add}
        initialTimeframeValue={"72"}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "change",
  target: "168",
  parameter: "timeframefilter"
};

const skip = {
  actionNeeded: "click",
  target: "button",
  parameter: "open-report"
}

 const skipSteps = 1;
export default withChallengeInstance(ReportAdServerStep1, action, skip, skipSteps);
