import React from 'react';
import Layout from '../../../../common/Layout';
import ChallengeTemplate from '../ChallengeTemplate';
import { ApolloClient } from "@apollo/client";
//import { client } from "../../../../settings/apollo";
import { apolloSecureConfig } from "../../../../settings/apollo";
import { evaluateStep } from "../simulation";
import {
  // GET_CHALLENGE,
  // EVALUATE_STEP,
  GET_CHALLENGE_INSTANCE
} from '../queries';
import { Query } from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";

class AppraiseResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        id: props.match.params.id,
        name: '',
        isSubmitting: false,
    };
    this.submit = this.submit.bind(this);
  }

  async coreSubmit(parameter, validationField, challengeToDo) {
    if (!this.state.isSubmitting) {
      this.setState({isSubmitting: true});
      const action = {
          actionNeeded: 'click',
          target: 'button',
          parameter: parameter,
          validationField: validationField
      };
      const client = new ApolloClient(apolloSecureConfig());
      const res = await evaluateStep(this.state.id, action, client, challengeToDo);
      let value = res.data.challengeStepEvaluator;
      this.setState({ validationField: '' })
      if (value !== '')
          await this.props.history.push(value + this.props.match.params.id);
    }
  }

  submit(parameter, validationField, challengeToDo){
    if (!this.state.isSubmitting) {
      this.coreSubmit(parameter, validationField, challengeToDo)
      .then(() => this.setState({isSubmitting: false}));
    } else {
      setTimeout(() => {
        this.setState({isSubmitting: false});
      }, 1000);
    }
  }

  render() {
    return (
      <Query
        query={GET_CHALLENGE_INSTANCE}
        fetchPolicy="no-cache"
        variables={{ id: this.state.id }}>
        {({ loading, error, data }) => {
          if (loading) return (<LoadingAnimation />);
          if (error) return `Error! ${error.message}`;
          //this.state.type = data.challengeInstance.challengeType
          if (data.challengeInstance)
            return (
              <Layout
                name={"Challenge"}
                hideMenu={true}
                isValidation={data.challengeInstance.isValidation}
              >
                <ChallengeTemplate
                  history={this.props.history}
                  id={this.props.match.id}
                  type={data.challengeInstance.challengeType}
                  submit={this.submit}
                  onChange={this.onChange}
                  isSubmitting={this.state.isSubmitting}
                />
              </Layout>
            )
        }}
      </Query>
    );
  }
}

export default AppraiseResult;
