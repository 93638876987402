import React, { useState, useEffect } from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = props => makeStyles((theme) => ({
  timer: {
    width: 110,
    height: 110,
    position: 'absolute',
    top: -27,
    left: -134,
    '@media (max-width:1600px)': {
      top: props.hasMedia ? -100 : -27,
      left: props.hasMedia ? 25 : -134,
    },
    '@media (max-width:1500px)': {
      top: props.hasMedia ? -100 : -109,
      left: props.hasMedia ? 25 : 25,
    }
  },
  timerContent: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timerText: {
    fontSize: theme.typography.pxToRem(14),
    color: '#595959',
    lineHeight: 1.5
  },
  timerValue: {
    fontSize: theme.typography.pxToRem(21),
    color: '#595959',
    lineHeight: 1,
    '@media (max-width:1600px)': {
      fontSize: props.hasMedia ? theme.typography.pxToRem(16) : theme.typography.pxToRem(18),
    },
  }
}));

const CircularCountDown = (props) => {
  const classes = useStyles(props)();
  const[time, setTime] = useState(props.time);
  const mediaQueryImg = useMediaQuery('(max-width:1600px)');
  const mediaQueryNoImg = useMediaQuery('(max-width:1500px)');

  useEffect(() => {
    setTime(props.time)
  }, [props.time]);

  let sizeCountdown = null;
  if (props.hasMedia && mediaQueryImg) {
    sizeCountdown = 80;
  } else if (!props.hasMedia && mediaQueryNoImg) {
    sizeCountdown = 90;
  } else {
    sizeCountdown = 110
  }

  return (
    <div className={props.challengeInfo === true ? classes.timer : null}>
      {time !== undefined ?
        <CountdownCircleTimer
          size={sizeCountdown}
          strokeWidth={5}
          isPlaying
          key={props.step}
          duration={time-1}
          initialRemainingTime={time}
          colors={[
            [blue[900], 0.33],
            [yellow[400], 0.33],
            [red[500]]
          ]}
        >
        {({ remainingTime }) =>
          <div className={classes.timerContent}>
            <div className={classes.timerValue}>{remainingTime}</div>
            <div className={classes.timerText}>Sec</div>
          </div>
        }
        </CountdownCircleTimer>
      : null }
    </div>
  );
};

export default CircularCountDown;
