import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Button, Box, Typography, SvgIcon } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ReactComponent as closeFullScreenIcon } from '../../../assets/images/sandbox-tools/close-fullscreen.svg';

const useStyles = (media) => makeStyles(theme => ({
  root: {
    marginBottom:30
  },
  cardHeaderRoot: {
    padding: theme.spacing(3, 3, 3.5, 3),
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3, 3, 2, 3),
    },
  },
  cardMedia: {
    minWidth:52,
    height: 52,
    position:'relative',
    borderRadius:6,
    backgroundImage: media.radialGradient,
    marginRight:12,
    '& div': {
      width:42,
      height:42,
      position:'absolute',
      backgroundColor:theme.palette.common.white,
      top:0,
      bottom:0,
      left:0,
      right:0,
      margin:'auto',
      borderRadius:'100%',
      boxShadow:`${theme.shadows[1]}`,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      '& img':{
        width:28,
        height:'auto'
      }
    }
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(21),
    },
  },
  cardAction: {
    margin:0,
    [theme.breakpoints.down(630)]: {
      marginTop:18,
    },
  },
  cardSubTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight:1.5,
    marginBottom:16,
  },
  cardDescription: {
    fontSize: theme.typography.pxToRem(16),
  },
  cardContent: {
    maxWidth:648,
    margin:'0 auto',
    padding: theme.spacing(2.5, 3, 3.5, 3),
  },
  openToolBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    padding: theme.spacing(0.93, 4.8, 0.93, 4.8),
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: 0.2,
    borderRadius:6,
    border: `solid 3px ${theme.palette.common.blue[700]}`,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  }
}));

const SandboxDetails = ({ title, shortDescription, longDescription, media, isExternal, link }) => {
  const classes = useStyles(media)();

  let actionButton = null;
  if (isExternal) {
    actionButton = <Button
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      className={classes.openToolBtn}
      color="default"
      variant="contained"
      endIcon={<OpenInNewIcon style={{ fontSize: 24, marginRight:0 }} />}>
      Open Tool
    </Button>
  } else {
    actionButton = <Button
      component={RouterLink}
      to={`/${link}`}
      className={classes.openToolBtn}
      color="default"
      variant="contained"
      endIcon={<SvgIcon component={closeFullScreenIcon} viewBox="0 0 24 24" style={{ fontSize: 24 }}/>}>
      Open Tool
    </Button>
  }

  return (
    <Card className={classes.root} elevation={3}>
      <CardHeader
        title={
          <Box display="flex" justify="flex-start" alignItems="center">
            <Box className={classes.cardMedia}>
              <div>
                <img src={media.imageLink} alt={title} />
              </div>
            </Box>
            <Typography className={classes.cardTitle} variant="h1" component="h1">
              {title}
            </Typography>
          </Box>
        }
        action={actionButton}
        classes={{
          root: classes.cardHeaderRoot,
          action: classes.cardAction
        }}
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardSubTitle} variant="h2" component="h2">
          {shortDescription}
        </Typography>
        <Typography className={classes.cardDescription} variant="body1" component="div">
          {longDescription ? <ReactMarkdown children={longDescription} /> : null}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SandboxDetails;
