import React, { useState, useEffect } from 'react';
import { ApolloClient, gql } from '@apollo/client/core';
import { apolloConfig } from '../../settings/apollo';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Link } from '@material-ui/core';
import Authlayout from '../../hoc/AuthLayout/AuthLayout';
import AuthForm from '../../common/components/AuthForm/AuthForm';
import AuthFormField from '../../common/components/AuthForm/AuthFormField/AuthFormField';
import AuthFormSubmit from '../../common/components/AuthForm/AuthFormSubmit/AuthFormSubmit';
import AuthAlert from '../../common/components/AuthAlert/AuthAlert';
import AuthDialog from '../../common/components/Dialogs/AuthDialog/AuthDialog';
import AuthTextBlock from '../../common/components/AuthTextBlock/AuthTextBlock';

const validationSchema = yup.object({
  username: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const client = new ApolloClient(apolloConfig);

const SEND_RESET_PASSWORD_MAIL = gql`
  mutation sendResetPasswordMail($email: String!, $baseUrl: String!) {
    sendResetPasswordMail(email: $email, baseUrl: $baseUrl)
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    zIndex:2,
  },
  formLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    textDecoration: 'underline',
  },
}));

const ForgotPassword = props => {
  const referral = new URLSearchParams(props.location.search).get("referral");
  const pathToLogin = referral ? `/login?referral=${referral}` : "/login";
  const classes = useStyles();

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [sendResetPasswordMail] = useMutation(SEND_RESET_PASSWORD_MAIL, {client});

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendResetPasswordMail({ variables: {
        email: values.username,
        baseUrl: window.location.origin + '/reset-password'
      }})
      .then(({ data }) => {
        setHasError(false);
        setSuccessDialogOpen(true);
      })
      .catch(error => {
        setSuccessDialogOpen(false);
        setHasError(true);
        setErrorMsg(error.toString());
      });
    },
  });

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  useEffect(() => {
    if (successDialogOpen === true) {
      const delay = setTimeout(() => {
        setSuccessDialogOpen(false);
        props.history.push("/login");
      }, 5000);
      return () => clearTimeout(delay);
    }
  }, [successDialogOpen, props.history]);

  return (
    <>
      <Authlayout>
        <Container maxWidth="md" className={classes.container}>

          { hasError ?
            <AuthAlert errorMsg={errorMsg} />
          : null }

          <AuthForm
            formHandleSubmit={formik.handleSubmit}
            formTitle={'Forgot your password?'}
            formSubtitle={<AuthTextBlock
              primaryText={<Box color="text.secondary">
                Please enter the email address associated with your account and we will email you a link to reset your password.
              </Box>}
            />}
            formFooter={
              <>
                <Link component={RouterLink} className={classes.formLink} to={pathToLogin}>Return to login</Link>
              </>
            }
          >
            <AuthFormField
              fieldType={'text'}
              fieldName={'username'}
              fieldLabel={'Email address'}
              fieldValue={formik.values.username}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.username && Boolean(formik.errors.username)}
              fieldHelperText={formik.touched.username && formik.errors.username}
            />
            <AuthFormSubmit
              textSubmit={'Send reset instructions'}
            />
          </AuthForm>

          <AuthDialog
            emailIcon={true}
            dialogClose={handleSuccessDialogClose}
            dialogOpen={successDialogOpen}
            dialogTitle={'Email sent'}
            dialogMessage={
              <>
                An email has been sent to <strong>{formik.values.username}</strong> with further instructions. You will be redirected to the login page in a few seconds.
              </>
            }
            dialogBtnRouter={true}
            dialogBtnLink={'/login'}
            dialogActions={true}
            dialogBtnText={'Back to login'}
          />

        </Container>
      </Authlayout>
    </>
  );
}

export default ForgotPassword;
