import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    wordBreak: 'break-all'
  },
  formControlLabel: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    '& .MuiFormControlLabel-label': {
      wordBreak: 'break-word',
    },
  },
  blue900: {
    color: `${blue[900]} !important`
  }
}))

const CheckboxesModule = props => {
  const {
    randomizeAnswer,
    stepIndex,
    onChanged,
    options
  } = props;

  const [FirstMount, setFirstMount] = useState(stepIndex);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [checked, setChecked] = useState({})
  const [checkboxesList, setCheckboxList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let checkedObject = {};
    for (let property in options) {
      checkedObject[property] = false
    }
    setChecked(checkedObject)
    if (FirstMount !== stepIndex) {
      setChecked(checkedObject)
      setSelectedCheckboxes([]);
      setFirstMount(stepIndex);
    }
  }, [FirstMount, stepIndex, options]);

  useEffect(() => {
    let checkboxeArray = null;
    if (randomizeAnswer === 'yes') {
      checkboxeArray = shuffle(Object.entries(options));
      setCheckboxList(checkboxeArray);
    } else {
      checkboxeArray = Object.entries(options);
      setCheckboxList(checkboxeArray);
    }
  }, [stepIndex, randomizeAnswer, options]);

  const onChange = (event) => {
    const selectedCheckboxesUpdate = selectedCheckboxes;
    const findName = selectedCheckboxesUpdate.indexOf(event.target.name);
    if (findName > -1) {
      selectedCheckboxesUpdate.splice(findName, 1);
    } else {
      selectedCheckboxesUpdate.push(event.target.name);
    }
    setSelectedCheckboxes(selectedCheckboxesUpdate);
    setChecked({ ...checked, [event.target.name]: event.target.checked });
    onChanged(selectedCheckboxes);
  }

  const shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  //console.log('CheckboxesModule', props)
  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormGroup>
          {checkboxesList.map(([key, value]) => {
            return (
              <FormControlLabel
                key={key}
                className={classes.formControlLabel}
                label={value}
                control={
                  <Checkbox
                    color="primary"
                    onChange={(event) => onChange(event)}
                    type="checkbox"
                    name={key}
                    value={value}
                    checked={checked[key]}
                    classes={{ checked: classes.blue900 }} />
                  }
              />
            )
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default CheckboxesModule;
