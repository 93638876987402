import Layout from "../Layout";
import React from 'react';
import MyProfile from '../components/MyProfile/MyProfile';

const EditUserProfile = () => (
    <Layout name="Dashboard">
        <div className="wizard-container">
            <MyProfile/>
        </div>
    </Layout>
);

export default EditUserProfile;