import React from "react";
import {campaignsColumns} from "../../../../constants/columns";
import FirstStepQuery from "../FirstStepQuery";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";

const ReportAdServerStep1 = ({  _add, type }) => {
  return (
    <TableLayout name={"Campaigns"}>
      <FirstStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type} 
        handleEditClick={_add}        />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "select-campaign"
};

export default withChallengeInstance(ReportAdServerStep1, action);
