import React from 'react';
import { withRouter } from "react-router";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const GET_USER_DATA = gql`
  query me {
    me {
      phone
      birthDate
      userRole
      userType
      country
      city
      name
      email
      description
      token
      password
      photo
    }
  }
`;

const GET_CAREER_PATHS_NAMES = gql`
  query careerPathsNames {
    careerPathsNames {
          id
          name
          slug
        }
      }
`;

export const GET_SANDBOX_TOOLS = gql`
  query sandboxes {
    sandboxes(sort: "sandboxOrder:asc") {
      data {
        attributes {
          name
          slug
          isExternal
        }
      }
    }
}`;

const useStyles = makeStyles((theme) => ({
  sidebarFix: {
    backgroundColor: 'transparent',
    '&:before':{
      backgroundColor: 'transparent',
    }
  },
  navItem: {
    textDecoration: 'none !important',
  },
  placeholderItem: {
    margin: '10px 15px 0px',
    padding: '8px 10px'
  }
}));

const MainMenu = (props) => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_USER_DATA, {fetchPolicy: "cache-and-network"});
  const careerPathsNames = useQuery(GET_CAREER_PATHS_NAMES, {fetchPolicy: "cache-and-network"});
  const sandboxTools = useQuery(GET_SANDBOX_TOOLS);

  let careerListItems = null;
  if (careerPathsNames.loading) {
    careerListItems = <li className="nav-item"><div className="nav-link">Loading...</div></li>;
  } else if (careerPathsNames.error) {
    careerListItems = <div>Error! {careerPathsNames.error.message}</div>;
  } else {
    careerListItems = careerPathsNames.data.careerPathsNames.map((career, index) => {
      return (
      <li key={`cp-${index}`} className={`nav-item ${props.location.pathname.includes(career.slug) ? "active" : ""}`}>
        <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to={"/career-paths/"+career.slug}>
          <span className="sidebar-normal"> {career.name} </span>
        </Link>
      </li>
      )
    })
  }

  let sandboxListItems = null;
  if (sandboxTools.loading) {
    sandboxListItems = <li className="nav-item"><div className="nav-link">Loading...</div></li>;
  } else if (sandboxTools.error) {
    sandboxListItems = <div>Error! {sandboxTools.error.message}</div>;
  } else {
    sandboxListItems = sandboxTools.data.sandboxes.data.map(({attributes}, index) => {
      return (
      <li key={`sandbox-${index}`} className={`nav-item ${props.location.pathname.includes(attributes.slug) ? "active" : ""}`}>
        <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to={"/sandbox-tools/"+attributes.slug}>
          <span className="sidebar-mini"><i className="material-icons">{ attributes.isExternal ? 'open_in_new' : 'close_fullscreen'}</i></span>
          <span className="sidebar-normal">{attributes.name}</span>
        </Link>
      </li>
      )
    })
  }

  let challengesListItem = null;
  let adminSettingsListItem = null;
  if (loading) {
    challengesListItem =
    <li className="nav-item">
      <div className={`nav-link ${classes.placeholderItem}`}>
        <span className="ml-1 mr-3 float-left">
          <img src="./../assets/icon/challenge-icon.svg" alt="icon" width="25" height="25" />
        </span>
        <p>Loading...</p>
      </div>
    </li>;
    adminSettingsListItem =
      <li className="nav-item">
        <div className={`nav-link ${classes.placeholderItem}`}>
          <i className="material-icons">admin_panel_settings</i>
        </div>
        <p>Loading...</p>
      </li>;
  } else if (error) {
    challengesListItem = <div>Error! {error.message}</div>;
    adminSettingsListItem = <div>Error! {error.message}</div>;
  } else {
    adminSettingsListItem = data.me.userRole === "superuser" || data.me.userRole === "coach" ?
      <li className={`nav-item ${(props.location.pathname.includes("admin-settings") || props.location.pathname.includes("register")) ? "active" : ""}`}>
        <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/admin-settings">
          <i className="material-icons">admin_panel_settings</i>
          <p>Admin Settings</p>
        </Link>
      </li> : null;
  }

  return (
    <div>
      <div className={`sidebar ${classes.sidebarFix}`} data-color="orange" data-background-color="gray" data-image={false}>

        <div className="logo">
          <a href="https://gamsplatform.com/" target="_blank" className="simple-text logo-normal" rel="noreferrer">
            <img src="./assets/img/new-gams-logo.png" alt="GAMS logo" width="223" height="110" hspace="20" />
          </a>
        </div>

        <div className="sidebar-wrapper">

          <div className="user">
            <div className="photo">
              <img src={loading ? '/assets/img/default-avatar.png' : ( data.me.photo ? data.me.photo : '/assets/img/default-avatar.png') } alt='img'/>

            </div>
            <div className="user-info">
              <a data-toggle="collapse" href="#collapseExample" className="username">
                <span>
                  My account
                  <b className="caret" />
                </span>
              </a>
              <div className={`collapse ${['/profile', '/help'].includes(props.location.pathname) ? "show" : ""}`} id="collapseExample">
                <ul className="nav">
                  <li className={`nav-item ${props.location.pathname.includes("profile") ? "active" : ""}`}>
                    <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/profile">
                      <span className="sidebar-mini"> EP </span>
                      <span className="sidebar-normal"> Edit Profile </span>
                    </Link>
                  </li>
                  <li className={`nav-item ${props.location.pathname.includes("help") ? "active" : ""}`}>
                    <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/help">
                      <span className="sidebar-mini">HL</span>
                      <span className="sidebar-normal">Help</span>
                    </Link>
                  </li>
                  <li className={`nav-item`}>
                    <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/login" onClick={props.onLogout}>
                      <span className="sidebar-mini">LO</span>
                      <span className="sidebar-normal">Logout</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul className="nav">
            <li className={`nav-item ${props.location.pathname==="/" ? "active" : ""}`}>
              <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/">
                <i className="material-icons ">widgets</i>
                <p>Dashboard</p>
              </Link>
            </li>

            {/* <li className={`nav-item ${props.location.pathname.includes("gams") ? "active" : ""}`}>
              <a href="/sandbox-tools" className="nav-link">
                <i className="material-icons">drag_indicator</i>
                <p>Sandbox tools </p>
              </a>
            </li> */}

            <li className={`nav-item ${props.location.pathname.includes("career-paths") ? "active" : ""}`}>
              <a data-toggle="collapse" href="#careers" className="nav-link">
                <i className="material-icons">web</i>
                <p>
                  Digital Careers
                  <b className="caret" />
                </p>
              </a>
              <div className={`collapse ${props.location.pathname.includes("career-paths") ? "show" : ""}`} id="careers">
                <ul className="nav">
                  <li className={`nav-item ${props.location.pathname === '/career-paths' ? "active" : ""}`}>
                    <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/career-paths">
                      <span className="sidebar-normal"> All Careers </span>
                    </Link>
                  </li>
                  {careerListItems}
                </ul>
              </div>
            </li>

            <li className={`nav-item ${props.location.pathname.includes("sandbox-tools") ? "active" : ""}`}>
              <a data-toggle="collapse" href="#sandboxTools" className="nav-link">
                <i className="material-icons">drag_indicator</i>
                <p>
                  Sandbox tools
                  <b className="caret" />
                </p>
              </a>
              <div className={`collapse ${props.location.pathname.includes("sandbox-tools") ? "show" : ""}`} id="sandboxTools">
                <ul className="nav">
                  <li className={`nav-item ${props.location.pathname === '/sandbox-tools' ? "active" : ""}`}>
                    <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/sandbox-tools">
                      <span className="sidebar-mini"><i className="material-icons">sort</i></span>
                      <span className="sidebar-normal">All Sandbox tools</span>
                    </Link>
                  </li>

                  {sandboxListItems}

                </ul>
              </div>
            </li>

            {/* <li className={`nav-item ${props.location.pathname.includes("gams") ? "active" : ""}`}>
              <a data-toggle="collapse" href="#sandboxtools" className="nav-link">
                <i className="material-icons">drag_indicator</i>
                <p>
                  Sandbox tools
                  <b className="caret" />
                </p>
              </a>
              <div className={`collapse ${['/campaigns', '/creatives', '/advertisers', '/placements'].includes(props.location.pathname) ? "show" : ""}`} id="sandboxtools">
                <ul className="nav">
                  <li className={`nav-item ${props.location.pathname.includes("campaign-ads-manager") ? "active" : ""}`}>
                    <a data-toggle="collapse" href="#campaign-ads-manager" className="nav-link" style={{ paddingLeft:16 }}>
                      <i className="material-icons">close_fullscreen</i>
                      <p>
                        <span style={{ fontSize:12.5 }}>Campaign Ads Manager</span>
                        <b className="caret" />
                      </p>
                    </a>
                    <div className={`collapse ${['/campaigns', '/creatives', '/advertisers', '/placements'].includes(props.location.pathname) ? "show" : ""}`} id="campaign-ads-manager">
                      <ul className="nav">
                        <li className={`nav-item ${props.location.pathname.includes("campaign") ? "active" : ""}`}>
                          <a className="nav-link" href="/campaigns" style={{ paddingLeft:22 }}>
                            <i className="material-icons">near_me</i>
                            <p>Campaigns</p>
                          </a>
                        </li>

                        <li className={`nav-item ${props.location.pathname.includes("creative") ? "active" : ""}`}>
                          <a className="nav-link" href="/creatives" style={{ paddingLeft:22 }}>
                            <i className="material-icons">collections</i>
                            <p>Creatives</p>
                          </a>
                        </li>

                        <li className={`nav-item ${props.location.pathname.includes("advertiser") ? "active" : ""}`}>
                          <a className="nav-link" href="/advertisers" style={{ paddingLeft:22 }}>
                            <i className="material-icons">business</i>
                            <p>Advertisers</p>
                          </a>
                        </li>

                        <li className={`nav-item ${props.location.pathname.includes("placement") ? "active" : ""}`}>
                          <a className="nav-link" href="/placements" style={{ paddingLeft:22 }}>
                            <i className="material-icons">web</i>
                            <p>Placements</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li> */}

            {challengesListItem}

            <li className={`nav-item ${props.location.pathname.includes("psc") ? "active" : ""}`}>
              <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/psc">
                <i className="material-icons">military_tech</i>
                <p>Profile Scorecard</p>
              </Link>
            </li>

            <li className={`nav-item ${props.location.pathname.includes("subscription") ? "active" : ""}`}>
              <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/subscription">
                <i className="material-icons">card_membership</i>
                <p> Subscription </p>
              </Link>
            </li>

            <li className={`nav-item ${props.location.pathname.includes("rankings") ? "active" : ""}`}>
              <Link className={`nav-link ${classes.navItem}`} component={RouterLink} to="/rankings">
                <i className="material-icons">leaderboard</i>
                <p>Rankings</p>
              </Link>
            </li>

            {adminSettingsListItem}

          </ul>
        </div>

        {/* <div className="sidebar-background" style={{ backgroundImage: `url(${Background})` }}></div> */}

      </div>
    </div>
  );
};

export default withRouter(MainMenu);
