import React from 'react';

import {client} from "../../../settings/apollo";
import {evaluateStep} from "./simulation";
import {GET_CHALLENGE_INSTANCE} from "./queries";
import LoadingAnimation from "../../../common/components/base/LoadingAnimation";

function withChallengeInstance(WrappedComponent, action, skip, skipSteps) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { id: props.match.params.id, time: 120 };
      this._add = this._add.bind(this)
      this._skip = this._skip.bind(this)
    }

    async _add() {
      const {id} = this.state;
      const res = await evaluateStep(id, action, client);
      let value = res.data.challengeStepEvaluator;
      if (value !== "")
        this.props.history.push(`${value}${id}`);
    }
    async _skip() {
      const {id} = this.state;
      for (let i = 0; i < skipSteps; i++) {
        const res = await evaluateStep(id, skip, client);
        let value = res.data.challengeStepEvaluator;
        if (value !== "")
          this.props.history.push(`${value}${id}`);
      }
     
    }

    componentDidMount() {
      client
        .query({
          query: GET_CHALLENGE_INSTANCE,
          variables: { id: this.state.id },
          fetchPolicy: 'no-cache'
        })
        .then(res => {
          this.setState({
            type: res.data.challengeInstance.challengeType,
            currentStep: res.data.challengeInstance.currentStep
            
          });
        });
    }

    render() {
      const { type } = this.state;
      if (!type) return <LoadingAnimation />;

      return (
        <WrappedComponent _add={this._add} _skip={this._skip} type={type} {...this.props} />
      );
    }
  };
}

export default withChallengeInstance;
