import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography, Icon } from '@material-ui/core';
import Tree from "react-d3-tree";
import { useCenteredTree } from "../../../utils/helpers/automationTree";
import TreeNode from './TreeNode/TreeNode';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'auto',
    border: `solid 2px ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[50],
    height: 'calc(100% - 130px)',
    minHeight: 360,
  },
  path: {
    stroke: `${theme.palette.grey[500]} !important`
  },
  workflowStateBox: {
    padding: theme.spacing(0, 1),
    color: theme.palette.common.white,
    height: 30,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& span': {
      marginRight: 2
    },
    '& p': {
      fontSize: theme.typography.pxToRem(14),
    }
  }
}));

// Here we're using `renderCustomNodeElement` render a component that uses
// both SVG and HTML tags side-by-side.
// This is made possible by `foreignObject`, which wraps the HTML tags to
// allow for them to be injected into the SVG namespace.
const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps
}) => {
  const { width, height, x, y, onEditEntry, onEditStep, onSelectStep, onDeleteNode, isSimulation } = foreignObjectProps;

  let newForeignObjectProps;
  if (nodeDatum.type === 'label') {
    newForeignObjectProps = { width: 40, height: 26, x: -20, y: 10 };
  } else if (nodeDatum.type === 'button') {
    newForeignObjectProps = { width: 140, height: 38, x: -70, y: -34 };
  } else {
    newForeignObjectProps = { width: width, height: height, x: x, y: y };
  }

  return (
    <g>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...newForeignObjectProps}>
        <TreeNode
          isSimulation={isSimulation}
          id={nodeDatum.id}
          title={nodeDatum.name}
          description={nodeDatum.description}
          iconName={nodeDatum.icon}
          type={nodeDatum.type}
          contactsCount={nodeDatum.contactsCount}
          onSelectStep={onSelectStep}
          onEdit={nodeDatum.type === 'entry' ? onEditEntry : ['action', 'condition'].includes(nodeDatum.type) ? onEditStep : null}
          onDelete={onDeleteNode}
        />
      </foreignObject>
    </g>
  )
};

const AutomationTree = ({ tree, handleSelectEntry, handleEditEntry, handleEditStep, handleSelectStep, handleDeleteNode, workflowState, isSimulation, loading, error }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 320, y: isSimulation ? 150 : 140 };
  const foreignObjectProps = {
    width: nodeSize.x,
    height: nodeSize.y,
    x: -160,
    y: isSimulation ? -75 : -70,
    onSelectStep: handleSelectStep,
    onEditEntry: handleEditEntry,
    onDeleteNode: handleDeleteNode,
    onEditStep: handleEditStep,
    isSimulation: isSimulation
  };

  const getNode = (nodeId) => {
    return tree.nodes.find(({ _id }) => _id === nodeId );
  }

  const getTree = (root) => {
    const node = getNode(root);

    if (node.children.length === 0) {
      return {
        id: node._id,
        name: node.title,
        description: node.description,
        icon: node.icon,
        type: node.type,
        contactsCount: node.contactsCount
      };
    } else {
      return {
        id: node._id,
        name: node.title,
        description: node.description,
        icon: node.icon,
        type: node.type,
        contactsCount: node.contactsCount,
        children: node.children.map((child) => {
          return getTree(child);
        })
      };
    }
  };

  if (loading) {
    return (
      <Box ref={containerRef} className={classes.root}>
        <LoadingSpinner styles={{paddingTop: 70}}/>
      </Box>
    );
  }

  if (error) {
    return (
      <Box ref={containerRef} className={classes.root}>
        <div>Error! {error.message}</div>
      </Box>
    );
  }

  return (
    <Box ref={containerRef} className={classes.root}>
      {!isSimulation && (
        <Box className={classes.workflowStateBox} style={workflowState ? {width: 200, backgroundColor: theme.palette.success.main} : {width: 215, backgroundColor: alpha(theme.palette.grey[800], 0.5)}}>
          <Icon>{workflowState ? 'play_arrow' :  'stop'}</Icon>
          <Typography variant="body1" component="p">The workflow is {workflowState ? 'active' :  'not active'}</Typography>
        </Box>
      )}
      {tree.nodes.length !== 0 ?
        <Tree
          data={getTree(tree.nodes[0]._id)}
          translate={translate}
          nodeSize={nodeSize}
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
          }
          orientation="vertical"
          pathFunc="step"
          rootNodeClassName="node__root"
          branchNodeClassName="node__branch"
          leafNodeClassName="node__leaf"
          pathClassFunc={(d) => { return classes.path }}
          zoomable={true}
          zoom={1}
        />
        :
        <Box textAlign="center" pt="60px">
          <TreeNode
            type="starter"
            title="Add an entry point"
            description="Click here to add an entry point"
            onSelectEntry={handleSelectEntry}
          />
        </Box>
      }
    </Box>
  );
}

export default AutomationTree;
