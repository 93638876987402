import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link, Box, Typography, TextField } from '@material-ui/core';
import { crmData } from "../../../../utils/data/sandboxes";
import { automationTableColumns } from "../../../../utils/data/crmAutomation";
import { GET_CRM_AUTOMATIONS } from "../../graphql/queries";
import { DELETE_CRM_AUTOMATION, CREATE_CRM_AUTOMATION } from "../../graphql/mutations";
import { useQuery, useMutation } from '@apollo/client';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxTable from "../../../../common/components/SandboxTable/SandboxTable";
import SandboxTableHeader from "../../../../common/components/SandboxTable/SandboxTableHeader/SandboxTableHeader";
import SandboxTableBody from '../../../../common/components/SandboxTable/SandboxTableBody/SandboxTableBody';
import SearchFilter from '../../../../common/components/SandboxTable/SandboxTableFilters/SearchFilter/SearchFilter';
import SandboxDialog from "../../../../common/components/Dialogs/SandboxDialog/SandboxDialog";
import SandboxDialogForm from "../../../../common/components/Dialogs/SandboxDialog/SandboxDialogForm/SandboxDialogForm";
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import LoadingButton from "../../../../common/components/base/LoadingButton/LoadingButton";
import AddIcon from '@material-ui/icons/Add';

const validationSchema = yup.object({
  name: yup
    .string('Enter a workflow name')
    .required('Workflow name is required'),
});

const useStyles = makeStyles(theme => ({
  actionBtn: {
    width: 245,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    padding: theme.spacing(1, 3, 1, 3),
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: 0.2,
    borderRadius:6,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  },
  dialogBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
  dialogDefaultBtn: {
    color: theme.palette.grey[500],
    '&:focus': {
      outline: 'none',
    }
  },
  textLink: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration:'none',
    textTransform: 'capitalize',
    color: theme.palette.common.gamsDarkBlue,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue
    }
  },
  dialogText: {
    marginTop:10,
    marginBottom:36,
  },
  dialogFormField: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  dialogInput: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
  },
  dialogLabel: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  dialogSubmitBtn: {
    minWidth: 198,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    padding: theme.spacing(1.1, 3, 1.1, 3),
    letterSpacing: 0.4,
    [theme.breakpoints.down('xs')]: {
      paddingTop:8.5,
      paddingBottom:8.5,
    },
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: theme.palette.common.gamsDarkBlue,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      borderRight: `solid 1px rgba(0,0,0,0.39)`,
    },
  },
}));

const CrmAutomationPage = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [workflowDialogOpen, setWorkflowDialogOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [deleteCrmAutomation, { data: dcaData, loading: dcaLoading, reset: dcaReset }] = useMutation(DELETE_CRM_AUTOMATION);
  const [createCrmAutomation, { loading: ccaLoading }] = useMutation(CREATE_CRM_AUTOMATION);
  const { loading, error, data, refetch } = useQuery(GET_CRM_AUTOMATIONS, {
    variables: { page: 0 },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createCrmAutomation({
        variables: {
          input: {
            name: values.name,
          }
        }
      })
      .then(({ data }) => {
        setWorkflowDialogOpen(false);
        props.history.push(`automation/${data.createCrmAutomation._id}/edit`);
      })
      .catch(error => {
        setWorkflowDialogOpen(false);
        setErrorDialogOpen(true)
        setErrorMsg(error.toString());
      });
    },
  });

  const deleteItem = () => {
    deleteCrmAutomation({ variables: { id: deleteId } })
    .then(({ data }) => {
      setDeleteDialogOpen(false);
      refetch();
    })
    .catch(error => {
      setDeleteDialogOpen(false);
      setErrorDialogOpen(true);
      setErrorMsg(error.toString());
    });
  }

  const handleSearchChange = (event) => {
    refetch({ page: 0, search: event.target.value })
  };

  const handlePageChange = (event, page) => {
    refetch({ page: page });
    setPage(page);
  }

  const handleEdit = (id) => {
    props.history.push(`/crm/automation/${id}/edit`);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleWorkflowDialogClose = () => {
    setWorkflowDialogOpen(false);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const handleCreateWorkflow = () => {
    setWorkflowDialogOpen(true);
  }

  const createData = (_id, id, name, creationDate, _username) => {
    return { _id, id, name, creationDate, _username };
  }

  const rows = data?.crmAutomations?.records.map((item) => {
    return createData(item._id, item.numberId, item.name, format(new Date(item.creationDate), 'd/MM/yyyy'), item.username);
  });

  return (
    <SandboxLayout layoutData={crmData} name="automation">
      <SandboxHeader
        title="Automation"
        subTitle={<>You can find and manage your workflows in the table hereby, all workflows are built from the users data in the <Link className={classes.textLink} component={RouterLink} to={`/crm/contacts`}>Contacts</Link> and <Link className={classes.textLink} component={RouterLink} to={`/crm/lists`}>Lists</Link> sections.</>}
        extraStyles={{ marginBottom: 50 }}
        action={
          <Button
            onClick={handleCreateWorkflow}
            className={classes.actionBtn}
            color="default"
            variant="contained"
            startIcon={<AddIcon style={{ fontSize: 24 }}/>}
          >Create Workflow
          </Button>
        }
      />

      {loading ? (
        <LoadingSpinner boxed={true} styles={{ paddingTop: 70}}/>
      ) : error ? (
        <div>Error! {error.message}</div>
      ) : (
        <SandboxTable
          page={page}
          onPageChange={handlePageChange}
          count={data.crmAutomations.totalCount}
          rowPerPage={data.crmAutomations.rowPerPage}
          tableToolbar={
            <SearchFilter
              searchLabel="Search..."
              onSearchChange={handleSearchChange}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              onSetPage={setPage}
            />
          }
        >
          <SandboxTableHeader
            tableColumns={automationTableColumns}
            tableCellAlign="right"
            lastTableCellAlign="center"
          />
          <SandboxTableBody
            tableColumns={automationTableColumns}
            rows={rows}
            tableCellAlign="right"
            lastTableCellAlign="center"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </SandboxTable>
      )}

      <SandboxDialog
        id="startWorkflowDialog"
        maxWidth={'md'}
        workflowIcon={true}
        dialogClose={handleWorkflowDialogClose}
        dialogOpen={workflowDialogOpen}
        dialogTitle="Start a new workflow"
      >
        <SandboxDialogForm
          formHandleSubmit={formik.handleSubmit}
          dialogContent={
            <>
              <Typography
                component="p"
                variant="body1"
                className={classes.dialogText}
              >Enter a workflow name and click start, you will be redirected to your workflow editing page.
              </Typography>
              <TextField
                className={classes.dialogFormField}
                fullWidth
                name="name"
                label="Workflow name"
                placeholder="Enter a workflow name"
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{className: classes.dialogInput}}
                InputLabelProps={{className:classes.dialogLabel}}
              />
            </>
          }
          dialogActions={
            <>
              <Button
                className={classes.dialogDefaultBtn}
                color="default"
                variant="outlined"
                size="large"
                onClick={handleWorkflowDialogClose}
              >Cancel
              </Button>
              <LoadingButton
                className={classes.dialogSubmitBtn}
                variant="contained"
                type="submit"
                size="large"
                loading={ccaLoading}
                circleSize={26.25}
              >Start Automation
              </LoadingButton>
            </>
          }
        />
      </SandboxDialog>

      <SandboxDialog
        id="deleteAutomationDialog"
        warningIcon={true}
        dialogOnExited={() => dcaReset()}
        dialogClose={handleDeleteDialogClose}
        dialogOpen={deleteDialogOpen}
        dialogTitle="Delete Automation Workflow"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">Are you sure you want to delete this Automation Workflow?</Typography>
          </Box>
        }
        dialogActions={
          <>
            <Button
              className={classes.dialogDefaultBtn}
              color="default"
              variant="outlined"
              size="large"
              onClick={handleDeleteDialogClose}
            >Cancel
            </Button>
            <Button
              className={classes.dialogBtn}
              color="default"
              variant="contained"
              size="large"
              onClick={deleteItem}
              disabled={dcaLoading || dcaData?.deleteCrmAutomation === true}
            >Delete item
            </Button>
          </>
        }
      />

      <SandboxDialog
        id="errorAutomationDialog"
        errorIcon={true}
        dialogClose={handleErrorDialogClose}
        dialogOpen={errorDialogOpen}
        dialogTitle="Error"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">{errorMsg}</Typography>
          </Box>
        }
        dialogActions={
          <Button
            className={classes.dialogDefaultBtn}
            color="default"
            variant="outlined"
            size="large"
            onClick={handleErrorDialogClose}
          >Cancel
          </Button>
        }
      />
    </SandboxLayout>
  );
}

export default CrmAutomationPage;
