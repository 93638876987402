import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import Message from '../../common/components/Message/Message';

const useStyles = makeStyles((theme) => ({
  root: {},
  successIcon: {
    color: theme.palette.success.main
  },
}));

const ThankYouPage = (props) => {
  const classes = useStyles();

  return(
    <div className={classes.root}>
      <Message
        icon={
          <Box className={classes.successIcon}>
            <DoneIcon style={{ fontSize: 280}}/>
          </Box>
        }
        title="Thank you for your payment!"
        subTitle="We've emailed you a confirmation."
        description="Click the button <<Go to GAMS>>, to return in the gams app and continue to progress in your new career paths and win more badge!"
        btnLink="/subscription"
        btnText="Go to GAMS"
      />
    </div>
  );
}

export default ThankYouPage;
