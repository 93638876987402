import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import './CarouselStyles.css'

SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    borderRadius: 6,
    border: `solid 3px ${theme.palette.grey[300]}`,
    marginTop: 18,
    '& [data-rmiz-wrap="visible"]': {
      display: 'flex',
    },
    '& [aria-label="Zoom image"]:focus': {
      outline: 'none'
    },
    '& .swiper-button-prev,& .swiper-button-next ': {
      color: theme.palette.common.gamsDarkBlue
    },
    '& .swiper-pagination-bullet-active': {
      background: theme.palette.common.gamsDarkBlue
    }
  },
  slideImg: {
    width: '100%',
    height: 'auto',
  }
}));

const Carousel = ({ carouselSlides, spaceBetween, slidesPerView, extraRootStyles, extraSlideStyles, navigation }) => {
  const classes = useStyles();

  let slides = 'No image carousel.';
  if (carouselSlides) {
    slides = carouselSlides.map((slide, index) => {
      if (slide.hasOwnProperty('attributes')) {
        const { name, url } = slide.attributes;
        return (
          <SwiperSlide key={`slide-${index}`} style={extraSlideStyles}>
            <Zoom wrapStyle={{ width: '100%' }}>
              <img
                src={url}
                alt={name}
                className={classes.slideImg}
              />
            </Zoom>
          </SwiperSlide>
        );
      } else {
        return (
          <SwiperSlide key={`slide-${index}`} style={extraSlideStyles}>
            <Zoom wrapStyle={{ width: '100%' }}>
              <img
                src={slide.link}
                alt={`Slide-${index}`}
                className={classes.slideImg}
              />
            </Zoom>
          </SwiperSlide>
        );
      }
    })
  }

  return (
    <Swiper
      spaceBetween={spaceBetween ? spaceBetween : 0}
      navigation={navigation !== null && navigation !== undefined ? navigation : true}
      pagination={{ clickable: true }}
      className={classes.root}
      slidesPerView={slidesPerView ? slidesPerView : 1}
      style={extraRootStyles}
    >
      {slides}
    </Swiper>
  );
}

export default Carousel;
