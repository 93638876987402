import gql from "graphql-tag";

export const MY_DATA = gql`
  query me {
    me {
      userRole
      courseGroups {
        _id
        name
        groupChallenges
      }
    }
  }
`;

export const GET_RESULTS = gql`
  query challengeListResults(
    $page: Int
    $courseGroups: [String]
    $userGroup: String
    $challenge: Int
  ) {
    challengeListResults(
      page: $page
      courseGroups: $courseGroups
      userGroup: $userGroup
      challenge: $challenge
    ) {
      records {
        _id
        userName
        userSurname
        challengeTitle
        scores
        userExpirationDate
      }
      pages
      totalCount
    }
  }
`;

export const GET_CHALLENGE_DEFINITION_NAMES = gql`
  query challengeDefinitionNames {
    challengeDefinitionNames {
      id
      name
      title
    }
  }
`;
