import React, { useState, useEffect} from 'react';
import gql from "graphql-tag";
import { useMutation } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, IconButton, Typography, Box, Link } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Pricing from '../../../components/Princing/Princing';
import PageHeaderCentered from '../../PageHeaderCentered/PageHeaderCentered';
import { env } from '../../../../env';

const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($priceId: String!,  $host: String!) {
    createCheckoutSession(priceId: $priceId, host: $host )
  }
`;

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    borderRadius: 8,
    border:`solid 3px ${theme.palette.common.gamsDarkBlue}`,
  },
  dialogTitle: {
    position:'relative',
    margin: 0,
    padding: theme.spacing(2),
    '& h5': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3,
    }
  },
  closeButton: {
    position: 'absolute',
    right: 4,
    top: 0,
    color: theme.palette.common.black,
    '& svg': {
      width:32,
      height:32,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  dialogContent: {
    padding:theme.spacing(1,4,5,4)
  },
  faqMsg: {
    '& p': {
      color: theme.palette.text.secondary
    },
    '& a': {
      color: theme.palette.common.gamsPetroleumBlue
    }
  },
  faqLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    '&:focus': {
      outline: 'none',
    },
  }
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      {/* <Typography variant="h5">{children}</Typography> */}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const SubscriptionDialog = ({ dialogOpen, dialogClose, dialogLocation, enablePrice, title, subTitle, pricingPlan, ctaFaqs }) => {
  const classes = useStyles();

  const stripe = useStripe();
  const [priceId, setPriceId] = useState(env.REACT_APP_PRICE_ID_YEAR); // Annually plan
  const [createCheckoutSessionMutation, {data}] = useMutation(CREATE_CHECKOUT_SESSION);

  const createCheckoutSession = async function() {
    await createCheckoutSessionMutation({ variables: { priceId, host:window.location.origin } })
  };

  const selectPlan = (priceId) => {
    setPriceId(priceId);
  }

  useEffect(() => {
    if(data) {
      stripe.redirectToCheckout({
        sessionId: data.createCheckoutSession
      })
      .then("ok");
    };
  }, [data, stripe]);

  let anchorLink = null;
  if (dialogLocation === '/subscription') {
    anchorLink = <Link className={classes.faqLink} component="button" onClick={dialogClose}>FAQs here.</Link>;
  } else {
    anchorLink = <Link component={HashLink} to={ctaFaqs?.link} smooth>{ctaFaqs?.textLink}</Link>;
  }

  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="lg"
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{
        paper: classes.dialogRoot
      }}
    >
      <DialogTitle onClose={dialogClose}>{'dialogTitle'}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <PageHeaderCentered
          title={title}
          subTitle={subTitle}
          description={
            <Box className={classes.faqMsg}>
              <Typography variant="body1">
                {ctaFaqs?.text} {anchorLink}
              </Typography>
            </Box>
          }
        />
        <Pricing
          selectPlan={selectPlan}
          createCheckoutSession={createCheckoutSession}
          enablePrice={enablePrice}
          dialogLocation={dialogLocation}
          dialogClose={dialogClose}
          pricingPlan={pricingPlan}
        />
      </DialogContent>
    </Dialog>
  );
}

export default SubscriptionDialog;
