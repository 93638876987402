import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Navigation from "./components/Navigation";
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KKW6ZKT'
}
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Navigation />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
