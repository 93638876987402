import React, { useState } from 'react';
import plusButtonStyle from "../../../settings/otherStyles";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";

const AddItemButton = ({OnClick}) => {
  const [disabled, setDisabled] = useState(false);

  const actionHandler = e => {
    setDisabled(true);
    OnClick();
  }

  return (
    <Fab
      onClick={actionHandler}
      color="primary"
      aria-label="Add"
      className={plusButtonStyle.fab}
      disabled={disabled}
    >
      <AddIcon />
    </Fab>
  );
};

export default AddItemButton;
