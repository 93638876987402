import React from 'react';
//import Layout from '../../common/Layout';
import {Formik} from 'formik';
import {Mutation, Query} from "@apollo/client/react/components";
import PlacementForm from './PlacementForm';
import {withRouter} from "react-router-dom";
//import {validatePlacement} from '../Validations';
import LoadingAnimation from '../../common/components/base/LoadingAnimation';
import {GET_PLACEMENTS, ADD_PLACEMENT} from "./queries";
import * as Yup from 'yup';
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

const placementSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a name.'),
  size: Yup.string()
    .required('Please select a size.'),
});

class PlacementInsert extends React.Component {
  render() {
    return (
      <SandboxLayout name="Placements" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <div className="col-md-8 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <Mutation mutation={ADD_PLACEMENT}>
                {(addPlacement) => (
                  <Query query={GET_PLACEMENTS}>
                    {({loading, error, data}) => {
                      if (loading) return <p><LoadingAnimation/></p>;
                      ;
                      if (error) return `Error! ${error.message}`;
                      return (
                        <Formik
                          initialValues={{name: '', subPlacementName: '', parent: '', size: ''}}
                          //validate={validatePlacement}
                          validationSchema={placementSchema}
                          onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(false);
                            addPlacement({
                              variables: {
                                input: {
                                  name: values.name,
                                  subPlacementName: values.subPlacementName,
                                  parent: values.parent,
                                  size: values.size
                                }
                              }
                            }).catch((error) => {
                              console.log(error);
                            }).then(() => this.props.history.push('/placements'));
                          }}
                        >
                          {({isSubmitting, values, handleChange, setFieldValue, errors, touched, handleSubmit}) => (
                            <PlacementForm
                              parent="Parent"
                              size="Size"
                              isSubmitting={isSubmitting}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                              handleSubmit={handleSubmit}
                            />
                          )}
                        </Formik>
                      )
                    }}
                  </Query>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(PlacementInsert);
