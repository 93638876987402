import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    marginBottom:28,
  },
  gridItem: {
    alignItems:'center'
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 7.5
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.4,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    },
  }
}));

const SubHeader = ({title, subTitle, cta}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.gridRoot} container>
      <Grid className={classes.gridItem} item xs={12} sm={cta ? 8 : 12} md={cta ? 6 : 12} lg={cta ? 8 : 12}>
        <Box pr={3}>
          <Typography className={classes.title} variant="h3">
            {title}
          </Typography>
          <Typography className={classes.subTitle} variant="h4">
            {subTitle}
          </Typography>
        </Box>
      </Grid>
      {cta ?
        <Grid className={classes.gridItem} item xs={12} sm={4} md={6} lg={4}>
          {cta}
        </Grid>
      : null }
    </Grid>
  );
}

export default SubHeader;
