import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PSC_BY_ID } from "./apolloQueries";
import PdfLearningTopics from '../../common/components/LearningTopics/PdfLearningTopics';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { apolloConfig } from "../../settings/apollo";
import { ApolloClient } from '@apollo/client/core';

const client = new ApolloClient(apolloConfig);

const useStyles = makeStyles((theme) => ({
  boxTitle: {
    marginTop:15,
    marginBottom:0
  },
  pscTitle: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.4px',
    marginBottom: 0,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
}));

const PdfProfileScoreCard = props => {
  const classes = useStyles();
  const {
    loading,
    error,
    data
  } = useQuery(GET_PSC_BY_ID, {
    variables: {
      id: props.match.params.id,
    },
    fetchPolicy: "cache-and-network",
    client
  });

  if (loading) return "loading...";
  if (error) return `Error! ${error.message}`;

  const psc = data.profileScoreCardById;

  return (
    <>
      <Box className={classes.boxTitle}>
        <Typography className={classes.pscTitle} variant="h1">
          Profile Scorecard: Digital Meta-Skills Dynamic Certification
        </Typography>
      </Box>
      <main>
        <PdfLearningTopics
          expirationDate={psc.expirationDate}
          serialNumber={psc.serialNumber}
          username={psc.username}
          badges={psc.badges}
          careerPaths={psc.careerPaths}
          urlPath={props.match.path}
          photo={psc.photo} />
      </main>
    </>
  );
}

export default PdfProfileScoreCard;
