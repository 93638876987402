import gql from "graphql-tag";

export const CREATE_BANNER = gql`
  mutation createBanner($input: BannerInput) {
    createBanner(input: $input)
  }
`;

export const UPDATE_BANNER = gql`
  mutation updateBanner($input: BannerInput) {
    updateBanner(input: $input)
  }
`;

export const DELETE_BANNER= gql`
  mutation deleteBanner($id: String!) {
    deleteBanner(id: $id)
  }
`;
