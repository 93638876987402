import React from "react";
import { Formik } from "formik";
// import { Query } from "@apollo/client/react/components";
import { withRouter } from "react-router";

// import Layout from "../../../../common/Layout";
import { GET_CHALLENGE_INSTANCE } from '../queries';
import validateAdvertiser from "../../../Validations";
import AdvertiserForm from "../../../advertiser/AdvertiserForm";
import { SecureQuery } from "../../../../settings/graphqlHelper";
// import { defaultTimeDown } from "../../../../settings/dataSets";
// import ChallengePanel from "../../ChallengePanel";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import SecondStepChallengeLayout from "../SecondStepChallengeLayout";
import {
  evaluateSecondStep,
  evaluateThirdStep
} from "../../../../settings/commonFunctions";

class AdvertisersAdServerStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, isClicked: false };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  coreSubmit = async values => {
    const { id } = this.props.match.params;
    const action = {
      actionNeeded: "complete",
      target: JSON.stringify({
        type: "advertiser",
        fields: {
          advertiserName: values.name.trim(),
          defaultLandingPage: values.defaultLandingUrl,
          category: values.category
        }
      })
    };
    let res = await evaluateSecondStep(action, id);
    let value = res.data.challengeStepEvaluator;
    if (value) {
      let finalTarget = await evaluateThirdStep(id);
      if (finalTarget) this.props.history.push(finalTarget + id);
    }
  };

  submit = values => {
    if(!this.state.isClicked){
      this.setState({isClicked: true}, () => {
        this.coreSubmit(values).then(() => {
          setTimeout(() => {
            this.setState({isClicked: false})
          }, 800);
        })
      });
    }
  };

  render() {
    if (!this.state.type)
      return <LoadingAnimation />;

    return (
      <SecondStepChallengeLayout pageName={"Advertisers"} challengeType={this.state.type}>
        <Formik
          initialValues={{ name: "", defaultLandingUrl: "", category: "" }}
          validate={validateAdvertiser}
          onSubmit={this.submit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit }) => (
            <AdvertiserForm
              values={values}
              caption="Category"
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </SecondStepChallengeLayout>
    );
  }
}

export default withRouter(AdvertisersAdServerStep2);
