import React from 'react'
import { Query } from "@apollo/client/react/components";
import gql from 'graphql-tag';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const MY_DATA = gql`
query me {
  me {
        userRole
 }
}
`;


const UserPageNavigation = (props) => {
    return (
        <div className="row">
            <div className="col-md-flex mx-2">
                <a href="/admin-settings/users">
                    {props.path === "/admin-settings/users" ?
                        <button className="btn btn-primary btn-disable">Users</button>
                        :
                        <button className="btn btn-secondary">Users</button>
                    }
                </a>
            </div>
            <div className="col-md-flex mx-2">
                <a href="/admin-settings/results">
                    {props.path === "/admin-settings/results" ?
                        <button className="btn btn-primary btn-disable">Results</button>
                        :
                        <button className="btn btn-secondary">Results</button>
                    }
                </a>
            </div>
            <Query query={MY_DATA}
                fetchPolicy="network-only"
            >
                {({ loading, error, data }) => {
                    if (loading) return <LoadingSpinner styles={{ paddingTop: 75 }} />;
                    if (error) return `Error! ${error.message}`;
                    const userData = data.me;
                    return (
                        userData.userRole === "superuser" ?
                            <>
                                <div className="col-md-flex mx-2">
                                    <a href="/admin-settings/userGroups">
                                        {props.path === "/admin-settings/userGroups" ?
                                            <button className="btn btn-primary btn-disable">User Groups</button>
                                            :
                                            <button className="btn btn-secondary">User Groups</button>
                                        }
                                    </a>
                                </div>
                                <div className="col-md-flex mx-2">
                                    <a href="/admin-settings/coaches">
                                        {props.path === "/admin-settings/coaches" ?
                                            <button className="btn btn-primary btn-disable">Coaches</button>
                                            :
                                            <button className="btn btn-secondary">Coaches</button>
                                        }
                                    </a>
                                </div>
                                <div className="col-md-flex mx-2">
                                    <a href="/admin-settings/ChallengesToDo">
                                        {props.path === "/admin-settings/ChallengesToDo" ?
                                            <button className="btn btn-primary btn-disable">Challenges</button>
                                            :
                                            <button className="btn btn-secondary">Challenges</button>
                                        }
                                    </a>
                                </div>
                                <div className="col-md-flex mx-2">
                                    <a href="/admin-settings/banners">
                                        {props.path === "/admin-settings/banners" ?
                                            <button className="btn btn-primary btn-disable">Banners</button>
                                            :
                                            <button className="btn btn-secondary">Banners</button>
                                        }
                                    </a>
                                </div>
                            </> : null
                    )
                }}
            </Query>
        </div>
    )
}
export default UserPageNavigation
