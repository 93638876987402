import React, {Component} from 'react';
import Layout from '../../../../common/Layout';
import {Query} from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import { GET_CHALLENGE, GET_CHALLENGE_INSTANCE } from '../queries';
import {SecureQuery} from '../../../../settings/graphqlHelper';
import ChallengePanel from '../../ChallengePanel';
import LineItemsSimulation from "../LineItemsSimulation";
import { client} from "../../../../settings/apollo";
import {evaluateStep} from "../simulation";
import { campaignVar, creativesVar, creativeVar, deliveryVar, frequencyCAPVar, lineItemsVar, lineItemVar, recencyVar, typeVar } from '../../../../settings/cache';

class ReportAdServerStep5 extends Component{
  constructor(props) {
    super(props);

    this.state = {lineItems: [
      {
        "name":"skinius_lineitem_thissummer_banner_300x250",
        "lineitemID":"LI10002",
        "type":"Standard campaign",
        "scheduleDuration":"168",
        "status":"Active",
        "budgetPlanned":"1500",
        "impressionsPlanned":"3000000",
        "cpm":"0,50",
        "impressionsDelivered":"1290000",
        "budgetDelivered":"645",
        "clicks":"6450",
        "conversions":"580",
        "placement":"PL109",
        "delivery":"ASAP",
        "frequency":"3",
        "recency":"24",
        "creatives": [
          {
            "adsName": "skinius_thissummer_banner_300x250",
            "url": "skinius.it",
            "creative": "skinius.it_300x250_banner",
            "adStatus": "Active",
            "__typename" : "Creative"
          }
        ]
    },
    {
        "name":"skinius_lineitem_thissummer_banner02_300x250",
        "lineitemID":"LI10003",
        "type":"Standard campaign",
        "scheduleDuration":"168",
        "status":"Active",
        "budgetPlanned":"1320",
        "impressionsPlanned":"6000000",
        "cpm":"0,22",
        "impressionsDelivered":"710000",
        "budgetDelivered":"355",
        "clicks":"1442",
        "conversions":"170",
        "placement":"PL103",
        "delivery":"ASAP",
        "frequency":"1",
        "recency":"48",
        "creatives":  [
          {
            "adsName": "skinius_thissummer_banner02_300x250",
            "url": "skinius.it",
            "creative": "skinius.it_300x250_banner",
            "adStatus": "Active"
          }
        ]
    }
    ],
      id: props.match.params.id, name:''};
      campaignVar({});
      creativesVar([]);
      creativeVar({});
      lineItemsVar([]);
      lineItemVar({});
      this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: {id: this.state.id}
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType,
        step: res.data.challengeInstance.currentStep,
        isSubmitting: false
      });
    }).catch((error)=>{
      console.log(error);
    });
  }

  handleTab(){

  };

  async submit() {
    this.setState({ isSubmitting: true })
    const action={
      actionNeeded: 'complete',
      target: JSON.stringify({
        type: "lineitem",
        fields: {

          "lineitemType":  typeVar(),
          "deliveryType": deliveryVar(),
          "frequency": frequencyCAPVar(),
          "recency": recencyVar()
        }
      })
    };
    const res = await evaluateStep(this.state.id, action, client);
    let value = res.data.challengeStepEvaluator;
    if(value!=='')
      this.props.history.push(value + this.props.match.params.id);
  }

  render()
  {
    if(!this.state.type)
      return <LoadingAnimation/>;
      console.log(this.state)
    return (<Layout name="Campaigns">
        <div className="wizard-container">
          <div className="col-md-12 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <div>
                <Query query={GET_CHALLENGE}
                       fetchPolicy="network-only"
                       variables={{
                         id: this.state.type
                       }}
                >
                  {({loading, error, data}) => {
                    if (loading) return (<LoadingAnimation/>);
                    if (error) return `Error! ${error.message}`;
                    if(!data || data.challenge === null)
                      return <div>No data available</div>;
                    const challenge = JSON.parse(data.challenge);

                    let step = parseInt(this.state.step);
                    const StepCountDownTime = challenge.steps[5].countdownTime;
                    //let items = [];
                    if(challenge.steps[step])
                       //items = challenge.steps[step].data;
                       //console.log(challenge)

                    return (
                      <div>
                        <LineItemsSimulation
                          handleTab={this.handleTab}
                          caption="Line Items"
                          submit={this.submit}
                          name={this.state.name}
                          id={this.state.id}
                          items={this.state.lineItems}
                          lineItemsLength = {this.state.lineItems.length}
                          isSubmitting={this.state.isSubmitting}
                        />
                        <ChallengePanel
                          time={StepCountDownTime}
                          description={challenge.challengeDescription}
                          coach={challenge.coach}
                          challengeTitle={challenge.challengeTitle}
                          challengeIsValidation={challenge.isValidation}
                          challengeDifficulty={challenge.challengeDifficulty}
                          challengeAverageTime={challenge.challengeAverageTime}
                        />
                      </div>
                    );
                  }}
                </Query>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReportAdServerStep5;

