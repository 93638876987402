import React, { useEffect, useState } from "react";
import Layout from "../../common/Layout";
import PropTypes from "prop-types";
import AddItemButton from "../../common/components/base/AddItemButton";
import ItemsGrid from "../../common/components/widgets/ItemsGrid";
import Swal from 'sweetalert2';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { useMutation, useQuery } from "@apollo/client";
import { client } from "../../settings/apollo";
import { getBannerDefinitions, GET_BANNERS_DEFINITIONS } from "./graphql/queries";
import { DELETE_BANNER } from "./graphql/mutations";

const columns = ["title", "sections",  "action"];
const columnsName = ["title", "sections" ,"action" ]

const BannersList = (props) => {
  BannersList.propTypes = {
    page: PropTypes.number,
    name: PropTypes.string
  }
  const [banners, setBanners] = useState([]);
  const [deleteBanner] = useMutation(DELETE_BANNER);
  const { loading, error, data } = useQuery(GET_BANNERS_DEFINITIONS);

  useEffect(() => {
    getBannerDefinitions(client).then(async (value) => {
      setBanners(value.bannerDefinitions);
    }).catch((e)=> console.log(e))
  }, [data]);

  const removeBanner = (id) => {
    const index = banners.findIndex((item) => {
      return (item._id === id);
    });
    const clonedBanners=[...banners];
    clonedBanners.splice(index,1);
    setBanners(clonedBanners)
  }

   const remove = (item) => {
    Swal.fire({
      title: 'Are you sure you want to delete this banner?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.value===true) {
        deleteBanner({ variables: { id: item._id } })
        .then((data)=> {
          if (data.data.deleteBanner) {
            Swal.fire({
              type: 'success',
              title: 'Banner deleted!',
              showConfirmButton: false,
              timer: 1500
            })
            .then((result)=> { if(result.dismiss==='timer') removeBanner(item._id) })
          }
        },
          (error) => {
            Swal.fire({
            type: 'error',
            title: 'Error deleting banner',
            showConfirmButton: false,
            timer: 1500
            })
            console.log(error);
          }
        )
      }
    })
  }

  const edit = (item) => {
    props.history.push(`/admin-settings/banners/${item._id}`)
  }

  const create = () => {
    props.history.push(`/admin-settings/banners/create`)
  }

  return (
    <Layout name="Banner List">
      <div className="wizard-container">
          <AddItemButton OnClick={()=>create()} />
            { loading && (<LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 15, marginBottom: 30 }} />) }
            { error && (<div>`Error! ${error.message}`</div>) }
            { !banners && (<div>No Banners available</div>) }
            { banners &&
               (<div>
                  <ItemsGrid
                    columns={columns}
                    columnsName={columnsName}
                    remove={(index, value)=>remove(value)}
                    edit={(index, value)=>edit(value)}
                    title={"Banners"}
                    items={banners}
                    pagination={true}
                    />
                </div>
              )}
      </div>
    </Layout>
  );
}

export default BannersList;
