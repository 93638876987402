import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Button, SvgIcon, Box, Typography, Tooltip, Avatar, Link, useMediaQuery } from '@material-ui/core';
import { ReactComponent as iconChallenge } from '../../../../assets/images/career-single/icon-challenge.svg';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DoneIcon from '@material-ui/icons/Done';
import TimerIcon from '@material-ui/icons/Timer';
import { ReactComponent as validationIcon } from '../../../../assets/images/career-single/validation-icon.svg';
import avatarDefault from '../../../../assets/images/avatar/riccardo-guggiola.jpg';
import CountdownTimer from './CountdownTimer';

const useStyles = (careerStatus) => makeStyles((theme) => ({
  paper: {
    borderRadius: 6,
    overflow:'hidden',
  },
  topicTitle: {
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  tableContainer: {
    //maxHeight: 362,
  },
  tableHeader: {
    display:'flex',
    justifyContent:'space-between',
    backgroundColor: careerStatus === 'explore' ? theme.palette.grey[400]: theme.palette.common.orange[400],
    padding: '16px 16px',
    '& h3':{
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0.3,
      textShadow: `1px 1px 3px rgba(0,0,0, 0.5)`,
    },
    '& div':{
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0.3,
      textShadow: `1px 1px 3px rgba(0,0,0, 0.5)`,
      textTransform:'capitalize'
    },
    '& .MuiSvgIcon-root': {
      marginRight:3,
      filter: 'drop-shadow(1px 1px 3px rgba(0,0,0, 0.3))',
    }
  },
  tableCell: {
    color: careerStatus === 'explore' ? theme.palette.grey[400]: null,
    overflow:'hidden'
  },
  tableBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    textTransform: 'none',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon': {
      margin:0,
      marginRight:3,
    },
  },
  tableBtnDisabled: {
      backgroundColor: theme.palette.common.gamsGrey[300],
      color: theme.palette.common.gamsDarkBlue,
      lineHeight:1.2,
      textTransform: 'none',
      borderRadius: 15,
  },
  tablePagination: {
    '& .MuiButtonBase-root':{
      outline: 'none',
    }
  },
  tooltip: {
    padding:theme.spacing(1,1.5),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    fontSize: theme.typography.pxToRem(13),
    lineHeight:1.5,
    border: `solid 2px ${theme.palette.warning.light}`
  },
  rowTitle: {
    minWidth:180,
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0
    },
  },
  avatar: {
    width:48,
    height:48,
    marginRight:7,
    border:`solid 1px ${theme.palette.grey[400]}`,
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    }
  },
  challengeTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      [theme.breakpoints.up('lg')]: {
        maxWidth: 200,
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 200,
      },
    }
  },
  coachName: {
    marginRight:6,
    '& a': {
      textDecoration:'underline',
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
      }
    }
  },
}));
  
const CareerTopicBlock = ({ topicChallenges, topicLevel, topicTitle, challengeAction, careerStatus, onHideViewDialogOpen }) => {
  const classes = useStyles(careerStatus)();
  const theme = useTheme();
  const isMobile = setIsMobile;
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const createData = (chId, chTitle, chDifficulty, chStatus, chIsValidation, chCanRestart, chAverageTime, chCoachAvatar, chCoachName, chCoachSurname, chCoachLink, chTimeToRestart) => {
    return { chId, chTitle, chDifficulty, chStatus, chIsValidation, chCanRestart, chAverageTime, chCoachAvatar, chCoachName, chCoachSurname, chCoachLink, chTimeToRestart };
  }

  const setIsMobile = () => {
    const userAgent = window.navigator.userAgent;
    if (/mobile|tablet/i.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  const rows = topicChallenges.map((challenge) => {
    if(challenge.coach) return createData(challenge.id, challenge.challengeTitle, challenge.challengeDifficulty, challenge.status, challenge.isValidation, challenge.canRestart, challenge.averageTime, challenge.coach.photo, challenge.coach.name, challenge.coach.surname, challenge.coach.linkedin, challenge.timeToRestart)
    return createData(challenge.id, challenge.challengeTitle, challenge.challengeDifficulty, challenge.status, challenge.isValidation, challenge.canRestart, challenge.averageTime, null, 'coach', '', '', challenge.timeToRestart)
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.tableHeader}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <AssignmentIcon/>
          <Typography variant="h3" className={classes.topicTitle}>{topicTitle}</Typography>
        </Box>
        <Typography variant="body2" component="div">{topicLevel}</Typography>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Challenge title</TableCell>
              <TableCell align="right" style={{ width: 80, paddingLeft: 0 }}>Difficulty</TableCell>
              <TableCell align="right" style={{ width: 90 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const hideChallenge = row.chIsValidation === true && isMobile;

              let startButton = null;
              const restartMsg = 'Pay attention! If you start the challenge again the result in the Profile ScoreCard will change to positive or negative.';
              const inProgressMsg = 'Did you close by accident the Challenge? Try to refresh the page and wait for a couple of minutes or please contact us.';
              
              if (careerStatus === 'explore') {
                startButton = <div style={{ width:28, height:28 }}></div>;
              } else {
                if (row.chStatus === 'in progress') {
                  if (row.chIsValidation === false) {
                    startButton =
                  <Button
                    className={classes.tableBtn}
                    startIcon={<SvgIcon className={classes.tableBtnIcon} component={iconChallenge} viewBox="0 0 128 128" style={{ fontSize:16 }}/>}
                    onClick={() => { hideChallenge ? onHideViewDialogOpen() : challengeAction(row.chId,true);
                    }}
                    
                  >Start
                  </Button>;
                  } else {
                  startButton =
                  <Tooltip
                    title={inProgressMsg}
                    classes={{
                      tooltip: classes.tooltip
                    }}>
                    <Button
                      className={classes.tableBtnDisabled}
                    >in progress
                    </Button>
                  </Tooltip>
                  }
                } else if (
                  (row.chStatus === 'finished' && row.chCanRestart === false) ||
                  (row.chIsValidation === true && row.chStatus === 'in progress'))
                {
                  if (row.chStatus === 'finished' && row.chCanRestart === false) {
                    startButton = row.chTimeToRestart &&
                      <CountdownTimer
                        startDate={row.chTimeToRestart}
                        titleToolTip={"Time left to retry the Challenge"}
                        textExpiredTime={"Time's up!"}
                        />;
                  } else {
                    startButton = <div style={{ width:28, height:28 }}></div>;
                  }
                } else if (
                  (row.chStatus === 'finished' && row.chCanRestart === true) ||
                  (row.chIsValidation === false && row.chStatus !== 'not started'))
                {
                  startButton =
                  <Tooltip
                    title={row.chIsValidation === true ? restartMsg : ""}
                    classes={{
                      tooltip: classes.tooltip
                    }}>
                    <Button
                      className={classes.tableBtn}
                      startIcon={<SvgIcon className={classes.tableBtnIcon} component={iconChallenge} viewBox="0 0 128 128" style={{ fontSize:16 }}/>}
                      onClick={() => { challengeAction(row.chId, row.chCanRestart);
                      }}
                      style={{ backgroundColor: '#03506B' }}
                    >Restart
                    </Button>
                  </Tooltip>;
                } else {
                  startButton =
                  <Button
                    className={classes.tableBtn}
                    startIcon={<SvgIcon className={classes.tableBtnIcon} component={iconChallenge} viewBox="0 0 128 128" style={{ fontSize:16 }}/>}
                    onClick={() => { challengeAction(row.chId)
                    }}
                  >Start
                  </Button>;
                }
              }

              return (
                <TableRow key={row.chTitle}>
                  <TableCell className={classes.tableCell} style={{ paddingRight: 0 }} component="th" scope="row">
                    <Box
                      alignItems='center'
                      display='flex'
                    >
                      <Avatar
                        src={row.chCoachAvatar ? row.chCoachAvatar : avatarDefault}
                        className={classes.avatar}
                      />
                      <Box className={classes.rowTitle}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <div className={classes.challengeTitle}>
                            <span>{row.chTitle}</span>
                          </div>
                          { row.chIsValidation === true ?
                            <Tooltip title="This challenge is valid for the Profile Scorecard!" enterTouchDelay={0} arrow>
                              <SvgIcon className={classes.tabLabelIcon} component={validationIcon} viewBox="0 0 24 24" style={{ fontSize: 24, marginLeft:2 }}/>
                            </Tooltip>
                          : null }
                          { row.chStatus === 'finished' ? <DoneIcon style={{ color: '#03506B', marginRight:2 }}/> : null }
                        </Box>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <div className={classes.coachName}>
                            By: <Link
                              href={row.chCoachLink ? row.chCoachLink : 'https://it.linkedin.com/in/riccardoguggiola'}
                              rel="noopener noreferrer"
                              target="_blank">
                            {row.chCoachName ? `${row.chCoachName} ${row.chCoachSurname}` : 'Riccardo Guggiola'}</Link>
                          </div>
                          {true && (
                            <> •<TimerIcon style={{ fontSize: 16, marginRight: 4, marginLeft: 4, color:'#9e9e9e' }} /> {row.chAverageTime}</>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right" style={{ textTransform: "capitalize", paddingLeft: 0 }}>
                    {row.chDifficulty}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right" style={{ paddingLeft: 2 }}>
                    {startButton}
                  </TableCell>
                </TableRow>
              )}
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 81 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from, to, count }) => (`Showing ${from} to ${to} of ${count !== -1 ? count : null} Challenges`)}
      />
    </Paper>
  );
}

export default CareerTopicBlock ;
