import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DataTableToolbar} from "./DataTableToolbar";
import {DataTableFooter} from "./DataTableFooter";
import Table from '@material-ui/core/Table';
import DataTableHeader from "./DataTableHeader";
import DataTableBody from "./DataTableBody";
import moment from 'moment';

class DataTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    records: PropTypes.object,
    keyColumn: PropTypes.string.isRequired,
    editUrl: PropTypes.string || PropTypes.object,
    page: PropTypes.number
  };

  constructor(props) {
    super(props);

    if (props.columns && props.records) {

      this.handleDeleteClick = this.handleDeleteClick.bind(this);
      this.handleSort = this.handleSort.bind(this);
      this.sortList = this.sortList.bind(this);
      this.formatRecords = this.formatRecords.bind(this);
      this.handleCustomActionClick = props.handleCustomActionClick.bind(this);
      this.highlightRecord = props.highlightRecord.bind(this);
      this.status = props.status.bind(this);

      this.state = {
        ...props,
        records: (props.records.records!==undefined ? this.formatRecords(props.records.records) : []),
        count: props.records.totalCount,
        pages: props.records.pages,
        order: 'asc',
        orderBy: 'name',
        selected: (props.selected!==undefined ? props.selected : [])
      };
    }
  }

  isTimestamp(value){
    return (value!==null && !isNaN(value) && value.length===13)
  }
  formatRecords(records){
     return records.map((item) => {
       let result = {};
       Object.assign(result,item);
       Object.keys(item).forEach(key => {


         if(this.isTimestamp(item[key]) )
            result[key] = moment(parseInt(item[key])).format('YYYY-MM-DD');
         }
        )
        return result;
      })
  }
  filterSort = (a, b, orderBy) => {
    if ((a[orderBy] ? a[orderBy].toUpperCase() : "") < (b[orderBy] ? b[orderBy].toUpperCase() : ""))
      return -1;
    else return 1;
    //return 0;
  }

  sortList = (orderBy) => {
    let sortedData = [...this.state.records];
    sortedData.sort((a, b) => {
      return this.state.order === 'asc' ? this.filterSort(a, b, orderBy) : -this.filterSort(a, b, orderBy);
    });
    this.setState({records: this.formatRecords(sortedData)})
  }

  handleSort = (event) => {
    let orderBy = event.currentTarget.textContent;
    this.setState({order: this.state.order === 'desc' ? 'asc' : 'desc'}, () => {
      this.sortList(orderBy)
    });
    this.setState({orderBy});
  }

  handleDeleteClick(id, e) {
    e.preventDefault();
    if (window.confirm('Are you sure you wish to delete this item?')) {
      this.state.deleteRow({
        event: e,
        id: id
      });
    }
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      const allIndexes = this.state.records.map((item,index) => index);
      this.setState(state => ({ selected: allIndexes }));
      this.props.onSelectedIndex(allIndexes, this.state.records)
      return;
    }
    this.setState({ selected: [] });
    this.props.onSelectedIndex([], this.state.records)
  };

  handleSelectClick = (event, index, record) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(index)
    let newSelected = [];


      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, index);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }


    this.setState({ selected: newSelected });
    this.props.onSelectedIndex(newSelected, this.state.records)
  };

  render() {
    return (
      <div className="card">
        <div className="card-header card-header-icon card-header-primary">
          <DataTableToolbar header={this.state.header}/>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <Table
                id="datatables"
                className="table table-striped table-no-bordered table-hover dataTable dtr-inline"
                role="grid"
                aria-describedby="datatables_info"
              >
                <DataTableHeader
                  header={this.state.header}
                  columnsToHide={this.state.columnsToHide}
                  columns={this.state.columns}
                  handleSort={this.handleSort}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  numSelected={this.state.selected ? this.state.selected.length : null}
                  rowCount={this.state.records.length}
                  checkboxes={this.state.checkboxes}
                />
                <DataTableBody
                  header={this.state.header}
                  records={this.state.records}
                  columnsToHide={this.state.columnsToHide}
                  columns={this.state.columns}
                  keyColumn={this.state.keyColumn}
                  editUrl={this.state.editUrl}
                  showDeleteIcon={this.state.showDeleteIcon}
                  customDeleteIcon={this.state.customDeleteIcon}
                  showEditIcon={this.state.showEditIcon}
                  handleDeleteClick={this.handleDeleteClick}
                  handleEditClick={this.state.handleEditClick}
                  onSelectClick={this.handleSelectClick}
                  selected={this.state.selected}
                  handleCustomActionClick={this.handleCustomActionClick}
                  showCustomAction={this.state.hasCustomAction}
                  checkboxes={this.state.checkboxes}
                  highlightRecord={this.highlightRecord}
                  status={this.status}
                />
              </Table>
              <DataTableFooter
                count={this.state.count}
                next={this.state.next}
                first={this.state.first}
                last={this.state.last}
                page={this.state.page}
                pages={this.state.pages}
                previous={this.state.previous}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataTable.defaultProps = {
  highlightRecord: (record) => {return false},
  handleCustomActionClick: (record) => {return null},
  status: (record) => {return null},
  selected: []

}

export default DataTable;
