import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, Box, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  listBox: {
    maxWidth: 600,
    margin: '0 auto',
    marginBottom:30
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginBottom:6
  },
  listRoot: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding:0,
    borderRadius: 6,
    '& li::after': {
      content:'""',
      display:'block',
      width:'95%',
      margin:'0 auto',
      borderBottom: `solid 1px ${theme.palette.grey[300]}`
    },
    '& li:last-child::after': {
      display:'none',
    }
  },
  listItem: {
    paddingTop:12,
    paddingBottom:11,
    whiteSpace: 'pre-wrap', 
    overflowWrap: 'break-word',
  },
  listItemIcon: {
    display:'flex',
    minWidth:'auto',
    '& .done-icon': {
      color: theme.palette.success.main,
    },
    '& .close-icon': {
      color: theme.palette.error.main,
    }
  }
}));

const ResultTextAnswers = ({ answers }) => {
  const classes = useStyles();
  let isFalse = false;

  return (
    <>
      <Box className={classes.listBox}>
        <Typography className={classes.listTitle} component="h3">Your answers</Typography>
        <List className={classes.listRoot}>
          {answers.map((v, index) => {
            if(v.isCorrect === false) {
              isFalse = true;
            }
            if(v.errorType!=='missed') 
              return (<ListItem className={classes.listItem} key={`answer-text-item-${index}`}>
              <ListItemText
                primary={v.value}
                style={v.isCorrect === true ? { borderBottom: 'none' } : null}
              />
              <ListItemSecondaryAction>
                <ListItemIcon className={classes.listItemIcon}>
                  { v.isCorrect ?
                    <DoneIcon className={'done-icon'} />
                  :
                    <CloseIcon className={'close-icon'} />
                  }
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>
          )
          return null;
          })}
        </List>
      </Box>

      { isFalse && (
        <Box className={classes.listBox}>
          <Typography className={classes.listTitle} component="h3">Correct answers</Typography>
          <List className={classes.listRoot}>
            {answers.map((a, index) => a.correctAnswer !== null && (
              <ListItem className={classes.listItem} key={`answer-text-item-${index}`}>
                <ListItemText
                  primary={a.correctAnswer}
                  style={a.isCorrect === true ? { borderBottom: 'none' } : null}
                />
                <ListItemSecondaryAction>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </>
  );
};

export default ResultTextAnswers;
