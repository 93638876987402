import { SecureQuery } from "./graphqlHelper";
import { EVALUATE_STEP } from "../components/challenges/AdServer/queries";

export const isObject = value => {
  return value && typeof value === "object" && value.constructor === Object;
};

export const evaluateSecondStep = async (action, id) => {
  let res = await SecureQuery.asyncMutation({
    mutation: EVALUATE_STEP,
    variables: { id: id, action: action }
  });
  return res;
};

export const evaluateThirdStep = async id => {
  let evaluateSecondStep = await SecureQuery.asyncMutation({
    mutation: EVALUATE_STEP,
    variables: {
      id: id,
      action: {
        actionNeeded: "click",
        target: "button",
        parameter: "submit"
      }
    }
  });
  let finalTarget = evaluateSecondStep.data.challengeStepEvaluator;
  return finalTarget;
};
