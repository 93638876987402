export const selectList = [
  {
    title: "Italy",
    value: "Italy"
  },
  {
    title: "Spain",
    value: "Spain"
  },
  {
    title: "France",
    value: "France"
  },
  {
    title: "Germany",
    value: "Germany"
  },
  {
    title: "England",
    value: "England"
  },
  {
    title: "Netherlands",
    value: "Netherlands"
  },
]
