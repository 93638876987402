import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
//import Layout from "./Layout";
import plusButtonStyle from "../settings/otherStyles";
import SimpleSearch from "./components/data-table/SimpleSearch";
import SandboxLayout from "../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../utils/data/sandboxes";

const TableLayout = (props) => {
  const{name, _add, _keyPress} = props;
  const [disabled, setDisabled] = useState(false);

  const actionHandler = e => {
    setDisabled(true);
    _add();
  }

  return (
    <SandboxLayout name={name} challengeMode={true} layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
      {props._add !== undefined ?
        <Fab
          onClick={actionHandler}
          color="primary"
          aria-label="Add"
          className={plusButtonStyle.fab}
          disabled={disabled}
        >
          <AddIcon />
        </Fab>
      : null }
        <div className="row">
          <div className="col-sm-12 col-md-10" />
          <div className="col-sm-12 col-md-2">
            <SimpleSearch keyPress={_keyPress} />
          </div>
        </div>
        {props.children}
      </div>
    </SandboxLayout>
  );
};

export default TableLayout;
