import React, { Component } from "react";
//import Layout from "../../common/Layout";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import DataTable from "../../common/components/data-table/DataTable";
import { client } from "../../settings/apollo";
import AddItemButton from "../../common/components/base/AddItemButton";
import SimpleSearch from "../../common/components/data-table/SimpleSearch";
import {DEFAULT_USER_EMAIL} from "../../common/components/Constants";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const columns = ["name", "size", "type", "actions"];

const DELETE_CREATIVE = gql`
  mutation deleteCreative($input: String) {
    deleteCreative(input: $input)
  }
`;

const GET_CREATIVES = gql`
  query creatives($name: String, $page: Int!) {
    creatives(name: $name, page: $page) {
      records {
        _id
        username
        name
        size
        type
      }
      pages
      totalCount
    }
  }
`;


class Creatives extends Component {
  static propTypes = {
    page: PropTypes.number,
    name: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { page: 0, name: "" };
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._add = this._add.bind(this);
    this._first = this._first.bind(this);
    this._keyPress = this._keyPress.bind(this);
  }

  _previousPage(e) {
    e.preventDefault();
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0 });
  }

  _deleteRow(event) {
    client
      .mutate({ mutation: DELETE_CREATIVE, variables: { input: event.id.id } })
      .catch(error => console.log(error))
      .then(() => this.forceUpdate());
  }

  _add() {
    this.props.history.push("/creative");
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({ name: e.target.value,
                      page: 0 });
    }
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
  }

  _showEditIcon(record){
    return (record.username !== DEFAULT_USER_EMAIL);
  }
  _showDeleteIcon(record){
    return (record.username !== DEFAULT_USER_EMAIL);
  }

  render() {
    return (
      <SandboxLayout name="Creatives" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <AddItemButton OnClick={this._add} />
          <div className="row">
            <div className="col-sm-12 col-md-10" />
            <div className="col-sm-12 col-md-2">
              <SimpleSearch keyPress={this._keyPress} />
            </div>
          </div>
          <Query
            query={GET_CREATIVES}
            fetchPolicy="network-only"
            variables={{
              page: this.state.page,
              name: this.state.name,
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 30 }} />;
              if (error) return `Error! ${error.message}`;

              if (!data || data.creatives.records===undefined || data.creatives.records.length === 0)
                return (
                  <DataTable
                  header="Creatives"
                  columns={columns}
                  records={{}}>

                </DataTable>
                )
              else
                return (
                  <div>
                    <DataTable
                      header="Creatives"
                      editUrl="/creative/update/"
                      columns={columns}
                      records={data.creatives}
                      deleteRow={this._deleteRow}
                      keyColumn="_id"
                      page={this.state.page}
                      next={this._nextPage}
                      first={this._first}
                      last={this._last.bind(this, data.creatives.pages)}
                      previous={this._previousPage}
                      columnsToHide={["username"]}
                      showEditIcon={this._showEditIcon}
                      showDeleteIcon={this._showDeleteIcon}
                    />
                  </div>
                );
            }}
          </Query>
        </div>
      </SandboxLayout>
    );
  }
}

export default Creatives;
