import gql from "graphql-tag";

export const GET_USER_CHALLENGES = gql`
  query me {
    me {
      courseGroups {
        _id
        name
        groupChallenges
      }
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  mutation createChallenge($input: ChallengeInput) {
    createChallenge(input: $input) {
      _id
    }
  }
`;

export const GET_CHALLENGE = gql`
  query challenge($id: Int){
    challenge(id: $id)
    }
`;

export const GET_CHALLENGE_DEFINITIONS = gql`
  query challengeDefinitions{
    challengeDefinitions {
      id
      challengeName
      challengeTitle
      challengeDifficulty
    }
    }
`;

export const GET_CHALLENGE_DEFINITIONS_ASSIGNED = gql`
  query challengeDefinitionsAssigned{
    challengeDefinitionsAssigned {
      id
      challengeName
      challengeTitle
      challengeDifficulty
    }
    }
`;

export async function getChallengeDefinitions(client) {
  try {
    let data = await client.query({
      query: GET_CHALLENGE_DEFINITIONS,
    });
    let res = data.data.challengeDefinitions;
    return (res);
  }catch (e) {
    console.log(e);
  }
}

export async function getChallenge(id, client) {
  try {
    let res = await client.query({
      query: GET_CHALLENGE,
      variables: {id}
    });
    return JSON.parse(res.data.challenge);
  }catch (e) {
    console.log(e);
  }
}