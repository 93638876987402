import React from 'react';
import SimpleDropDown from '../../common/components/base/SimpleDropDown';
import { ErrorMessage } from 'formik';
import { movingTab, width100 } from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";

class AdvertiserForm extends React.Component {
  requiredText = 'Advertiser Name (required)';
  countClick = 0;

  constructor(props) {
    super(props);

    if (props.update) {
      this.requiredText = '';
      this.countClick = 0;
    }
    this.state = props;
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
  }

  handleClickSubmit(values){
    this.countClick++
    if (this.countClick === 1) {
      this.props.handleSubmit(values);
    }
    setTimeout(()=> (this.countClick = 0), 1000);
  }

  render() {
    const { values } = this.props;

    return (
      <>
        <div className="card-header text-center">
          <h3 className="card-title">
            Advertiser
          </h3>
        </div>
        <div className="wizard-navigation">
          <div className="moving-tab" style={movingTab}>
            Info
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="input-group form-control-lg">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">face</i>
                </span>
              </div>
              <div className="form-group bmd-form-group">
                <TextField
                  required
                  label={this.requiredText}
                  name="name"
                  value={values.name}
                  onChange={this.state.handleChange}
                  margin="normal"
                  style={width100}
                />
                <ErrorMessage name="name" component="div" />
              </div>
              <div className="input-group form-control-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="material-icons">record_voice_over</i>
                  </span>
                </div>
                <div className="form-group bmd-form-group">
                  <TextField
                    required
                    label=" Default Landing URL (required) "
                    onChange={this.state.handleChange}
                    name="defaultLandingUrl"
                    value={values.defaultLandingUrl}
                    margin="normal"
                    style={width100}
                  />
                  <ErrorMessage name="defaultLandingUrl" component="div" />
                </div>
                <div className="col-md-8 ml-auto mr-auto">
                  <div className="form-group bmd-form-group ml-auto mr-auto">
                    <SimpleDropDown
                      handleChange={(event) => this.props.setFieldValue("category", event.currentTarget.textContent)}
                      id="category" name="category"
                      items={['Internet', 'Media', 'Beauty', 'Fashion', 'Other']}
                      caption={this.state.caption} header='Select Type' />
                  </div>
                </div>
              </div>
              <div className="form-group bmd-form-group">
                <button
                  type="button"
                  onClick={this.handleClickSubmit}
                  disabled={ this.countClick >= 1}
                  className="btn btn-next btn-fill btn-rose btn-wd"
                  name="save"
                  value="Save"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdvertiserForm;
