import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Card, CardHeader, CardContent, CardActions, Typography, Button, Divider, Box, Grid, Hidden } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import blue from '@material-ui/core/colors/blue';
import QuestionType from './QuestionType/QuestionType';
import MediaType from './MediaType/MediaType';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChallengeInfo from './ChallengeInfo/ChallengeInfo';
import ChallengeHint from './ChallengeHint/ChallengeHint';
import StepProgressBar from './StepProgressBar/StepProgressBar';
import Carousel from '../Carousel/Carousel';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const styles = theme => ({
  paper: {
    minHeight: 620,
    height: '100%',
    borderRadius: 6,
    position: 'relative'
  },
  card: {
    boxShadow: 'none',
    background: 'transparent',
    borderRadius: 0,
    height: '100%',
    paddingBottom: 100,
    [theme.breakpoints.down(1500)]: {
      paddingBottom: 188
    }
  },
  cardHeader: {
    padding: theme.spacing(3),
    '& > div > h2': {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  cardContent: {
    padding: theme.spacing(5, 3, 3, 3)
  },
  cardAction: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(2.4, 3)
  },
  buttonContained: {
    backgroundColor: blue[900],
    '&:hover': {
      backgroundColor: blue[800]
    }
  },
  question: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.62,
    letterSpacing: 0.2,
    '& ul': {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0, 0, 0, 4)
    },
    '& li': {
      marginBottom: theme.spacing(1.5)
    },
    '& a': {
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
        textDecoration: 'underline'
      }
    }
  },
  videoTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.62,
    letterSpacing: 0.2,
    marginBottom: 20
  },
  innerBox: {
    maxWidth: 600,
    margin: '0 auto'
  },
  innerBoxCarousel: {
    maxWidth: 'auto',
    margin: '0 auto'
  },
  gridContainer: {
    flexGrow: 1
  }
});

const ChallengeBlock = props => {
  const {
    challenge,
    history,
    classes,
    stepIndex,
    hasMedia,
    onChanged,
    onSkipStep,
    hasCarousel,
    isSubmitting
  } = props;
  const matchesMediaQuery = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const step = challenge.steps;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(isSubmitting);
  }, [isSubmitting]);
  const currentStep = step[stepIndex];
  const currentQuestionField = currentStep.questionField;
  const currentVideoTitle = currentStep.videoTitle;
  return (
    <Paper className={classes.paper}>
      <Card className={classes.card}>
        <StepProgressBar steps={step} stepIndex={stepIndex} />

        <CardHeader
          title={`Step ${stepIndex + 1}`}
          titleTypographyProps={{ variant: 'h2', component: 'h2' }}
          classes={{ root: classes.cardHeader }}
        />
        <Divider component='div' />

        {disabled ? (
          <CardContent className={classes.cardContent}>
            <LoadingSpinner />
          </CardContent>
        ) : (
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3} className={classes.gridContainer}>
              {hasMedia ? (
                <Hidden only={['xs', 'sm', 'md']}>
                  <Grid
                    item
                    xs={currentQuestionField ? 6 : 12}
                    style={
                      currentQuestionField
                        ? null
                        : { marginBottom: -14 }
                    }
                  >
                    {currentVideoTitle ? (
                      <Box className={classes.innerBox}>
                        <Typography
                          component='h3'
                          variant='body1'
                          color='inherit'
                          className={classes.videoTitle}
                        >
                          {currentVideoTitle}
                        </Typography>
                      </Box>
                    ) : null}
                    <Box className={classes.innerBox}>
                      <MediaType
                        currentStep={currentStep}
                        stepIndex={stepIndex}
                      />
                    </Box>
                  </Grid>
                </Hidden>
              ) : null}

              <Grid
                item
                xs={
                  hasMedia && matchesMediaQuery && currentQuestionField
                    ? 6
                    : 12
                }
              >
                {currentQuestionField ? (
                <Box className={classes.innerBox}>
                  <Typography
                    component='div'
                    variant='body1'
                    color='inherit'
                    className={classes.question}
                  >
                    {parse(currentQuestionField)}
                  </Typography>
                </Box>
                ) : null}

                {hasMedia ? (
                  <Hidden only={['lg', 'xl', 'xxl']}>
                    <Box className={classes.innerBox}>
                      {currentVideoTitle ? (
                        <Box className={classes.innerBox}>
                          <Typography
                            component='h3'
                            variant='body1'
                            color='inherit'
                            className={classes.videoTitle}
                          >
                            {currentVideoTitle}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box mt={2}>
                        <MediaType
                          currentStep={step[stepIndex]}
                          stepIndex={stepIndex}
                        />
                      </Box>
                    </Box>
                  </Hidden>
                ) : null}

                {hasCarousel ? (
                  <Box className={classes.innerBoxCarousel}>
                    <Carousel carouselSlides={step[stepIndex].imageCarousel} />
                  </Box>
                ) : null}

                <Box className={classes.innerBox}>
                  <QuestionType
                    currentStep={step[stepIndex]}
                    onChanged={onChanged}
                    stepIndex={stepIndex}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        )}

        <CardActions className={classes.cardAction}>
          <Box flexGrow={1}>
            <ChallengeInfo
              skip={onSkipStep}
              history={history}
              step={stepIndex}
              time={step[stepIndex].countdownTime}
              description={challenge.challengeDescription}
              hasMedia={hasMedia}
              coach={challenge.coach}
              challengeTitle={challenge.challengeTitle}
              challengeIsValidation={challenge.isValidation}
              challengeDifficulty={challenge.challengeDifficulty}
              challengeAverageTime={challenge.averageTime}
            />
            {step[stepIndex].HintTitle ? (
              <ChallengeHint currentStep={step[stepIndex]} />
            ) : null}
          </Box>
          <Box>
            <Button
              variant='contained'
              color='primary'
              className={classes.buttonContained}
              size='large'
              endIcon={<ArrowForwardIosIcon />}
              disabled={disabled}
              onClick={() => {
                props.onSubmit(step.length);
                if (step.length === stepIndex + 1) {
                  setDisabled(true);
                }
              }}
            >
              {step.length > stepIndex + 1 ? 'Next Step' : 'Finish'}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(ChallengeBlock);
