import React, { useEffect, useState} from 'react';
import './App.css';
import {withRouter} from 'react-router';
import HttpsRedirect from 'react-https-redirect';
import { ApolloProvider } from '@apollo/client';
import {client} from "./settings/apollo";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { env } from './env';

const stripePromise = loadStripe(env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = (props) => {
  const [loading, setLoading] = useState(true);

  // Specific styles added for the Workflow Tree in Automation feature, look on /crm/automation/:id/edit
  const currentRoute = props.location.pathname;
  const isWorkflowPage = currentRoute.includes('crm/automation') && currentRoute.includes('edit');
  const isSimulationPage = currentRoute.includes('crm/automation') && currentRoute.includes('simulation');
  if (isWorkflowPage || isSimulationPage) {
    document.querySelector('body').style.height = '100%';
    document.querySelector('html').style.height = '100%';
    document.getElementById('root').style.height = '100%';
  } else {
    document.querySelector('body').style.height = '';
    document.querySelector('html').style.height = '';
    document.getElementById('root').style.height = '';
  }

  useEffect(() => {
    const el = document.querySelector("#preloader");
    if (el) {
      el.remove();  // removing the spinner element
      setLoading(!loading); // showing the app
    }
  }, [loading]);

  if (loading) {
    return null; //app is not ready
  }

  return (
    <ApolloProvider client={client}>
      <Elements stripe={stripePromise}>
        <HttpsRedirect>{props.children}</HttpsRedirect>
      </Elements>
    </ApolloProvider>
  );
};

export default withRouter(App);
