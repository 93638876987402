import gql from "graphql-tag";

export const queryCreatives = gql`
  query {
      creatives{
          creative
          url
          adsName
          status
      }
  }
`;

export const queryLineItems = gql`
  query {
      lineItems @client{
        name
        type
        delivery
        placement
        schedule
        budgetPlanned
        budgetDelivered
        impressionsPlanned
        impressionsDelivered
        cpm
        tag
        clicks
        conversions
        recency
        frequencyCAP
        status
        creatives{
            creative
              url
              adsName
              status
          }
      }
  }
`;


export const queryCampaign = gql`
  query {
      Campaign{
        _id
        name
        advertiser
        duration
        lineItems{
          name
          creatives{
              adsName
          }
         }
       }
  }
`;

export const queryLineItemFrequency = gql`
  query {
      changeFrequency @client
  }
`;

export const queryLineItemFrequencyCAP = gql`
  query {
      frequencyCAP @client
  }
`;

export const queryLineItemPlacement = gql`
  query {
      placement @client
  }
`;

export const queryLineItemSchedule = gql`
  query {
      schedule @client
  }
`;

export const queryLineItemDelivery = gql`
  query {
      delivery @client
  }
`;

export const queryLineItemRecency = gql`
  query {
      recency @client
  }
`;

export const queryLineItemType = gql`
  query getType{
      type @client
  }
`;

export const queryLineItemTag = gql`
  query {
      tag @client
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($input:CampaignUpdateInput){
    updateCampaign(input:$input)
  }
`;

export const UPDATE_CAMPAIGN_LINE_ITEMS = gql`
  mutation updateCampaignLineItems($id: String $input:[LineItemInput]){
    updateCampaignLineItems(id:$id, input:$input)
  }
`;


export const GET_CAMPAIGN = gql`
  query campaign($id: String!){
    campaign(id: $id){
        _id
        name
        advertiser
        duration
        lineItems{
          name
          delivery
          placement
          schedule
          budgetPlanned
          budgetDelivered
          impressionsPlanned
          impressionsDelivered
          cpm
          tag
          clicks
          conversions
          recency
          status
          type
          creatives{
              adsName
              creative
              url
              status
          }
        }
  }
}
`;

export const ADD_CAMPAIGN = gql`
  mutation createCampaign($input:CampaignCreateInput){
    createCampaign(input:$input){
      _id
    }
  }
`;

export const GET_ADVERTISERS = gql`
    query{
        advertisersProjected{
            _id
            name
            username
        }
    }
`;

export const GET_CREATIVES = gql`
    query{
        creativesProjected{
            _id
            name
            username
        }
    }
`;

export const GET_CREATIVES_SIMULATION = gql`
    query{
        creativesSimulation{
            _id
            name
        }
    }
`;

export const GET_CAMPAIGNS = gql`
  query campaigns($name: String, $page: Int){
    campaigns(name: $name, page: $page){
      records{
        _id
      	name
        advertiser
        duration
      }
      pages
      totalCount
    }
  }
`;

export const DELETE_CAMPAIGN = gql`
      mutation deleteCampaign($input:String){
        deleteCampaign(input:$input)
      }
  `;
