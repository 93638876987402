import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, CardActions, Box, Typography, Button, Tooltip } from '@material-ui/core';
import CircularProgressWithLabel from '../../../common/components/base/CircularProgressWithLabel/CircularProgressWithLabel';
import thumbnailPlaceholder from '../../../assets/images/career-list/career-thumbnail-placeholder.jpg';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    minHeight: 549,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      minHeight: 574
    },
  },
  cardMedia: {
    height: 190,
  },
  circularProgress: {
    position: 'absolute',
    top: -92,
    right: theme.spacing(3),
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(3, 3, 0, 3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    },
  },
  cardActions: {
    padding: theme.spacing(0, 3, 3, 3),
    marginTop: 'auto'
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 13,
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  cardDescription: {
    marginBottom: 16,
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  toolBox: {
    marginBottom: theme.spacing(3),
    maxHeight: 109,
    overflow: 'hidden',
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },
  toolBoxItem: {
    margin: theme.spacing(1),
  },
  toolIcon: {
    width: 40,
    height: 40,
    padding: 4,
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  cardBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const CareerBlock = (career) => {
  const classes = useStyles();
  const { name, status, totalFinishedChallenges, totalChallenges, description, toolsUsed, slug, thumbnail, isComingSoon } = career.career;
  const thumbnailImage = thumbnail.data.attributes.url;

  let btnText = null;
  let btnStatus = false;
  if (status === 'not started') {
    btnText = 'Start';
  } else if (status === 'in progress') {
    btnText = 'Continue';
  } else if (status === 'finished') {
    btnText = 'Continue';
  } else if (status === 'explore' && isComingSoon !== true) {
    btnText = 'Explore';
  } else if (status === 'coming soon' || isComingSoon === true) {
    btnText = 'Coming soon';
    btnStatus = true;
  }

  const progressBar = (totalFinishedChallenges / totalChallenges) * 100;

  return (
    <Card className={classes.cardRoot}>
      <CardMedia
        component="img"
        alt={name}
        height="190"
        image={thumbnailImage ? thumbnailImage : thumbnailPlaceholder}
        title={name}
        className={classes.cardMedia}
      />
      <CardContent className={classes.cardContent}>
        {status === 'coming soon' || isComingSoon === true ? null :
          <Box className={classes.circularProgress}>
            <CircularProgressWithLabel value={progressBar} />
          </Box>
        }
        <Typography className={classes.cardTitle} gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Typography className={classes.cardDescription} variant="body1" component="p">
          {description}
        </Typography>
        <Box
          className={classes.toolBox}
          display="flex"
          justifyContent="flex-start"
          flexWrap="wrap">
          {toolsUsed?.map((tool, index) => {
            const image = tool.icon.data.attributes.url;
            return (
              <Box
                key={`tool-${index}`}
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.toolBoxItem}>
                <div className={classes.toolIcon}>
                  <Tooltip title={tool.title} enterTouchDelay={0} arrow>
                    <img src={image} alt={tool.title} />
                  </Tooltip>
                </div>
              </Box>
            );
          })}
        </Box>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          component={RouterLink}
          to={`/career-paths/${slug}`}
          className={classes.cardBtn}
          fullWidth
          color="default"
          variant="contained"
          size="large"
          disabled={btnStatus}>
          {btnText}
        </Button>
      </CardActions>
    </Card>
  )
}

export default CareerBlock;
