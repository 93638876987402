import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ImageModule = ({ imgSrc, imgAlt, ...props}) => {

  return (
    <Zoom wrapStyle={{ width: '100%' }}>
      <img
        style={{ width: '100%' }}
        src={imgSrc}
        alt={imgAlt}
      />
    </Zoom>
  );
}

export default ImageModule;
