import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { Paper, Grid, Button, TextField, MenuItem, Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ConditionalField from './ConditionalField/ConditionalField';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  formRoot: {
    margin:'0 auto',
    borderRadius: 6,
    padding: theme.spacing(3),
  },
  formBlock: {
    marginBottom:0
  },
  gridRoot: {
    '& .MuiGrid-item': {
      marginBottom:40,
      [theme.breakpoints.down('xs')]: {
        marginBottom:30,
      },
    },
    '& .MuiGrid-item:last-child': {
      marginBottom:0
    }
  },
  formField: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  selectControl: {
    minWidth: 150,
    marginRight:10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.common.gamsDarkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsDarkBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
    '&:focus':{
      backgroundColor:'white'
    }
  },
  conditionBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `solid 1px ${theme.palette.grey[300]}`,
    borderRadius: 8,
    padding: theme.spacing(2.3, 2),
    marginBottom: 15
  },
  conditionBlockLeft: {},
  conditionBlockRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.grey[500]
    }
  },
  iconBtn: {
    color: theme.palette.grey[800],
    margin: theme.spacing(0),
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {},
    '& svg': {
      width: 24,
      height: 24,
    }
  },
  addConditionBtn: {
    fontSize: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.gamsPetroleumBlue,
    textTransform:'none',
    '&:hover': {},
    '&:focus': {
      outline: 'none',
    }
  },
  submitBtn: {
    fontSize: theme.typography.pxToRem(18),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    paddingTop:11.5,
    paddingBottom:11.5,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textTransform:'none',
    [theme.breakpoints.down('xs')]: {
      paddingTop:8.5,
      paddingBottom:8.5,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
  formLabel: {
    marginBottom: 10
  },
  rowBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 80,
    '& > div:first-child': {
      paddingTop: 15,
      flexGrow: 1
    }
  },
  graphBox: {
    width: 24,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderTop: `solid 2px ${theme.palette.grey[300]}`,
    borderBottom: `solid 2px ${theme.palette.grey[300]}`,
    borderRight: `solid 2px ${theme.palette.grey[300]}`,
    position: 'relative',
    '& div': {
      width:48,
      height: 25,
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.common.white,
      padding:2,
      textAlign: 'center',
      color: theme.palette.grey[500],
      lineHeight: 1.4,
    }
  }
}));

const SandboxFormConditions = ({ newItemsLists, onFormSubmit, validationSchema, initialValues, enableReinitialize, submitTextBtn }) => {
  const classes = useStyles();
  const filterFieldsList = newItemsLists.filterFields;
  const filterConditionsList =  newItemsLists.filterConditions;
  const filterValuesList = newItemsLists.filterValues;
  const filterValuesInputTypeList = newItemsLists.filterValuesInputType;

  return (
    <Paper className={classes.formRoot} elevation={3}>
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          onFormSubmit(values)
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue, isValid }) => (
          <Form noValidate autoComplete="off">
            <Grid className={classes.gridRoot} container spacing={2}>

              <Grid item xs={12}>
                <Field name="name">
                  {({ field, meta }) => (
                    <TextField
                      value={field.value}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={classes.formField}
                      fullWidth
                      id={field.name}
                      autoComplete={field.name}
                      label="List name"
                      variant="outlined"
                      error={Boolean(meta.touched && meta.error)}
                      helperText={meta.touched && meta.error ? meta.error : ""}
                      inputProps={{className: classes.input}}
                      InputLabelProps={{className:classes.label}}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.formLabel} variant="body1" component="div">Show contacts that match ALL of these conditions:</Typography>
                <FieldArray name="andConditions">
                  {({ push, remove }) => (
                    <Box>
                      <Box className={classes.rowBox}>
                        <Box>
                          {values.andConditions.map((condition, index) => {
                            const selectedIndex = filterFieldsList.indexOf(condition.filterField);

                            const filterField = `andConditions[${index}].filterField`;
                            const touchedFilterField = getIn(touched, filterField);
                            const errorFilterField = getIn(errors, filterField);

                            const filterCondition = `andConditions[${index}].filterCondition`;
                            //const touchedFilterCondition = getIn(touched, filterCondition);
                            //const errorFilterCondition = getIn(errors, filterCondition);

                            const filterValue = `andConditions[${index}].filterValue`;
                            //const touchedFilterValue = getIn(touched, filterValue);
                            //const errorFilterValue = getIn(errors, filterValue);

                            const handleOnChange = (event) => {
                              setFieldValue(filterField, event.target.value)
                              setFieldValue(filterCondition, "");
                              setFieldValue(filterValue, "");
                            }

                            return (
                              <Box className={classes.conditionBlock} key={`andCondition-${index}`}>

                                <Box className={classes.conditionBlockLeft}>
                                  <TextField
                                    size="small"
                                    select
                                    className={classes.selectControl}
                                    id={filterField}
                                    name={filterField}
                                    value={condition.filterField}
                                    onChange={handleOnChange}
                                    label={condition.filterField === "" ? "Select field 1" : ""}
                                    error={Boolean(touchedFilterField && errorFilterField)}
                                    helperText={touchedFilterField && errorFilterField ? errorFilterField : ""}
                                    variant="outlined"
                                    InputLabelProps={{shrink: false}}
                                    SelectProps={{
                                      IconComponent: ExpandMoreIcon,
                                      MenuProps: {
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "right"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "right"
                                        },
                                        getContentAnchorEl: null
                                      },
                                      classes: {root: classes.selectRoot}
                                    }}
                                  >
                                    {filterFieldsList.map((selectItem, index) => {
                                      return (
                                        <MenuItem
                                          key={`select-${index}`}
                                          value={selectItem}
                                        >{selectItem}
                                        </MenuItem>
                                      )
                                    })}
                                  </TextField>

                                  <ConditionalField
                                    labelText="Select field 2"
                                    name={filterCondition}
                                    filterList={filterConditionsList[selectedIndex]}
                                  />

                                  <ConditionalField
                                    labelText={filterValuesInputTypeList[selectedIndex] === 'text' ? 'Type age' : 'Select field 3'}
                                    name={filterValue}
                                    filterList={filterValuesList[selectedIndex]}
                                    filterValuesInputType={filterValuesInputTypeList[selectedIndex]}
                                  />
                                </Box>

                                <Box className={classes.conditionBlockRight}>
                                  <Typography variant="body1" component="span">AND</Typography>
                                  <IconButton
                                    className={classes.iconBtn}
                                    type="button"
                                    onClick={() => remove(index)}
                                  ><CloseIcon />
                                  </IconButton>
                                </Box>

                              </Box>
                            );
                          })}
                          {values.andConditions.length === 0 &&
                            <Button
                              className={classes.addConditionBtn}
                              type="button"
                              variant="contained"
                              size="large"
                              startIcon={<AddIcon />}
                              onClick={() => push({ filterField: "", filterCondition: "", filterValue: "" })}
                            >Add condition
                            </Button>
                          }
                        </Box>

                        <Box className={classes.graphBox}>
                          <Typography variant="body1" component="div">AND</Typography>
                        </Box>

                      </Box>

                      {values.andConditions.length !== 0 &&
                        <Button
                          className={classes.addConditionBtn}
                          type="button"
                          variant="contained"
                          size="large"
                          startIcon={<AddIcon />}
                          onClick={() => push({ filterField: "", filterCondition: "", filterValue: "" })}
                        >Add condition
                        </Button>
                      }

                    </Box>
                  )}
                </FieldArray>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.formLabel} variant="body1" component="div">And match ANY of these conditions:</Typography>
                <FieldArray name="orConditions">
                  {({ push, remove }) => (
                    <Box>
                      <Box className={classes.rowBox}>
                        <Box>
                          {values.orConditions.map((condition, index) => {
                            const selectedIndex = filterFieldsList.indexOf(condition.filterField);

                            const filterField = `orConditions[${index}].filterField`;
                            const touchedFilterField = getIn(touched, filterField);
                            const errorFilterField = getIn(errors, filterField);

                            const filterCondition = `orConditions[${index}].filterCondition`;
                            //const touchedFilterCondition = getIn(touched, filterCondition);
                            //const errorFilterCondition = getIn(errors, filterCondition);

                            const filterValue = `orConditions[${index}].filterValue`;
                            //const touchedFilterValue = getIn(touched, filterValue);
                            //const errorFilterValue = getIn(errors, filterValue);

                            const handleOnChange = (event) => {
                              setFieldValue(filterField, event.target.value)
                              setFieldValue(filterCondition, "");
                              setFieldValue(filterValue, "");
                            }

                            return (
                              <Box className={classes.conditionBlock} key={`orCondition-${index}`}>

                                <Box className={classes.conditionBlockLeft}>
                                  <TextField
                                    size="small"
                                    select
                                    className={classes.selectControl}
                                    id={filterField}
                                    name={filterField}
                                    value={condition.filterField}
                                    onChange={handleOnChange}
                                    label={condition.filterField === "" ? "Select field 1" : ""}
                                    error={Boolean(touchedFilterField && errorFilterField)}
                                    helperText={touchedFilterField && errorFilterField ? errorFilterField : ""}
                                    variant="outlined"
                                    InputLabelProps={{shrink: false}}
                                    SelectProps={{
                                      IconComponent: ExpandMoreIcon,
                                      MenuProps: {
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "right"
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "right"
                                        },
                                        getContentAnchorEl: null
                                      },
                                      classes: {
                                        root: classes.selectRoot,
                                      }
                                    }}
                                  >
                                    {filterFieldsList.map((selectItem, index) => {
                                      return (
                                        <MenuItem
                                          key={`select-${index}`}
                                          value={selectItem}
                                        >{selectItem}
                                        </MenuItem>
                                      )
                                    })}
                                  </TextField>

                                  <ConditionalField
                                    labelText="Select field 2"
                                    name={filterCondition}
                                    filterList={filterConditionsList[selectedIndex]}
                                  />

                                  <ConditionalField
                                    labelText={filterValuesInputTypeList[selectedIndex] === 'text' ? 'Type age' : 'Select field 3'}
                                    name={filterValue}
                                    filterList={filterValuesList[selectedIndex]}
                                    filterValuesInputType={filterValuesInputTypeList[selectedIndex]}
                                  />
                                </Box>

                                <Box className={classes.conditionBlockRight}>
                                  <Typography variant="body1" component="span">OR</Typography>
                                  <IconButton
                                    className={classes.iconBtn}
                                    type="button"
                                    onClick={() => remove(index)}
                                  ><CloseIcon />
                                  </IconButton>
                                </Box>

                              </Box>
                            );
                          })}

                          {values.orConditions.length === 0 &&
                            <Button
                              className={classes.addConditionBtn}
                              type="button"
                              variant="contained"
                              size="large"
                              startIcon={<AddIcon />}
                              onClick={() => push({ filterField: "", filterCondition: "", filterValue: "" })}
                            >Add condition
                            </Button>
                          }

                        </Box>

                        <Box className={classes.graphBox}>
                          <Typography variant="body1" component="div">OR</Typography>
                        </Box>

                      </Box>

                      {values.orConditions.length !== 0 &&
                        <Button
                          className={classes.addConditionBtn}
                          type="button"
                          variant="contained"
                          size="large"
                          startIcon={<AddIcon />}
                          onClick={() => push({ filterField: "", filterCondition: "", filterValue: "" })}
                        >Add condition
                        </Button>
                      }

                    </Box>
                  )}
                </FieldArray>
              </Grid>

              <Grid item container justifyContent="flex-end" xs={12}>
                <Button
                  className={classes.submitBtn}
                  type="submit"
                  variant="contained"
                  //disabled={!isValid || values.orConditions.length === 0}
                >{submitTextBtn}
                </Button>
              </Grid>

            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
}

export default SandboxFormConditions;
