import React from "react";
import * as PropTypes from "prop-types";
import {DataTableFooter} from "./DataTableFooter";

export const DataTableToolbar = (props) => {
  return (
    <div>
      <div className="card-icon">
        <i className="material-icons">assignment</i>
      </div>
      <h4 className="card-title">{props.header}</h4>
    </div>
  );
};

DataTableFooter.propTypes = {
  header: PropTypes.string
};
