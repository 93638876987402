import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: theme.palette.common.gamsGrey[300],
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    '& .MuiTableCell-head': {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.4),
      paddingBottom: theme.spacing(1.4),
    }
  },
}));

const SandboxTableHeader = ({ tableColumns }) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {tableColumns.map((column) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={column.style}
            >
              {column.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default SandboxTableHeader;

