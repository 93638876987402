import gql from "graphql-tag";

export const ADD_PLACEMENT = gql`
  mutation createPlacement($input: PlacementInput){
    createPlacement(input: $input){
      _id
      name
      size
    }
  }
`;

export const GET_PLACEMENTS = gql`
  {
    placements(page:0) {
      records{
        _id
        name
      }
      totalCount
      pages
    }
  }
`;

export const GET_PROJECTED_PLACEMENTS = gql`
  query{
    placementsProjected{
          _id
          name
    }
}
`;

export const UPDATE_PLACEMENT = gql`
  mutation updatePlacement($input: PlacementUpdateInput){
        updatePlacement(input: $input)
  }
`;

export const GET_PLACEMENT = gql`
  query placement($id: String!){
    placement(id: $id){
      _id
      name
      subPlacementName
      parent 
      size
    }
  }
`;

export const DELETE_PLACEMENT = gql`
      mutation deletePlacement($input:String){
        deletePlacement(input:$input)
      }
`;
