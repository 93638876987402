import { ApolloClient, HttpLink, from } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import {Cookies} from "react-cookie";
import fetch from 'cross-fetch';
import {cache} from "./cache"
import { createUploadLink } from "apollo-upload-client";
import { env } from '../env';
import { RetryLink } from "@apollo/client/link/retry";


const cookies = new Cookies();

const BASE_URL = env.REACT_APP_API_ENDPOINT

const httpLink = new HttpLink({
  uri: BASE_URL, fetch
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors){
    if (graphQLErrors[0].message === "Cannot read properties of undefined (reading 'questionField')") {
      let url = window.location.href;
      let pathname = window.location.pathname;
      let finalUrl = url.replace(pathname, '')
      window.location.replace('/errorPageChallenge');
    }
    if (graphQLErrors[0].message === 'Error: Access Denied') {
      let url = window.location.href;
      console.log(url)
      let pathname = window.location.pathname;
      let finalUrl = url.replace(pathname, '')
      window.location.replace('/errorPage');
    }
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  };
});
const retryLink = new RetryLink();


export const apolloConfig = {
  link: from([errorLink,retryLink,httpLink]),
  cache: cache,
  connectToDevTools: true
};

export const apolloSecureConfig = () => {
  const secureHttpLink = createUploadLink({
    uri: BASE_URL,
    fetch,
    headers: {
      authorization: `Bearer ${
        cookies.get('jwt')
        }`,
    },
  });

  return {
    connectToDevTools: true,
    link: from([errorLink, retryLink, secureHttpLink]),
    cache: cache,
    clientState: {
      defaults: {
        authStatus: {
          __typename: 'authStatus',
          status: 'loggedOut'
        },
        campaign: {},
        creative: {},
        creatives: [],
        lineItems: [],
        lineItem: {},
        changeFrequency: '',
        frequencyCAP: '',
      },
      resolvers: {}
    }
  }
};

export const client = new ApolloClient(apolloSecureConfig());

export const CacheHelper = {
  get: (query, client, emptyValue) => {
    try {
      return client.cache.readQuery({query: query});
    } catch (error) {
      if (emptyValue)
        return emptyValue;
      return null;
    }
  },
  getString: (query, client, emptyValue) => {
    try {
      let res = client.cache.readQuery({query: query});
      return res[Object.keys(res)[0]];
    } catch (error) {
      if (emptyValue)
        return emptyValue;
      return null;
    }
  },
  getArray: (resultNameKey, query, client, emptyValue) => {
    try {
      let res = client.cache.readQuery({query: query});
      if (Array.isArray(res))
        return res;
      if (resultNameKey in res)
        return res[resultNameKey];
    } catch (error) {
      if (emptyValue)
        return emptyValue;
      return null;
    }
  },
  resetObject: (query, key, client) => {
    client.cache.writeQuery({query: query, data: {[key]: {}}});
  },
  resetArray: (query, key, client) => {
    client.cache.writeQuery({query: query, data: {[key]: []}});
  },
  resetString: (query, key, client) => {
    const data = client.readQuery({query});
    client.writeQuery({query: query, data: {...data, [key]:""}});

  }
};
