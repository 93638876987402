import React, { Component } from "react";
import * as PropTypes from "prop-types";
import ChallengeDialog from "../../ChallengeDialog";
import gql from "graphql-tag";
import { SecureQuery } from "../../../../settings/graphqlHelper";
import CircularCountDown from "../../base/CircularCountDown";
import { evaluateStep } from "../../../../components/challenges/AdServer/simulation";
import { ApolloClient } from "@apollo/client";
import { apolloSecureConfig } from "../../../../settings/apollo";
import ChallengeContext from '../../../../store/challenge-context';

const DELETE_CHALLENGE = gql`
  mutation deleteChallenge($input: String) {
    deleteChallenge(input: $input)
  }
`;
const FINISH_CHALLENGE = gql`
  mutation finishChallenge($id: String) {
    finishChallenge(id: $id)
  }
`;

class ChallengeInfo extends Component {
  static contextType = ChallengeContext;

  constructor(props) {
    super(props);
    this.state = { ...props, open: false, key: 0 };
    this.handleExit = this.handleExit.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.timerReset = React.createRef();
    this.timerSet = React.createRef();
    this.timerStart = React.createRef();
  }

  resetTimer(reset) {
    reset();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.step !== this.props.step)
      this.setState({key: this.state.key+1})
  }

  handleExit = () => {
    var id;
    if (window.location.pathname.split("/").pop()) {
      id = window.location.pathname.split("/").pop();
      if (
        window.confirm(
          "Are you sure you want to exit the Challenge? Your record won’t be saved and you will need to retake the challenge"
        )
      ) {
        SecureQuery.mutate({
          mutation: DELETE_CHALLENGE,
          variables: { input: id }
        })
        .catch(error => console.log(error))
        .then(() => this.forceUpdate());
        if (this.context.challengeOrigineRoute) {
          this.props.history.push(this.context.challengeOrigineRoute);
        } else {
          this.props.history.goBack()
        }
      }
    } else {
      this.setState({ open: false });
    }
  };

  handleSkip = () => {
    var id;
    const action = {
      actionNeeded: "click",
      target: "button",
      parameter: "skip",
      validationField: "skip"
    };
    if (window.location.pathname.split("/").pop()) {
      id = window.location.pathname.split("/").pop();
      if (
        window.confirm(
          "Are you sure you want to SKIP the step of the Challenge? Your score will be 0"
        )
      ) {
      const client = new ApolloClient(apolloSecureConfig());
      const skip = evaluateStep(id, action,client)
      const location  = this.props.history
      const skipStep = this.props.skip
      skip.then(function(res){
        location ?
        location.push(res.data.challengeStepEvaluator + id)
        :
        window.location.assign(res.data.challengeStepEvaluator + id)
       }).then(function(){
         skipStep()
       })
      }
    } else {
      this.setState({ open: false });
    }
  };

  handleFinish = () => {
    var id;
    if (window.location.pathname.split("/").pop()) {
      id = window.location.pathname.split("/").pop();
      if (
        window.confirm(
          "Are you sure you want to FINISH the Challenge? Your progress will be saved only until this step and so it will be your scoring"
        )
      ) {
        SecureQuery.mutate({
          mutation: FINISH_CHALLENGE,
          variables: { id }
        })
        .catch(error => console.log(error))
        .then(() => this.props.history.push("/challenge/end/" + id)
        );
      }
    } else {
      this.setState({ open: false });
    }
  };

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <CircularCountDown
          time={this.props.time}
          step={this.state.key}
          challengeInfo={true}
          hasMedia={this.props.hasMedia} />
        <div>
          <ChallengeDialog
            //title="Info Challenge"
            handleExit={this.handleExit}
            handleFinish={this.handleFinish}
            handleSkip={this.handleSkip}
            undoName="Resume"
            step={this.props.step}
            challengeInfo={true}
            brief={this.props.description}
            coach={this.props.coach}
            challengeTitle={this.props.challengeTitle}
            challengeIsValidation={this.props.challengeIsValidation}
            challengeDifficulty={this.props.challengeDifficulty}
            challengeAverageTime={this.props.challengeAverageTime}
          />
        </div>
      </div>
    );
  }
}

ChallengeInfo.propTypes = {
  time: PropTypes.any,
  description: PropTypes.any,
  handleEnd: PropTypes.any,
  coach: PropTypes.any,
  challengeTitle: PropTypes.any,
  challengeIsValidation: PropTypes.any,
  challengeDifficulty: PropTypes.any,
  challengeAverageTime: PropTypes.any,
};

export default ChallengeInfo;
