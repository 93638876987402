const validateAdvertiser = values => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  return errors;
};
export default validateAdvertiser;

export const validateLogin = values => {
  let errors = {};
  if (!values.username) {
    errors.username = 'Please set a valid email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
    errors.username = 'Invalid email address';
  } else if (!values.password) {
    errors.password = 'Please set a password';
  }
  return errors;
};

export const validateRegistration = values => {
  let errors = {};
  if (!values.username) {
    errors.username = 'Please set a valid email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.username)) {
    errors.username = 'Invalid email address';
  } else if (!values.password) {
    errors.password = 'Please set a password';
  } else if (!values.name) {
    errors.name = 'Please set name';
  }
  return errors;
};

export const validateEditPassword = values => {
  let errors = {};
  if (!values.password) {
    errors.password = 'Please set a new password';
  } else if(!values.password2) {
    errors.password = 'Please confrm new password';
  }
  if(values.password.length<6 || values.password.length>20 ){
    errors.password = 'Password must be between 6 and 20 characters';
  }
  return errors;
}

export const validateCreative = values => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Please set a name';
  }
  return errors;
};
export const validateCreativeItem = values => {
  let errors = {};
  if (!values.adsName) {
    errors.adsName = 'Please set a name';
  }
  return errors;
};

export const validatePlacement = values => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  return errors;
}

export const validateUserGroup = values => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  return errors;
}

export const validateLineItem = values => {
  let errors = {};
  if (!values.name)
    errors.name = 'Please set a name';
  else if (!values.budgetPlanned)
    errors.budgetPlanned = 'Please set a planned budget';
  else if (!values.impressionsPlanned)
    errors.impressionsPlanned = 'Plsease set an planned impression';
  else if (!values.cpm)
    errors.cpm = 'Please set a cpm'
  return errors;
};

export const validateCampaign = values => {
  let errors = {};
  if (!values.name) {
    errors.name = 'Please set a name';
  }
  return errors;
};
