import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formRoot: {
    maxWidth: 630,
    margin:'0 auto'
  },
  formBlock: {
    paddingTop:30,
    margin:'0 auto',
    maxWidth: 570,
    [theme.breakpoints.down('xs')]: {
      paddingTop:25,
    },
  },
  formTitle: {
    fontSize: theme.typography.pxToRem(56),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom:26,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(27),
      marginBottom:15,
    },
  },
  gridRoot: {
    '& .MuiGrid-item': {
      marginBottom:9,
      [theme.breakpoints.down('xs')]: {
        marginBottom:2,
      },
    }
  },
  formFooter: {
    marginTop:20,
    textAlign: 'center'
  },
  formFooterText: {
    fontSize: theme.typography.pxToRem(18),
  },
  logo: {
    width: 120,
    margin:'0 auto',
    marginBottom:30,
    '& img': {
      width:'100%',
      height:'auto'
    }
  },
}));

const AuthForm = ({ children, formTitle, formSubtitle, formFooter, formHandleSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.formRoot}>
        <Box>
          <Typography variant="h4" component="h4" className={classes.formTitle}>
            {formTitle}
          </Typography>
          {formSubtitle}
        </Box>

        <form className={classes.formBlock} onSubmit={formHandleSubmit}>
          <Grid className={classes.gridRoot} container spacing={2}>
            {children}
          </Grid>
        </form>

        { formFooter ?
          <Box className={classes.formFooter}>
            <Typography variant="body1" component="p" className={classes.formFooterText}>
              {formFooter}
            </Typography>
          </Box>
        : null}
      </div>
    </>
  )
}

export default AuthForm;
