import React from 'react'
import Layout from '../../common/Layout'
import AdminSettingsNavigation from "./AdminSettingsNavigation"

class AdminSettings extends React.Component{
    render(){
        return(
            <Layout name="Admin Settings">
                <AdminSettingsNavigation/>
            </Layout>
        )
    }
}

export default AdminSettings