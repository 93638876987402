import { useQuery } from '@apollo/client';
import gql from "graphql-tag";

const GET_LINKEDIN_AUTHORIZATION = gql`
query getAuthorization($code: String, $redirectUri: String){
    getAuthorization(code: $code, redirectUri: $redirectUri)
  }
`;

function LinkedinAuthorization (props) {

    const {
        loading,
        error,
        data
      } = useQuery(GET_LINKEDIN_AUTHORIZATION, {
        variables: {
          code: props.code,
          redirectUri: props.redirectUri,
        },
        fetchPolicy: "network-only",
      });
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    if(data){
      props.onSuccess(data);
    }
    return null;


}

export default LinkedinAuthorization
