import Button from "@material-ui/core/Button/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import DialogContentText from "@material-ui/core/DialogContentText/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import React from "react";
import { useState } from "react";
import Swal from 'sweetalert2';
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const UPDATE_FILE = gql`
  mutation($file: Upload!, $id: Int!) {
    uploadUpdatedChallengeDefinition(file: $file, id: $id)
  }
`;
const INSERT_FILE = gql`
  mutation($file: Upload!) {
    uploadChallengeDefinition(file: $file)
  }
`;


export default function UploadDialog(props, ref) {
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUpdate] = useMutation(UPDATE_FILE);
  const [fileInsert] = useMutation(INSERT_FILE);

  const {
    title,
    handleUndo,
    handleClose,
    uploadItem,
    open,
    undoName,
    saveName,

  } = props;

  

  const replaceFile = async () =>  {

    try{
      if( uploadItem ){
        await fileUpdate({variables:{file: file, id: uploadItem.id}})    
      }
      else {
        await fileInsert({variables:{file: file}})    
      }
      Swal.fire({
        type: 'success',
        title: 'Saved',
        showConfirmButton: false,
        timer: 1500
      })    
    }
    catch(error)
    {
      console.log(error)
    }
    setFile(null);
    handleClose();
  }



  return (
    <div>
      <Dialog
        open={open || modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.children.length > 0 ? (
                props.children
            ) : null}
          </DialogContentText>
          <input type='file' onChange={(event) => setFile(event.target.files[0])}></input>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUndo ? handleUndo : () => setModal(false)}
            color="primary"
          >
            {undoName || "Cancel"}
          </Button>
          {file ?
            <Button
              onClick={() => replaceFile()}
              color="primary"
            >
              {saveName || "Save"}
            </Button>
            :
            null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}