import React from 'react';
import Layout from '../../common/Layout';
import { Formik } from 'formik';
import { Mutation } from "@apollo/client/react/components";
import UserGroupForm from './UserGroupForm';
import { withRouter } from "react-router-dom";
import { validateUserGroup } from '../Validations';
import { ADD_USER_GROUP } from "./queries";

class UserGroupInsert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChangeParent(event) {
    this.setState({
      parent: event.label
    });
  }
  render() {
    return (
      <Layout name="User Groups">
        <div className="wizard-container">
          <div className="col-md-8 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <Mutation mutation={ADD_USER_GROUP}>
                {(createUserGroup) => (
                  <Formik
                    initialValues={{ name: '', groupChallenges: [], isCareerPath: false }}
                    validate={validateUserGroup}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      createUserGroup({
                        variables: {
                          input: {
                            name: values.name,
                            groupChallenges: values.groupChallenges,
                            isCareerPath: values.isCareerPath==='true'
                          }
                        }
                      }).catch((error) => {
                        console.log(error);
                      }).then(() => this.props.history.push('/admin-settings/userGroups'));
                    }}
                  >
                    {({ isSubmitting, values, handleChange, setFieldValue }) => (
                      <UserGroupForm
                        isSubmitting={isSubmitting}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouter(UserGroupInsert);
