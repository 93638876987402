import React, {Component} from 'react';
//import Layout from '../../../../common/Layout';
import {Query} from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import { GET_CHALLENGE, GET_CHALLENGE_INSTANCE } from '../queries';
import {SecureQuery} from '../../../../settings/graphqlHelper';
import ChallengePanel from '../../ChallengePanel';
import LineItemsSimulation from "../LineItemsSimulation";
import {client} from "../../../../settings/apollo";
import {evaluateStep} from "../simulation";
import { campaignVar, creativesVar, creativeVar, lineItemsVar, lineItemVar } from '../../../../settings/cache';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../../../utils/data/sandboxes";

class CampaignAdServerStep5 extends Component{
  constructor(props) {
    super(props);

    this.state = {lineItems: [], id: props.match.params.id, name:''};
    campaignVar({});
    creativesVar([]);
    creativeVar({});
    lineItemsVar([]);
    lineItemVar({});
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: {id: this.state.id}
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType,
        step: res.data.challengeInstance.currentStep,
        isSubmitting: false
      });
    }).catch((error)=>{
      console.log(error);
    });
  }

  handleTab(){

  };

  async submit() {
    try {
      this.setState({ isSubmitting: true });
      const action={
        actionNeeded: 'click',
        target: 'button',
        parameter: 'save'
      };
      console.log(action.target);
      const res = await evaluateStep(this.state.id, action, client);
      const value = res.data.challengeStepEvaluator;
      console.log(value)
      if (value !== '') {
        this.props.history.push(value + this.props.match.params.id)
      } else {    
        this.setState({ isSubmitting: false });
      }
    } catch (err) {
      this.setState({ isSubmitting: false });
    }
  }

  render()
  {
    if(!this.state.type)
      return <LoadingAnimation/>;

    return (<SandboxLayout name="Campaigns" challengeMode={true} layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <div className="col-md-12 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <div>
                <Query query={GET_CHALLENGE}
                       fetchPolicy="network-only"
                       variables={{
                         id: this.state.type
                       }}
                >
                  {({loading, error, data}) => {
                    if (loading) return (<LoadingAnimation/>);
                    if (error) return `Error! ${error.message}`;
                    if(!data || data.challenge === null)
                      return <div>No data available</div>;
                    const challenge = JSON.parse(data.challenge);

                    let step = parseInt(this.state.step);
                    const StepCountDownTime = challenge.steps[4].countdownTime;
                    let items = [];
                    if(challenge.steps[step])
                       items = challenge.steps[step].data;

                    return (
                      <div>
                        <LineItemsSimulation
                          handleTab={this.handleTab}
                          caption="Line Items"
                          submit={this.submit}
                          name={this.state.name}
                          id={this.state.id}
                          items={items}
                          isSubmitting={this.state.isSubmitting}
                        />
                        <ChallengePanel
                          time={StepCountDownTime}
                          description={challenge.challengeDescription}
                          coach={challenge.coach}
                          challengeTitle={challenge.challengeTitle}
                          challengeIsValidation={challenge.isValidation}
                          challengeDifficulty={challenge.challengeDifficulty}
                          challengeAverageTime={challenge.averageTime}
                        />
                      </div>
                    );
                  }}
                </Query>
              </div>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default CampaignAdServerStep5;
