import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Box, Menu, MenuItem, Link, Divider, Typography, IconButton } from '@material-ui/core';
import Logo from '../../../common/components/Logo/Logo';
import gamsLogo from '../../../assets/images/logo/new-gams-logo.png';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = (media) => makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: theme.spacing(0,2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  logo: {
    width: 108,
    height:'auto',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  btn: {
    color: '#555555',
    '&:hover': {
      color: '#555555',
    },
    '&:focus': {
      outline: 'none',
    }
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary,
    }
  },
  head: {
    marginLeft: 93,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  headMedia: {
    minWidth:42,
    height: 42,
    position:'relative',
    borderRadius:6,
    backgroundImage: media.radialGradient,
    marginRight:9,
    [theme.breakpoints.down('xs')]: {
      minWidth:32,
      height: 32,
    },
    '& div': {
      width:35,
      height:35,
      position:'absolute',
      backgroundColor:theme.palette.common.white,
      top:0,
      bottom:0,
      left:0,
      right:0,
      margin:'auto',
      borderRadius:'100%',
      boxShadow:`${theme.shadows[1]}`,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      [theme.breakpoints.down('xs')]: {
        width:25,
        height: 25,
      },
      '& img':{
        width:23,
        height:'auto',
        [theme.breakpoints.down('xs')]: {
          width:16,
        },
      }
    }
  },
  headTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

const Header = ({ handleDrawerToggle, handleLogout, media, title, challengeMode, subscriptionEl }) => {
  const classes = useStyles(media)();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      color="inherit"
      elevation={3}>
      <Toolbar disableGutters>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Box className={classes.logo}>
          <Logo
            alt="GAMS"
            logoSrc={gamsLogo}
            style={{ width:'100%', height:'auto'}}
          />
        </Box>

        <Box className={classes.head} display="flex" justify="flex-start" alignItems="center">
          <Box className={classes.headMedia}>
            <div>
              <img src={media.src} alt={title} />
            </div>
          </Box>
          <Typography className={classes.headTitle} variant="h1" component="h1">
            {title}
          </Typography>
        </Box>

        <div style={{flexGrow: 1}}></div>
        {subscriptionEl}
        { !challengeMode &&
          <div style={{minWidth: 96}}>
            <IconButton
              className={classes.btn}
              component={RouterLink}
              to="/help"
              color="inherit"
            >
              <HelpOutlineIcon />
            </IconButton>
            <IconButton
              className={classes.btn}
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  underline="none"
                  component={RouterLink}
                  to="/profile"
                >Profile</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  underline="none"
                  component={RouterLink}
                  to="/settings"
                >Settings</Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  underline="none"
                  component={RouterLink}
                  to="/subscription"
                >Subscription</Link>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <Link
                  className={classes.link}
                  underline="none"
                  component={RouterLink}
                  to="/login"
                  onClick={handleLogout}
                >Log out</Link>
              </MenuItem>
            </Menu>
          </div>
        }
      </Toolbar>
    </AppBar>
  );
}

export default Header;
