import gql from "graphql-tag";

export const GET_CHALLENGE = gql`
  query challenge($id: Int){
    challenge(id: $id)
    }
`;

export const EVALUATE_STEP = gql`
  mutation challengeStepEvaluator($id: String, $action: Action){
    challengeStepEvaluator(id: $id, action: $action)
    }
`;

export const GET_CHALLENGE_INSTANCE = gql`
  query challengeInstance($id: String){
    challengeInstance(id: $id){
      _id
      challengeType
      challenge
      currentStep
      resultReview
      resultPercent
      url
      description
      scores
      logs
      stepTime
      time
      isValidation
    }
  }
`;

export const GET_ADVERTISERS_SIMULATION = gql`
    query{
        advertisersSimulation{
            _id
            name
            username
        }
    }
`;
export const GET_CHALLENGES = gql`
    query {
      challengesProjected{
      _id
      name
        }
    }
`;
