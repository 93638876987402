import gql from "graphql-tag";

export const UPDATE_USER = gql`
mutation updateMe($input: UserUpdateInput){
  updateMe(input: $input)
}
`;

export const UPLOAD_PHOTO = gql`
mutation($file: Upload!) {
  uploadPhoto(file: $file)
}
`;

export const REMOVE_PHOTO = gql`
mutation {
  removePhoto
}
`;

export const DELETE_ME = gql`
  mutation deleteMe{
    deleteMe
  }
`;