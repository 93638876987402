import React from "react";
import { Query } from "@apollo/client/react/components";
import PropTypes from "prop-types";
//import Layout from "../../../common/Layout";
import { GET_CHALLENGE } from "./queries";
import LoadingAnimation from "../../../common/components/base/LoadingAnimation";
import ChallengePanel from "../ChallengePanel";
import SandboxLayout from "../../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../../utils/data/sandboxes";

const SecondStepChallengeLayout = props => {
  const { pageName, challengeType } = props;
  return (
    <SandboxLayout name={pageName} challengeMode={true} layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
        <div className="col-md-8 col-12 mr-auto ml-auto">
          <div
            className="card card-wizard active"
            data-color="rose"
            id="wizardProfile"
          >
            <Query
              query={GET_CHALLENGE}
              fetchPolicy="network-only"
              variables={{
                id: challengeType
              }}
            >
              {({ loading, error, data }) => {
                if (loading) return <LoadingAnimation />;
                if (error) return `Error! ${error.message}`;

                let challenge = JSON.parse(data.challenge);
                return (
                  <div>
                    {props.children}
                    <ChallengePanel
                      time={challenge.steps[1].countdownTime}
                      description={challenge.challengeDescription}
                      coach={challenge.coach}
                      challengeTitle={challenge.challengeTitle}
                      challengeIsValidation={challenge.isValidation}
                      challengeDifficulty={challenge.challengeDifficulty}
                      challengeAverageTime={challenge.averageTime}
                    />
                  </div>
                );
              }}
            </Query>
          </div>
        </div>
      </div>
    </SandboxLayout>
  );
};

SecondStepChallengeLayout.propTypes = {
  pageName: PropTypes.string.isRequired,
  challengeType: PropTypes.number.isRequired
};

SecondStepChallengeLayout.defaultTypes = {
  pageName: ""
};

export default SecondStepChallengeLayout;
