import gql from "graphql-tag";

export const ADD_USER_GROUP = gql`
  mutation createUserGroup($input: UserGroupInput){
    createUserGroup(input: $input){
      _id
      name
    }
  }
`;


export const GET_USER_GROUPS = gql`
query userGroups($name: String, $page: Int, $idList:[String]){
    userGroups(name: $name, page:$page, idList: $idList){
        records{
          _id
          name
          groupChallenges
        }
    totalCount
    pages
    }
}
`;

export const GET_USER_GROUPS_BY_IDS = gql`
  query userGroupsByIds($idList: [String]){
    userGroupsByIds(idList: $idList ){
        records{
          _id
          name
        }
    }
}
`;


export const UPDATE_USER_GROUP = gql`
  mutation updateUserGroup($input: UserGroupUpdateInput){
        updateUserGroup(input: $input)
  }
`;

export const GET_USER_GROUP = gql`
  query userGroup($id: String!){
    userGroup(id: $id){
      _id
      name
      groupChallenges
      isCareerPath
    }
  }
`;

export const DELETE_USER_GROUP = gql`
      mutation deleteUserGroup($input:String){
        deleteUserGroup(input:$input)
      }
`;
