import Button from '@material-ui/core/Button/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogContentText from '@material-ui/core/DialogContentText/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import React from "react";

export default function DialogView(props) {
  const {title, handleUndo, handleClose, handleSave, open, undoName, saveName} = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUndo} color="primary">
            {undoName || "Cancel"}
          </Button>
          <Button onClick={handleSave} color="primary">
            {saveName || "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

