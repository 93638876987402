import {InMemoryCache, makeVar} from "@apollo/client"

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {

        campaign:  {
          read () {
            return campaignVar();
          }
        },
        lineItem:  {
          read () {
            return lineItemVar();
          }
        },
        lineItems:  {
          read () {
            return lineItemsVar();
          }
        },
        changeFrequency:  {
          read () {
            return changeFrequencyVar();
          }
        },
        frequencyCAP:  {
          read () {
            return frequencyCAPVar();
          }
        },
        type: {
          read () {
            return typeVar();
          }
        },
        schedule: {
          read(){
            return scheduleVar();
          }
        },
        delivery: {
          read() {
            return deliveryVar();
          }
        },
        placement: {
          read() {
            return recencyVar();
          }
        },
        recency: {
          read(){
            return recencyVar();
          }
        },
        tag: {
          read() {
            return tagVar();
          }
        },
        searchResult: {
          read() {
            return searchResult();
          }
        }

      }
    }
  }
});

export const campaignVar = makeVar({});
export const creativeVar = makeVar({});
export const creativesVar = makeVar([]);
export const lineItemVar = makeVar({});
export const lineItemsVar = makeVar([]);
export const changeFrequencyVar = makeVar();
export const frequencyCAPVar = makeVar();
export const typeVar= makeVar();
export const scheduleVar= makeVar();
export const deliveryVar = makeVar();
export const placementVar = makeVar();
export const recencyVar = makeVar();
export const tagVar = makeVar();
export const searchResult = makeVar([]);

export const CacheItems = 
  {
    lineItems: 'lineItems',
    lineItem: 'lineItem',
    creatives: 'creatives',
    creative: 'creative',
    changeFrequency: 'changeFrequency',
    frequencyCAP: 'frequencyCAP',
    campaign: 'Campaign',
    type: 'lineItemType'
  };