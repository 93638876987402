import React, {Component} from "react";
import CreativeItem from "./CreativeItem";
import {creativesVar} from "../../settings/cache";
import {creativesGridColumns} from "../../settings/dataSets";
import ItemsGrid from "../../common/components/widgets/ItemsGrid";
import {centerText} from "../../settings/otherStyles";

class LineItemsCreatives extends Component {
  constructor(props) {
    super(props);
    this.state = {...props};
    this.handleAdd = this.handleAdd.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
  }

  componentDidUpdate(prevProps) {
    if( this.props.value !== prevProps.value){
      creativesVar(this.props.value)
  }
    let creatives = creativesVar()
    if (creatives && this.state.items.length !== creatives.length) {
      this.setState({items: creatives})
    }
  }

  deleteRow(index) {
    let results = creativesVar();
    results.splice(index, 1);
    creativesVar(results);
    this.state.items.splice(index, 1);
    this.setState({items: results});

  }

  handleAdd() {
    let results = creativesVar();
    this.setState({items: results})
  }

  isSimulation(tag) {
    if (tag.props.simulation) {
      return true
    }
  }


  render() {
    
    let tag;
    if (this.state.simulation) {
      tag = <CreativeItem simulation={true} handleAdd={this.handleAdd}/>;
    } else {
      tag = <CreativeItem handleAdd={this.handleAdd} />;
    }

    const simulation = this.isSimulation(tag)
    
    return (
      (simulation ? <div style={centerText}>
      {this.state.items.length > 0 && <ItemsGrid columns={creativesGridColumns} title="Associated ADS" items={this.state.items} remove={this.deleteRow} response="true" />}
      {tag}
    </div> : <div style={centerText}>
      {this.state.items.length > 0 && <ItemsGrid columns={creativesGridColumns} title="Associated ADS" items={this.state.items} remove={this.deleteRow} />}
      {tag}
    </div>)
      
    );
  }
}

export default LineItemsCreatives;
