import React from 'react';
import {Formik} from 'formik';
import gql from "graphql-tag";
import {Mutation} from "@apollo/client/react/components";
import AdvertiserForm from './AdvertiserForm';
import {Query} from "@apollo/client/react/components";
import {withRouter} from 'react-router';
import validateAdvertiser from '../Validations';
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';

const UPDATE_ADVERTISER = gql`
  mutation updateAdvertiser($input: AdvertiserUpdateInput){
        updateAdvertiser(input: $input)
  }
`;

const GET_ADVERTISER = gql`
  query advertiser($id: String!){
    advertiser(id: $id){
    _id
    name
    defaultLandingUrl
    category
  }
}
`;

class AdvertiserUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {id: props.match.params.id};
  }

  render() {
    const id = {id: this.state.id};

    return (
      <SandboxLayout name="Advertisers" layoutData={campaignAdsManagerData}>
        <div class="wizard-container">
          <div class="col-md-8 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <Query query={GET_ADVERTISER} variables={id}>
                {({loading, error, data}) => {
                  if (loading) return <LoadingSpinner styles={{ paddingTop: 70 }} />;
                  if (error) return <p>Error :(</p>;

                  return (
                    <Mutation mutation={UPDATE_ADVERTISER}>
                      {updateAdvertiser => (
                        <Formik
                          initialValues={{...data.advertiser}}
                          validate={validateAdvertiser}
                          onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(false);
                            updateAdvertiser({
                              variables: {
                                input: {
                                  _id: data.advertiser._id,
                                  username: "",
                                  name: values.name,
                                  defaultLandingUrl: values.defaultLandingUrl,
                                  category: values.category
                                }
                              }
                            }).then(() => this.props.history.push('/advertisers'));
                          }}
                        >
                          {({isSubmitting, values, handleChange, setFieldValue}) => (
                            <AdvertiserForm update='True'
                                            caption={data.advertiser.category}
                                            values={values}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            isSubmitting={isSubmitting}/>
                          )}
                        </Formik>
                      )}
                    </Mutation>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(AdvertiserUpdate);
