import gql from "graphql-tag";

export const GET_SANDBOXLIST_PAGE = gql`
  query {
    sandboxListPage {
      data {
        id
        attributes {
          pageHeader {
            title
            description
          }
        }
      }
    }
  }
`;

export const GET_SANDBOX_TOOLS = gql`
  query sandboxes {
    sandboxes(sort: "sandboxOrder:asc") {
      data {
        attributes {
          name
          shortDescription
          slug
          sandboxIcon {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            iconGradient
          }
        }
      }
    }
}`;
