import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    padding: 0,
    borderRadius: 8,
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    '& h5': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(0.4, 0),
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:focus': {
      outline: 'none',
    }
  },
  dialogContent: {
    paddingTop: 24,
    paddingBottom: 28
  }
}));

const AutomationTreeDialog = ({ dialogOpen, dialogClose, dialogTitle, dialogContent, dialogActions, children }) => {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth
      maxWidth={'sm'}
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{paper: classes.dialogRoot}}
    >

      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h5">{dialogTitle}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={dialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {children}

      {dialogContent &&
      <DialogContent className={classes.dialogContent} dividers>
        {dialogContent}
      </DialogContent>
      }

      {dialogActions &&
      <DialogActions>
        {dialogActions}
      </DialogActions>
      }

    </Dialog>
  );
}

export default AutomationTreeDialog;
