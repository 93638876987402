import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/styles";
import Radio from '@material-ui/core/Radio';
import {statuses} from "../../../settings/dataSets";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
}));

const StatusList = props => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      {
        statuses.map(status => {
          return (
            <ListItem key={status.label}>
              <div className="row">
                <div className="col-10">
                <ListItemText>{status.label}</ListItemText>
                </div>
                <div className="col-2">
                <Radio checked={props.value === status.label} value={status.label} name={props.name}
                     onChange={props.onChange} style={{color: status.color}}/>
                </div>
              </div>
            </ListItem>
          )
        })
      }
    </List>
  )
}

export default StatusList;
