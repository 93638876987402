import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: 34,
    marginTop: 24,
    borderRadius: 6,
    '@media print': { // styles for pdf psc
      padding: theme.spacing(0, 3),
      paddingBottom: 0,
      marginTop: 0,
    }
  },
  topicTitle: {
    fontSize: theme.typography.pxToRem(21),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 0.8,
    marginBottom: 20,
    '@media print': { // styles for pdf psc
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.primary,
      textAlign: 'center',
    }
  },
  careerTitle: {
    fontSize: theme.typography.pxToRem(23),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 0.8,
    marginBottom: 22,
    textAlign: 'center',
  }
}));

const TopicBlock = ({ children, careerpathName }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={2}>
      {careerpathName ? <Typography className={classes.careerTitle} variant="h3">{careerpathName}</Typography> : null /* visible for psc pdf */}
      {careerpathName ? null : <Typography className={classes.topicTitle} variant="h3">Learning Topics</Typography> /* hidden for psc pdf */}
      {children}
    </Paper>
  );
}

export default TopicBlock;
