import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ResultStep from './ResultStep/ResultStep';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    marginBottom:25
  }
}));

const ChallengeResultSteps = ({ stepResults }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.title}  variant="h2">
        Steps summary
      </Typography>

      {stepResults.map((step, index) => (
        <ResultStep
          step={step}
          stepIndex={index}
          key={`result-step-${index}`}
        />
      ))}
    </Box>
  );
};

export default ChallengeResultSteps;
