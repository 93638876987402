import React from 'react';
import { Toolbar, Typography, Container, Box, Button, Grid, Icon, IconButton } from '@material-ui/core';
import gamsLogo from '../../assets/images/logo/new-gams-logo.png';
import icon from '../../assets/images/psc/expired-icon.svg'
import PartnersBlock from '../../common/components/PartnersBlock/PartnersBlock';
import { makeStyles } from '@material-ui/core/styles';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  containerXl: {
    maxWidth:700
  },
  toolbar: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
  logoBox: {
    maxWidth:200,
    margin: 'auto',
    paddingTop:10,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  headerBlock: {
    paddingTop: 65,
    paddingBottom: 70,
    '& h1': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(42),
      color: theme.palette.grey[800],
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.3px',
      lineHeight:1.3,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(28),
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 55,
      paddingBottom: 60,
    },
  },
  expiredIcon: {
    paddingBottom:18,
    '& img': {
      width: 90,
      height:'auto',
      display: 'block',
      margin: 'auto',
    }
  },
  footerBlock: {
    position:'relative',
    flexGrow: 1,
    paddingTop: 60,
    paddingBottom: 150,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '& h2': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(36),
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: '0.3px',
      lineHeight:1.5,
      marginBottom:40,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(21),
        marginBottom:30,
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 60,
      paddingBottom: 200,
    },
  },
  signupBtn: {
    backgroundColor: theme.palette.common.gamsOrange,
    color: theme.palette.common.white,
    border: `solid 4px ${theme.palette.common.white}`,
    width:320,
    margin:'auto',
    textAlign: 'center',
    display: 'block',
    '&:hover': {
      backgroundColor: theme.palette.common.orange[500],
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('xs')]: {
      width:'100%',
    },
  },
  footerGrid: {
    position: 'absolute',
    bottom:15,
    [theme.breakpoints.down('xs')]: {
      bottom:5,
    },
  },
  iconBtn: {
    border: `solid 2px ${theme.palette.common.white}`,
    color: theme.palette.common.white,
    margin: theme.spacing(0,0.8),
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
    },
    '& svg': {
      width: 24,
      height: 24,
    }
  },
  copyText: {
    padding:theme.spacing(0,4),
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingTop:15,
      paddingBottom:15,
    },
  },
  textLink: {
    padding:theme.spacing(0,4),
    textAlign: 'right',
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
    },
    '& a': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingTop:15,
      paddingBottom:15,
      display:'block',
      margin:'center',
    },
  },
  btnLink: {
    border: `solid 1px ${theme.palette.common.white}`,
    margin:'auto',
    textAlign: 'center',
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gamsDarkBlue,
    },
    '& a': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 15,
    },
  },
}));

const CourtesyPage = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoBox}>
          <img src={gamsLogo} alt="GAMS Logo" />
        </Box>
      </Toolbar>

      <div className={classes.headerBlock}>
        <Container
          maxWidth="xl"
          classes={{ maxWidthXl: classes.containerXl }}>
          <Box className={classes.expiredIcon}>
            <img src={icon} alt="icon expired"/>
          </Box>
          <Typography variant="h1" component="h1">
            Oops… <br/> This Profile Scorecard is expired!
          </Typography>
        </Container>
      </div>
      <PartnersBlock />
      <div className={classes.footerBlock}>
        <Container
          maxWidth="xl"
          classes={{ maxWidthXl: classes.containerXl }}>
          <Typography variant="h2" component="h2">
            Start your Challenges and achieve the Profile Scorecard.
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="default"
            className={classes.signupBtn}
            href="/register"
            rel="noopener noreferrer"
            target="_blank"
          >Sign Up
          </Button>
        </Container>

        <Grid
          className={classes.footerGrid}
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography className={classes.copyText} variant="body1">
              {'Copyright © '}
              {new Date().getFullYear()}
              {' GAMS'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box textAlign="center">
              <Typography variant="body1" className={classes.btnText}>Discover more about the Profile Scorecard at</Typography>
              <Button
                className={classes.btnLink}
                variant="outlined"
                color="default"
                href="https://gamsplatform.com/profile-scorecard/"
                rel="noopener noreferrer"
                target="_blank"
                size="large">
                gamsplatform.com
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" className={classes.socialBox}>
              <IconButton
                className={classes.iconBtn}
                href="https://www.linkedin.com/company/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-linkedin" style={{ color: '#fff' }}></Icon>
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.facebook.com/GAMSPlatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-facebook-f" style={{ color: '#fff' }} />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.instagram.com/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <InstagramIcon />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.youtube.com/channel/UC3yjGdmVYQktS8LmW_RW0Rg"
                rel="noopener noreferrer"
                target="_blank">
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </div>

    </div>
  );
}

export default CourtesyPage;
