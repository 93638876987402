import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { useField } from "formik";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  formField: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectControl: {
    width: 150,
    marginRight:10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.common.gamsDarkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsDarkBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
    '&:focus':{
      backgroundColor:'white'
    }
  },
  selectMenuItem: {
    paddingLeft: theme.spacing(0.5)
  },
  selectCheckbox: {
    color: theme.palette.common.gamsPetroleumBlue,
    '&:hover': {
      backgroundColor: 'none',
    }
  }
}));

const selectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right"
  },
  getContentAnchorEl: null
};

const ConditionalField = (props) => {
  const classes = useStyles();
  const { name, labelText, filterList, filterValuesInputType } = props;
  const [field, meta] = useField(name);

  let inputField = '';
  if (filterList && filterValuesInputType === 'multipleSelect') {
    inputField = (
      <TextField
        name={field.name}
        size="small"
        select
        className={classes.selectControl}
        label={field.value.length === 0 || field.value === "" ? labelText : ""}
        error={Boolean(meta.touched && meta.error)}
        helperText={ meta.touched && meta.error ? meta.error : ""}
        variant="outlined"
        InputLabelProps={{shrink: false}}
        SelectProps={{
          multiple: true,
          value: field.value ? field.value : [],
          onChange: field.onChange,
          IconComponent: ExpandMoreIcon,
          MenuProps: selectMenuProps,
          renderValue: (selected) => selected.join(', '),
          classes: { root: classes.selectRoot }
        }}
      >
        {filterList.map((selectItem, index) => {
          return (
            <MenuItem
              className={classes.selectMenuItem}
              key={`select-${index}`}
              value={selectItem}
            >
              <Checkbox
                color="default"
                classes={{
                  checked: classes.selectCheckbox
                }}
                checked={field.value.indexOf(selectItem) > -1}
              />
              <ListItemText primary={selectItem} />
            </MenuItem>
          )
        })}
      </TextField>
    );
  } else if (filterList && filterValuesInputType === 'text') {
    inputField = (
      <TextField
        className={classes.formField}
        name={field.name}
        size="small"
        label={field.value === "" ? labelText : ""}
        variant="outlined"
        value={field.value}
        onChange={field.onChange}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error ? meta.error : ""}
        inputProps={{className: classes.input}}
        InputLabelProps={{className:classes.label, shrink: false}}
      />
    );
  } else if (filterList && !filterValuesInputType) {
    inputField = (
      <TextField
        name={field.name}
        onChange={field.onChange}
        value={field.value}
        size="small"
        select
        className={classes.selectControl}
        label={field.value === "" ? labelText : ""}
        error={Boolean(meta.touched && meta.error)}
        helperText={ meta.touched && meta.error ? meta.error : ""}
        variant="outlined"
        InputLabelProps={{shrink: false}}
        SelectProps={{
          IconComponent: ExpandMoreIcon,
          MenuProps: selectMenuProps,
          classes: { root: classes.selectRoot }
        }}
      >
        {filterList.map((selectItem, index) => {
          return (
            <MenuItem
              key={`select-${index}`}
              value={selectItem}
            >{selectItem}
            </MenuItem>
          )
        })}
      </TextField>
    );
  }

  return inputField;
};

export default ConditionalField;
