import React from 'react';
import parse from 'html-react-parser';
import { Link as RouterLink, matchPath } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Hidden, Toolbar, Box, ListSubheader, Button, Icon } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logo from '../../../common/components/Logo/Logo';
import gamsLogo from '../../../assets/images/logo/new-gams-logo.png';

const drawerWidth = 240;

const useStyles = (challengeMode) => makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appBarSpacer: theme.mixins.toolbar,
  logo: {
    width: 108,
    height:'auto',
    [theme.breakpoints.down('xs')]: {
      paddingTop:12,
    },
  },
  exitBtnBox: {
    paddingLeft: theme.spacing(1),
    marginTop:8,
  },
  exitBtn: {
    marginTop:10,
    marginBottom:10,
    color: theme.palette.grey[800],
    [theme.breakpoints.down('xs')]: {
      marginBottom:10,
      fontSize: theme.typography.pxToRem(12),
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  list: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
    marginTop: challengeMode ? 60 : 24,
  },
  listItem: {
    marginTop:6,
    marginBottom:6,
    paddingRight:0,
    paddingLeft:12,
    //transition:'none',
    '&:hover': {
      color: theme.palette.common.gamsOrange,
      backgroundColor:`transparent`,
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.gamsOrange,
      }
    },
    '&:focus': {
      outline: 'none',
    },
  },
  listItemSelected: {
    backgroundColor:`${theme.palette.common.gamsOrange} !important`,
    color: theme.palette.common.white,
    borderRadius:8,
    '&:hover': {
      color: theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.white,
      }
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    }
  },
  listItemText: {
    '& span span': {
      fontSize: theme.typography.pxToRem(10),
    }
  },
  listItemIcon: {
    minWidth:30,
  },
  listSubheader: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(16),
    lineHeight:1.5,
    marginBottom:15,
  }
}));

const Sidebar = ({ handleDrawerToggle, mobileOpen, window, title, items, history, challengeMode, btnRoute }) => {
  const classes = useStyles(challengeMode)();
  const theme = useTheme();

  const drawer = (
    <div>
      { !challengeMode &&
        <Box className={classes.exitBtnBox}>
          <Button
            startIcon={<ArrowBackIcon />}
            component={RouterLink}
            to={btnRoute}
            className={classes.exitBtn}
          >Exit</Button>
        </Box>
      }
      <List
        className={classes.list}
        subheader={title &&
          <ListSubheader
            className={classes.listSubheader}
            component="div"
            id="nested-list-subheader"
            disableGutters
          >{title}
          </ListSubheader>
        }
      >
        {items && items.map((item, index) => {
          let active = null;
          const currentRoute = history.location.pathname.toLowerCase();
          const itemRoute = item.href;

          if (currentRoute.includes('campaign') && itemRoute === '/campaigns') {
            active = true;
          } else if (currentRoute.includes('creative') && itemRoute === '/creatives') {
            active = true;
          } else if (currentRoute.includes('advertiser') && itemRoute === '/advertisers') {
            active = true;
          } else if (currentRoute.includes('placement') && itemRoute === '/placements') {
            active = true;
          } else if (currentRoute.includes(itemRoute)) {
            active = true;
          } else {
            active = itemRoute ? !!matchPath(itemRoute, {
              path: currentRoute.toLowerCase(),
              end: false
            }) : false;
          }

          return (
            <ListItem
              button
              component={RouterLink}
              disableRipple
              disabled={item.disabled ? item.disabled : challengeMode}
              key={item.title}
              to={item.href}
              selected={active}
              classes={{
                root: classes.listItem,
                selected: classes.listItemSelected
              }}
            >
              <ListItemIcon classes={{ root:classes.listItemIcon }}>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary={parse(item.title)}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer}>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar>
            <Box className={classes.logo}>
              <Logo
                alt="GAMS"
                logoSrc={gamsLogo}
                style={{ width:'100%', height:'auto'}}
              />
            </Box>
          </Toolbar>
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.appBarSpacer} />
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Sidebar;
