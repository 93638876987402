import Button from "@material-ui/core/Button/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import DialogContentText from "@material-ui/core/DialogContentText/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import React from "react";
import { useState } from "react";

export default function UploadPhotoDialog(props, ref) {
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);

  const {
    title,
    handleUndo,
    handleClose,
    open,
    undoName,
    saveName,
    handleUpload,
    idUser
  } = props;

  

  



  return (
    <div>
      <Dialog
        open={open || modal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.children.length > 0 ? (
                props.children
            ) : null}
          </DialogContentText>
          <input type='file' onChange={(event) => setFile(event.target.files[0])}></input>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleUndo ? handleUndo : () => setModal(false)}
            color="primary"
          >
            {undoName || "Cancel"}
          </Button>
          {file ?
            <Button
              onClick={() => handleUpload(file, idUser)}
              color="primary"
            >
              {saveName || "Save"}
            </Button>
            :
            null
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}