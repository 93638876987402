import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import { Formik, Form } from 'formik';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Query, Mutation } from "@apollo/client/react/components";
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import LoadingSpinner from "../../../common/components/LoadingSpinner/LoadingSpinner";

const GET_USER_DATA = gql`
 query me {
  me {
        privacyPolicy
        profileScorecard
        publishAchievement
 }
}
`;

const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($input: UserUpdateSettingsInput){
    updateUserSettings(input: $input)
  }
`;
class UserSettings extends Component {
  render() {
    return (
      <Query query={GET_USER_DATA}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingSpinner styles={{ paddingTop: 60 }} />;
          if (error) return `Error! ${error.message}`;
          const userData = data.me;
          return (
            <Mutation mutation={UPDATE_USER_SETTINGS}>
              {updateUserSettings => (
                <Formik
                  initialValues={{
                    privacyPolicy: userData.privacyPolicy,
                    profileScorecard: userData.profileScorecard,
                    publishAchievement: userData.publishAchievement
                  }}
                  validate={values => {
                    const errors = {};
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      setSubmitting(false);
                      updateUserSettings({
                        variables: {
                          input: values
                        }
                      }).catch((error) => {
                        console.log(error);
                      }).then(
                        Swal.fire({
                          type: 'success',
                          title: 'Saved',
                          showConfirmButton: false,
                          timer: 1500
                        })
                      );

                    }, 1000);
                  }}
                >
                  {({ values, handleChange, isSubmitting }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-6 d-flex align-self-stretch">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Notification: </h5>
                            </div>
                            <div className="card-body">
                              <h5>
                                Challenge to do
                  <Switch className="float-right" inputProps={{ 'aria-label': 'primary checkbox' }} />
                              </h5>
                              <br />
                              <h5>
                                Demopath to do
                  <Switch className="float-right" inputProps={{ 'aria-label': 'primary checkbox' }} />
                              </h5>
                              <br />
                              <h5>
                                Messages from Coach
                  <Switch className="float-right" inputProps={{ 'aria-label': 'primary checkbox' }} />
                              </h5>
                              <br />
                              <h5>
                                Messages from School
                  <Switch className="float-right" inputProps={{ 'aria-label': 'primary checkbox' }} />
                              </h5>
                              <br />
                              <h5>
                                Complete profile
                  <Switch className="float-right" inputProps={{ 'aria-label': 'primary checkbox' }} />
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Privacy Policy: </h5>
                            </div>
                            <div className="card-body">
                              <h5>
                                <a href="https://gamsplatform.com/privacy-policy/" target="_blank" rel="noreferrer">System privacy policy</a>
                                <Switch className="float-right"
                                  name="privacyPolicy"
                                  value={values.privacyPolicy}
                                  checked={values.privacyPolicy}
                                  onChange={handleChange} />
                              </h5>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Profile Scorecard: </h5>
                            </div>
                            <div className="card-body">
                              <h5>
                                Share my profile scorecard with recruiters
                  <Switch className="float-right"
                                  name="profileScorecard"
                                  value={values.profileScorecard}
                                  checked={values.profileScorecard}
                                  onChange={handleChange} />
                              </h5>
                            </div>
                          </div>

                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title">Publish your achievement</h5>
                            </div>
                            <div className="card-body">
                              <h5>
                              Share your results
                  <Switch className="float-right"
                                  name="publishAchievement"
                                  value={values.publishAchievement}
                                  checked={values.publishAchievement}
                                  onChange={handleChange} />
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ justifyContent: "center" }}>
                        <Button className="btn btn-fill btn-rose" type="submit" variant="contained" size="large">
                          <SaveIcon />
                    Save
              </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </Mutation>
          );
        }}
      </Query>

    )
  }
}

export default UserSettings;
