import React, {Component} from 'react';
//import Layout from '../../common/Layout';
import PropTypes from 'prop-types';
import {Query} from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
import AddItemButton from '../../common/components/base/AddItemButton';
import SimpleSearch from "../../common/components/data-table/SimpleSearch";
import {SecureQuery} from "../../settings/graphqlHelper";
import gql from "graphql-tag";
import {DELETE_PLACEMENT} from "./queries";
import {DEFAULT_USER_EMAIL} from "../../common/components/Constants";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const GET_PLACEMENTS = gql`
  query placements($name: String, $page: Int!){
    placements(name: $name, page:$page){
        records{
          _id
          username
          name
          size
        }
    totalCount
    pages
    }
}
`;

const columns = ["name",  "size", "actions"];

class Placements extends Component {
  static propTypes = {
    page: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {name: '', page: 0};
    this._first = this._first.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._keyPress = this._keyPress.bind(this);
    this._add = this._add.bind(this);
  }

  _previousPage(e) {
    e.preventDefault();
    if (this.state.page > 0) {
      this.setState({page: this.state.page - 1});
    }
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0 });
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({page: this.state.page + 1});
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
  }

  _deleteRow(event) {
    SecureQuery.mutate({mutation: DELETE_PLACEMENT, variables: {input: event.id.id},})
      .catch(error => console.log(error)).then(() => this.forceUpdate());
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({name: e.target.value,
                    page: 0 });
    }
  }

  _add() {
    this.props.history.push("/placement")
  }

  _showEditIcon(record){
    return (record.username !== DEFAULT_USER_EMAIL);
  }
  _showDeleteIcon(record){
    return (record.username !== DEFAULT_USER_EMAIL);
  }

  render() {
    return (
      <SandboxLayout name="Placement" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <AddItemButton OnClick={this._add}/>
          <div className="row">
            <div className="col-sm-12 col-md-10"/>
            <div className="col-sm-12 col-md-2">
              <SimpleSearch keyPress={this._keyPress}/>
            </div>
          </div>
          <Query query={GET_PLACEMENTS} fetchPolicy="network-only"
                 variables={{name: this.state.name, page: this.state.page}}>
            {({loading, error, data}) => {
              if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 30 }} />;
              if (error) return `Error! ${error.message}`;

            return (
              <DataTable header='Placements'
                         editUrl="/placement/update/"
                         ages={data.placements.pages}
                         columns={columns}
                         records={data.placements}
                         deleteRow={this._deleteRow}
                         keyColumn='_id'
                         page={this.state.page}
                         first = {this._first}
                         last = {this._last.bind(this, data.placements.pages)}
                         next={this._nextPage}
                         previous={this._previousPage}
                         columnsToHide={["username"]}
                         showEditIcon={this._showEditIcon}
                         showDeleteIcon={this._showDeleteIcon}
                         />
            );
          }}
          </Query>
        </div>
      </SandboxLayout>
    );
  }
}

export default Placements;
