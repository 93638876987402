import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import './GamsTransferList.css';

const CustomCheckbox = withStyles({
  root: {
    color: '#999999',
    '&$checked': {
      color: '#3F51B4',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomButton = withStyles((theme) => ({
  root: {
    width: 100,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      fontSize: 12,
    },
    height: 28,
    fontSize: 14,
    marginTop:5,
    marginBottom:5,
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#3F51B4',
    border: 'none',
    '&:hover': {
      backgroundColor: '#5C6CC8',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#5C6CC8',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      backgroundColor: '#5C6CC8',
    },
    '&:disabled': {
      boxShadow: 'none',
      border: 'none',
      backgroundColor: '#5C6CC8',
    },
  },
}))(Button);

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = props => {

  const [checked, setChecked] = React.useState([]);

  let left =  (props.left !== null ? props.left : []);
  let right = (props.right !== null ? props.right : []);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleChange = props.handleChange;

  function setLeft(value){
    left= value;
  }
  function setRight(value){
    right = value;
  }
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    handleChange(left,right)
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    handleChange(left,right)
  };

  // const handleCheckedLeft = () => {
  //   setLeft(left.concat(rightChecked));
  //   setRight(not(right, rightChecked));
  //   setChecked(not(checked, rightChecked));
  //   handleChange(left,right)
  // };

  // const handleAllLeft = () => {
  //   setLeft(left.concat(right));
  //   setRight([]);
  //   handleChange(left,right)
  // };

  const handleRemoveCheckedRight = () => {
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    handleChange(left,right)
  };

  const handleRemoveAllRight = () => {
    setRight([]);
    handleChange(left,right)
  };

  const customList = (items) => (
    <div className="gams-trl">
      <Paper className="gams-trl__paper">
        <List className="gams-trl__list" dense component="div" role="list">
          {items.map((item) => {
            const labelId = `transfer-list-item-${item.id}-label`;

            return (
              <ListItem disableGutters divider className="gams-trl__item" key={item.id} role="listitem" button onClick={handleToggle(item)}>
                <ListItemIcon>
                  <CustomCheckbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.value}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    </div>
  );

  return (
    <div className="gams-trl">
      <Grid
        container
        spacing={0}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={5}>
          <div className="gams-trl__label">{props.leftTitle}</div>
          {customList(left)}
        </Grid>

        <Grid item xs>
          <Grid container direction="column" alignItems="center">
            <CustomButton
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              Add all
            </CustomButton>
            <CustomButton
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              Add
            </CustomButton>
            <CustomButton
              variant="outlined"
              size="small"
              onClick={handleRemoveCheckedRight}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              Remove
            </CustomButton>
            <CustomButton
              variant="outlined"
              size="small"
              onClick={handleRemoveAllRight}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              Remove all
            </CustomButton>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <div className="gams-trl__label">{props.rightTitle}</div>
          {customList(right)}
        </Grid>

      </Grid>
    </div>
  );
}

export default TransferList;
