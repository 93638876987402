import React from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Paper, Tabs, Tab, Typography, Box, AppBar, SvgIcon, Link } from '@material-ui/core';
import { ReactComponent as icon01 } from '../../../assets/images/career-single/icon01.svg';
import { ReactComponent as icon02 } from '../../../assets/images/career-single/icon02.svg';
import { ReactComponent as icon03 } from '../../../assets/images/career-single/icon03.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight:346,
    borderRadius: 6,
    overflow: 'hidden'
  },
  root: {
    flexGrow: 1,
    minHeight: 346,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  tabMenu: {
    minWidth:160,
    borderRight: `1px solid ${theme.palette.divider}`,
    '& button': {
      [theme.breakpoints.up('md')]: {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    '& button:focus': {
      outline: 'none',
    },
    '& button.Mui-selected': {
      color: theme.palette.common.gamsPetroleumBlue
    },
    [theme.breakpoints.up('md')]: {
      flex:'0 1 auto',
      paddingTop:50,
    },
  },
  tabflexContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent:'center'
    },
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.gamsPetroleumBlue,
    height: 4
  },
  tabLabelIcon: {
    border:`solid 1px ${theme.palette.grey[300]}`,
    borderRadius:'50%',
    marginBottom:'4px !important',
  },
  tabPanel: {
    [theme.breakpoints.up('md')]: {
      flex:'1 1 auto',
    },
    '& h2': {
      margin:0,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight:1.2,
      marginBottom:10,
    },
    '& .content ul': {
      paddingLeft:38,
    },
    '& .content li': {
      marginBottom:9,
    },
    '& .tool-list': {
      listStyle:'none',
      margin:0,
      padding:0,
    },
    '& .tool-item': {
      marginBottom:21,
      '& .tool-link': {
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        color:theme.palette.text.primary,
        textDecoration:'none',
        '&:hover': {
          color:theme.palette.text.secondary,
        }
      },
      '& .tool-div': {
        display:'flex',
        justifyContent:'center',
        alignItems:'flex-start',
        color:theme.palette.text.primary,
        textDecoration:'none',
      },
      '& .icon': {
        maxWidth:64,
        minWidth:24,
        width: '100%',
        marginRight:15,
        '& img': {
          width:'100%',
          height:'auto'
        }
      },
      '& .content': {
        fontSize: theme.typography.pxToRem(14),
        '& h3':{
          fontSize: theme.typography.pxToRem(14),
          fontWeight: theme.typography.fontWeightMedium,
          margin:0,
        }
      }
    },
  },
  tabBox: {
    maxWidth: 660,
    margin:'0 auto',
    padding:theme.spacing(3.5, 3, 3.5, 3)
  },
  icon: {
    border:`solid 1px ${theme.palette.grey[300]}`,
    borderRadius:'50%',
  },
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      className={classes.tabPanel}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabBox}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const CareerDetails = ({ aboutCareer, keySkills, toolsUsed }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let tabLabels = ([
    <Tab
      className={classes.tabLabel}
      key={'tab-0'}
      label="About Career"
      icon={<SvgIcon className={classes.tabLabelIcon} component={icon01} viewBox="0 0 128 128" style={{ fontSize: 32 }}/>}
      {...a11yProps(0)}
    />,
    <Tab
      className={classes.tabLabel}
      key={'tab-1'}
      label="Key Skills"
      icon={<SvgIcon className={classes.tabLabelIcon} component={icon02} viewBox="0 0 128 128" style={{ fontSize: 32 }}/>}
      {...a11yProps(1)}
    />,
    <Tab
      className={classes.tabLabel}
      key={'tab-2'}
      label="Tools Used"
      icon={<SvgIcon className={classes.tabLabelIcon} component={icon03} viewBox="0 0 128 128" style={{ fontSize: 32 }}/>}
      {...a11yProps(2)}
    />,
  ]);

  let tabs = null;
  if (mediaQuery) {
    tabs = (
      <AppBar position="static" color="default">
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabMenu,
            indicator: classes.tabIndicator,
            flexContainer: classes.tabflexContainer
          }}
        >
          {tabLabels}
        </Tabs>
      </AppBar>
    )
  } else {
    tabs = (
      <Tabs
        orientation="vertical"
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.tabMenu,
          indicator: classes.tabIndicator,
        }}
      >
        {tabLabels}
      </Tabs>
    )
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        {tabs}
        <TabPanel value={value} index={0}>
          <h2 className="title">About Career</h2>
          <div className="content">{aboutCareer ? <ReactMarkdown linkTarget="_blank" children={aboutCareer} /> : null}</div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <h2 className="title">Key Skills</h2>
          <div className="content">{keySkills ? <ReactMarkdown children={keySkills} /> : null}</div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h2 className="title">Tools Used</h2>
          <ul className="tool-list">
            {toolsUsed.map((tool, index) => {
              let toolEl = null;
              const image = tool.icon.data?.attributes.url;
              if (tool.link) {
                toolEl =
                <Link
                  className="tool-link"
                  href={tool.link}
                  rel="noopener noreferrer"
                  target="_blank">
                  <div className="icon">
                    <img src={image} alt={tool.title} />
                  </div>
                  <div className="content">
                    <h3>{tool.title}</h3>
                    <div>{tool.description}</div>
                  </div>
                </Link>
              } else {
                toolEl =
                <Box className="tool-div">
                  <div className="icon">
                    <img src={image} alt={tool.title} />
                  </div>
                  <div className="content">
                    <h3>{tool.title}</h3>
                    <div>{tool.description}</div>
                  </div>
                </Box>
              }
              return (
                <li key={`tool-${index}`} className="tool-item">
                  {toolEl}
                </li>
              );
            })}
          </ul>
        </TabPanel>
      </div>
    </Paper>
  );
}

export default CareerDetails;
