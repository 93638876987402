import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  SubmitBtn: {
    fontSize: theme.typography.pxToRem(18),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    paddingTop:11.5,
    paddingBottom:11.5,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    textTransform:'none',
    [theme.breakpoints.down('xs')]: {
      paddingTop:8.5,
      paddingBottom:8.5,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      borderRight: `solid 1px rgba(0,0,0,0.39)`,
    },
  },
}));

const SandboxFormSubmit = ({ textSubmit, btnSubmitFullWidth })=> {
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={12} justifyContent='flex-end'>
        <Button className={classes.SubmitBtn} color="default" variant="contained" fullWidth={btnSubmitFullWidth} type="submit">
          {textSubmit}
        </Button>
      </Grid>
    </>
  );
}

export default SandboxFormSubmit;
