import React from "react";
import { withRouter } from "react-router-dom";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignUpdate from './ReportCampaignForm';
import { creativeImages, creativeSizes } from "../../../../constants/constants";
import {
  evaluateSecondStep} from "../../../../settings/commonFunctions";
import ThisStepQuery from "../ThisStepQuery";
import { deliveryVar, frequencyCAPVar, recencyVar, typeVar } from "../../../../settings/cache";

class ReportAdServerStep7 extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { ...props };
  }

  handleChangeType(e) {
    const type = e.currentTarget.textContent;
    this.setState({
      type: type,
      size: creativeSizes[type],
      fileName: creativeImages[type]
    });
  }

  handleSubmit = async values => {
    const { id } = this.props.match.params;
    //const { type, size } = this.state;
    console.log(values);
    const action = {
      actionNeeded: "complete",
      target: JSON.stringify({
        type: "lineitem",
        fields: {
          "lineitemName": values.name,
          "lineitemType":  typeVar(),
          "budgetPlanned": values.budgetPlanned,
          "impressionsPlanned": values.impressionsPlanned,
          "CPM": values.cpm,
          "deliveryType": deliveryVar(),
          "frequency": frequencyCAPVar(),
          "recency": recencyVar(),
        }
      })
    };
    console.log(action.target);
    let res = await evaluateSecondStep(action, id);
    let value = res.data.challengeStepEvaluator;
    if (value) {
      this.props.history.push(value + id);
    }
  };

  render() {
    return (
      <TableLayout name={"Campaigns"}>
        <ThisStepQuery
          challengeType={5}
          currentStep = {7}
          CustomComponent={ReportCampaignUpdate}
          customComponentProps={{
            editRow: () => { },
            items: [
              {
                name: "skinius_lineitem_thissummer_banner_300x250",
                lineitemID: "LI10002",
                lineitemType: "standard campaign",
                scheduleDuration: "168",
                lineitemStatus: "active",
                budgetPlanned: "1500",
                impressionsPlanned: "3000000",
                CPM: "0,50",
                impressionsDelivered: "1290000",
                budgetDelivered: "",
                clicks: "103200",
                CTR: "",
                conversions: "51600",
                conversionRate: "",
                placementID: "PL109",
                deliveryType: "ASAP",
                frequency: "3-lifetime",
                recency: "24-hours",
                adName: "skinius_thissummer_banner_300x250",
                adSize: "300x250",
                creativeID: "CR004",
                status: "Active"
              },
              {
                name: "skinius_lineitem_thissummer_banner02_300x250",
                lineitemID: "LI10003",
                lineitemType: "standard-campaign",
                scheduleDuration: "168",
                lineitemStatus: "paused",
                budgetPlanned: "1320",
                impressionsPlanned: "6000000",
                CPM: "0,22",
                impressionsDelivered: "710000",
                budgetDelivered: "",
                clicks: "4302",
                CTR: "",
                conversions: "129",
                conversionRate: "",
                placementID: "PL103",
                deliveryType: "ASAP",
                frequency: "1-lifetime",
                recency: "72-hours",
                adName: "skinius_thissummer_banner02_300x250",
                adSize: "300x250",
                creativeID: "CR004",
                status: "Paused"
              }], currentTab: 1,
            saveLineItem: this.handleSubmit
          }}
        />
      </TableLayout>
    );
  }
}

export default withRouter(ReportAdServerStep7);
