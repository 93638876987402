import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableRow, TableCell, IconButton, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  actionBox: {
    '& button': {
      color: theme.palette.common.gamsDarkBlue,
      margin: theme.spacing(0, 0.45),
      '&:focus': {
        outline: 'none',
      },
    }
  },
}));

const SandboxTableBody = ({ rows, onEdit, onDelete, tableColumns }) => {
  const classes = useStyles();

  return (
    <TableBody>
      {rows.map((row) => {

        return (
          <TableRow key={row._id}>
            {tableColumns.map((column) => {

              const value = row[column.id];

              if (column.id === 'actions') return (
                <TableCell key={column.id} align={column.align}>
                  {row._username !== "default@gamsplatform.com" &&
                    <Box className={classes.actionBox}>
                      <IconButton
                        onClick={() => onEdit(row._id)}
                        aria-label="edit"
                        className={classes.margin}
                        size="small"
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        onClick={() => onDelete(row._id)}
                        aria-label="delete"
                        className={classes.margin}
                        size="small"
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  }
                </TableCell>
              );

              return (
                <TableCell key={column.id} align={column.align}>
                  {column.id === 'id' ? '#' : ''}{column.format && typeof value === 'number' ? column.format(value) : value}
                </TableCell>
              );

            })}
          </TableRow>
        )}

      )}
    </TableBody>
  );
}

export default SandboxTableBody;
