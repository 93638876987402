import React, { useState } from 'react';
//import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import Swal from 'sweetalert2';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import UploadPhotoDialog from '../../../components/users/UploadPhotoDialog';
import {GET_USER_DATA,GET_CHALLENGES,GET_CHALLENGE_DEFINITIONS} from './graphQl/queries';
import {UPDATE_USER, UPLOAD_PHOTO, REMOVE_PHOTO, DELETE_ME} from './graphQl/mutations';
import LoadingSpinner from "../../../common/components/LoadingSpinner/LoadingSpinner";
import { env } from '../../../env';
import { Cookies } from "react-cookie";
import {client} from "../../../settings/apollo";
import { withRouter } from "react-router";


const MyProfile = (props) => {
const cookie = new Cookies();
const [uploadDialog, setUploadDialog] = useState(false);
const userDataQuery = useQuery(GET_USER_DATA);
const challengesDataQuery = useQuery (GET_CHALLENGES);
const challengeDefinitionsQuery = useQuery (GET_CHALLENGE_DEFINITIONS);
const [updateUserMutation] = useMutation(UPDATE_USER);
const [fileUpdate] = useMutation(UPLOAD_PHOTO);
const [removePhoto] = useMutation(REMOVE_PHOTO);
const [deleteMe] = useMutation(DELETE_ME,{
  onError: (error) => {
    Swal.fire(error.toString(), '', 'error')
  },
  onCompleted: () =>
  {   
    Swal.fire({
      title: 'Account deleted!',
      icon: 'success',
      timer: 1500,
      showConfirmButton: false
    }).then(() => {
      cookie.remove("jwt");
      client.resetStore();   
      props.history.push('/login') 
    });
  }
  },
);

if (userDataQuery.loading || challengesDataQuery.loading || challengeDefinitionsQuery.loading ) return <LoadingSpinner styles={{ paddingTop: 30 }} />;
if (userDataQuery.error) return `Error! ${userDataQuery.error.message}`;
if (challengesDataQuery.error) return `Error! ${challengesDataQuery.error.message}`;
if (challengeDefinitionsQuery.error) return `Error! ${challengeDefinitionsQuery.error.message}`;

const challengeDefinitions = challengeDefinitionsQuery.data.challengeDefinitions;
const userData = userDataQuery.data.me;
const userGroups = userData.courseGroups.map((item) => item.name)
const challengesList = [];
const groupsChallenges = userData.courseGroups.map(
  item => item.groupChallenges
);
groupsChallenges.map(item =>
  item.forEach(item => {
    challengesList.push(item);
  })
);
const userChallenges = [];
const UniqueChallengeList = [
  ...new Set(challengesList.sort((a, b) => a - b))
];
UniqueChallengeList.map(item =>
  userChallenges.push(
    challengeDefinitions.find(i => i.id === parseInt(item))
  )
);
const totalScore = ((userChallenges.map((item) => item.totalObtainableScore)).reduce((a, b) => a + b, 0))

const challenges = challengesDataQuery.data.challengesAll.records
let userChallengesList = [];
userChallengesList = (_.uniqBy(challenges, 'challengeType'))
let totalUserScore = _.sum(userChallengesList.map((item) => _.sum(item.scores.map((item) => parseInt(item)))))

const handleRemovePhoto = async () => {
  try{
    await removePhoto({      refetchQueries: [
      { query: GET_USER_DATA }
    ]});
    Swal.fire({
      type: 'success',
      title: 'Photo Removed',
      showConfirmButton: false,
      timer: 1500
    })
  }
  catch(error)
  {
    console.log(error)
  }
}
const handleUpload = async (file) =>  {
  if(file.size > 2097152){
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: "Max Image Size: 2 MB ",
        showConfirmButton: false,
        timer: 2500
      })
    setUploadDialog(false)
    return
  }
  try{
      await fileUpdate({
        variables:{file},
        refetchQueries: [
          { query: GET_USER_DATA }
        ]
      });
    Swal.fire({
      type: 'success',
      title: 'Saved',
      showConfirmButton: false,
      timer: 1500
    })
  }
  catch(error)
  {
    Swal.fire({
      type: 'error',
      title: 'Error',
      text: error,
      showConfirmButton: false,
      timer: 1500
    })
  }
  setUploadDialog(false)
}
    return (
      <div>
            <Formik
                    initialValues={{
                      name: userData.name,
                      surname: userData.surname,
                      sigla: userData.sigla,
                      email: userData.email,
                      birthDate: userData.birthDate,
                      country: userData.country,
                      phone: userData.phone,
                      city: userData.city,
                      description: userData.description,
                      linkedin: userData.linkedin,
                      userRole: userData.userRole
                      //courseGroups: userData.courseGroups
                    }}
                    onSubmit={(values, actions) => {
                      setTimeout(() => {
                        actions.setSubmitting(false);
                        updateUserMutation({
                          variables: {
                            input: values
                          }
                        }).catch((error) => {
                          console.log(error);
                        }).then(
                          Swal.fire({
                            type: 'success',
                            title: 'Saved',
                            showConfirmButton: false,
                            timer: 1500
                          })
                        );

                      }, 1000);
                    }}
                  >
                    {props => (
                      <form onSubmit={props.handleSubmit}>
                        <div className="row">
                          <div className="col-md-4 d-flex align-items-stretch  flex-wrap">
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Profile picture</h5>
                              </div>
                              <div className="card-body">
                              <img src={userDataQuery.data.me.photo ? `${userDataQuery.data.me.photo}?${new Date().getTime()}` : env.PUBLIC_URL + '/assets/img/default-avatar.png'}
                                  alt="Profile gams" className="rounded-circle img-fluid float-left w-50" />
                                <h5>
                                  {/* jpg, png - max dimensions 150x150px, max weight 3 MB */}
                                </h5>

                                <Button className="btn btn-outline-primary" value="upload" onClick={() => setUploadDialog(true)}>Upload your photo</Button>
                                <Button
                                  className="btn btn-outline-primary"
                                  value="upload"
                                  onClick={() => {
                                        Swal.fire({
                                                title: 'Are you sure you want to remove your photo?',
                                                showCancelButton: true,
                                                confirmButtonText: `Yes`,
                                                }).then((result) => {
                                                if (result.value) {
                                                  handleRemovePhoto();
                                                }
                                                })}
                                    }>Remove photo
                                  </Button>

                                <UploadPhotoDialog
                                title="Upload your photo"
                    handleUndo={()=>setUploadDialog(false)}
                    handleClose={()=>setUploadDialog(false)}
                    handleUpload={handleUpload}
                    open={uploadDialog}
                    saveName="Save"
                    button={true}
                    >
                         Select your photo. The optimal size is 360x360px

                      </UploadPhotoDialog>

                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Profile External Link</h5>
                              </div>
                              <div className="card-body">
                                <h3>
                                  <b>
                                  <div className="form-group bmd-form-group">
                                      <input defaultValue={props.values.linkedin}
                                          type="text"
                                          className="form-control"
                                          name="linkedin"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur} />
                                  </div>                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 d-flex align-items-stretch flex-wrap">
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Name</h5>
                              </div>
                              <div className="card-body">
                                <h3>
                                  <b>
                                  <div className="form-group bmd-form-group">
                                      <input defaultValue={props.values.name}
                                          type="text"
                                          className="form-control"
                                          name="name"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur} />
                                  </div>
                                  </b>
                                </h3>
                              </div>
                            </div>                            
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Last Name</h5>
                              </div>
                              <div className="card-body">
                                <h3>
                                  <b>
                                  <div className="form-group bmd-form-group">
                                      <input defaultValue={props.values.surname}
                                          type="text"
                                          className="form-control"
                                          name="surname"
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur} />
                                  </div>                                  </b>
                                </h3>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Birthdate</h5>
                              </div>
                              <div className="card-body">
                                <h3>
                                  <b>
                                    <div className="form-group bmd-form-group">
                                      <input
                                        defaultValue={moment(props.values.birthDate).format('YYYY-MM-DD')}
                                        name="birthDate"
                                        type="date"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        className="form-control" />
                                    </div>
                                  </b>
                                </h3>
                              </div>
                            </div>

                          </div>
                          <div className="col-md d-flex align-items-stretch">
                            <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">email</h5>
                              </div>
                              <div className="card-body d-flex align-items-center justify-content-center">
                                <h3>
                                  <b>
                                    <div className="form-group bmd-form-group">
                                      {/*<label className="bmd-label-floating">Email</label>
                       <input defaultValue="email" type="text" className="form-control" /> */}
                                      <h5 className="card-title">{props.values.email}</h5>
                                      <Link to={{
                                                                                    pathname: `/password`,
                                                                                }} className="btn btn-outline-primary">
                                                                                    Edit Password
                                                                    </Link>
                                    </div>
                                    {/* <div className="form-group bmd-form-group">
                      <label className="bmd-label-floating">Password</label>
                      <input defaultValue="password" type="password" className="form-control" />
                    </div> */}
                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-md d-flex align-items-stretch">
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">MY SCORE</h4>
                              </div>
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                      <h2>
                                        <b>
                                          {totalUserScore}/{totalScore}
                                        </b>
                                      </h2>
                                    </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-7 d-flex align-items-stretch">
                            <div className="card">
                              <div className="card-header card-header-warning">
                                <h5 className="card-title">Contacts: </h5>
                              </div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label htmlFor="cityInput" className="bmd-label-floating">City</label>
                                  <input id="cityInput"
                                    type="text"
                                    name="city"
                                    className="form-control"
                                    defaultValue={props.values.city}
                                    autoFocus=""
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur} />
                                </div>
                                <div className="form-group">
                                  <label className="bmd-label-floating">Country</label>
                                  <input type="text"
                                    name="country"
                                    className="form-control"
                                    defaultValue={props.values.country}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur} />
                                </div>
                                <div className="form-group">
                                  <label className="bmd-label-floating">Phone</label>
                                  <input type="text"
                                    name="phone"
                                    className="form-control"
                                    defaultValue={props.values.phone}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur} />
                                </div>
                                   </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                          <div className="card">
                              <div className="card-header">
                                <h5 className="card-title">Description</h5>
                              </div>
                              <div className="card-body">
                                <h3>
                                  <b>
                                  <div className="form-group bmd-form-group">
                                      <input                          
                                          type="text"
                                          className="form-control"
                                          name="description"
                                          defaultValue={props.values.description}
                                          onChange={props.handleChange}
                                          onBlur={props.handleBlur}              
                                      />
                                  </div>                                  </b>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        {userData.userRole !== 'student' &&
                        (
                        <div className="row">
                          <div className="col-md-5 d-flex align-items-stretch">
                            <div className="card">
                              <div className="card-header card-header-warning">
                                <h5 className="card-title">School Group</h5>
                              </div>
                              <div className="card-body">
                                {userGroups.map((item, index) =>
                                  <h5 key={index}>
                                    {item}
                                  </h5>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        )}
                        <div className="col-md-12">
                        <Box  style={{  display: "flex", justifyContent: "space-around"}}>

                          <Button className="btn btn-fill btn-rose" type="submit" variant="contained" size="large">
                            <SaveIcon />
                    Save
                  </Button>
                  <Button className="btn  btn-primary" onClick={() => {
                                                            Swal.fire({ 
                                                                    title: 'Are you sure you want to delete your account ?',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: `Yes`,
                                                                    }).then((result) => {
                                                                    if (result.value) {
                                                                      deleteMe();
                                                                    }
                                                                    })}
                                                            }>Delete Account</Button>
                        </Box>                                    
                        </div>
                      </form>
                    )}
                  </Formik>
      </div>
    )}

export default withRouter(MyProfile);
