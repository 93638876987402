import camLogo from '../../assets/images/sandbox-tools/campaign-ads.svg';
import crmLogo from '../../assets/images/sandbox-tools/crm.svg';

export const campaignAdsManagerData = {
  title: "Campaign Ads Manager",
  sdbInfoRoute: "/sandbox-tools/campaign-ads-manager",
  media: {
    src: camLogo,
    radialGradient: "radial-gradient(circle, #ffe3a2, #f8d189, #f2bf71, #ecad5b, #e79a46)"
  },
  items: [
    {
      href: '/campaigns',
      icon: 'near_me',
      title: 'Campaigns'
    },
    {
      href: '/creatives',
      icon: 'collections',
      title: 'Creatives'
    },
    {
      href: '/advertisers',
      icon: 'business',
      title: 'Advertisers'
    },
    {
      href: '/placements',
      icon: 'web',
      title: 'Placements'
    },
  ]
};

export const crmData = {
  title: "CRM",
  sdbInfoRoute: "/sandbox-tools/crm",
  media: {
    src: crmLogo,
    radialGradient: "radial-gradient(circle, #9fd0ec, #7db2d3, #5b95ba, #3878a2, #025d8a)"
  },
  items: [
    {
      href: '/crm/contacts',
      icon: 'people',
      title: 'Contacts'
    },
    {
      href: '/crm/lists',
      icon: 'format_list_bulleted',
      title: 'Lists',
    },
    {
      href: '/crm/automation',
      icon: 'autorenew',
      title: 'Automation',
    }
  ]
};

export const dataTable = {
  "crmLists": {
    "records": [
      {
        "_id": "61b876ba094f982fa7284ee1",
        "id": "#01",
        "name": "list name 01",
        "contactsTotalCount": 60
      },
      {
        "_id": "61b876ba094f982fa7284ee2",
        "id": "#02",
        "name": "list name 02",
        "contactsTotalCount": 70
      },
      {
        "_id": "61b876ba094f982fa7284ee3",
        "id": "#03",
        "name": "list name 03",
        "contactsTotalCount": 45
      },
      {
        "_id": "61b876ba094f982fa7284ee4",
        "id": "#04",
        "name": "list name 04",
        "contactsTotalCount": 54
      },
      {
        "_id": "61b876ba094f982fa7284ee5",
        "id": "#05",
        "name": "list name 05",
        "contactsTotalCount": 34
      },
    ],
    "totalCount": 5,
    "pages": 0,
    "rowsPerPage": 15
  }
}
