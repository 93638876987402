import gql from "graphql-tag";

export const MY_DATA = gql`
query me {
  me {
        userRole
        courseGroups{
          _id
          name
          groupChallenges
        }
 }
}
`;

export const GET_COACHES = gql`
    query coaches($name:String, $page: Int){
      coaches(name:$name, page: $page){
        records{
            _id
            sigla
            name
            surname
            email
            expirationDate
        }
        pages
        totalCount
        }
    }
`;

export const GET_COACHES_TABLE = gql`
    query coaches($name:String, $page: Int){
      coaches(name:$name, page: $page){
        records{
            _id
            name
            surname
            sigla
            email
            userRole
            courseGroups{
              name
            }
            privacyPolicy,
            profileScorecard,
            publishAchievement,
        }
        pages
        totalCount
        }
    }
`;
