import { deliveryVar, placementVar, recencyVar, scheduleVar, tagVar, typeVar } from './cache';

export const Builder = {
  copy(src) {
    let res = {};
    for (let prop in src) {
      if (prop !== '__proto__' && prop !== '__typename')
        res[prop] = src[prop];
    }
    return res;
  },
  copyWithoutId(src) {
    let res = Object.assign({}, src);
    delete res.__proto__;
    if (res.hasOwnProperty('__typename'))
      delete res.__typename;
    if (res.hasOwnProperty('_id'))
      delete res._id;
    if (res.hasOwnProperty('id'))
      delete res.id;
    return res;
  },
  getLineItem(values) {
    let placement = placementVar();
    let schedule = scheduleVar();
    let delivery = deliveryVar();
    let recency = recencyVar();
    let type = typeVar()
    if (!values.tag)
      values.tag = tagVar();

    return {
      ...values,
      delivery: delivery,
      placement: placement,
      schedule: schedule,
      //frequency: frequency,
      recency,
      type
    };
  },
  createCampaign(campaign) {
    return {...this.copy(campaign), __typename: "Campaign"};
  },
  createLineItems(campaign) {
    let items = [];
    if (campaign['lineItems']) {
      for (let i = 0; i < campaign.lineItems.length; i++) {
        let creatives = [];
        let lineItem = campaign.lineItems[i];
        if (lineItem['creatives']) {
          for (let j = 0; j < lineItem.creatives.length; j++) {
            let creativeItem = lineItem.creatives[j];
            let creative = {
              adsName: creativeItem.adsName,
              creative: creativeItem.creative,
              type: creativeItem.type,
              url: creativeItem.url
            };
            creatives.push(creative);
          }
        }
        let item = {...lineItem, creatives}
        items.push(item);
      }
    }
    return items;
  },
  createCreative(state) {
    return {
      adsName: state.adsName,
      //primaryText: state.adsName + " " + state.creative + " Status:" + state.type,
      url: state.landingUrl,
      creative: state.creative,
      status: state.type,
    };
  }
};
