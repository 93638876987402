import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Typography, Divider } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  profilePaper: {
    overflow: 'hidden',
    paddingBottom: 32,
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 20,
      marginBottom:15
    },
  },
  topProfile: {
    //background: 'rgb(95,153,192)',
    background: 'rgb(95,153,192) radial-gradient(circle, rgba(95,153,192,1) 6%, rgba(63,122,162,1) 63%, rgba(42,91,119,1) 100%)',
    height:110,
    position: 'relative',
  },
  avatar: {
    width: 120,
    height:120,
    border: 'solid 7px #fff',
    position: 'absolute',
    top:0,
    left: 0,
    bottom: '-60%',
    right:0,
    margin: 'auto',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[300],
    '& > img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 120,
      height: 120,
      marginTop: -60,
      marginLeft: -60,
    }
  },
  placeholderAvatar: {
    fill: theme.palette.grey[700],
    position: 'absolute',
    top: '60%',
    left: '50%',
    width: 110,
    height: 110,
    marginTop: -55,
    marginLeft: -55,
  },
  contentProfile: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  username: {
    marginBottom: 13,
    '& > h3': {
      fontSize: theme.typography.pxToRem(21),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: 5,
    },
    '& > h4': {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.common.gamsOrange,
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  certification: {
    marginBottom: 12,
    '& > div': {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3
    },
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3
    }
  },
  date: {
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  footerProfile: {
    marginTop:25,
    paddingLeft: 8,
    paddingRight: 8,
    [theme.breakpoints.down('md')]: {
      marginTop:10,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop:15,
    },
  },
  badgeTitle: {
    fontSize: theme.typography.pxToRem(21),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    marginTop: 18,
    [theme.breakpoints.down('md')]: {
      marginTop:12,
    },
  },
  badgesBox: {
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 5,
    },
    '& div': {
      width: 65,
      height: 65,
      margin: '8px 4px',
      display: 'inline-block',
      verticalAlign: 'middle',
      '& img': {
        width: '100%',
        height: 'auto',
      }
    }
  }
}));

const PscProfile = ({ expirationDate, serialNumber, username, badges, photo }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.profilePaper} elevation={2}>

      <Box className={classes.topProfile}>
        <div className={classes.avatar}>
          {photo ?
            <img src={photo} alt={username} />
          :
            <svg className={`${classes.placeholderAvatar}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 333" fillRule="evenodd" clipRule="evenodd" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality">
              <path d="M283 254c-12-14-59-39-76-43 0 0-17-11-7-28 14-24 31-44 31-74 0-104-130-104-130 0 0 24 11 48 25 68 17 24-2 33-2 33-20 7-58 27-73 44 63 65 168 66 232 0z"/>
            </svg>
          }
        </div>
      </Box>

      <Box
        className={classes.contentProfile}
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'column' }}
        paddingTop={5}
        textAlign="center"
      >
        <Box
          order={{ sm: 2, md: 2, lg: 1 }}
          flex="1 1 0"
          textAlign="center"
          className={classes.username}
        >
          <Typography variant="h3">{username}</Typography>
          <Typography variant="h4">GAMS Talent Hub</Typography>
        </Box>
        <Box
          order={{ sm: 1, md: 1, lg: 2 }}
          flex="1 1 0"
          textAlign={{ sm:'left', md:'left', lg:'center' }}
          className={classes.certification}
        >
          <Typography component="div">Certification Number</Typography>
          <Typography component="span">{serialNumber}</Typography>
        </Box>
        <Box
          order={{ sm: 3 }}
          flex="1 1 0"
          textAlign={{ sm:'right', md:'right', lg:'center' }}
          className={classes.date}
        >
          <Typography component="span">Exp. date {format(new Date(expirationDate), 'd MMM yyyy')}</Typography>
        </Box>
      </Box>

      <Box className={classes.footerProfile}>
        <Divider variant="middle" />
        <Typography className={classes.badgeTitle} component="h3">Like-a-pro Badges</Typography>
        <Box textAlign="center">
          <Box
            display="block"
            className={classes.badgesBox}
          >
          {badges.map((badge, index) => {
            return (
              <div key={index}>
                <img src={badge} alt="Gams Badge" />
              </div>
            )
          })}
          </Box>
        </Box>
      </Box>

    </Paper>
  );
}

export default PscProfile;
