import React, { useState, useEffect } from "react";
import { GET_CHALLENGE } from "./queries";
import PageHeader from "../../../common/components/PageHeader/PageHeader";
import { useQuery } from '@apollo/client';
import ChallengeBlock from '../../../common/components/ChallengeBlock/ChallengeBlock';
import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    flexGrow: 1,
    marginTop: -130,
    position: 'relative',
    paddingBottom: 50
  },
  TwoColumn: {
    maxWidth: 1150,
  },
  OneColumn: {
    maxWidth: 980,
  }
});

const ChallengeTemplate = (props) => {
  const [submitting, setSubmitting] = useState(props.isSubmitting);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setSubmitting(props.isSubmitting);
  }, [props.isSubmitting]);

  var parameter = "param";
  var questionField = "";

  const {
    loading,
    error,
    data
  } = useQuery(GET_CHALLENGE, {
    variables: {
      id: props.type,
      type: props.type
    },
    fetchPolicy: "network-only",
  });

  if (loading) return "";
  if (error) return `Error! ${error.message}`;
  if (!data || data.challenge === null)
    return <div>No Challenge description</div>;
  const challenge = JSON.parse(data.challenge);
  const {
    classes
  } = props;

  function submit(value) {
    if (!submitting){
      props.submit(parameter, questionField, challenge);
      if (step + 1 < value) {
        setStep(step + 1);
      }
    }
  }

  function onChange(parameter1, value) {
    parameter = value;
    questionField = value;
    return true;
  }

  const hasMedia =
    challenge.steps[step].imageLink ||
    challenge.steps[step].videoLink
  ;
  const hasCarousel = challenge.steps[step].imageCarousel;

  return (
    <>
      <PageHeader data={{ 'challengeTitle': challenge.challengeTitle }} />
      <Container
        className={classes.container}
        classes={{ maxWidthLg: hasMedia ? classes.TwoColumn : classes.OneColumn }}
      >
        <ChallengeBlock
          challenge={challenge}
          history={props.history}
          onSubmit={submit}
          onChanged={onChange}
          stepIndex={step}
          hasMedia={hasMedia}
          hasCarousel={hasCarousel}
          onSkipStep={() => setStep(step + 1)}
          isSubmitting={submitting}
        />
      </Container>
    </>
  );
}

export default withStyles(styles)(ChallengeTemplate);
