import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    marginTop: 30
  },
  button: {
    border: `solid 1px ${blue[700]}`,
    color: blue[700],
    '&:hover': {
      color: blue[900]
    },
    '&:focus': {
      color: blue[900]
    }
  }
}))

const ButtonModule = props => {
  const classes = useStyles()
  const {
    currentStep
  } = props;

  let link = null;
  let target = null;
  let textBtn = null;
  if (currentStep.reportDownloadLink) {
    link = currentStep.reportDownloadLink;
    target = '_blank';
    textBtn = 'Download file';
  } else if (currentStep.openTabLink) {
    link = currentStep.openTabLink;
    target = '_blank';
    textBtn = 'Open link';
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="outlined"
        size="large"
        color="primary"
        href={link}
        target={target}
        onClick={() => props.onChanged("parameter", "next")}
        download={currentStep.reportDownloadLink ? true : false}
      >
        {textBtn}
      </Button>
      {props.onChanged("parameter", "next")}
    </div>
  );
}

export default ButtonModule;
