import React from 'react';
//import Layout from '../../common/Layout';
import {Formik} from 'formik';
import gql from "graphql-tag";
import {Mutation} from "@apollo/client/react/components";
import AdvertiserForm from './AdvertiserForm';
import {withRouter} from "react-router-dom";
import validateAdvertiser from '../Validations';
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

const ADD_ADVERTISER = gql`
  mutation createAdvertiser($input:AdvertiserInput){
    createAdvertiser(input:$input){
      _id
      username
      name
      defaultLandingUrl
      category
    }
  }
`;

class AdvertiserInsert extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.state = {category: ''};
  }

  handleChangeCategory(event) {
    this.setState({category: event.currentTarget.textContent});
  }

  render() {
    return (
      <SandboxLayout name="Advertisers" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <div className="col-md-8 col-12 mr-auto ml-auto">
            <div className="card card-wizard active" data-color="rose" id="wizardProfile">
              <Mutation mutation={ADD_ADVERTISER}>
                {(addAdvertiser) => (
                  <Formik
                    initialValues={{name: '', defaultLandingUrl: ''}}
                    validate={validateAdvertiser}
                    onSubmit={(values, {setSubmitting}) => {
                      setSubmitting(false);
                      addAdvertiser({
                        variables: {
                          input: {
                            "username": "",
                            "name": values.name,
                            "defaultLandingUrl": values.defaultLandingUrl,
                            "category": values.category
                          }
                        }
                      })
                        .then(() => this.props.history.push('/advertisers'));
                    }}
                  >
                    {({isSubmitting, values, handleChange, setFieldValue, handleSubmit}) => (
                      <AdvertiserForm values={values}
                                      caption="Category"
                                      handleChange={handleChange}
                                      setFieldValue={setFieldValue}
                                      handleChangeCategory={this.handleChangeCategory}
                                      isSubmitting={isSubmitting}
                                      handleSubmit={handleSubmit}
                                      />
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(AdvertiserInsert);
