import React, { Component } from 'react';
import { width100 } from '../../../settings/otherStyles';
import { Form } from 'formik';
import Autocomplete from "../../../common/components/base/Autocomplete";
import { durations } from "../../../settings/dataSets";
import { Query } from "@apollo/client/react/components";
import LoadingAnimation from "../../../common/components/base/LoadingAnimation";
import { GET_ADVERTISERS_SIMULATION } from "./queries";
import TextField from '@material-ui/core/TextField';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

class CampaignFormSimulation extends Component {
  constructor(props) {
    super(props);
    if (props.items)
      this.state = { ...props, currentTab: 0 };
    else
      this.state = { ...props, items: [], currentTab: 0 };
    this.handleChange = this.handleChange.bind(this);
    this.handleTab = this.handleTab.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.name !== nextProps)
      this.setState({ name: nextProps.name });
  }

  handleChange = (event, currentTab) => {
    this.setState({ currentTab });
  };

  deleteRow(index) {

  }

  handleTab = (values) => {
    
    if(!this.props.isSubmitting) {
      if (this.state.handleTab) {
        this.state.handleTab(values);
      }
    }
  };

  render() {
    const { currentTab } = this.state;
    const { setFieldValue, values } = this.props;
    return (
      <div>
        <Card>
          <div>
            <AppBar position="static" color="default">
              <Tabs value={currentTab} onChange={this.handleChange}>
                <Tab disableRipple label="Campaign" />
                <Tab disableRipple label="Line Items"
                  disabled={!values.name}
                />
              </Tabs>
            </AppBar>
          </div>
          <Form>
            <CardContent>
              {currentTab === 0 && <div className="row justify-content-center">
                <div className="col-lg-10 mt-3">
                  <div className="input-group form-control-lg">
                    <div className="form-group bmd-form-group">
                      <TextField
                        required
                        id="campaignName"
                        label="Name"
                        name="name"
                        defaultValue={values.name}
                        placeholder="campaignname_brand_period"
                        onChange={e => setFieldValue("name", e.target.value)}
                        margin="normal"
                        style={width100}
                      />
                    </div>
                  </div>
                  <div className="input-group form-control-lg">
                    <div className="form-group bmd-form-group">
                      <Autocomplete single={values.duration} handleChange={(item) => item ? setFieldValue("scheduleDuration", item.label) : setFieldValue("scheduleDuration", "")}
                        key="1" values={durations} placeholder="Schedule Duration" />
                    </div>
                  </div>
                  <div className="input-group form-control-lg">
                    <div className="form-group bmd-form-group">
                      <Query query={GET_ADVERTISERS_SIMULATION} fetchPolicy="network-only"
                        variables={{}}>
                        {({ loading, error, data }) => {
                          if (data && data['advertisersSimulation'])
                            return (
                              <Autocomplete key="3" placeholder="Advertiser"
                                handleChange={(item) => item ? setFieldValue("advertiser", item.label) : setFieldValue("advertiser", "")}
                                values={data.advertisersSimulation.map(value => ({
                                  value: value._id,
                                  label: value.name
                                }))} />
                            );
                          if (loading) return (<LoadingAnimation />);
                          if (error) return `Error! ${error.message}`;
                        }}
                      </Query>
                    </div>
                  </div>
                </div>
              </div>}
              {currentTab === 1 && <div>
                <div>
                </div>
              </div>}
              <div className="card-footer">
                <div className="ml-auto">
                  {
                    this.state.currentTab === 0 &&
                    <button className="btn btn-fill btn-rose"
                      disabled={this.props.isSubmitting}
                      onClick={() => this.handleTab(values)}>Next</button>
                  }
                </div>
                <div className="ml-auto mr-auto">
                </div>
                <div className="clearfix"></div>
              </div>
            </CardContent>
          </Form>
        </Card>
      </div>
    );
  }
}

export default CampaignFormSimulation;
