export const lineItemsInitValues = {
  name: '',
  budgetPlanned: '',
  budgetDelivered: 0,
  impressionsPlanned: '',
  impressionsDelivered: 0,
  cpm: '',
  tag: '',
  clicks: 0,
  conversions: 0,
  status: '',
}
