import React from "react";

import { campaignsColumns } from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignReport from './ReportCampaignReport';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep3 = ({ _add, type }) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        currentStep = {11}
        CustomComponent={ReportCampaignReport}
        customComponentProps={{
          handleBackToCampaign: _add,
          timeFrame: 168,
          lineItems: [
            {
              "name": "LI10002",
              "values":
              {
                "impressionsDelivered": [385645, 461755, 442600, 373263, 379794, 493151, 463009],
                "budgetDelivered": [193, 231, 221, 187, 190, 247, 232],
                "clicks": [1990, 2476, 1984, 7156, 6681, 9633, 9920],
                "conversions": [156, 236, 188, 778, 668, 820, 643]
              }
            },
            {
              "name": "LI10003",
              "values":
              {
                "impressionsDelivered": [232186, 255164, 222650, 0, 0, 0, 0],
                "budgetDelivered": [51, 56, 49, 0, 0, 0, 0],
                "clicks": [469, 471, 502, 0, 0, 0, 0],
                "conversions": [49, 64, 57, 0, 0, 0, 0]
              }
            },
            {
              "name": "LI10004",
              "values":
              {
                "impressionsDelivered": [0, 0, 0, 0, 427686, 781971, 670135, 14],
                "budgetDelivered": [0, 0, 0, 214, 391, 335, 0],
                "clicks": [0, 0, 0, 15311, 16209, 16926, 1],
                "conversions": [0, 0, 0, 1468, 1536, 1326, 0]
              }
            },
            {
              "name": "LI10005",
              "values":
              {
                "impressionsDelivered": [0, 0, 0, 671019, 703548, 715433, 0],
                "budgetDelivered": [0, 0, 0, 336, 352, 358, 0],
                "clicks": [0, 0, 0, 13311, 11209, 11216, 0],
                "conversions": [0, 0, 0, 2368, 1236, 2041, 0]
              }
            }
          ]
        }}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "back-to-campaign"
};

export default withChallengeInstance(ReportAdServerStep3, action);
