import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import clsx from 'clsx';
import { Box, Typography, Card, CardHeader, CardContent, CardActions, Collapse, Popover, Button, Grid, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import ResultTableAnswers from '../ResultTableAnswers/ResultTableAnswers';
import ResultTextAnswers from '../ResultTextAnswers/ResultTextAnswers';
import ResultImageAnswers from '../ResultImageAnswers/ResultImageAnswers';
import Carousel from '../../Carousel/Carousel';
import ImageModule from '../../ChallengeBlock/modules/ImageModule';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import PopHover from './PopOver';

const useStyles = makeStyles((theme) => ({
  stepRoot: {
    position:'relative',
    marginBottom:30,
    '& button': {
      '&:hover': {},
      '&:focus': {
        outline: 'none',
      },
    }
  },
  icon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.warning.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  cardContent: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    paddingTop:0,
    paddingBottom:20,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  stepsInfo: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'baseline',
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      justifyContent:'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems:'center',
      margin:'0 19%',
      [theme.breakpoints.down('md')]: {
        margin:'0 15%',
      },
      [theme.breakpoints.down('sm')]: {
        margin:'0 10%',
      },
    }
  },
  stepScore: {
    '& div:first-child': {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& div:last-child': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
      lineHeight: 1.1
    }
  },
  stepTime: {
    '& div:first-child': {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& div:last-child': {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
      lineHeight: 1.1
    },
    '& .step-time-number': {
      paddingLeft:5,
    },
    '& .step-time-text': {
      fontSize: theme.typography.pxToRem(18),
      paddingLeft:0.5,
    }
  },
  stepQuestion: {
    minWidth:150,
    '& button': {
      width: 175,
      color: theme.palette.common.gamsDarkBlue,
      borderColor: theme.palette.common.gamsDarkBlue,
      [theme.breakpoints.down('xs')]: {
        marginTop:15
      },
      '&:hover': {
        backgroundColor: theme.palette.common.blue[600],
        color: theme.palette.common.white,
      },
      '&:focus': {
        outline: 'none',
      },
      '&:active': {
        backgroundColor: theme.palette.common.blue[600],
        color: theme.palette.common.white,
      },
    }
  },
  stepValuation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    [theme.breakpoints.up('lg')]: {
      marginBottom:10,
      marginTop:-10,
    },
    [theme.breakpoints.down('md')]: {
      position:'absolute',
      top:5,
      right:12
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(54),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(42),
      },
    },
    '& span': {
      lineHeight:1.1,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        marginTop:-2
      },
    }
  },
  iconTime: {
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    [theme.breakpoints.up('md')]:{
      marginLeft: theme.typography.pxToRem(301),
    },
    marginTop: theme.typography.pxToRem(-30),
    [theme.breakpoints.down('md')]:{
      marginRight: theme.typography.pxToRem(-11)
    },
    '& svg': {
      fontSize: theme.typography.pxToRem(54),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(42),
      },
    },
    '& span': {
      lineHeight:1.1,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        
      },
    }
  },
  iconCorrect: {
    color: theme.palette.success.main,
  },
  iconWrong: {
    color: theme.palette.error.main,
  },
  cardActions: {
    borderTop: `solid 1px ${theme.palette.grey[200]}`,
    '& button': {
      margin: '0 auto',
      textTransform:'none',
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightRegular,
    }
  },
  popover: {
    maxWidth:940,
    padding:theme.spacing(2)
  },
  popoverContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'top',
  },
  popoverText: {
    alignItems: 'center',
  },
  popoverCloseBtn: {
    marginLeft: '0.250rem',
  },
  imageCarousel: {
    '& .swiper-container': {
      marginTop:0,
    }
  }
}));

const ResultStep = ({ stepIndex, step }) => {
  console.log(stepIndex, '/', step)
  const classes = useStyles();
  const { answer, question, obtainableScore, score, time, questionType, imageCarousel, imageLink, countdownTime } = step;
  let showWarning = false;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("")

  const handleCollapseClick = stepIndex => {
    if (selectedIndex === stepIndex) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(stepIndex)
    }
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const outOfTime = (answers) => {
    showWarning = false;
    answers.forEach((answer) => {
      for (let i = 0; i < answers.length; i++) {
        const element = answers[i];
        let totalTime = +time.minutes * 60 + +time.seconds;
        if (element.isCorrect === true) {
          if (countdownTime) {
            if (countdownTime < totalTime) {
              showWarning = true;
            }
            else {
              showWarning = false;
            }
          } else {
              showWarning = false;
          }
        }
      }
    })
    return showWarning
  }

  const isStepCorrect = (answers) => {
    let result = true;
    answers.forEach((answer) => {
      if (answer.isCorrect !== true) {
        result = false;
      } else {
        if(score === 0){
          showWarning = true;
        }
      }
    })
    return result;
  }

  const answerByQuestionType = (questionType, answer) => {
    if (questionType === 'imageSelection') {
      return <ResultImageAnswers answers={answer} />;
    }
    return <ResultTextAnswers answers={answer} />;
  };

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  return (
    <Card className={classes.stepRoot}>

      <CardHeader
        classes={{
          title: classes.cardTitle
        }}
        title={`Step ${stepIndex + 1}`}
      />

      <CardContent className={classes.cardContent}>
        <Box className={classes.stepsInfo}>
          <div className={classes.stepScore}>
            <Typography variant="body1" component="div">{score}/{ obtainableScore } </Typography>
            <Typography variant="body1" component="div">Score</Typography>
          </div>
          <div className={classes.stepTime}>
            <Typography variant="body1" component="div">
              <span className={'step-time-number'}>{time.minutes}</span><span className={'step-time-text'}>m</span>
              <span className={'step-time-number'}>{time.seconds}</span><span className={'step-time-text'}>s</span>
            </Typography>
            <Typography variant="body1" component="div">Time</Typography>
          </div>
          <div className={classes.stepQuestion}>
            {question && (
              <>
                <Button
                  variant="outlined"
                  aria-describedby={popoverId}
                  onClick={handlePopoverClick}
                  startIcon={<InfoOutlinedIcon />}
                >
                  Step question
                </Button>
                <Popover
                  id={popoverId}
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: classes.popover
                  }}
                >
                  <Box className={classes.popoverContent}>
                    <Grid container spacing={3} className={classes.popoverText}>
                      <Grid item xs={12} sm={imageLink !== null ? 7 : 12}>
                        <Typography component="div">
                          {parse(question)}
                        </Typography>
                      </Grid>
                      { imageLink !== null && (
                      <Grid item xs={12} sm={5}>
                        <ImageModule
                          imgSrc={imageLink}
                          ImgAlt="image module alt"
                        />
                      </Grid>
                      )}
                      { imageCarousel !== null && (
                      <Grid className={classes.imageCarousel} item xs={12}>
                        <Carousel carouselSlides={imageCarousel} />
                      </Grid>
                      )}
                    </Grid>
                    <Box className={classes.popoverCloseBtn}>
                      <IconButton 
                        component="span"
                        onClick={handlePopoverClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Popover>
              </>
            )}
          </div>
        </Box>
        <div>
        {outOfTime(answer) ? (
        <Box className={classes.iconTime} name>
        <PopHover text="It's correct, but you're out of time">
          <WarningIcon className={classes.icon}/>
        </PopHover>
      </Box>
      ) : (
        <div></div>
      )}
        </div>
        
        
        <Box className={classes.stepValuation}>
          { isStepCorrect(answer) ? <DoneIcon className={classes.iconCorrect} /> : <CloseIcon className={classes.iconWrong} fontSize="large" />}
          <Typography variant="body1" component="span" color="textSecondary">{isStepCorrect(answer) ? 'Correct' : 'Wrong'}</Typography>
        </Box>
      </CardContent>

      <CardActions className={classes.cardActions} disableSpacing>
        <Button
          onClick={() => { handleCollapseClick(stepIndex) }}
          aria-expanded={stepIndex === selectedIndex}
          endIcon={<ExpandMoreIcon className={clsx(classes.expand, {
            [classes.expandOpen]: stepIndex === selectedIndex,
          })} />}
        >
          View answers
        </Button>
      </CardActions>
      <Collapse in={stepIndex === selectedIndex} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContentCollapse}>
          { question === null ? <ResultTableAnswers answers={answer}/> : answerByQuestionType(questionType, answer) }
        </CardContent>
      </Collapse>

    </Card>
  );
};

export default ResultStep;
