import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import CareerTopicBlock from '../../components/CareerLearningTopics/CareerTopicBlock/CareerTopicBlock';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    marginBottom:40,
  }
}));

const GridWithXXL = ({ xxl, ...other }) => { // Used to add extra breakpoint to Grid
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...other} />;
};

const CareerLearningTopics = ({ learningTopics, challengeAction, careerStatus, careerStartLevel, onHideViewDialogOpen }) => {
  const classes = useStyles();
  let careerStatusCheck = null;
  const [hideViewDialogOpen, setHideViewDialogOpen] = useState(false);
  const [deviceType, setDeviceType] = useState('');

  const handleHideViewDialogOpen = () => {
    setHideViewDialogOpen(true);
};

const handleHideViewDialogClose = () => {
  setHideViewDialogOpen(false);
};
  
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (/mobile|tablet/i.test(userAgent)) {
      setDeviceType('mobile');
    } else {
      setDeviceType('desktop');
    }
  }, []);


  return (
    <Grid container spacing={3} className={classes.gridRoot}>
      {learningTopics.map((topic, index) => {

        if (careerStatus === 'explore') {
          careerStatusCheck = careerStatus
        } else {
          if(careerStartLevel >= topic.level) {
            careerStatusCheck = null
          } else {
            careerStatusCheck = 'explore'
          }
        }

        return (
          <GridWithXXL key={`topic-${index}`} item xs={12} sm={12} md={12} lg={6} xl={6} xxl={4}>
            <CareerTopicBlock
              careerStatus={careerStatusCheck}
              topicChallenges={topic.challenges}
              topicLevel={topic.levelName}
              topicTitle={topic.name}
              challengeAction={challengeAction}
              onHideViewDialogOpen={handleHideViewDialogOpen}
            />
          </GridWithXXL>
        )
      })}
    </Grid>
  );
}

export default CareerLearningTopics ;
