import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, CircularProgress, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    minHeight: '230px'
  },
  spinnerBox: {
    width: '100%',
    minHeight: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: theme.palette.common.gamsDarkBlue,
  }
}));

const LoadingSpinner = ({ boxed, styles }) => {
  const classes = useStyles();

  return (
    <>
      {boxed ?
        <Paper className={classes.paper} style={styles}>
          <Box className={classes.spinnerBox}>
            <CircularProgress className={classes.spinner} />
          </Box>
        </Paper>
      :
        <Box style={styles}>
          <Box className={classes.spinnerBox}>
            <CircularProgress className={classes.spinner} />
          </Box>
        </Box>
      }
    </>
  );
}

export default LoadingSpinner;
