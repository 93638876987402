import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, MenuItem, Link, Menu, Box, IconButton } from '@material-ui/core';
import { Cookies } from "react-cookie";
import {client} from "../../settings/apollo";
import PersonIcon from '@material-ui/icons/Person';
import gamsLogo from '../../assets/images/logo/new-gams-logo.png';

const cookie = new Cookies();

const useStyles = makeStyles(theme => ({
  box: {
    padding: theme.spacing(0,1),
    marginTop:5,
    marginBottom:30
  },
  logoBox: {
    maxWidth:120,
    paddingTop:10,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  btnLogout: {
    color: '#555555',
    '&:hover': {
    },
    '&:focus': {
      outline: 'none',
    }
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.text.primary,
    }
  }
}));

const SubLayout = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.box}>
        <Box className={classes.logoBox}>
          <img src={gamsLogo} alt="GAMS Logo" />
        </Box>
        <Box>
          <IconButton className={classes.btnLogout} aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <PersonIcon/>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>
              <Link
                className={classes.link}
                underline="none"
                component={RouterLink}
                to="/login"
                onClick={() => {
                  cookie.remove("jwt");
                  client.resetStore();
                }}
              >Log out</Link>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Container maxWidth="lg">
        {props.children}
      </Container>
    </>
  );
}

export default SubLayout;
