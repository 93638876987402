import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import Layout from '../../common/Layout';
import SandboxDetails from '../../common/components/SandboxDetails/SandboxDetails';
import Carousel from '../../common/components/Carousel/Carousel';
import AdditionalInfo from '../../common/components/AdditionalInfo/AdditionalInfo';
import ObtainableBadges from '../../common/components/ObtainableBadges/ObtainableBadges';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { useQuery } from '@apollo/client';
import { GET_SANDBOX_BY_SLUG } from './graphql/queries';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom:30
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginBottom:15,
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius:6
  }
}));

const SandboxToolsSingle = (props) => {
  const classes = useStyles();
  const { slug } = props.match.params;
  const {data, loading, error} = useQuery(GET_SANDBOX_BY_SLUG, {
    variables: {
      slug:  {"eq": slug},
    },
  });

  if (loading) {
    return (
      <Layout>
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      </Layout>
    )
  }
  if (error) {
    return (
      <Layout>
        <div>Error! {error.message}</div>
      </Layout>
    )
  }

  const sandbox = data?.sandboxes?.data[0]?.attributes;
  const { name, openToolLink, shortDescription, sandboxIcon, longDescription, isExternal, obtainableBadges, sandboxGallery, resources, connectedCareersPaths } = data ? sandbox : null;
  const imageLink = sandboxIcon.image.data.attributes.url;
  const radialGradient = sandboxIcon.iconGradient;

  const badgesData = obtainableBadges && obtainableBadges.data.length > 0;
  const carouselData = sandboxGallery && sandboxGallery.data.length > 0;
  const resourcesData = resources && resources.listItem.length > 0;
  const careerPathsData = connectedCareersPaths && connectedCareersPaths.listItem.length > 0;

  return (
    <Layout>
      <Grid container spacing={badgesData ? 3 : 0} className={classes.gridRoot} style={badgesData ? null : {maxWidth:1024, margin:'auto'}}>

        <Grid item xs={12} md={12} lg={badgesData ? 9 : 12} xl={badgesData ? 9 : 12}>

          <SandboxDetails
            title={name}
            shortDescription={shortDescription}
            longDescription={longDescription}
            media={{imageLink, radialGradient}}
            isExternal={isExternal}
            link={openToolLink}
          />

          {carouselData &&
            <section className={classes.section}>
              <Typography className={classes.sectionTitle} variant="h3">
                Sandbox gallery
              </Typography>
              <Paper elevation={3} className={classes.paper}>
                <Carousel
                  spaceBetween={18}
                  slidesPerView={3}
                  navigation={sandboxGallery.data.length <= 3 ? false : true}
                  extraRootStyles={{ border: 'none', borderRadius:0, margin:0 }}
                  extraSlideStyles={{ border: 'solid 1px #ccc' }}
                  carouselSlides={sandboxGallery.data}
                />
              </Paper>
            </section>
          }

        {resourcesData || careerPathsData ?
          <section className={classes.section}>
            <Typography className={classes.sectionTitle} variant="h3">
              Additional Information
            </Typography>
            <Grid container spacing={3} className={classes.gridRoot}>
              {resourcesData &&
                <Grid item xs={12} sm={6}>
                  <AdditionalInfo
                    title="External Resources"
                    infoItems={resources.listItem}
                  />
                </Grid>
              }
              {careerPathsData &&
                <Grid item xs={12} sm={6}>
                  <AdditionalInfo
                    title="Connected Careers paths"
                    infoItems={connectedCareersPaths.listItem}
                  />
                </Grid>
              }
            </Grid>
          </section>
        : null}

        </Grid>

        {badgesData &&
          <Grid item xs={12} md={12} lg={3} xl={3}>
            <ObtainableBadges badges={obtainableBadges.data} />
          </Grid>
        }

      </Grid>
    </Layout>
  );

}

export default SandboxToolsSingle;
