import React, {useEffect, useState} from 'react';
import { useMutation } from '@apollo/client';
import { EXTEND } from './graphql/mutations';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Message from '../../common/components/Message/Message';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { ApolloClient } from '@apollo/client/core';
import { apolloConfig } from '../../settings/apollo';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop:40,
  },
  successIcon: {
    color: theme.palette.success.main
  },
  errorIcon: {
    color: theme.palette.error.main
  },
  customerBox: {
    maxWidth:600,
    margin:'auto',
    textAlign:'center',
    marginTop:28,
    marginBottom:30,
  },
  customerMsg: {
    marginBottom:10,
  },
  customerBtn: {
    color: theme.palette.common.gamsDarkBlue,
    lineHeight:1.2,
    textTransform:'none',
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));
const client = new ApolloClient(apolloConfig);

const RefreshAccount = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [extendAutomation, { loading }] = useMutation(EXTEND,{client});
  const [hasError, setHasError] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    extendAutomation({
      variables: {
        id: id
      }
    })
    .then(({ data }) => {
      setHasError(false);
    })
    .catch(error => {
      setHasError(true);
      setErrorMsg(error.toString());
    });
  }, [id, extendAutomation]);

  return (
    <div className={classes.root}>
      {loading || hasError === null ? (
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      ) : hasError ? (
        <Message
          icon={
            <Box className={classes.errorIcon}>
              <ErrorOutlineIcon style={{ fontSize: 240}}/>
            </Box>
          }
          title={errorMsg}
          description={<>
            <Box className={classes.customerBox}>
              <Typography className={classes.customerMsg} variant="body1" color="textPrimary">
                If you're having trouble logging in, please get in touch with our knowledgeable staff who are always happy to help.
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Email us at:
                <Link
                  variant="body1"
                  className={classes.customerBtn}
                  href="mailto:learn@gamsplatform.com"
                  rel="noopener noreferrer"
                  target="_blank"
                > learn@gamsplatform.com
                </Link>
              </Typography>
            </Box>
          </>}
          btnLink="/"
          btnText="Go to GAMS"
        />
      ) : (
        <Message
          icon={
            <Box className={classes.successIcon}>
              <AutorenewIcon style={{ fontSize: 240}}/>
            </Box>
          }
          title="You correctly refreshed your account!"
          description={<>
            <Box className={classes.customerBox}>
              <Typography className={classes.customerMsg} variant="body1" color="textPrimary">
                Now go back to the login page and continue to play with GAMS. If you have any trouble logging in, please email us at: <Link
                  variant="body1"
                  className={classes.customerBtn}
                  href="mailto:learn@gamsplatform.com"
                  rel="noopener noreferrer"
                  target="_blank"
                > learn@gamsplatform.com
                </Link>
              </Typography>
            </Box>
          </>}
          btnLink="/login"
          btnText="Back to login"
        />
      )}
    </div>
  )
}

export default RefreshAccount;
