import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  circle: {
    color: theme.palette.grey[300]
  }
}));

const LoadingButton = (props) => {
  const classes = useStyles();
  const { loading, circleSize, ...other } = props;

  if (loading) {
    return (
      <Button {...other} disabled={loading}>
        <CircularProgress
          thickness={3}
          size={circleSize}
          classes={{
            circle: classes.circle
          }}
        />
      </Button>
    );
  } else {
    return (
      <Button {...other} />
    );
  }
}

export default LoadingButton;
