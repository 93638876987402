import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Link,
  Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom:20,
    '& .MuiLink-root': {
      color:theme.palette.text.secondary,
      textTransform:'capitalize',
      '&:hover': {
        color: theme.palette.common.gamsPetroleumBlue,
      }
    },
  },
  activeRoute: {
    color:theme.palette.text.primary,
    textTransform:'capitalize',
  }
}));

const Breadcrumbs = props => {
  const {
    location: { pathname }
  } = props;
  const pathnames = pathname.split("/").filter(x => x);
  const classes = useStyles();

  return (
    <MUIBreadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      {pathnames.length > 0 ? (
        <Link
          component={RouterLink}
          color="inherit"
          to="/"
        >Home</Link>
      ) : (
        <Typography className={classes.activeRoute}>Home</Typography>
      )}
      {pathnames.map((name, index) => {
        let routeName = name.trim().replaceAll('-', ' ');
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography className={classes.activeRoute} key={routeName}>{routeName}</Typography>
        ) : (
          <Link
            component={RouterLink}
            color="inherit"
            key={routeName}
            to={routeTo}
          >{routeName}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default withRouter(Breadcrumbs);
