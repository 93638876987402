export const listsTableColumns = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'right',
    style: { minWidth: 170 }
  },
  {
    id: 'totalListCount',
    label: 'Total Contacts',
    align: 'right',
    style: { minWidth: 170 }
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center',
    style: { minWidth: 100 }
  },
];
