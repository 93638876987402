import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  alert: {
    marginBottom:18,
    border: `solid 1px ${theme.palette.error.light}`
  }
}));

const AuthAlert = ({ errorMsg }) => {
  const classes = useStyles();

  return (
    <Alert className={classes.alert} severity="error">
      {errorMsg}
    </Alert>
  )
}

export default AuthAlert;
