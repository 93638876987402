import React from "react";
import {campaignsColumns} from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignUpdate from './ReportCampaignForm';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep4 = ({ _add, type }) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        currentStep = {4}
        CustomComponent={ReportCampaignUpdate}
        customComponentProps={{
          handleLineItems: _add

        }}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "tab",
  parameter: "lineItems"
};

export default withChallengeInstance(ReportAdServerStep4, action);
