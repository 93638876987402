import { Link } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import { CSVLink } from "react-csv";
import Swal from 'sweetalert2';


function CSVService(props){
    const { 
        label,
        csvData, 
        fileName, 
        onClickCalculate 
    } = props;
    const [data, setData] = useState([]);
    let calculatedData = [];
    const csvLink = useRef();
    
    const getTransactionData = async () => {
        Swal.fire({
            type: 'success',
            title: 'Generating your download',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 1500,
            onBeforeOpen: async () => {
                if (onClickCalculate) {
                    calculatedData = await onClickCalculate(props.list);
                    setData(calculatedData); 
                    csvLink.current.link.click()
                } else csvLink.current.link.click()
            }
        })
    }
    
    return(
     <div>
        <Link style={{cursor: 'pointer', color: '#9c27b0'}} onClick={getTransactionData}>{label}</Link>
        <CSVLink
            data={csvData ? csvData : data}
            filename={fileName}
            className='hidden'
            ref={csvLink}
        />
    </div>
    )
}

export default CSVService
