import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 40,
    margin:'0 auto',
    textAlign:'center',
  },
  title: {
    fontSize: theme.typography.pxToRem(36),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 20
  },
  subTitle: {
    maxWidth:540,
    margin:'0 auto',
    marginBottom:8,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.4,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    },
  }
}));

const PageHeaderCentered = ({ icon, title, subTitle, description }) => {
  const classes = useStyles();

  return (
    <Box
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.root}>
      <Box pr={3}>
        {icon ? icon : null}
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        <Typography className={classes.subTitle} variant="h2">
          {subTitle}
        </Typography>
      </Box>
      {description}
    </Box>
  )
}

export default PageHeaderCentered;
