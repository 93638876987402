import React, { useState } from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from "@material-ui/core";
import VideoFrame from "../../base/videoFrame";
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles((theme) => ({
  buttonOutlined: {
    marginLeft: 8,
    borderColor: blue[900],
    color: blue[900],
    borderRadius: 17,
    '&:hover': {
      borderColor: blue[800],
    },
    '&:focus': {
      outline: 'none',
    }
  },
  dialogText: {
    color: theme.palette.text.primary,
    lineHeight: 1.6,
    letterSpacing: 0.2,
    '& > a': {
      color: blue[500]
    }
  },
  dialogBtnOutlined: {
    borderColor: blue[900],
    color: blue[900],
    '&:hover': {
      borderColor: blue[800],
    },
    '&:focus': {
      outline: 'none',
    }
  },
  hintDescription: {
    '& p': {
      marginBottom:14
    },
    '& ul': {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0 ,0, 0, 4)
    },
    '& li': {
      marginBottom: theme.spacing(0.5)
    },
    '& a': {
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
        textDecoration:'underline',
      }
    }
  }
}));

const ChallengeHint = props => {
  const [modal, setModal] = useState(false);
  const classes = useStyles();
  const {
    currentStep,
  } = props;

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={classes.buttonOutlined}
        onClick={() => setModal(true)}
      >
        HINT FOR THIS STEP
      </Button>

      <Dialog
        open={modal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {currentStep.HintTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component="div"
            className={classes.dialogText}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            {currentStep.HintVideo && (
              <VideoFrame
                src={currentStep.HintVideo}
                styles={{ marginBottom: 24, minWidth: 552 }}
              />
            )}
            {currentStep.HintDescription && (
              <Box className={classes.hintDescription}>
                {parse(currentStep.HintDescription)}
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModal(false)}
            color="primary"
            className={classes.dialogBtnOutlined}
          >
            {"Close"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ChallengeHint;
