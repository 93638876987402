import { gql, useMutation, useQuery, } from '@apollo/client';
import React, { useState } from 'react'
import TransferList from "../../../common/components/widgets/TransferList";
import { client } from '../../../settings/apollo';
import Button from "@material-ui/core/Button/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import LoadingAnimation from '../../../common/components/base/LoadingAnimation';
import SimpleSearch from '../../../common/components/data-table/SimpleSearch';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './TransferUsersToGroup.css';
import SearchUsers from '../SearchUsers';
import {GET_USER_GROUP} from '../../../components/userGroup/queries'

const CustomButton = withStyles((theme) => ({
  root: {
    width: 115,
    fontSize: 16,
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#E91E63',
    border: 'none',
    '&:hover': {
      backgroundColor: '#FF4282',
    },
    '&:active': {
      backgroundColor: '#FF4282',
    },
    '&:focus': {
      border: 'none'
    },
  },
}))(Button);


const GET_USERS = gql`
    query users($userGroup: String){
        users(userGroup: $userGroup){
        records{
            _id
            sigla
            name
            surname
        }
        pages
        totalCount
        }
    }
`;

const SET_USER_FOR_GROUP = gql `
    mutation setUsersForUserGroup($id: String, $input: [Users]){
      setUsersForUserGroup(id: $id, input: $input)
}
`;

function TransferUsersToGroup (props) {
    const open = props.open;
    const userGroupId = props.userGroupId
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [userGroup, setUsergroup] = useState([]);
    const [setUserForGroup, mutation ] = useMutation(SET_USER_FOR_GROUP);
    const [nameSearch, setNameSearch] = useState();
    const handleClose = props.handleClose;
    const getUsersQueryResult = useQuery(GET_USERS,{
      variables: {
          userGroup: userGroupId,
          },
      fetchPolicy: "network-only",
      client: client,
      notifyOnNetworkStatusChange: true,
      onError: () => { console.log("error getting users")},
      onCompleted: (data) => {
                     if(data!==getUsersQueryResult.previousData) {
                      const  usersArray =  data.users.records.map((user) => {
                        return {id: user._id, value: user.sigla+ " " + user.name + " " + user.surname}
                      });
                      setRight(usersArray);
                    }

    }});

    const {
      // loading,
      // error,
      // data,
      previousData,
      //variables
    } = useQuery(GET_USER_GROUP,{
      variables: {
        id: userGroupId,
      },
      fetchPolicy: "network-only",
      client: client,
      notifyOnNetworkStatusChange: true,
      onError: () => { console.log("error getting user group")},
      onCompleted: (data) => {
        if(data!==previousData) {
          setUsergroup(data.userGroup.name);
        }
      }
    });

    function handleChange(left,right) {
        setLeft(left);
        setRight(right);
    }

  function save(input){
    setUserForGroup({
      variables: {
        id: input.userGroupId,
        input: input.users.map(record => ({
          _id: record.id,
          name: record.value
        }))
      }
    })
    if (mutation.loading) return <LoadingAnimation />;
    if (mutation.error) return <p>An error occurred</p>;
  }

  function handleSearchResult(data) {
    if(data===undefined) return;
      const users=data.usersNotInUserGroup.records
    if(users===undefined) return;
      const usersArray = users.map((user) => {
        return {id: user._id,  value: (user.sigla || '' )+ " " + user.name + " " + (user.surname || '')}
    });
    if(left !== usersArray){
      setLeft(usersArray)
    }
  }

  function _keyPress(e){
    if (e.keyCode === 13) {
      setNameSearch(e.target.value);

    }
  }


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="tug-dialog"
      >
        <MuiDialogTitle className="tug-dialog__title" disableTypography id="alert-dialog-title">
          <Typography variant="h6" component="h2">{"Assign Users to group"}</Typography>
          <IconButton aria-label="close" className="tug-dialog__close" onClick={handleClose}>
            <CloseIcon style={{ fontSize: 28 }}/>
          </IconButton>
        </MuiDialogTitle>

        <DialogContent className="tug-dialog__content">
          <SimpleSearch keyPress={_keyPress} />
          <TransferList
            left={left}
            right={right}
            rightTitle={"User group: "+userGroup}
            leftTitle="Users"
            handleChange={handleChange}
          ></TransferList>
        </DialogContent>
        <SearchUsers name={nameSearch} userGroupId={userGroupId} handleSearchResult={handleSearchResult} />
        <DialogActions className="tug-dialog__footer">
          <CustomButton
            variant="contained"
            size="medium"
            className="tug-dialog__button"
            onClick={e => {
                save({ userGroupId: userGroupId, users: right });
                handleClose();
            }}
            autoFocus
          >
            Save
          </CustomButton>
        </DialogActions>

      </Dialog>
    </div>
  )
}

export default TransferUsersToGroup
