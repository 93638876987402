import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { crmData } from "../../../../utils/data/sandboxes";
import { contactsTableColumns } from "../../../../utils/data/crmContactsPage";
import { GET_CRM_CONTACTS } from "../../graphql/queries";
import { DELETE_CRM_CONTACT } from "../../graphql/mutations";
import { useQuery, useMutation } from '@apollo/client';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxTable from "../../../../common/components/SandboxTable/SandboxTable";
import SandboxTableHeader from "../../../../common/components/SandboxTable/SandboxTableHeader/SandboxTableHeader";
import SandboxTableBody from '../../../../common/components/SandboxTable/SandboxTableBody/SandboxTableBody';
import SelectFilter from '../../../../common/components/SandboxTable/SandboxTableFilters/SelectFilter/SelectFilter';
import SearchFilter from '../../../../common/components/SandboxTable/SandboxTableFilters/SearchFilter/SearchFilter';
import SandboxDialog from "../../../../common/components/Dialogs/SandboxDialog/SandboxDialog";
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  actionBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    padding: theme.spacing(1.1, 3.5, 1.1, 3.5),
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: 0.2,
    borderRadius:6,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  },
  dialogBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
  dialogDefaultBtn: {
    '&:focus': {
      outline: 'none',
    }
  },
}));

const CrmContactsPage = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [selectValue, setSelectValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { loading, error, data, refetch } = useQuery(GET_CRM_CONTACTS, {
    variables: { page: 0 },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const [deleteCrmContact] = useMutation(DELETE_CRM_CONTACT);

  const handleContactListChange = (event) => {
    refetch({ page: 0, listId: event.target.value });
    setSelectValue(event.target.value);
  };

  const handleSearchChange = (event) => {
    refetch({ page: 0, search: event.target.value });
  };

  const handlePageChange = (event, page) => {
    refetch({ page: page });
    setPage(page);
  }

  const handleEdit = (id) => {
    props.history.push(`/crm/contacts/edit/${id}`);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const deleteItem = () => {
    deleteCrmContact({ variables: { id: deleteId } })
    .then(({ data }) => {
      setDeleteDialogOpen(false);
      // Refresh page after delete mutation
      props.history.push({ pathname: "/crm/empty" });
      props.history.replace({ pathname: "/crm/contacts" });
    })
    .catch(error => {
      setDeleteDialogOpen(false);
      setErrorDialogOpen(true);
      setErrorMsg(error.toString());
    });
  }

  const createData = (_id, name, surname, email, country, age, _username) => {
    return { _id, name, surname, email, country, age, _username };
  }

  const rows = data && data.crmContacts.records.map((item) => {
    return createData(item._id, item.name, item.surname, item.email, item.country, item.age, item.username)
  });

  return (
    <SandboxLayout layoutData={crmData}>

      <SandboxHeader
        title="Contacts"
        subTitle="You can find and manage your contacts in the list hereby."
        extraStyles={{ marginBottom: 50 }}
        action={
          <Button
            component={RouterLink}
            to={'/crm/contacts/add'}
            className={classes.actionBtn}
            color="default"
            variant="contained"
            startIcon={<AddIcon style={{ fontSize: 24 }}/>}>
            Add Contact
          </Button>
        }
      />

      {loading ? (
        <LoadingSpinner boxed={true} styles={{ paddingTop: 70}}/>
      ) : error ? (
        <div>Error! {error.message}</div>
      ) : (
        <SandboxTable
          page={page}
          onPageChange={handlePageChange}
          count={data.crmContacts.totalCount}
          rowPerPage={data.crmContacts.rowPerPage}
          tableToolbar={[
            <SelectFilter
              key={1}
              selectValue={selectValue}
              onSetPage={setPage}
              selectList={data.crmContacts.lists}
              selectLabel="Contact lists"
              onSelectChange={handleContactListChange}
              count={data.crmContacts.totalCount}
            />,
            <SearchFilter
              key={2}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              onSetPage={setPage}
              searchLabel="Search..."
              onSearchChange={handleSearchChange}
            />
          ]}
        >
          <SandboxTableHeader
            tableColumns={contactsTableColumns}
            tableCellAlign="left"
            lastTableCellAlign="center"
          />
          <SandboxTableBody
            tableColumns={contactsTableColumns}
            rows={rows}
            tableCellAlign="left"
            lastTableCellAlign="center"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </SandboxTable>
      )}

      <SandboxDialog
        id="deleteContactDialog"
        warningIcon={true}
        dialogClose={handleDeleteDialogClose}
        dialogOpen={deleteDialogOpen}
        dialogTitle="Delete item"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">Are you sure you want to delete this item?</Typography>
          </Box>
        }
        dialogActions={
          <>
            <Button
              className={classes.dialogDefaultBtn}
              color="default"
              variant="outlined"
              size="large"
              onClick={handleDeleteDialogClose}
            >Cancel
            </Button>
            <Button
              className={classes.dialogBtn}
              color="default"
              variant="contained"
              size="large"
              onClick={deleteItem}
            >Delete item
            </Button>
          </>
        }
      />
      <SandboxDialog
        id="errorContactDialog"
        errorIcon={true}
        dialogClose={handleErrorDialogClose}
        dialogOpen={errorDialogOpen}
        dialogTitle="Error"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">{errorMsg}</Typography>
          </Box>
        }
        dialogActions={
          <>
            <Button
              className={classes.dialogDefaultBtn}
              color="default"
              variant="outlined"
              size="large"
              onClick={handleErrorDialogClose}
            >Cancel
            </Button>
          </>
        }
      />

    </SandboxLayout>
  )
}

export default CrmContactsPage;
