import React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import SecondStepChallengeLayout from "../SecondStepChallengeLayout";
import validateAdvertiser from "../../../Validations";
import CreativeForm from "../../../creative/CreativeForm";
import { creativeImages, creativeSizes } from "../../../../constants/constants";
import { SecureQuery } from "../../../../settings/graphqlHelper";
import { GET_CHALLENGE_INSTANCE } from '../queries';
import {
  evaluateSecondStep,
  evaluateThirdStep
} from "../../../../settings/commonFunctions";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";

class CreativeAdServerStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {id: props.match.params.id, isClicked: false, ...props};
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  handleChangeType(e) {
    const type = e.currentTarget.textContent;
    this.setState({
      type: type,
      size: creativeSizes[type],
      fileName: creativeImages[type]
    });
  }

  coreSubmit = async values => {
    const { id } = this.props.match.params;
    const action = {
      actionNeeded: "complete",
      target: JSON.stringify({
        type: "creative",
        fields: {
          creativeName: values.name.trim(),
          creativeSize: values.size,
          creativeType: values.type
        }
      })
    };
    let res = await evaluateSecondStep(action, id);
    let value = res.data.challengeStepEvaluator;
    if (value) {
      let finalTarget = await evaluateThirdStep(id);
      if (finalTarget) this.props.history.push(finalTarget + id);
    }
  };

  submit = values => {
    if(!this.state.isClicked){
      this.setState({isClicked: true}, () => {
        this.coreSubmit(values).then(() => {
          setTimeout(() => {
            this.setState({isClicked: false})
          }, 800);
        })
      });
    }
  };

  render() {
    if (!this.state.type)
      return <LoadingAnimation />;

    return (
      <SecondStepChallengeLayout pageName={"Creative"} challengeType={this.state.type}>
        <Formik
          initialValues={{ name: "", type: "" , size: "", fileName:""}}
          validate={validateAdvertiser}
          onSubmit={this.submit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit }) => (
            <CreativeForm
              logo={false}
              caption="Category"
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              creativeImages={creativeImages}
              creativeSizes={creativeSizes}
              isSubmitting={isSubmitting}
              values={values}
            />
          )}
        </Formik>
      </SecondStepChallengeLayout>
    );
  }
}

export default withRouter(CreativeAdServerStep2);
