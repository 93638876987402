import gql from "graphql-tag";

export const CHALLENGE_RESULTS = gql`
  query challengeResults($id: ID!) {
    challengeResults(id: $id) {
      score
      obtainableScore
      title
      solution
      totalSteps
      completedSteps
      time {
        minutes
        seconds
      }
      coach {
        name
        surname
        photo
        linkedin
      }
      stepResults {
        question
        questionType
        actionNeeded
        score
        countdownTime
        obtainableScore
        imageLink
        imageCarousel {
          link
          title
        }
        time {
          minutes
          seconds
        }
        answer {
          isCorrect
          key
          value
          correctAnswer
          errorType
        }
      }
      nextChallenge {
        id
      }
      availableCareers {
        name
        obtainableScore
        score
        totalChallenges
        totalFinishedChallenges,
        status
        slug
      }
      isTrial,
      finishedCareer {
        name
        obtainableScore
        score
        totalChallenges
        totalFinishedChallenges,
        status
        slug
      }
      finishedLearningTopic {
        name
        score
        badge
      }
    }
  }
`;
