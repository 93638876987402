import React from 'react';
import Background from "../background.jpg";

const FullSimpleLayout = (props) => (
        <div className="wrapper wrapper-full-page">
            <div className="page-header login-page header-filter"
                 style={{backgroundPosition: "top center", backgroundSize: "cover", backgroundImage: `url(${Background})`}}
                 filter-color="black">
                <div className="container">
                    {props.children}
                </div>
            </div>
        </div>
    );

export default FullSimpleLayout;
