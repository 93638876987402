import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { List, ListSubheader, ListItem, ListItemIcon, ListItemText, Collapse, Icon } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  listRoot: {},
  listItem: {
    backgroundColor: theme.palette.grey[50],
    border: `solid 2px ${theme.palette.grey[500]}`,
    borderRadius: 6,
    marginTop: 12,
    marginBottom: 12,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  nestedListRoot: {
    marginLeft: theme.spacing(3),
  },
  nestedListItem: {
    border: `solid 2px ${theme.palette.grey[500]}`,
    borderRadius: 6,
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0,
    },
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  listSubheader: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    margin: 0,
    lineHeight: 2.4
  }
}));

const ListNodeButtons = ({ dialogListNodes, dialogListName, onNodeButtonClick, action }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState({});

  return (
    <List
      component="nav"
      className={classes.listRoot}
      subheader={ dialogListName &&
        <ListSubheader component="div" className={classes.listSubheader}>
          {dialogListName}
        </ListSubheader>
      }
    >
      {dialogListNodes.map(item => {

        const nodeType = item.type;
        let color;
        switch (nodeType) {
          case 'entry':
            color = theme.palette.automation.darkBlue[500];
            break;
          case 'action':
            color = theme.palette.automation.blue[500];
            break;
          case 'condition':
            color = theme.palette.automation.green[500];
            break;
          default:
            color = theme.palette.grey[400];
        }

        return (
          <div key={item.id}>

            <ListItem
              button
              onClick={item.items !== null ? () => setOpen((prevState => ({...prevState, [item.id]: !prevState[item.id]}))) : () => onNodeButtonClick(item.id, action)}
              className={classes.listItem}
              style={{ border: `solid 2px ${color}`, }}
            >
              <ListItemIcon>
                <Icon style={{ color: color }}>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText primary={item.title} />
              {item.items !== null ? open[item.id] ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>

            {item.items !== null &&
              (
                <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    className={classes.nestedListRoot}
                  >
                  {item.items.map(nestedItem => {
                    return (
                      <ListItem
                        key={nestedItem.title}
                        button
                        onClick={() => onNodeButtonClick(nestedItem.id, action)}
                        className={classes.nestedListItem}
                        style={{ border: `solid 2px ${color}` }}
                      >
                        <ListItemIcon>
                          <Icon style={{ color: color }}>{nestedItem.icon}</Icon>
                        </ListItemIcon>
                        <ListItemText primary={nestedItem.title} />
                      </ListItem>
                    )
                  })}
                  </List>
                </Collapse>
              )
            }
          </div>
        )
      })}
    </List>

  );
}

export default ListNodeButtons;
