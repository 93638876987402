import gql from "graphql-tag";

export const GET_CHALLENGE_DEFINITIONS = gql`
query challengeDefinitions{
  challengeDefinitions {
    id
    challengeName
    challengeTitle
    challengeDifficulty
    totalObtainableScore
  }
  }
`;
export const GET_CHALLENGES = gql`
   query challengesAll( $page: Int) {
     challengesAll(page: $page) {
       records {
         _id
         challengeType
         scores
         currentStep
       }
       pages
       totalCount
     }
   }
 `;

export const GET_USER_DATA = gql`
 query me {
  me {
        phone
        birthDate
        country
        city
        name
        surname
        photo
        sigla
        email
        description
        token
        password
        linkedin
        courseGroups{
          _id
          name
          groupChallenges
        }
        userRole
 }
}
`;