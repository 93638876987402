import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
//import Layout from "../../common/Layout";
import plusButtonStyle from "../../settings/otherStyles";
import SimpleSearch from "../../common/components/data-table/SimpleSearch";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

const AdvertiserList = (props) => {
  return (
    <SandboxLayout name="Advertiser" layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
        <Fab
          onClick={props._add}
          color="primary"
          aria-label="Add"
          className={plusButtonStyle.fab}
        >
          <AddIcon />
        </Fab>
        <div className="row">
          <div className="col-sm-12 col-md-10" />
          <div className="col-sm-12 col-md-2">
            <SimpleSearch keyPress={props._keyPress} />
          </div>
        </div>
        {props.children}
      </div>
    </SandboxLayout>
  );
};

export default AdvertiserList;
