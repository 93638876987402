import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputAdornment, OutlinedInput} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  searchField: {
    minWidth: 240,
    //'& .MuiInputBase-adornedStart': {},
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    },
    '& .MuiIconButton-root': {
      padding: 6,
      marginRight: -7,
      '&:focus': {
        outline: 'none',
      },
    }
  },
  //searchInput: {},
}));

const SearchFilter = ({ onSearchChange,  searchLabel, onSetPage, searchValue, setSearchValue }) => {
  const classes = useStyles();

  const handleSearchChange = (event) => {
    if (event.keyCode === 13) {
      onSetPage(0);
      onSearchChange(event)
    }
    setSearchValue(event.target.value);
  };

  return (
    <Box>
      <FormControl
        variant="outlined"
        size="small"
        className={classes.searchField}
        fullWidth
      >
        <OutlinedInput
          id="search"
          name="search"
          autoComplete="Search"
          placeholder={searchLabel}
          type={'text'}
          value={searchValue}
          onChange={handleSearchChange}
          onKeyDown={handleSearchChange}
          inputProps={{className: classes.searchInput}}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon style={{ color: 'rgba(0, 0, 0, 0.54)', }} />
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
}

export default SearchFilter;
