import React, {Component} from 'react';
//import Layout from '../../common/Layout';
import {
  ADD_CAMPAIGN,
  //queryLineItemAdsTag
} from "./queries";
import CampaignForm from './CampaignForm';
// import {ApolloClient} from "@apollo/client";
// import {apolloSecureConfig, client} from "../../settings/apollo";
// import {queryLineItems, GET_LOCAL_STATE} from "./queries";
import {
  //CacheItems,
  lineItemsVar
} from "../../settings/cache";
import {SecureQuery} from "../../settings/graphqlHelper";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

class CampaignInsert extends Component {
  constructor(props) {
    super(props);
    this.state = {items: []};
    this.handleAdvertiser = this.handleAdvertiser.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.handleName = this.handleName.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleAdvertiser(item) {
    this.setState({advertiser: item != null ? item.label : item});
  }

  handleName(e) {
    this.setState({name: e.target.value});
  }

  handleDuration(item) {
    this.setState({duration: item != null ? item.label : item});
  }

  submit() {
    if (this.state.name && this.state.name !== '' && this.state.duration !== undefined && this.state.duration !== null) {
      let items = lineItemsVar();
      SecureQuery.mutate({
        mutation: ADD_CAMPAIGN,
        variables: {
          input:
            {
              name: this.state.name,
              advertiser: this.state.advertiser,
              duration: this.state.duration,
              lineItems: items
            }
        },
      }).catch((error) => {
        console.log(error);
      }).then(() => this.props.history.push('/campaigns'));
    }
  }

  render() {
    return (
      <SandboxLayout name="Campaign" layoutData={campaignAdsManagerData}>
        <CampaignForm handleAdvertiser={this.handleAdvertiser}
                      handleDuration={this.handleDuration}
                      handleName={this.handleName}
                      caption="Category"
                      submit={this.submit}
                      duration={this.state.duration}
                      advertise={this.state.advertiser}
                      name={this.state.name}
        />
      </SandboxLayout>
    );
  }
}

export default CampaignInsert;
