import gql from "graphql-tag";

// Mutations for the contacts section
// -----------------------------------------------------
export const CREATE_CRM_CONTACT = gql`
  mutation createCrmContact($input: CrmContactInput) {
    createCrmContact(input: $input){
      actions
      _id
      name
      surname
      email
      country
      age
      username
    }
  }
`;

export const UPDATE_CRM_CONTACT = gql`
  mutation updateCrmContact($id: ID!, $input: CrmContactInput) {
    updateCrmContact(id: $id, input: $input)
  }
`;

export const DELETE_CRM_CONTACT = gql`
  mutation deleteCrmContact($id: ID!) {
    deleteCrmContact(id: $id)
  }
`;

// Mutations for the lists section
// -----------------------------------------------------
export const CREATE_CRM_LIST = gql`
  mutation createCrmList($input: CrmListInput) {
    createCrmList(input: $input){
      _id
      name
    }
  }
`;

export const UPDATE_CRM_LIST = gql`
  mutation updateCrmList($id: ID!, $input: CrmListInput) {
    updateCrmList(id: $id, input: $input)
  }
`;

export const DELETE_CRM_LIST = gql`
  mutation deleteCrmList($id: ID!) {
    deleteCrmList(id: $id)
  }
`;

// Mutations for the Automation section
// -----------------------------------------------------
export const DELETE_CRM_AUTOMATION = gql`
  mutation deleteCrmAutomation($id: ID!) {
    deleteCrmAutomation(id: $id)
  }
`;

export const CREATE_CRM_AUTOMATION = gql`
  mutation createAutomation($input: CrmAutomationInput){
    createCrmAutomation(input: $input ){
      _id
      name
    }
  }
`;

export const UPDATE_CRM_AUTOMATION = gql`
  mutation updateCrmAutomation($id: ID!, $input: CrmAutomationInput!){
    updateCrmAutomation(id: $id, input: $input)
  }
`;

export const CREATE_CRM_ENTRY_NODE = gql`
  mutation createCrmEntryNode($id: ID!, $node: CrmAutomationNodeInput!){
    createCrmEntryNode(id: $id, node: $node ){
      _id
    }
  }
`;

export const CREATE_CRM_CONDITION_NODE = gql`
  mutation createCrmConditionNode($id: ID!, $node: CrmAutomationNodeInput!, $parent: ID!){
    createCrmConditionNode(id: $id, node: $node, parent: $parent){
      _id
    }
  }
`;

export const CREATE_CRM_ACTION_NODE = gql`
  mutation createCrmActionNode($id: ID!, $node: CrmAutomationNodeInput!, $parent: ID!){
    createCrmActionNode(id: $id, node: $node, parent: $parent){
      _id
    }
  }
`;

export const UPDATE_CRM_AUTOMATION_NODE = gql`
  mutation updateCrmAutomationNode($automationId: ID!, $nodeId: ID!, $node: CrmAutomationNodeInput!){
    updateCrmAutomationNode(automationId: $automationId, nodeId: $nodeId, node: $node )
  }
`;

export const DELETE_CRM_AUTOMATION_NODE = gql`
  mutation deleteCrmAutomationNode($id: ID!){
    deleteCrmAutomationNode(id: $id)
  }
`;

export const ACTIVATE_CRM_AUTOMATION = gql`
  mutation activateAutomation($id: ID!){
    activateAutomation(id: $id)
  }
`;

export const DEACTIVATE_CRM_AUTOMATION = gql`
  mutation deactivateAutomation($id: ID!){
    deactivateAutomation(id: $id)
  }
`;
