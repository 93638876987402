import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Paper, LinearProgress } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { normalise } from "../../../utils/helpers/helpers";

const useStyles = makeStyles(theme => ({
  progressBarColor: {
    marginTop: theme.typography.pxToRem(10),
    backgroundColor: lighten(theme.palette.common.gamsPetroleumBlue, 0.6),
    '& > .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
    }
  },
  percentScore: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  scoreContainer: {
    fontSize: theme.typography.pxToRem(14),
    '&>span': {
      color: theme.palette.text.secondary,
    }
  },
  paperContainerTitle: {
    marginTop: theme.typography.pxToRem(18),
  },
  ctaBtn: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.common.gamsDarkBlue,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(13),
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },    
  premiumBtn: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },      
  finishedBtn: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
  tableBtn: {
    lineHeight: 0.7,
    textTransform: 'none',
    borderRadius: 15,
    fontSize: theme.typography.pxToRem(12),
  },
  paperContainer: {
    overflow: 'hidden',
    padding: theme.typography.pxToRem(16),
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 20,
      marginBottom:15
    },
  },
  dialogCaption: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  dialogActions: {
    padding: theme.spacing(0, 3),
  },
}));

const MiniCareerBrief = ({ career, buttonTitle, isTrial }) => {
  const classes = useStyles();

  const careerStatus = career.status;
  const isProgressCareerStatus = careerStatus === 'in progress';
  const normalizedValue = normalise(career.totalFinishedChallenges, 0, career.totalChallenges);

  return (
    <Paper className={classes.paperContainer} variant="outlined">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" component="h4" className={classes.dialogCaption}>{career.name}</Typography>
        {!isTrial ? <Button
          size="medium"
          variant="outlined"
          component={RouterLink}
          className={classes.ctaBtn}
          to={`/career-paths/${career.slug}`}>
          {buttonTitle}
        </Button> : <Button
            className={`${classes.tableBtn} ${classes.premiumBtn}`}
            component={RouterLink}          
            to="/subscription"
          >In Premium plan </Button>}
      </Box>
      {!isTrial && careerStatus !== 'not started' ? <Box>
        <Box className={classes.paperContainerTitle} display="flex" justifyContent="space-between" alignItems="center">
          {careerStatus === 'finished' ? <Button
            className={`${classes.tableBtn} ${classes.finishedBtn}`}
          >Completed </Button> : null}
          {isProgressCareerStatus ? <Typography component="span" className={classes.percentScore}>{Math.round(normalizedValue)}%</Typography> : null}
          <Typography className={`${classes.scoreContainer}`}>Score: {career.score} of <span>{career.obtainableScore}</span></Typography>
        </Box>
        {isProgressCareerStatus ? <LinearProgress
            variant="determinate"
            value={normalizedValue}
            className={`${classes.progressBarColor}`}/> : null}
      </Box> : null}
  </Paper>
  );
}

export default MiniCareerBrief;
