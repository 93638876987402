import React, { Component } from 'react';
import Layout from '../../common/Layout';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import plusButtonStyle from '../../settings/otherStyles';
import { client } from '../../settings/apollo';
import CSVService from '../CSVservice';
import AdminSettingsNavigation from '../adminSettings/AdminSettingsNavigation';
import '../users/UserList.css';
import CalendarDialog from '../../common/components/Dialogs/CalendarDialog/CalendarDialog';
import moment from 'moment';
import Swal from 'sweetalert2';
import { GET_COACHES, GET_COACHES_TABLE, MY_DATA } from './graphql/queries';
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';
import SimpleSearch from '../../common/components/data-table/SimpleSearch';
const columns = [
  "sigla",
  "name",
  "surname",
  "email",
  "expirationDate",
  "actions"
];

const UPDATE_USERS_EXPIRATION_DATE = gql`
  mutation updateUsersExpirationDate($input: UsersExpirationDateInput) {
    updateUsersExpirationDate(input: $input)
  }
`;

class Coaches extends Component {
  static propTypes = {
    page: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      page: props.match.params.page ? parseInt(props.match.params.page) : 0,
      selectedIndexes: [],
      users: []
    };
    this.datePickerDialog = React.createRef();
    this.openDatePickerDialog = this.openDatePickerDialog.bind(this);
    this.handleSelectedIndexes = this.handleSelectedIndexes.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._add = this._add.bind(this);
    this._first = this._first.bind(this);
    this._last = this._last.bind(this);
    this._first = this._first.bind(this);
    this._keyPress = this._keyPress.bind(this);
    this.today = moment();
    this.client = client;
  }

  _previousPage(e) {
    e.preventDefault();

    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 });
      this.props.history.push(
        "/admin-settings/coaches/" + (parseInt(this.state.page) - 1)
      );
    } else {
      this.setState({ page: 0 });
      this.props.history.push('/admin-settings/coaches');
    }
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
    this.props.history.push(
      '/admin-settings/coaches/' + (parseInt(this.state.page) + 1)
    );
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0 });
    this.props.history.push('/admin-settings/coaches');
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
    this.props.history.push('/admin-settings/coaches/' + (pages - 1));
  }

  _add(event) {
    this.props.history.push('/admin-settings/register');
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({
        name: e.target.value,
        page: 0
      });
    }
  }

  isSelected(user) {
    return user._id.this.state.selectedIndexes;
  }

  handleDateChange = date => {
    this.setState({ selectedIndexes: [], users: [] });

    const ids = this.state.selectedIndexes.map(index => {
      return this.state.users[index]._id;
    });
    const { error } = this.client.mutate({
      mutation: UPDATE_USERS_EXPIRATION_DATE,
      variables: { input: { expirationDate: date, ids: ids } }
    });
    if (error) console.log("error changing date");
    else
      Swal.fire({
        type: "success",
        title: "Expiring date changed.",
        showConfirmButton: false,
        timer: 1500
      });
  };

  openDatePickerDialog = () => {
    if (this.state.selectedIndexes.length === 0) {
      Swal.fire({
        type: "warning",
        text: "Select users first.",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close"
      });
      return;
    }
    this.datePickerDialog.current.open();
  };

  handleSelectedIndexes = (selectedIndexes, records) => {
    selectedIndexes.map(i => console.log("selected record", records[i]));
    this.setState({ selectedIndexes: selectedIndexes });
    this.setState({ users: records });
  };

  handleDeleteAction = () => {
    return false;
  };

  highlightRecord(record) {
    return moment(record.expirationDate).isBefore(this.today);
  }

  render() {
    return (
      <Layout name="Users">
        <div className="user-list">
          <Query query={MY_DATA} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading)
                return <LoadingSpinner styles={{ paddingTop: 30 }} />;
              if (error) return `Error! ${error.message}`;
              const userData = data.me;
              const UserGroupList = [];
              const coachGroups = [];
              const courseGroups = data.me.courseGroups.map(item => item);
              courseGroups.map(item => {
                UserGroupList.push(item._id);
                coachGroups.push({ value: item._id, label: item.name });
                return null;
              });
              return (
                <div className="wizard-container">
                  {userData.userRole === "superuser" ? (
                    <Fab
                      onClick={this._add}
                      color="primary"
                      aria-label="Add"
                      className={plusButtonStyle.fab}
                    >
                      <AddIcon />
                    </Fab>
                  ) : null}

                  <div className="row">
                    <div className="col-sm-12 col-md-10" />
                    <div className="col-sm-12 col-md-2">
                      <SimpleSearch keyPress={this._keyPress} />
                    </div>
                  </div>
                  <AdminSettingsNavigation path={this.props.match.path} />

                  <Query
                    query={GET_COACHES}
                    fetchPolicy="network-only"
                    variables={{
                      name: this.state.name,
                      page: this.state.page,
                      courseGroups: UserGroupList,
                      userGroup: this.state.userGroup,
                    }}
                  >
                    {({ loading, error, data, previousData }) => {
                      if (loading)
                        return (
                          <LoadingSpinner
                            boxed={true}
                            styles={{
                              paddingTop: 40,
                              marginTop: 15,
                              marginBottom: 30
                            }}
                          />
                        );
                      if (error) return `Error! ${error.message}`;
                      if (data.coaches === null)
                        return (
                          <b>
                            This feature is available just to Administrators
                          </b>
                        );
                      return (
                        <DataTable
                          header="Users"
                          editUrl="/admin-settings/users/"
                          pages={data.coaches.pages}
                          columns={columns}
                          records={data.coaches}
                          deleteRow={this._deleteRow}
                          keyColumn="_id"
                          page={this.state.page}
                          first={this._first}
                          last={this._last.bind(this, data.coaches.pages)}
                          next={this._nextPage}
                          previous={this._previousPage}
                          checkboxes={true}
                          selectedIndexes={this.state.selectedIndexes}
                          onSelectedIndex={this.handleSelectedIndexes}
                          showDeleteIcon={this.handleDeleteAction}
                          highlightRecord={this.highlightRecord}
                        />
                      );
                    }}
                  </Query>
                  <Query
                    query={GET_COACHES_TABLE}
                    fetchPolicy="network-only"
                    variables={{
                      name: this.state.name,
                      courseGroups: UserGroupList,
                      userGroup: this.state.userGroup,
                      userRole: "coach"
                    }}
                  >
                    {({ loading, error, data }) => {
                      if (loading)
                        return <LoadingSpinner styles={{ paddingTop: 30 }} />;
                      if (error) return `Error! ${error.message}`;
                      if (data.coaches === null)
                        return (
                          <b>
                            This feature is available just to Administrators
                          </b>
                        );
                      let usersList = [];
                      data.coaches.records.map((item, index) => {
                        if (item.courseGroups) {
                          //const courses = item.courseGroups.map((item) => item.name)
                        }
                        usersList.push({
                          _id: item._id,
                          name: item.name,
                          surname: item.surname,
                          sigla: item.sigla,
                          email: item.email,
                          _role: item.userRole,
                          _userGroup: item.courseGroups,
                          PrivacyPolicy: item.privacyPolicy,
                          ProfileScorecard: item.profileScorecard,
                          PublishAchievement: item.publishAchievement
                        });
                        return null;
                      });
                      return (
                        <CSVService
                          label={"Download Users Data"}
                          fileName={"GAMSUserData.csv"}
                          csvData={usersList.map((item, index) => item)}
                        />
                      );
                    }}
                  </Query>
                </div>
              );
            }}
          </Query>

          <CalendarDialog
            value={moment()}
            onHandleDateChange={this.handleDateChange}
            ref={this.datePickerDialog}
            styleDialog={{ display: "none" }}
          />
        </div>
      </Layout>
    );
  }
}

export default Coaches;
