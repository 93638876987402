import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
  },
  formControlLabel: {
    ...theme.typography.body1,
    color: theme.palette.text.primary
  },
  blue900: {
    color: `${blue[900]} !important`
  }
}))

const RadioModule = props => {
  const {
    onChanged,
    stepIndex
  } = props;
  const classes = useStyles();

  const [value, setValue] = useState('');
  const [changed, setChanged] = useState(false);
  const [lastIndex, setLastIndex] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    const changed = onChanged(event.target.value);
    setChanged(changed);
    setLastIndex(stepIndex);
  };

  useEffect(() => {
    if (changed && stepIndex !== lastIndex) setValue('');
  }, [changed, stepIndex, lastIndex])
  
  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <RadioGroup aria-label="t/f" name="t/f" value={value} onChange={handleChange}>
          <FormControlLabel
            className={classes.formControlLabel}
            type="Radio"
            name="t/f"
            value={"TRUE"}
            control={<Radio color="default" classes={{ checked: classes.blue900 }} />}
            label="True"
          />
          <FormControlLabel
            className={classes.formControlLabel}
            type="Radio"
            name="t/f"
            value={"FALSE"}
            control={<Radio color="default" classes={{ checked: classes.blue900 }}/>}
            label="False"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RadioModule;
