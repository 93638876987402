import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link } from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { lighten } from '@material-ui/core/styles';
import MiniCareerBrief from '../../MiniCareerBrief/MiniCareerBrief';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  alert: {
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
    color: theme.palette.text.secondary,
    marginBottom: '0.5rem',
  },
  link: {
    textDecoration: 'underline!important',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.secondary,
    }
  },
  dialogBtnDefault: {
    color: theme.palette.common.gamsDarkBlue,
    '&:focus': {
      outline: 'none',
      color: 'inherit',
    },
    '&:hover': {
      color: 'inherit',
    }
  },
  otherCareersTitle: {
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.typography.pxToRem(16)
  },
  dialogContent: {
    '&>*': {
      '&:first-child': {
        marginBottom: theme.typography.pxToRem(32)
      }
    }
  },
  otherCareers: {
    '& >*': {
      marginBottom: theme.typography.pxToRem(16),
    }
  },
  dialogRoot: {
    padding: theme.spacing(2.5, 0, 3.5, 0),
    borderRadius: 8,
    minWidth: '444px',
    maxWidth: '444px',
  },
  dialogHeading: {
    paddingTop: 0
  },
  dialogTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(27),
  },
  dialogCaption: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
  },
  dialogCaptionSpacer: {
    paddingTop: theme.typography.pxToRem(8),
  },
  dialogActions: {
    padding: theme.spacing(0, 1),
  },
  icon: {
    fontSize: theme.typography.pxToRem(27),
  },
  successGreenColor: {
    color: theme.palette.success.main
  },
  iconContainer: {
    backgroundColor: lighten(theme.palette.common.green[800], 0.9),
    width: theme.typography.pxToRem(56),
    height: theme.typography.pxToRem(56),
    margin: '0 auto',
    justifyContent: 'inherit',
    display: 'inherit',
    alignItems: 'inherit',
    borderRadius: '50%'
  },
}));

const PendingCareersDialog = ({ isTrial, finishedCareer, otherCareers, dialogOpen, dialogClose, dialogTitle, dialogCaption, dialogDiscoverOtherCareers, id }) => {
  const classes = useStyles();

  const getButtonTitle = (status) => {
    switch (status) {
      case 'finished':
        return 'DETAILS';
      case 'in progress':
        return 'CONTINUE';
      case 'not started':
        return 'START';
      default:
        return 'TO IMPLEMENT'
    }
  };

  const destinationSettings = {
    route: isTrial ? '/subscription' : '/career-paths',
    title: isTrial ? 'UPGRADE NOW' : 'CAREER PAGE'
  }

  return (
    <Dialog
      id={id}
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{
        paper: classes.dialogRoot
      }}
    >
      <DialogTitle className={classes.dialogHeading}>
        <Typography variant="h3" component="h3" className={classes.dialogTitle}>{dialogTitle}</Typography>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box className={`${classes.iconContainer}`}>
            <CheckCircleOutlineRoundedIcon className={`${classes.icon} ${classes.successGreenColor}`}/>
          </Box>
          <Typography variant="h4" component="h4" className={`${classes.dialogCaption} ${classes.dialogCaptionSpacer}`}>{dialogCaption}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <MiniCareerBrief career={finishedCareer} buttonTitle={getButtonTitle(finishedCareer.status)}></MiniCareerBrief>
        {otherCareers.length > 0 ? <Box>
          <Typography variant="h3" component="h3" className={classes.otherCareersTitle}>{dialogDiscoverOtherCareers}</Typography>
          <Box className={`${classes.otherCareers}`}>
          {otherCareers.map((fc) =>
          <MiniCareerBrief isTrial={isTrial} key={fc.id} career={fc} buttonTitle={getButtonTitle(fc.status)}></MiniCareerBrief>
        )}
        </Box>
      </Box> : null}
      {isTrial ? <Alert
        className={classes.alert}
        severity="warning"
        >
        <AlertTitle className={classes.alertTitle}>
        You have completed all the available careers
        </AlertTitle>
        <Box className={classes.alertSubtitle}>
          On the free plan, you’re limited to one career. For all careers and more, <Link
            className={classes.link}
            component={RouterLink}
            to="/subscription"
          >check out our Premium plan.</Link>
        </Box>
      </Alert> : null}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.dialogBtnDefault}
            color="primary"
            size="large"
            onClick={dialogClose}
          >CLOSE</Button>
          <Button
            className={classes.dialogBtnDefault}
            color="primary"
            size="large"
            component={RouterLink}
            to={destinationSettings.route}
          >{destinationSettings.title}
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PendingCareersDialog;
