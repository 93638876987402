import React from "react";
import SimpleDropDown from "../../common/components/base/SimpleDropDown";
import { ErrorMessage } from "formik";
import { movingTab, width100 } from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";
import { creativeTypes } from "../../constants/constants";

class CreativeForm extends React.Component {
  requiredText = "Creative Name (required)";
  countClick = 0;

  constructor(props) {
    super(props);

    if (props.update) {
      this.requiredText = "";
      this.countClick = 0;
    }
    this.state = { ...props, isClicked: false };

    this.handleClickSubmit = this.handleClickSubmit.bind(this);
  }

  onChange(event){
    const{ setFieldValue, creativeImages, creativeSizes}= this.props
    setFieldValue("type", event);
    setFieldValue("size", creativeSizes[event]);
    setFieldValue("fileName", creativeImages[event]);

  }

  handleClickSubmit(values){
    this.countClick++
    if (this.countClick === 1) {
      console.log('dentro', this.countClick);
      this.props.handleSubmit(values);
    }
    setTimeout(()=> (this.countClick = 0), 1000);
  }

  render() {
    const { values, handleChange } = this.props;
    return (
      <>
        <div className="card-header text-center">
          <h3 className="card-title">Creative</h3>
        </div>
        <div className="wizard-navigation">
          <div className="moving-tab" style={movingTab}>
            Info
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="input-group form-control-lg">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">face</i>
                </span>
              </div>
              <div className="form-group bmd-form-group">
                <TextField
                  required
                  label={this.requiredText}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  margin="normal"
                  style={width100}
                />
                <ErrorMessage name="name" component="div" />
              </div>
              <div className="col-md-12 ml-auto mr-auto">
                <div className="input-group form-control-lg">
                  <div className="form-group bmd-form-group">
                    <SimpleDropDown
                      key="2"
                      handleChange={(event)=>{this.onChange(event.currentTarget.textContent)}}
                      id="type"
                      name="Creatives Type"
                      items={Object.values(creativeTypes)}
                      caption={values.type || "Creatives Type"}
                      header="Select Creative"
                    />
                  </div>
                </div>
              </div>
              {values.fileName ? (
              <div className="col-md-12">
                <img
                  src={'../../assets/img/' + values.fileName}
                  className="img-fluid"
                  alt=" "
                />
              </div>
                ) : null}
              <div className="form-group bmd-form-group">
                <button
                  type="button"
                  onClick={this.handleClickSubmit}
                  disabled={ this.countClick >= 1}
                  className="btn btn-next btn-fill btn-rose btn-wd"
                  name="save"
                  value="Save"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreativeForm;
