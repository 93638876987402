import { gql, useQuery } from '@apollo/client';
import { client } from '../../settings/apollo';

const GET_USERS_BY_NAME = gql`
    query usersNotInUserGroup($name: String, $userGroup: String){
        usersNotInUserGroup(name: $name, userGroup: $userGroup){
        records{
            _id
            email
            name
            surname
            sigla
        }
        pages
        totalCount
        }
    }
`;
function SearchUsers (props) {
    const handleSearchResult = props.handleSearchResult;
    const name=props.name;
    const userGroupId=props.userGroupId;

    const {
      loading,
      error,
      //data,
      previousData
    } = useQuery(GET_USERS_BY_NAME, {
      variables: {
        name: name,
        userGroup: userGroupId
      },
      fetchPolicy: "network-only",
      client: client,
      skip: name===undefined,
      onCompleted: (data) => {
        if(data!==previousData) {
          handleSearchResult(data);
        }
      }
    })
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    return null;
}

export default SearchUsers
