import React from "react";

import { creativeColumns } from "../../../../constants/columns";
import FirstStepQuery from "../FirstStepQuery";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";

const CreativesAdServerStep1 = ({ _add, type }) => {
  return (
    <TableLayout name={"Creatives"} _add={_add}>
      <FirstStepQuery
        tableHeader={"Creatives"}
        tableColumns={creativeColumns}
        challengeType={type}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "new-creative"
};

export default withChallengeInstance(CreativesAdServerStep1, action);
