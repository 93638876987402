import React, { useContext, useState } from 'react';
import { CHALLENGE_RESULTS } from '../ChallengeResults/graphql/queries';
import { client } from "../../settings/apollo";
import {
  getChallenge,
  saveChallengeInstance
} from "../challenges/AdServer/simulation";
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { HashLink } from 'react-router-hash-link';
import { Container, Button, Box } from '@material-ui/core';
import Layout from '../../common/Layout';
import ChallengeResultHead from '../../common/components/ChallengeResultHead/ChallengeResultHead';
import ChallengeResultSteps from '../../common/components/ChallengeResultSteps/ChallengeResultSteps';
import ChallengeContext from '../../store/challenge-context';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ChallengeDialog from "../../common/components/ChallengeDialog";
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';
import PendingCareersDialog from "../../common/components/Dialogs/PendingCareersDialog/PendingCareersDialog";
import LearningTopicCompletedDialog from '../../common/components/Dialogs/LearningTopicCompletedDialog/LearningTopicCompletedDialog';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  goBackBtn: {
    marginBottom:10,
    [theme.breakpoints.down('xs')]: {
      marginBottom:35,
      fontSize: theme.typography.pxToRem(12),
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
    '&:focus': {
      outline: 'none',
    },
  },
}));

const ChallengeResults = (props) => {
  const classes = useStyles();
  const showDialog = props.match.params.showDialog ? !!parseInt(props.match.params.showDialog, 10) : true;
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [learningTopicFinishedOpen, setLearningTopicFinishedOpen] = useState(showDialog);
  const [hasReadAllDialogs, setHasReadAllDialogs] = useState(!showDialog);

  const [state, setState] = useState({
    challengesToDo: [],
    selectedChallengeDescription: "",
    selectedChallengeType: "",
    selectedChallengeTopic: "",
    selectedChallengeInitialLocation: "",
    selectedChallengeCoach: null,
    selectedChallengeTitle: null,
    selectedChallengeIsValidation: null,
    selectedChallengeDifficulty: null,
    selectedChallengeAverageTime: null,
    open: false
  });
  const context = useContext(ChallengeContext);

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    setHasReadAllDialogs(true);
  };

  const handleLearningTopicFinishedOpen = () => {
    setLearningTopicFinishedOpen(false);
    setSuccessDialogOpen(true);
  };

  const {
    loading,
    error,
    data
  } = useQuery(CHALLENGE_RESULTS, {
    variables: {
      id: props.match.params.id,
    }
  });

  if (loading) {
    return (
      <Layout>
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      </Layout>
    );
  }
  if (error) {
    return (
      <Layout>
        <div>Error! {error.message}</div>
      </Layout>
    );
  }

  const {score, obtainableScore, completedSteps, solution, coach, stepResults, time, totalSteps, nextChallenge, availableCareers, isTrial, finishedCareer, finishedLearningTopic} = data.challengeResults;
  if (!hasReadAllDialogs && !successDialogOpen && !finishedLearningTopic) {
    setSuccessDialogOpen(true);
  }

  async function handleOpen(id, restart) {
    const selectedChallenge = await getChallenge(id, client);
    const selectedChallengeDescription = selectedChallenge.challengeDescription;
    const selectedChallengeInitialLocation = selectedChallenge.steps[0].initialLocationSimulation;
    const selectedChallengeLearningTopic = selectedChallenge.challengeLearningTopic;
    const selectedChallengeCoach = selectedChallenge.coach;
    const selectedChallengeTitle = selectedChallenge.challengeTitle
    const selectedChallengeIsValidation = selectedChallenge.isValidation
    const selectedChallengeDifficulty = selectedChallenge.challengeDifficulty
    const selectedChallengeAverageTime = selectedChallenge.averageTime
    setState({
      selectedChallengeDescription,
      selectedChallengeInitialLocation,
      selectedChallengeType: id,
      selectedChallengeLearningTopic,
      selectedChallengeCoach,
      selectedChallengeTitle,
      selectedChallengeIsValidation,
      selectedChallengeDifficulty,
      selectedChallengeAverageTime,
      restart: restart || false,
      open: true
    });
  }

  const handleClose = () => {
    setState({ open: false });
  };

  async function handleSave() {
    const {
      selectedChallengeType,
      selectedChallengeInitialLocation,
      restart
    } = state;
    const id = await saveChallengeInstance(selectedChallengeType, client, restart);
    props.setChallengeOrigineRoute(context.challengeOrigineRoute) // set data in ChallengeContext
    props.history.push(`${selectedChallengeInitialLocation}/${id}`)
  }

  let btnRoute = null;
  if (context.challengeOrigineRoute) {
    btnRoute = `${context.challengeOrigineRoute}#learningTopics`;
  } else {
    btnRoute = '/';
  }

  const steps = `${completedSteps}/${totalSteps}`;
  const totalScore = `${score}/${obtainableScore}`;
  const nextChallengeId = nextChallenge ? nextChallenge.id : null;
  // const otherCareers = _.sortBy(availableCareers, [(o) => o.status !== 'not started' ]);

  return (
    <Layout>
      <Box
        component="div"
        display="flex"
        justifyContent="space-between">
        <Button
          startIcon={<ArrowBackIcon />}
          component={HashLink}
          to={btnRoute}
          className={classes.goBackBtn}
        >Back to Challenges</Button>
        {nextChallengeId &&
          <Button
            endIcon={<ArrowForwardIcon />}
            onClick={() => { handleOpen(nextChallengeId, true) }}
            className={classes.goBackBtn}
          >Next Challenge</Button>
        }
      </Box>
      <Container className={classes.container} maxWidth="md" disableGutters>
        <ChallengeResultHead
          coach={coach}
          totalSteps={steps}
          totalTime={time}
          totalScore={totalScore}
          solutionDesc={"Are you struggling to understand why you mistake on some steps? No worries, check the solution below. We've made for you a complete step-by-step solution guide to help you to understand each step's challenge."}
          solutionSrc={solution}
        />
        <ChallengeResultSteps
          stepResults={stepResults}
        />
      </Container>
      <ChallengeDialog
        //title="Start Challenge"
        handleUndo={handleClose}
        handleClose={handleClose}
        handleSave={handleSave}
        open={state.open}
        saveName="START CHALLENGE"
        button={true}
        challengeDashboard={true}
        coach={state.selectedChallengeCoach}
        brief={state.selectedChallengeDescription}
        challengeTitle={state.selectedChallengeTitle}
        challengeIsValidation={state.selectedChallengeIsValidation}
        challengeDifficulty={state.selectedChallengeDifficulty}
        challengeAverageTime={state.selectedChallengeAverageTime}
      />
      {/* {finishedCareer ? <PendingCareersDialog
        finishedCareer={finishedCareer}
        // otherCareers={otherCareers}
        dialogClose={handleSuccessDialogClose}
        dialogOpen={successDialogOpen}
        isTrial={isTrial}
        dialogTitle={"Career completed"}
        dialogCaption={"Well done! You have completed the career."}
        dialogDiscoverOtherCareers={"Continue with other careers:"}
      /> : null}
      {finishedLearningTopic ? <LearningTopicCompletedDialog
        finishedLearningTopic={finishedLearningTopic}
        dialogClose={handleLearningTopicFinishedOpen}
        dialogOpen={learningTopicFinishedOpen}
      /> : null} */}
    </Layout>
  );
};

export default ChallengeResults;
