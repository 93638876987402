import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableContainer, TablePagination, Box } from '@material-ui/core';
import TablePaginationActions from '../TablePaginationActions/TablePaginationActions';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'block',
    borderRadius: 6,
    overflow:'hidden',
    minHeight: 300,
  },
  paperHead: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 2, 0, 3),
    '& > div': {
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    }
  },
  tableContainer: {
    minHeight: 170,
  },
  tablePagination: {
    '& .MuiButtonBase-root':{
      outline: 'none',
    }
  },
  actionBox: {
    '& button': {
      color: theme.palette.common.gamsDarkBlue,
      margin: theme.spacing(0, 0.45),
      '&:focus': {
        outline: 'none',
      },
    }
  },
}));

const SandboxTable = ({ children, count, onPageChange, rowPerPage, tableToolbar, page }) => {
  const classes = useStyles();
  const rowsPerPage = rowPerPage ? rowPerPage : 15;

  const handleChangePage = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Paper className={classes.paper}>
      {tableToolbar &&
        <Box className={classes.paperHead}>
          {tableToolbar}
        </Box>
      }
      <TableContainer className={classes.tableContainer}>
        <Table aria-label="sandbox table">
          {children}
        </Table>
      </TableContainer>
      {onPageChange &&
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => (`Showing ${from} to ${to} of ${count !== -1 ? count : null} entries`)}
          ActionsComponent={TablePaginationActions}
        />
      }
    </Paper>
  )
}

export default SandboxTable;
