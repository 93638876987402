import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.primary,
      maxWidth:560,
      margin:'0 auto',
      marginBottom:9,
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(16),
        marginBottom:8,
      },
    },
    '& > div:last-child': {
      marginBottom:0
    }
  },
  formLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    textDecoration: 'underline',
  }
}));

const AuthTextBlock = ({ primaryText, secondaryText, cta, ctaText, ctaRoute, ctaBtnText }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} textAlign="center">
      {primaryText && (
        <Typography variant="body1" component="div">
          {primaryText}
        </Typography>
      )}
      {(secondaryText || cta) && (
        <Typography variant="body1" component="div">
          {secondaryText}
          {cta && (
            <>
              {ctaText} <Link
                component={RouterLink}
                className={classes.formLink}
                to={ctaRoute}
              >{ctaBtnText}</Link>
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default AuthTextBlock;
