import ItemsGrid from "../../../../common/components/widgets/ItemsGrid";
import {lineItemGridColumns} from "../../../../settings/dataSets";
import {Formik} from "formik";
import {validateLineItem} from "../../../Validations";
import LineItemForm from "../../../campaign/LineItemForm";
import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {lineItemsInitValues} from "../../../placement/InitValues";

class ReportLineItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.ResetLineItemForm = React.createRef();
  }

  handleSubmit = (values, setSubmitting, resetForm) => {
    resetForm(lineItemsInitValues);
    this.clearLineItemForm();
    if (this.state.saveLineItem)
      this.state.saveLineItem(values, setSubmitting);
  }

  clearLineItemForm = () => {
    this.ResetLineItemForm.current.resetLineItemForm()
  }

  render() {
    const {items, deleteRow, editRow, initialValues } = this.props;
    return (
      <div>
        <ItemsGrid columns={lineItemGridColumns} title="Line Items" items={items}
                   remove={deleteRow} edit={editRow} />
        <Formik
          initialValues={initialValues ? initialValues : lineItemsInitValues}
          validate={validateLineItem}
          onSubmit={(values, {setSubmitting, resetForm}) => this.handleSubmit(values, setSubmitting, resetForm)}
        >
          {({values, errors, handleChange, touched}) => (
            <LineItemForm values={values} errors={errors} handleChange={handleChange} touched={touched} ref={this.ResetLineItemForm} />
          )}
        </Formik>
      </div>
    )
  }
}

ReportLineItemContainer.propTypes = {
  items: PropTypes.array.isRequired,
  deleteRow: PropTypes.func.isRequired,
  saveLineItem: PropTypes.func.isRequired,
};


export default ReportLineItemContainer;
