import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import svgLogo from '../../assets/images/logo/new-gams-logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.bgAuth,
  },
  header: {
    textAlign: 'center',
    padding:theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding:theme.spacing(2,2,1.5,2),
    },
  },
  logo: {
    width:210,
    height:'auto',
    [theme.breakpoints.down('md')]: {
      width:190,
    },
    [theme.breakpoints.down('xs')]: {
      width:170,
    },
  },
  content: {
    paddingTop:30,
    [theme.breakpoints.down('lg')]: {
      paddingTop:10,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop:20,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop:18,
    },
  },
  copyrightBox: {
    color: theme.palette.grey[600],
    textAlign:'center',
    marginTop:25,
    marginBottom:10,
  }
}));

const AuthLayout = ({ children }) => {
  const classes = useStyles();

  useEffect(() => {
    document.querySelector('body').style.backgroundColor = '#F6F8FB';
  },[])

  return (
    <div className={classes.root}>
      <header className={classes.header} color="transparent">
        <Box>
          <img className={classes.logo} src={svgLogo} alt="test" />
        </Box>
      </header>
      <main className={classes.content}>
        {children}

        <Box className={classes.copyrightBox}>
          <Typography variant="body2" component="div">
            Copyright © {new Date().getFullYear()} GAMS
          </Typography>
        </Box>
      </main>
    </div>
  );
}

export default AuthLayout;
