import React, {Component} from 'react';
import {
  //rightText,
  width100
} from '../../settings/otherStyles';
import Autocomplete from "../../common/components/base/Autocomplete";
import {durations} from "../../settings/dataSets";
import {Query} from "@apollo/client/react/components";
import LoadingAnimation from "../../common/components/base/LoadingAnimation";
// import {ApolloClient} from "@apollo/client";
// import {apolloSecureConfig} from "../../settings/apollo";
import {Builder} from "../../settings/builder";
import TextField from '@material-ui/core/TextField';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {GET_ADVERTISERS} from './queries';
import LineItemContainer from "./LineItemContainer";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {CardActions} from "@material-ui/core";
import { creativesVar, lineItemsVar } from '../../settings/cache';

class CampaignForm extends Component {
  constructor(props) {
    super(props);
    if (props.items)
      this.state = {...props, currentTab: 0};
    else
      this.state = {...props, items: [], currentTab: 0};
      this.deleteRow = this.deleteRow.bind(this);
      this.updateRow = this.updateRow.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleTab = this.handleTab.bind(this);
      this.saveLineItem = this.saveLineItem.bind(this);
      this.openReport = this.openReport.bind(this);
      this.resetLineItemForm = this.resetLineItemForm.bind(this);
      this.ClearLineItemForm = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.name !==nextProps)
     this.setState({ name: nextProps.name });
  }

  handleChange = (event, currentTab) => {
    this.setState({currentTab});
  };

  deleteRow(index) {
    this.setState( state => {
        state.items.splice(index, 1);
    })

    this.setState({items: this.state.items});
    lineItemsVar(this.state.items);
  }

  updateRow(index, values) {
     let item = Builder.getLineItem(values);
     let creatives = creativesVar();
     item = {...item, creatives};
     this.state.items.splice(index, 1, item);
     this.setState({items: this.state.items});
     lineItemsVar(this.state.items);
  }

  handleTab = () => {
    const newTabIndex = this.state.currentTab === 0 ? 1 : 0;
    this.setState({
        currentTab: newTabIndex
    });
  };

  openReport = () => {
    this.props.history.push("/campaign");
  }

  resetLineItemForm = () => {
    document.getElementById("LineItemFormInput").reset();
    this.ClearLineItemForm.current.clearLineItemForm();
  }


  saveLineItem = async(values, setSubmitting) => {
    setSubmitting(false);
    let item = Builder.getLineItem(values);
    let creatives = creativesVar();
    item = {...item,  creatives};
    const items = Object.assign([], this.state.items);
    items.push(item);

    this.setState( state => {
      state.items.push(item)
    })
    lineItemsVar(items);
  };

  render() {
    const {currentTab, id} = this.state
    const {
      duration,
      //advertiser
    } = this.props;
    return (
      <div>
        {currentTab === 1 && <Grid container>
          <Grid item xs={6}>
            <p style={{margin: 0, lineHeight: 3}}>{this.state.name}</p>
          </Grid>
        </Grid>}
        <Card>
          <div>
            <AppBar position="static" color="default">
              <Tabs value={currentTab} onChange={this.handleChange}>
                <Tab disableRipple label="Campaign"/>
                <Tab disableRipple label="Line Items" disabled={!this.state.name}/>
              </Tabs>
            </AppBar>
          </div>
          <CardContent>
            {currentTab === 0 && <div className="row justify-content-center">
             <Grid container justify="space-around">
                <Grid item xs={10}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="campaignName"
                      label="Name"
                      value={this.state.name}
                      onChange={this.state.handleName}
                      placeholder="campaignname_brand_period"
                      margin="normal"
                      style={width100}
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Autocomplete
                      required
                      selectedValue={duration ? {id: duration, label: duration} : ""}
                      handleChange={this.state.handleDuration}
                      values={durations}
                      placeholder="Schedule Duration *"
                      defaultValue = {this.state.duration}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Query query={GET_ADVERTISERS} fetchPolicy="network-only">
                      {({loading, error, data}) => {
                        if (data && data['advertisersProjected'])
                          return (
                            <Autocomplete key="3" placeholder="Advertiser"
                                          handleChange={this.state.handleAdvertiser}
                                          //selectedValue={advertiser ? {id: advertiser, label: advertiser} : ""}
                                          values={data.advertisersProjected.map(value => ({
                                            value: value._id,
                                            label: value.name
                                          }))}
                                          defaultValue = {this.state.advertiser} />
                          );
                        if (loading) return (<LoadingAnimation/>);
                        if (error) return `Error! ${error.message}`;
                      }}
                    </Query>
                  </Grid>
                </Grid>
              </Grid>
            </div>}

            {currentTab === 1 &&
            <div>
              <LineItemContainer items={this.state.items} deleteRow={this.deleteRow} updateRow={this.updateRow} saveLineItem={this.saveLineItem} ref={this.ClearLineItemForm}/>
            </div>
            }
          </CardContent>

          <CardActions>
            <Grid container
                  direction="row"
                  justify={"space-around"}
                  alignItems="center">
              <Grid>
                {
                  this.state.currentTab === 0 &&
                  <button className="btn btn-fill btn-rose" disabled={!this.state.name}
                          onClick={this.handleTab}>Next</button>
                }
                {
                  this.state.currentTab === 1 &&
                  <button className="btn btn-fill btn-rose" onClick={this.handleTab}>Previous</button>
                }
              </Grid>
              <Grid>
                <button className="btn btn-fill btn-rose" onClick={this.state.submit}>
                  Save Campaign
                </button>
              </Grid>
              <Grid>
                {id ?
                <Link to={{
                  pathname: `/campaign/report/${id}`,
                  params: {id}
                }} className="btn btn-link">
                  <button className="btn btn-fill btn-rose">
                    Open Report
                  </button>
                </Link>
                :
                null
                }
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default CampaignForm;
