import React, { Component } from 'react';
import Layout from '../../common/Layout';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import plusButtonStyle from '../../settings/otherStyles';
import {client} from '../../settings/apollo';
import SimpleSearch from '../../common/components/data-table/SimpleSearch';
import CSVService from '../CSVservice'
import AdminSettingsNavigation from '../adminSettings/AdminSettingsNavigation';
import Select from 'react-select';
import { GET_USER_GROUPS } from "./../userGroup/queries";
import { Button } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import './UserList.css';
import CalendarDialog from '../../common/components/Dialogs/CalendarDialog/CalendarDialog';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";


const columns = ["sigla", "name", "surname", "email", "expirationDate", "actions"];
const columnsToHide = ["lastRequest"];

const MY_DATA = gql`
  query me {
    me {
      userRole
      courseGroups{
        _id
        name
        groupChallenges
      }
    } 
  }
`;

const GET_USERS = gql`
query users($name:String, $page: Int, $courseGroups: [String], $userGroup: String, $onlineDeltaTime: Int, $showCompletedChallenges: Boolean){
  users(name:$name, page: $page, courseGroups: $courseGroups, userGroup: $userGroup, onlineDeltaTime: $onlineDeltaTime, showCompletedChallenges: $showCompletedChallenges){
        records{
            _id
            sigla
            name
            surname
            email
            expirationDate
            lastRequest,
            isLogged
        }
        pages
        totalCount
        }
    }
`;

const GET_USERS_TABLE = gql`
    query users($name:String, $page: Int, $courseGroups: [String], $userGroup: String, $onlineDeltaTime: Int, $showCompletedChallenges: Boolean){
        users(name:$name, page: $page, courseGroups: $courseGroups, userGroup: $userGroup, onlineDeltaTime: $onlineDeltaTime, showCompletedChallenges: $showCompletedChallenges){
        records{
            _id
            name
            surname
            sigla
            email
            userRole
            time
            courseGroups{
              name
            }
            privacyPolicy,
            profileScorecard,
            publishAchievement,
            completedChallengeIds
        }
        pages
        totalCount
        }
    }
`;

const UPDATE_USERS_EXPIRATION_DATE = gql `
mutation updateUsersExpirationDate($input: UsersExpirationDateInput){
  updateUsersExpirationDate(input: $input)
}
`;

class Users extends Component {
  static propTypes = {
    page: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      page: props.match.params.page ? parseInt(props.match.params.page) : 0,
      selected: [],
      users:[],
      userGroup: null,
      onlineDeltaTime: undefined
    };
    this.datePickerDialog = React.createRef();
    this.openDatePickerDialog = this.openDatePickerDialog.bind(this);
    this.handleSelectedIndexes = this.handleSelectedIndexes.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._add = this._add.bind(this);
    this._first = this._first.bind(this);
    this._last = this._last.bind(this);
    this._first = this._first.bind(this);
    this._keyPress = this._keyPress.bind(this);
    this.today = moment();
    this.client = client;

  }
  

  _previousPage(e) {
    e.preventDefault();

    if (this.state.page >1) {
      this.setState({ page: this.state.page - 1 });
      this.props.history.push('/admin-settings/users/page/'+(parseInt(this.state.page) - 1) )

    }
    else {
      this.setState({ page: 0});
      this.props.history.push('/admin-settings/users' )
    }
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
    this.props.history.push('/admin-settings/users/page/'+(parseInt(this.state.page) + 1) )

  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0} );
    this.props.history.push('/admin-settings/users')
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
    this.props.history.push('/admin-settings/users/page/'+(pages - 1))
  }

  _add(event) {
    this.props.history.push("/admin-settings/register");
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({
        name: e.target.value,
        page: 0
      });
    }
  }

  isSelected(user){
    return user._id.this.state.selected
  }

  handleDateChange = date => {
    this.setState({ selected: [], users: [] });

    const ids = this.state.selected.map(index => {
      return this.state.users[index]._id;
    })
    const {error} = this.client.mutate({ mutation: UPDATE_USERS_EXPIRATION_DATE,
                         variables: { input: {expirationDate: date, ids: ids} } });
    if (error) console.log("error changing date")
    else (
      Swal.fire({
        type: 'success',
        title: 'Expiring date changed.',
        showConfirmButton: false,
        timer: 1500
      })
    )
  };

  openDatePickerDialog = () => {
    if (this.state.selected.length===0) {
      Swal.fire({
        type: 'warning',
        text: 'Select users first.',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close'
      })
      return;
    }
    this.datePickerDialog.current.open();
  }

  handleSelectedIndexes = (selected, records) => {
   selected.map((i) => console.log('selected record', records[i]))
   this.setState({selected: selected });
   console.log(this.state.selected, selected);
   this.setState({users: records});
  }

  handleDeleteAction = () => {
    return false;
  }

  highlightRecord (record) {
    return (moment(record.expirationDate).isBefore(this.today))
  }

  checkIsOnline = (record) => {
    if (record.isLogged) {
      return 0;
    } else {
      const lastRequestDate = moment(record.lastRequest);
      const diff = ['weeks', 'days', 'hours', 'minutes'].filter(item => this.today.diff(lastRequestDate, item));
      return diff.length > 0 ? `${this.today.diff(lastRequestDate, diff[0])} ${diff[0]}` : false;
    }
  }

  buildCompletedChallengesCsvStringFormat(data) {
    return data?.length > 0 ? data.join(' - ') : null;
  }

  getRole(){
    const data = MY_DATA;
    console.log(data)
    const myData = data.me;
    const role = myData.userRole
  }
  
  calculateDataCsv = async (UserGroupList) => {
    let result = [];
    await client.query({
      query: GET_USERS_TABLE,
      variables: { name: this.state.name, courseGroups: UserGroupList, userGroup: this.state.userGroup, onlineDeltaTime: this.state.onlineDeltaTime, showCompletedChallenges: true }
    }).then(res => {
      const data = res.data?.users;
      if (data === null) return null;
      let usersList = [];
      let courses;
      data.records.map((item) => {
        if (item.courseGroups) {
          courses = item.courseGroups.map((item) => item.name)
        }
        const newTime = moment.utc(item.time).format('YYYY/MM/DD hh:mm:ss')
        usersList.push({
          _id: item._id,
          name: item.name,
          surname: item.surname,
          sigla: item.sigla,
          email: item.email,
          _role: item.userRole,
          time: newTime,
          _userGroup: courses,
          PrivacyPolicy: item.privacyPolicy,
          ProfileScorecard: item.profileScorecard,
          PublishAchievement: item.publishAchievement,
          completedChallengeIds: this.buildCompletedChallengesCsvStringFormat(item.completedChallengeIds)
        });
        return null
      })

      result = usersList.map((item) => item);
    });
    return result;
  }

  render() {
    if(this.props.location.pathname.includes('page') &&
    !this.props.match.params.page)
    return (
      <Redirect
      to={{
        pathname: "../users",
      }}
    />
    )
    return (
      <Layout name="Users">
        <div className="user-list">
          <Query query={MY_DATA} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner styles={{ paddingTop: 30 }}/>;
              if (error) return `Error! ${error.message}`;
              const userData = data.me;
              const UserGroupList = []
              const coachGroups = []
              const courseGroups = data.me.courseGroups.map((item) => item)
              courseGroups.map((item) => {
                UserGroupList.push(item._id); coachGroups.push({ value: item._id, label: item.name })
                return null;
              })
              return (
                <div className="wizard-container">
                  {userData.userRole === "superuser" ?
                    <Fab onClick={this._add} color="primary" aria-label="Add" className={plusButtonStyle.fab}>
                      <AddIcon />
                    </Fab>

                    : null}
                  <AdminSettingsNavigation path={this.props.match.path} />

                  <Query query={GET_USER_GROUPS} fetchPolicy="network-only">
                    {({ loading, error, data }) => {
                      if (loading) return <LoadingSpinner />;
                      if (error) return `Error! ${error.message}`;
                      const userGroups = data.userGroups.records.map((item) => ({ value: item._id, label: item.name }))
                      return (
                        <div className="row justify-content-end user-list__controls">
                          <div className="col-xl-3 col-lg-12 text-right">
                            {userData.userRole === "superuser" ? 
                            <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className="btn-date"
                            onClick={this.openDatePickerDialog}
                          >
                            <EventIcon /><span>Change expiration date</span>
                          </Button>
                          : null}
                          </div>
                          <div className="col-xl-2 col-lg-6">
                              <SimpleSearch keyPress={this._keyPress} />
                          </div>
                          <div className="col-xl-3 col-lg-6">
                            <Select
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                              onChange={(values) => values != null ? this.setState({ onlineDeltaTime: values.value, page: 0 }) : this.setState({ onlineDeltaTime: undefined, page: 0 })}
                              options={[{value: 0, label: 'offline'}, {value: 120, label: 'online' }]}
                              isClearable={true}
                              placeholder={"Select by user activity"}
                            />
                          </div>
                          <div className="col-xl-3 col-lg-6">
                            <Select
                              styles={{
                                menu: provided => ({ ...provided, zIndex: 9999 })
                              }}
                              onChange={(values) => values != null ? this.setState({ userGroup: values.value, page: 0 }) : this.setState({ userGroup: null, page: 0 })}
                              options={userData.userRole === 'superuser' ? userGroups : coachGroups}
                              isClearable={true}
                              placeholder={"Select course groups"}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </Query>

                  <Query
                    query={GET_USERS}
                    fetchPolicy="no-cache"
                    variables={{
                      name: this.state.name,
                      page: this.state.page,
                      courseGroups: UserGroupList,
                      userGroup: this.state.userGroup,
                      onlineDeltaTime: this.state.onlineDeltaTime,
                      showCompletedChallenges: false
                      }}
                  >
                    {({ loading, error, data, previousData }) => {
                      if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 15, marginBottom: 30 }} />;
                      if (error) return `Error! ${error.message}`;
                      if (data.users === null) return <b>This feature is available just to Administrators</b>;
                      return (
                        <DataTable
                          header='Users'
                          editUrl="/admin-settings/users/"
                          pages={data.users.pages}
                          columns={columns}
                          records={data.users}
                          deleteRow={this._deleteRow}
                          keyColumn='_id'
                          page={this.state.page}
                          first={this._first}
                          last={this._last.bind(this, data.users.pages)}
                          next={this._nextPage}
                          previous={this._previousPage}
                          checkboxes={true}
                          selected={this.state.selected}
                          onSelectedIndex={this.handleSelectedIndexes}
                          showDeleteIcon={this.handleDeleteAction}
                          highlightRecord={this.highlightRecord}
                          status={this.checkIsOnline}
                          columnsToHide={columnsToHide}
                        />
                      );
                    }}
                  </Query>
                  <CSVService label={"Download Users Data"}
                    fileName={"GAMSUserData.csv"}
                    onClickCalculate={this.calculateDataCsv}
                    list={UserGroupList}
                  />
                </div>
              )
            }}
          </Query>

          <CalendarDialog
            value={moment()}
            onHandleDateChange={this.handleDateChange}
            ref={this.datePickerDialog}
            styleDialog={{display: "none"}}
          />

        </div>
      </Layout>
    );
  }
}

export default Users;
