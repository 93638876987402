import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import imgPartner01 from '../../../assets/images/psc/partners/psc-digi-skills.jpg';
import imgPartner02 from '../../../assets/images/psc/partners/psc-member.jpg';
import imgPartner03 from '../../../assets/images/psc/partners/psc-pleger.jpg';
import imgPartner04 from '../../../assets/images/psc/partners/psc-rep-dig.jpg';
import imgPartner05 from '../../../assets/images/psc/partners/logo_ISO_9001-2015.jpg';

const useStyles = (small, pscPublic) => makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    paddingTop: pscPublic ? 70 : 30,
    paddingBottom: pscPublic ? 20 : 50,
    backgroundColor: pscPublic ? theme.palette.common.white : 'transparent',
  },
  icon: {
    textAlign: 'center',
    width: small ? 64 : 128,
    height: small ? 64 : 128,
    flexWrap: 'wrap',
    '& img': {
      width:'100%',
      height:'auto'
    },
  },
  partnerBox: {
    width: small ? 64 : 128,
    height: small ? 64 : 128,
    border: pscPublic ? `none` : `solid 1px ${theme.palette.grey[300]}`,
    margin:'20px',
    flexWrap: 'wrap',
    '& img': {
      width:'100%',
      height:'auto'
    },
    [theme.breakpoints.down('xs')]: {
      margin:'8px',
    },
  },
  partnerTitle: {
    fontSize: small ? theme.typography.pxToRem(18) : theme.typography.pxToRem(24),
    color: pscPublic ? theme.palette.text.primary : theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: pscPublic ? 0 : 10
  }
}));

const PartnersBlock = ({ small, pscPublic }) => {
  const classes = useStyles(small, pscPublic)();

  return (
    <Box className={classes.root}>
      <Typography className={classes.partnerTitle} variant="h2">Partners</Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <div className={classes.partnerBox}>
          <img src={imgPartner01} alt="Partner01" />
        </div>
        <div className={classes.partnerBox}>
          <img src={imgPartner02} alt="Partner02" />
        </div>
        <div className={classes.partnerBox}>
          <img src={imgPartner03} alt="Partner03" />
        </div>
        <div className={classes.partnerBox}>
          <img src={imgPartner04} alt="Partner04" />
        </div>
      </Box>
      <Box>
        <p><Typography className={classes.partnerTitle} variant="h2">Certified Company</Typography> GAMS Platform by Farport Software srl certified UNI EN ISO 9001:2015</p>     
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        <div className={classes.icon}>
              <img src={imgPartner05} alt="Partner05" />
            </div>    
      </Box>
    </Box>
  )

}

export default PartnersBlock
