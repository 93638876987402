import React, { Component } from "react";
import LineChart from "../../../../common/components/charts/LineChart";
import Grid from '@material-ui/core/Grid';
import { campaignReportFields } from "../../../../settings/dataSets";
import { margin50 } from "../../../../settings/otherStyles";
import Typography from '@material-ui/core/Typography';
import SimpleAutocomplete from "../../../../common/components/base/SimpleAutocomplete";

class CampaignReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      selectedField: campaignReportFields[0].value,
      campaign: {
        lineItems: this.props.lineItems,
        timeFrame: this.props.timeFrame,
        },
        currentLineItemValues : 0
    }
    this.changeState = this.changeState.bind(this);
    this.renderChartData = this.renderChartData.bind(this);
    this.changeChartFilter = this.changeChartFilter.bind(this);
    this.handleLineItems = this.handleLineItems.bind(this);
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  UNSAFE_componentWillMount() {
    let chartSize = this.state.timeFrame / 24;
    let numbers = [];
    for (let i = 1; i <= chartSize; i++) {
      numbers.push(i);
    }
    this.setState({ labels: numbers },
      () => this.renderChartData());
    let campaign = {};
    if (this.state.lineItems) { campaign = { lineItems: this.state.lineItems }; }
    this.setState({ campaign });
  }

  renderChartData = (filter) => {
    let values = this.state.currentLineItemValues
    if (filter === "impressionsDelivered") {
      values ? (values = values[filter]) : values = 0 ;
    }
    if (filter === 'budgetDelivered') {
      values ? (values = values[filter]) : values = 0 ;
    }
    if (filter === 'clicks') {
      values ? (values = values[filter]) : values = 0 ;
    }
    if (filter === 'conversions') {
      values ? (values = values[filter]) : values = 0 ;
    }
    let data = [];
    this.state.labels.map((item ,index) => {
      data.push(values[index])
      return null;
    })
    this.setState({ series: data })
  }

  changeChartFilter = filter => {
    this.changeState('selectedField', filter);
    this.renderChartData(filter)
  }

  handleLineItems = item => {
    this.setState({currentLineItemValues : item})
  }

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={9}>
            <div>
              {campaignReportFields.map(item => {
                return (<span underline={item.value === this.state.selectedField ? 'always' : 'hover'} style={margin50}
                  onClick={() => this.changeChartFilter(item.value)}
                  component="button">{item.label}</span>)
              })}
            </div>
            <div id="roundedLineChart" className="ct-chart">
              <LineChart data={this.state.series} labels={this.state.labels} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5">Filters</Typography>
            {this.state.campaign.lineItems &&
              <SimpleAutocomplete values={this.state.campaign.lineItems.map(value => ({
                value: value.name,
                label: value.name,
                values: value.values
              }))}
                placeholder="All line Items"
                handleChange={item => this.handleLineItems(item.values)} />}

          </Grid>
        </Grid>
        <Grid>
          <button className="btn btn-fill btn-rose" onClick={this.state.handleBackToCampaign}>
            BACK TO CAMPAIGN
          </button>
        </Grid></div>
    )
  }
}

export default CampaignReport;
