import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import MetaSkills from './MetaSkills/MetaSkills';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  paper: {
    marginBottom: 30,
    padding:theme.spacing(3.5),
    borderRadius: 6
  },
  careerTitle: {
    fontSize: theme.typography.pxToRem(23),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 0.8,
    marginBottom: 30,
    textAlign: 'center',
  }
}));

const ImgLearningTopics = ({ careerPaths, urlPath }) => {
  const classes = useStyles();

  let CareerList = 'No Careers...';
  if (careerPaths) {
    CareerList = careerPaths.map((careerPath, index) => {
      return (
        <Paper className={classes.paper} key={`careerPath-${index}`}>
          <Typography className={classes.careerTitle} variant="h3">{careerPath.name}</Typography>
          <MetaSkills scores={careerPath.scores} urlPath={urlPath} />
        </Paper>
      );
    })
  }

  return (
    <div className={classes.root}>
      {CareerList}
    </div>
  );
}

export default ImgLearningTopics;
