import AutocompleteFreqRec from "../../common/components/base/AutocompleteFreqRec";
import SimpleDropDown from "../../common/components/base/SimpleDropDown";
import React, { Component } from "react";
import PlacementAutocomplete from './PlacementAutocomplete';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import LineItemCreatives from "./LineItemCreatives";
import { ErrorMessage, Form } from "formik";
// import { ApolloClient } from "@apollo/client";
// import { apolloSecureConfig } from "../../settings/apollo";
import TextField from "@material-ui/core/TextField";
import { leftPadding10, rightPadding10, width100 } from "../../settings/otherStyles";
import { delivery, durations, recency, tagBaseUrl } from "../../settings/dataSets";
import StatusList from "../../common/components/base/StatusList";
import { Grid, Box } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormatCustom from './../../common/components/base/NumberFormatCustom'
import {creativesVar, deliveryVar, frequencyCAPVar, placementVar, recencyVar, scheduleVar, tagVar, typeVar} from "../../settings/cache"

class LineItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      tag: ""
    };
    this.parseAndHandleChange = this.parseAndHandleChange.bind(this);
    this.generateTag = this.generateTag.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetLineItemForm = this.resetLineItemForm.bind(this);
    this.SimpleDropDownElementType = React.createRef();
    this.SimpleDropDownElementSchedule = React.createRef();
    this.SimpleDropDownElementDelivery = React.createRef();
  }

  resetLineItemForm = () => {
    this.SimpleDropDownElementType.current.resetSimpleDropdown()
    this.SimpleDropDownElementSchedule.current.resetSimpleDropdown()
    this.SimpleDropDownElementDelivery.current.resetSimpleDropdown()
    this.setState({
      frequencyCAP: '',
      placement: '',
      recency: '',
    })
    this.props.values.tag = ''
    this.props.values.creatives=[]
  }

  generateTag(itemName) {
    let tag = `${tagBaseUrl}/${itemName}`;
    let creatives = creativesVar();
    if (creatives.length > 0)
      tag = encodeURI(`${tag}/${creatives[0].adsName}`);
    tagVar(tag);
    this.setState({ 'tag': tag })
    this.props.values.tag = tag
  }

  handleChange = (key, item) => {
    this.setState({ [key]: item })
  }

  parseAndHandleChange = (value, setFieldValue, id) => {
    const parsed = parseFloat(value)
    return setFieldValue(id, parsed)
  }

  render() {
    let numbers = new Array(30);
    for (let i = 1; i <= 30; i++) {
      numbers.push({ value: i, label: i });
    }
    const { values, handleChange, setFieldValue, isSubmitting } = this.props;
    values.type = this.state.type;
    values.placement = this.state.placement ? this.state.placement.value : null;
    values.delivery = this.state.delivery;
    values.schedule = this.state.schedule ? this.state.schedule.value : null;
    values.recency = this.state.recency ? this.state.recency.value : null;
    values.frequencyCAP = this.state.frequencyCAP ? this.state.frequencyCAP.value : null;
    let tag;
    if (this.state.simulation) {
      tag = <LineItemCreatives simulation={true} items={[]} />;
    } else {
      tag = <LineItemCreatives items={[]} value={values.creatives} />;
    }
    return (
      <div>
        <Form style={{ flexGrow: 1 }} id="LineItemFormInput">
          <Grid container
            direction="row"
            justify="space-between">
            <Grid item xs={12}>
              <TextField
                required
                label="Line Item Name"
                id="name"
                name="name"
                value={values.name}
                placeholder="lineitemname_number_size_format"
                onChange={handleChange}
                margin="normal"
                style={width100}
              />
              <ErrorMessage name="name" component="div" />
            </Grid>
            <Grid item md={6} xs={12} style={rightPadding10}>
              <SimpleDropDown handleChange={event => {
                              typeVar(event.currentTarget.textContent);
                              this.handleChange('type', event.currentTarget.textContent)
                            }
              }
                ref={this.SimpleDropDownElementType}
                items={['Standard campaign', 'Tracking', 'Click tracking']}
                caption={this.state.type || 'Type'}
                header='Line Item Type' />
            </Grid>
            <Grid item md={6} xs={12} style={leftPadding10}>
              <SimpleDropDown
                ref={this.SimpleDropDownElementSchedule}
                handleChange={item => {
                  scheduleVar(item.value.toString());
                  this.handleChange('schedule', {value: item.value.toString(), label: item.label.toString()})
                }}
                id="size" name="size"
                items={durations}
                caption={this.state.schedule ? this.state.schedule.label : "Schedule duration"}
                header='Schedule duration' />
            </Grid>
          </Grid>
          <Grid container direction="row"
            justify="space-between">
            <Grid item xs={12} md={4}>
              <Box display="flex" alignItems="center" height="100%">
                <InputLabel>Line Item Status</InputLabel>
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <StatusList name="status" value={values.status} onChange={handleChange} />
            </Grid>
          </Grid>
          <Grid container direction="row"
            justify="space-between">

            <Grid item md={4} xs={12} style={rightPadding10}>
              <TextField
                required
                label="Budget Planned"
                name="budgetPlanned"
                value={values.budgetPlanned}
                placeholder="0000,00"
                onChange={event => this.parseAndHandleChange(event.target.value, this.props.setFieldValue, event.target.name)}
                margin="normal"
                style={width100}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <ErrorMessage name="budgetPlanned" component="div" /></Grid>
            <Grid item md={4} xs={12}>
              <TextField
                required
                label="Impressions Planned"
                name="impressionsPlanned"
                value={values.impressionsPlanned}
                placeholder="00.000.000"
                onChange={event => this.parseAndHandleChange(event.target.value, setFieldValue, event.target.name)}
                margin="normal"
                style={width100}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
              <ErrorMessage name="impressionsPlanned" component="div" />
            </Grid>
            <Grid item md={4} xs={12} style={leftPadding10}>
              <TextField
                required
                label="CPM"
                name="cpm"
                value={values.cpm}
                placeholder="00,00"
                onChange={handleChange}
                margin="normal"
                style={width100}
              />
              <ErrorMessage name="cpm" component="div" />
            </Grid>
          </Grid>
          <Grid container
            direction="row">
            <Grid item md={4} xs={12} style={rightPadding10}>
              <TextField
                label="Budget Delivered"
                name="budgetDelivered"
                value={values.budgetDelivered}
                margin="normal"
                style={width100}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                disabled={true}
                label="Impressions Delivered"
                name="impressionsDelivered"
                value={values.impressionsDelivered}
                margin="normal"
                style={width100}
              />
            </Grid>
          </Grid>

          <Grid container
            direction="row">
            <Grid item md={4} xs={12} style={rightPadding10}>
              <TextField
                label="Clicks"
                name="clicks"
                value={values.clicks}
                margin="normal"
                style={width100}
                disabled={true}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Conversions"
                name="conversions"
                value={values.conversions}
                margin="normal"
                style={width100}
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container
            direction="row"
            justify="space-between">
            <Grid item md={6} xs={12} style={rightPadding10}>
              <PlacementAutocomplete placeholder="Placement" simulation={this.state.simulation}
                single={this.state.placement ? this.state.placement.label : ""}
                selectedValue= {this.state.placement ? this.state.placement.label : ""}
                handleChange={event => {
                  if(event!==null){
                  placementVar(event.value);
                  this.handleChange('placement', event)}
                }
                }

              />
            </Grid>
            <Grid item md={6} xs={12} style={leftPadding10}>
              <SimpleDropDown
                ref={this.SimpleDropDownElementDelivery}
                handleChange={event => {
                  if(event!==null){
                  deliveryVar(event.currentTarget.textContent)
                  this.handleChange('delivery', event.currentTarget.textContent)}
                }
                }
                items={delivery}
                caption={this.state.delivery || "Delivery Type"}
                header='Select Delivery' />
            </Grid>
            <Grid item md={6} xs={12} style={rightPadding10}>
              <AutocompleteFreqRec
                values={numbers}
                placeholder="Frequency CAP"
                selectedValue={this.state.frequencyCAP}
                isClearable={false}
                handleChange={item => {
                  if(item!==null){
                    frequencyCAPVar(item.value.toString());
                    this.handleChange('frequencyCAP', {value: item.value.toString(), label: item.label.toString()})
                  }
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} style={leftPadding10}>
              <AutocompleteFreqRec 
                values={recency}
                placeholder="Recency"
                selectedValue={this.state.recency}
                handleChange={item => {
                  if(item!==null){
                    recencyVar(item.value.toString());
                    this.handleChange('recency', {value: item.value.toString(), label: item.label.toString()})
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 20 }}>
              {tag}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Ads Tag"
                name="tag"
                value={values.tag}
                onChange={handleChange}
                margin="normal"
                style={width100}
              />
              <Button className="btn btn-fill btn-warning" type="button" size="medium"
                onClick={() => this.generateTag(values.name)}>
               { "Generate Ad Tag >" }
                </Button>
            </Grid>
          </Grid>
          <Grid container justify="left"
            alignItems="center">
            <Grid>
              <Button
                className="btn btn-fill btn-success mt-2"
                type="submit"
                size="medium"
                disabled={isSubmitting}
              >
                <SaveIcon />
                Save line item
              </Button>
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

export default LineItemForm;
