import gql from "graphql-tag";

export const GET_HELP_PAGE = gql`
  query {
    helpPage {
      data {
        attributes {
          title
          faq {
            question
            answer
          }
          supportCta {
            message
            link
            textLink
          }
        }
      }
    }
  }
`;
