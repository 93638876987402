import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box, IconButton, TextField, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 9,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    minHeight: 48,
    '& > span': {
      color: theme.palette.text.secondary,
      marginRight: 8
    }
  },
  btn: {
    '&:focus': {
      outline: 'none'
    },
  },
  editInput: {
    background: theme.palette.common.white,
    marginRight: 5,
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
  },
  btnEdit: {
    color: theme.palette.grey[600],
    minWidth: 32,
    marginRight:5,
    marginLeft:5,
    '&:focus': {
      outline: 'none'
    },
  }
}));

const WorkflowTitle = ({ preTitle, title, handleAction, allowEdit, loading, error, isSimulation }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(title);

  const handleChange = (event) => {
    setValue(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setEdit(!edit);
      if (edit === true && value !== title) {
        handleAction(value);
      }
    }
  }

  const toggleInput = () => {
    setValue(title);
    setEdit(!edit);
  }

  const handleSave = () => {
    setEdit(!edit);
    if (edit === true && value !== title) {
      handleAction(value);
    }
  }

  if (loading) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.title} variant="body1" component="h1">
          <span>{preTitle}</span>
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.title} variant="body1" component="h1">
          <span>{preTitle}</span>
          {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="body1" component="h1">
        <span style={isSimulation ? { color: theme.palette.text.primary } : null}>{preTitle}</span>
        { !edit && title }
        { edit && (
          <Box display="flex" alignItems="center">
            <TextField className={classes.editInput} label="change title" value={value} onChange={handleChange} onKeyDown={handleKeyDown} variant="outlined" size="small" />

            <Box display="flex" alignItems="center">
              <Button className={classes.btnEdit} size="small" variant="contained" onClick={handleSave}>
                <CheckIcon/>
              </Button>
              <Button className={classes.btnEdit} size="small" variant="contained" onClick={toggleInput}>
                <CloseIcon/>
              </Button>
            </Box>
          </Box>
        )}
      </Typography>
      { allowEdit ? !edit && (
        <IconButton className={classes.btn} onClick={toggleInput}>
          <EditIcon />
        </IconButton>
      ) : null}
    </Box>
  );
}

export default WorkflowTitle;
