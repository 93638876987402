import React, { Component } from 'react';
import Background from './clint-mckoy.jpg';


class Error extends Component {
  render() {
    return (
        <div className="wrapper wrapper-full-page">
  <div className="page-header error-page header-filter" style={{backgroundImage: `url(${Background})`}}>
    <div className="content-center">
      <div className="row">
        <div className="col-md-12">
          <h1 className="title">404</h1>
          <h2>Page not found :(</h2>
          <h4>Ooooups! Looks like you got lost.</h4>
        </div>
      </div>
    </div>
  </div>
</div>
    );
  }
}

export default Error;

