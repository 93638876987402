import React, { useState } from 'react';
import Layout from '../../common/Layout';
import { Formik } from 'formik';
import UserGroupForm from './UserGroupForm';
import { withRouter } from 'react-router';
import LoadingAnimation from '../../common/components/base/LoadingAnimation';
import { validateUserGroup } from '../Validations';
import { UPDATE_USER_GROUP, GET_USER_GROUP } from "./queries";
import Swal from 'sweetalert2';
import { gql, useMutation, useQuery } from '@apollo/client';

const GET_CHALLENGE_DEFINITION = gql`
query challengeDefinitions {
  challengeDefinitions{
    id
    challengeName
  }
}
`;

const UserGroupUpdate = (props) => {

  const [id] = useState(props.match.params.id)
  const userGroupQuery = useQuery(GET_USER_GROUP, {variables: {id}})
  const challengesDefinitionsQuery = useQuery (GET_CHALLENGE_DEFINITION);
  const [updateUserGroupMutation] = useMutation(UPDATE_USER_GROUP,
   {
      onError: () => {                     
      Swal.fire({
        type: 'error',
        text: 'Error assigning challenges to group.',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close'
      })                     
  },
  onCompleted: () => {
    Swal.fire({
      type: 'success',
      title: 'Saved',
      showConfirmButton: false,
      timer: 1500
    })
    window.location.replace('/admin-settings/userGroups');
  }});

  if (userGroupQuery.loading || challengesDefinitionsQuery.loading) return <p><LoadingAnimation /></p>;
  if (userGroupQuery.error) return <p>Error :(</p>;
  if (challengesDefinitionsQuery.error) return <p>Error :(</p>;
    const challengesDefinitionsData =challengesDefinitionsQuery.data;
    const userGroupsChallenges = (userGroupQuery.data.userGroup.groupChallenges.map((item) => challengesDefinitionsData.challengeDefinitions.find(( def ) => {
      return def.id === parseInt(item)
    })))

    return (
      <Layout name="User Group">
        <div class="wizard-container">
          <div class="col-md-8 col-12 mr-auto ml-auto">
            <div class="card card-wizard active" data-color="rose" id="wizardProfile">
                        <Formik
                          initialValues={{ ...userGroupQuery.data.userGroup }}
                          validate={validateUserGroup}
                          onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            updateUserGroupMutation({
                              variables: {
                                input: {
                                  _id: userGroupQuery.data.userGroup._id,
                                  name: values.name,
                                  groupChallenges: values.groupChallenges,
                                  isCareerPath: values.isCareerPath==='true'
                                }
                              }
                            })                
                            .catch((error) => console.log(error))
                             }}
                        >
                          {({ isSubmitting, values, handleChange, setFieldValue }) => (
                            <UserGroupForm
                              update='True'
                              handleChangeSize={props.handleChangeSize}
                              handleChangeParent={props.handleChangeParent}
                              isSubmitting={isSubmitting}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              groupChallenges = {userGroupsChallenges.map((item) => item.id )}
                              groupChallengesSelect={userGroupsChallenges.map((item) => ({ label: item.challengeName, value: item.id }))}
                            />
                          )}
                        </Formik>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

export default withRouter(UserGroupUpdate);
