
import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { crmData } from "../../../../utils/data/sandboxes";
import { useMutation, useQuery } from '@apollo/client';
import { GET_CRM_LIST } from "../../graphql/queries";
import { UPDATE_CRM_LIST } from "../../graphql/mutations";
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxDialog from '../../../../common/components/Dialogs/SandboxDialog/SandboxDialog';
import SandboxFormConditions from '../../../../common/components/SandboxFormConditions/SandboxFormConditions';
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const validationSchema = yup.object().shape({
  name: yup.string('Enter your list name').required('Name is required'),
  andConditions: yup.array().of(
    yup.object().shape({
      filterField: yup.string().required("Filter required"),
      filterCondition: yup.string().required("Filter required"),
      filterValue: yup.lazy(val => (Array.isArray(val) ? yup.array().required("Filter required") : yup.number().positive('Please, re-enter a positive number').typeError('This field only accepts numbers').required("Filter required")))
    })
  ),
  orConditions: yup.array().of(
    yup.object().shape({
      filterField: yup.string().required("Filter required"),
      filterCondition: yup.string().required("Filter required"),
      filterValue: yup.lazy(val => (Array.isArray(val) ? yup.array().required("Filter required") : yup.number().positive('Please, re-enter a positive number').typeError('This field only accepts numbers').required("Filter required")))
    })
  )
});

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 860,
    margin: '0 auto',
  },
  goBackBtn: {
    marginBottom:10,
    color: theme.palette.common.gamsDarkBlue,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  alert: {
    marginBottom:18,
    border: `solid 1px ${theme.palette.error.light}`
  }
}));

const CrmListEdit = props => {
  const classes = useStyles();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [updateCrmList] = useMutation(UPDATE_CRM_LIST);
  const { loading, error, data } = useQuery(GET_CRM_LIST, {
    variables: { id: props.match.params.id },
    fetchPolicy: "cache-and-network"
  });

  const createData = (filterField, filterCondition, filterValue) => {
    return { filterField, filterCondition, filterValue };
  }

  const andConditions = data?.crmList.andConditions.map((item) => {
    return createData(item.filterField, item.filterCondition, item.filterValue);
  });

  const orConditions = data?.crmList.orConditions.map((item) => {
    return createData(item.filterField, item.filterCondition, item.filterValue);
  });

  const initialValues = {
    name: data?.crmList.name || '',
    andConditions: andConditions || [],
    orConditions: orConditions || [],
  }

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  const onFormSubmit = (values) => {
    //console.log("onSubmit", JSON.stringify(values, null, 2));
    updateCrmList({
      variables: {
        id: data.crmList._id,
        input: {
          name: values.name,
          andConditions: values.andConditions,
          orConditions: values.orConditions
        }
      }
    })
    .then(({ data }) => {
      setHasError(false);
      setSuccessDialogOpen(true);
    })
    .catch(error => {
      setSuccessDialogOpen(false);
      setHasError(true);
      setErrorMsg(error.toString());
    });
  }

  useEffect(() => {
    if (successDialogOpen === true) {
      const delay = setTimeout(() => {
        setSuccessDialogOpen(false);
        props.history.push("/crm/lists");
      }, 2000);
      return () => clearTimeout(delay);
    }
  }, [successDialogOpen, props.history]);

  return (
    <SandboxLayout layoutData={crmData} name="Lists">

      <Container maxWidth={false} className={classes.container} disableGutters>
        <SandboxHeader
          title="Edit list"
          subTitle="Edit information about a created list."
          extraStyles={{ marginBottom: 30 }}
          action={
            <Box textAlign="right" pt="30px" mr="-8px">
              <Button
                startIcon={<ArrowBackIcon />}
                component={RouterLink}
                to={'/crm/lists'}
                className={classes.goBackBtn}
              >Back to Lists</Button>
            </Box>
          }
        />

        {hasError ?
          <Alert className={classes.alert} severity="error">
            {errorMsg}
          </Alert>
        : null}

        {loading ? (
          <LoadingSpinner boxed={true} styles={{ paddingTop: 70}}/>
        ) : error ? (
          <div>Error! {error.message}</div>
        ) : (
          <SandboxFormConditions
            onFormSubmit={onFormSubmit}
            newItemsLists={data.crmList.newItems}
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            submitTextBtn="Save list"
          />
        )}
      </Container>

      <SandboxDialog
        id="successListDialog"
        successIcon={true}
        dialogClose={handleSuccessDialogClose}
        dialogOpen={successDialogOpen}
        dialogTitle={'List updated'}
        dialogMessage={
          <Box textAlign="center" pb="42px">
            <Typography variant="body1" component="p">You will be redirected to the lists page in a few seconds.</Typography>
          </Box>
        }
      />

    </SandboxLayout>
  )
}

export default CrmListEdit;
