import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 25
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(21),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 9
  },
  subTitle: {
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.5,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    },
    '& p': {
      marginBottom:6
    }
  },
  description: {
    color: theme.palette.text.primary,
    maxWidth: 600,
    marginRight:15,
    lineHeight:1.6,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    },
  }
}));

const SandboxHeader = ({ title, subTitle, description, customTitle, action, extraStyles }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuerySm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display={ mediaQuerySm ? "block" : "flex" }
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
      style={extraStyles}
    >
      <Box>
        {customTitle && customTitle}
        {title && (
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography className={classes.subTitle} variant="h2">
            {subTitle}
          </Typography>
        )}
        {description && (
          <Typography className={classes.description} variant="body1" component="div">
            {description}
          </Typography>
        )}
      </Box>
      {action && (
        <Box>
          {action}
        </Box>
      )}
    </Box>
  )
}

export default SandboxHeader;
