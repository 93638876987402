import React, { useState, useEffect } from 'react';
import { ApolloClient, gql } from '@apollo/client/core';
import { apolloConfig } from '../../settings/apollo';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box } from '@material-ui/core';
import Authlayout from '../../hoc/AuthLayout/AuthLayout';
import AuthForm from '../../common/components/AuthForm/AuthForm';
import AuthFormField from '../../common/components/AuthForm/AuthFormField/AuthFormField';
import AuthFormSubmit from '../../common/components/AuthForm/AuthFormSubmit/AuthFormSubmit';
import AuthAlert from '../../common/components/AuthAlert/AuthAlert';
import AuthDialog from '../../common/components/Dialogs/AuthDialog/AuthDialog';
import AuthTextBlock from '../../common/components/AuthTextBlock/AuthTextBlock';

const validationSchema = yup.object({
  password: yup
    .string('Enter your new password')
    .min(6, 'Password should be of minimum 6 characters length')
    .max(20, 'Password should be of maximum, 20 characters length')
    .required('Password is required'),
  passwordConfirmation: yup
    .string('Re-enter your new password')
    .test('passwords-match', 'Passwords must match', function(value){
      return this.parent.password === value
    })
    .required('Password is required'),
});

const client = new ApolloClient(apolloConfig);

const CHANGE_PASSWORD = gql`
  mutation changePassword($_id: String, $password: String, $resetLink: String) {
    changePassword(_id: $_id, password: $password, resetLink: $resetLink)
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    zIndex:2
  },
  formLink: {
    color: theme.palette.common.gamsPetroleumBlue,
    textDecoration: 'underline',
  },
}));

const ResetPassword = props => {
  const classes = useStyles();
  const sidebar = false; // active deactive sidebar (true - false)

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [changePassword] = useMutation(CHANGE_PASSWORD, {client});

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      changePassword({ variables: {
        password: values.passwordConfirmation,
        resetLink: window.location.origin + props.location.pathname
      }})
      .then(({ data }) => {
        setHasError(false);
        setSuccessDialogOpen(true);
      })
      .catch(error => {
        setSuccessDialogOpen(false);
        setHasError(true);
        setErrorMsg(error.toString());
      });
    },
  });

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  useEffect(() => {
    if (successDialogOpen === true) {
      const delay = setTimeout(() => {
        setSuccessDialogOpen(false);
        props.history.push("/login");
      }, 5000);
      return () => clearTimeout(delay);
    }
  }, [successDialogOpen, props.history]);

  return (
    <>
      <Authlayout
        sidebar={sidebar}
      >
        <Container maxWidth="md" className={classes.container}>

          { hasError ?
            <AuthAlert errorMsg={errorMsg} />
          : null }

          <AuthForm
            formHandleSubmit={formik.handleSubmit}
            formTitle={'Change Password'}
            formSubtitle={<AuthTextBlock
              primaryText={
                <Box color="text.secondary">
                  Please enter your new password.
                </Box>
              }
            />}
          >
            <AuthFormField
              fieldType={'password'}
              fieldGrid={12}
              fieldName={'password'}
              fieldLabel={'New password'}
              fieldLabelWidth={106}
              fieldValue={formik.values.password}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.password && Boolean(formik.errors.password)}
              fieldHelperText={formik.touched.password && formik.errors.password}
            />
            <AuthFormField
              fieldType={'password'}
              fieldGrid={12}
              fieldName={'passwordConfirmation'}
              fieldLabel={'Re-enter your new password'}
              fieldLabelWidth={207}
              fieldValue={formik.values.passwordConfirmation}
              fieldHandleChange={formik.handleChange}
              fieldError={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
              fieldHelperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
            />
            <AuthFormSubmit
              textSubmit={'Confirm'}
            />
          </AuthForm>

          <AuthDialog
            successIcon={true}
            dialogClose={handleSuccessDialogClose}
            dialogOpen={successDialogOpen}
            dialogTitle={'Success!'}
            dialogMessage={'Your password has been updated. You will be redirected to the login page in a few seconds.'}
            dialogBtnRouter={true}
            dialogBtnLink={'/login'}
            dialogActions={true}
            dialogBtnText={'Back to login'}
          />

        </Container>
      </Authlayout>
    </>
  );
}

export default ResetPassword;
