import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { GET_PSC_BADGES } from '../../../components/profileScoreCard/apolloQueries';
import { useQuery } from '@apollo/client';
import LoadingSpinner from "../../../common/components/LoadingSpinner/LoadingSpinner";


const useStyles = makeStyles(theme => ({
    imgstyle:{
        width: 62,
        height: 62,
        marginTop: 5,
        textAlign:'center',
    },
    border: {
        border: `solid 1px ${theme.palette.grey[300]}`,
        width: 72,
        height: 72,
        textAlign:'center',

    },
    badgeDisplay :{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        columnGap: 20,
        rowGap: 21,
    },
    dialogTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.primary,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightMedium,
      marginTop: 5

    },
    dialogContent: {
      paddingRight: 98,
      paddingLeft: 98,
      paddingBottom: 42
    },



}))

const BadgesDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, badgeEarned } = props;


    const handleClose = () => {
      onClose();
    };
    const {
      loading,
      error,
      data
    } = useQuery(GET_PSC_BADGES, {
      fetchPolicy: "cache-and-network",
    });

    const badges =  data?.profileBadges || [];

    return (
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogTitle id="alert-dialog-title">
          <h2 className={classes.dialogTitle}>
            {badgeEarned} Badges Earned
          </h2>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
        {loading ? (
          <LoadingSpinner styles={{ paddingTop: 65 }} />
        ) : error ? (
          <div>Error! {error.message}</div>
        ) : (
            <div className={classes.badgeDisplay}>
            {badges.length > 0 && badges.map((src, index) => (

            <div className={classes.border} key={index}>
                <img className={classes.imgstyle} src={src} alt='img'/>
            </div>

            ))

            }
            </div>

         )}
        </DialogContent>

      </Dialog>

    )
}

export default BadgesDialog
