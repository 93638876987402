import gql from "graphql-tag";

export const GET_PSC = gql`
  query profileScoreCard {
    profileScoreCard {
      serialNumber
      username
      expirationDate
      badges
      photo
      careerPaths {
        name
        scores {
          status
          challengeLearningTopic
          learningTopicScores {
            metaSkill
            score
            obtainableScore
          }
          overallScore
          overallObtainableScore
        }
      }
    }
  }
`;

export const GET_PSC_BY_ID = gql`
  query profileScoreCardById($id: ID!) {
    profileScoreCardById(id: $id) {
      serialNumber
      username
      expirationDate
      badges
      photo
      careerPaths {
        name
        scores {
          status
          challengeLearningTopic
          learningTopicScores {
            metaSkill
            score
            obtainableScore
          }
          overallScore
          overallObtainableScore
        }
        careerPathOverallScore
      }
    }
  }
`;

export const GET_PSC_BADGES = gql`
  query profileBadges {
    profileBadges
  }
`;
