import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(3),
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  cardContent: {
    paddingTop:0
  },
  badgeList: {
    maxWidth:184,
    padding:0,
    margin:'0 auto',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      maxWidth:600,
      justifyContent: 'center',
    },
    '& li': {
      width:82,
      padding:6,
      marginBottom: 16,
      border:`solid 1px ${theme.palette.grey[300]}`,
      [theme.breakpoints.down('md')]: {
        margin: '0px 5px 10px',
      },
    },
    '& img': {
      width: '100%',
      height: 'auto',
    }
  }
}));

const ObtainableBadges = ({ badges }) => {
  const classes = useStyles();

  return (
    <Card elevation={3}>
      <CardHeader
        title="Obtainable Badges"
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <ul className={classes.badgeList}>
          {badges.map((badge, index) => {
            const { url } = badge.attributes;

            return (
              <li key={`sdBadge-${index}`}>
                <img
                  src={url}
                  alt={`badge-${index}`}
                  loading="lazy"
                />
              </li>
            )
          })}
        </ul>
      </CardContent>
    </Card>
  );
}

export default ObtainableBadges;
