import React, { Component } from 'react';
//import Layout from '../../../../common/Layout';
import { Query } from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import { GET_CHALLENGE, GET_CHALLENGE_INSTANCE } from '../queries';
import { SecureQuery } from '../../../../settings/graphqlHelper';
import ChallengePanel from '../../ChallengePanel';
import LineItemsSimulation from "../LineItemsSimulation";
import { client } from "../../../../settings/apollo";
import { evaluateStep } from "../simulation";
import { campaignVar, creativesVar, creativeVar, deliveryVar, frequencyCAPVar, lineItemsVar, lineItemVar, recencyVar, typeVar } from '../../../../settings/cache';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../../../utils/data/sandboxes";

class CampaignAdServerStep5 extends Component {
  constructor(props) {
    super(props);

    this.state = { lineItems: [], id: props.match.params.id, name: '', step: 2, steps: '', isSubmitting: false };
    campaignVar({});
    creativesVar([]);
    creativeVar({});
    lineItemsVar([]);
    lineItemVar({});
    this.submit = this.submit.bind(this);
    this.changeTime = this.changeTime.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType,
        //step: res.data.challengeInstance.currentStep,
        isSubmitting: false,
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  handleTab() {

  };

  async changeTime() {
    if (this.state.step < 4) {
      await this.setState({ step: this.state.step + 1 })
    }
  }

  toggleSubmittingOff() {
    this.setState({ isSubmitting: false });
  }


  async submit() {
    try {
      this.setState({ isSubmitting: true });
      const action = {
        actionNeeded: 'complete',
        target: JSON.stringify({
          type: "lineitem",
          fields: {
            "lineitemType": typeVar(),
            "deliveryType": deliveryVar(),
            "frequency": frequencyCAPVar(),
            "recency": recencyVar()
          }
        })
      };

      const res = await evaluateStep(this.state.id, action, client);
      const value = res.data.challengeStepEvaluator;
      const shouldResetSubmitState = true;
      if (value !== '') {
        const newPathname = value + this.props.match.params.id;
        if (this.props.location.pathname !== newPathname) {
          this.shouldResetSubmitState = false;
          this.props.history.push(value + this.props.match.params.id);
        }
      }
      shouldResetSubmitState && this.setState({ isSubmitting: false });
    } catch (err) {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    if (!this.state.type)
      return <LoadingAnimation />;

    return (<SandboxLayout name="Campaigns" challengeMode={true} layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
        <div className="col-md-12 col-12 mr-auto ml-auto">
          <div className="card card-wizard active" data-color="rose" id="wizardProfile">
            <div>
              <Query query={GET_CHALLENGE}
                fetchPolicy="network-only"
                variables={{
                  id: this.state.type
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return (<LoadingAnimation />);
                  if (error) return `Error! ${error.message}`;
                  if (!data || data.challenge === null)
                    return <div>No data available</div>;
                  const challenge = JSON.parse(data.challenge);

                  let step = parseInt(this.state.step);
                  const StepCountDownTime = challenge.steps[step].countdownTime;
                  let items = [];
                  if (challenge.steps[step])
                    items = challenge.steps[step].data;
                  return (
                    <div>
                      <LineItemsSimulation 
                        handleTab={this.handleTab}
                        caption="Line Items"
                        submit={this.submit}
                        name={this.state.name}
                        id={this.state.id}
                        items={items}
                        changeTime={this.changeTime}
                        isSubmitting={this.state.isSubmitting}
                      />
                      <ChallengePanel
                        time={StepCountDownTime ? StepCountDownTime : 0}
                        description={challenge.challengeDescription}
                        step={step ? step : 2}
                        coach={challenge.coach}
                        challengeTitle={challenge.challengeTitle}
                        challengeIsValidation={challenge.isValidation}
                        challengeDifficulty={challenge.challengeDifficulty}
                        challengeAverageTime={challenge.averageTime}
                      />
                    </div>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
      </div>
    </SandboxLayout>
    );
  }
}

export default CampaignAdServerStep5;
