import Button from "@material-ui/core/Button/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import DialogContentText from "@material-ui/core/DialogContentText/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import React from "react";
import { useState } from "react";
import ExcelReader from "../../common/components/base/ExcelReader";

export default function UpdateReportDialog(props, ref) {
    const [modal, setModal] = useState(false);
    const [file, setFile] = useState([]);

    const {
        title,
        handleUndo,
        handleClose,
        // handleSave,
        // uploadItem,
        open,
        undoName,
        saveName,

    } = props;
    var description;
    if (props.children.length > 0) {
        description = props.children.split(" -");
    }

    function saveFile(){
        props.handleSave(file)
        setFile("")
    }

    return (
        <div>
            <Dialog
                open={open || modal}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.children.length > 0 ? (
                            <div>
                                <div> {description[0]}</div>
                                {description.map((item, index) => {
                                    if (index > 0) return <div> • {item}</div>;
                                    return null
                                })}
                            </div>
                        ) : null}
                    </DialogContentText>
                    <ExcelReader
                        separator={","}
                        onLoad={(data) => setFile(data)}
                        buttonLabel={"Convert"}
                    />

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUndo ? handleUndo : () => setModal(false)}
                        color="primary"
                    >
                        {undoName || "Cancel"}
                    </Button>
                    {file.length>0 ?
                        <Button
                            onClick={()=>saveFile()}
                            color="primary"
                        >
                            {saveName || "Save"}
                        </Button>
                        :
                        null
                    }
        </DialogActions>
            </Dialog>
        </div>
    );
}
