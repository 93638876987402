import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_HELP_PAGE } from "./graphql/queries";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import Layout from '../../common/Layout';
import PageHeaderCentered from '../../common/components/PageHeaderCentered/PageHeaderCentered';
import FaqBlock from '../../common/components/FaqBlock/FaqBlock';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop:50,
    marginBottom:55,
    marginLeft:'20%',
    marginRight:'20%',
  },
  description: {
    maxWidth:650,
    margin:'0 auto',
    '& p':{
      marginBottom:8
    }
  },
  icon:{
    fontSize:48,
    color: theme.palette.common.gamsPetroleumBlue
  },
  customerBox: {
    maxWidth:600,
    margin:'auto',
    textAlign:'center',
    marginTop:28,
    marginBottom:30,
  },
  customerMsg: {
    marginBottom:10,
  },
  customerBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    textTransform:'none',
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const ThankYouPage = (props) => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(GET_HELP_PAGE);

  const helpPage = data?.helpPage?.data?.attributes;

  if (loading) return (
    <Layout>
      <LoadingSpinner styles={{ paddingTop: 60 }} />
    </Layout>
  );

  if (error) return `Error! ${error.message}`;

  return(
    <Layout>
      <PageHeaderCentered
        icon={<ContactSupportIcon className={classes.icon} />}
        title={helpPage.title}
        // description={
        //   <Box className={classes.description}>
        //     <Typography variant="body1">
        //       Hey, it's time for some sunny holidays! From August 7th to 22nd, included, our team might be taking longer to assist you in your requests.
        //     </Typography>
        //     <Typography variant="body1">
        //       Be patient, and we'll do our best to reply to all of you, cheers. GAMS team.
        //     </Typography>
        //   </Box>
        // }
      />
      <Divider className={classes.divider} variant="middle" />
      <FaqBlock faqData={helpPage.faq} />
      <Box className={classes.customerBox}>
        <Typography className={classes.customerMsg} variant="body1" color="textPrimary">
            {helpPage.supportCta.message}
        </Typography>
        <Typography variant="body1" color="textPrimary">
          Email us at:
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="default"
          className={classes.customerBtn}
          href={helpPage.supportCta.link}
          rel="noopener noreferrer"
          target="_blank"
        >{helpPage.supportCta.textLink}
        </Button>
      </Box>
    </Layout>
  );
}

export default ThankYouPage;
