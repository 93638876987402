import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

const CalendarDialog = React.forwardRef((props, ref) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        value={props.value}
        onChange={props.onHandleDateChange}
        ref={ref}
        style={props.styleDialog ? props.styleDialog : undefined}
      />
    </MuiPickersUtilsProvider>
  );
});

export default CalendarDialog;
