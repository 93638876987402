import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import VideoDialog from '../Dialogs/VideoDialog/VideoDialog';
import placeholderImg from '../../../assets/images/career-list/career-fullimage-placeholder.jpg';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
  careerHeader: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: placeholderImg,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius:6,
    overflow:'hidden'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    //backgroundColor: 'rgba(36,36,36,0.13)',
  },
  careerHeaderGrid: {
    backgroundImage: 'linear-gradient(270deg, rgba(255,255,255,0.00) 0%, rgba(0,0,0,0.18) 50%)',
  },
  careerHeaderContent: {
    maxWidth:712,
    position: 'relative',
    padding: theme.spacing(4, 4, 5, 4),
    '& h1': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight:1.4,
      textShadow: `1px 1px 3px rgba(0,0,0, 0.5)`,
    },
    '& p': {
      fontSize: theme.typography.pxToRem(18),
      textShadow: `1px 1px 3px rgba(0,0,0, 0.5)`,
      lineHeight:1.6,
      marginBottom:theme.spacing(3),
    }
  },
  careerHeaderBtn: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: theme.spacing(1, 3.5, 1, 2.5),
    fontSize: theme.typography.pxToRem(18),
    textTransform:'none',
    letterSpacing: 0.2,
    borderRadius:8,
    '&:hover': {},
    '&:focus': {
      outline: 'none',
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  }
}));

const CareerHeader = ({ title, description, fullImage, videoLink }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Paper className={classes.careerHeader} style={{ backgroundImage: `url(${fullImage ? fullImage : placeholderImg})` }}>
        {/* Increase the priority of the hero background image */}
        {<img style={{ display: 'none' }} src={fullImage ? fullImage : placeholderImg} alt={title} />}
        <div className={classes.overlay} />
        <Grid container>
          <Grid item md={8} className={classes.careerHeaderGrid}>
            <div className={classes.careerHeaderContent}>
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                {title}
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                {description}
              </Typography>
              {videoLink ? <Button
                className={classes.careerHeaderBtn}
                color="default"
                variant="contained"
                onClick={handleDialogClickOpen}
                startIcon={<PlayArrowIcon style={{ fontSize: 40, marginRight:0 }} />}>
                Preview Career
              </Button> : null}
            </div>
          </Grid>
        </Grid>
      </Paper>

      {videoLink ? <VideoDialog
        dialogClose={handleDialogClose}
        dialogOpen={dialogOpen}
        dialogTitle={title}
        dialogVideoUrl={videoLink}
      /> : null}
    </>
  );
}

export default CareerHeader;
