import React from 'react';
import ButtonModule from '../modules/ButtonModule';
import TextInputModule from '../modules/TextInputModule';
import CheckboxesModule from '../modules/CheckboxesModule';
import RadioModule from '../modules/RadioModule';
import CheckboxesImageModule from '../modules/CheckboxesImageModule';

const QuestionType = props => {
  const {
    currentStep,
    onChanged,
    stepIndex
  } = props

  const checkQuestionType = (param) => {
    switch(param) {
      case 'download':
        return <ButtonModule currentStep={currentStep} onChanged={onChanged} />;
      case 'openTabLink':
        return <ButtonModule currentStep={currentStep} onChanged={onChanged} />;
      case 'openField':
        return <TextInputModule
          stepIndex={stepIndex}
          inputLabel={"Insert the answer here"}
          inputPlaceholder={currentStep.placeholder}
          valueType={currentStep.type}
          onChanged={value => onChanged("validationField", value)} />;
      case 'selection':
        return <CheckboxesModule
          inputLabel={"Check the following answers"}
          randomizeAnswer={currentStep.randomizeAnswer}
          stepIndex={stepIndex}
          onChanged={value => onChanged("validationField", value)}
          options={currentStep.answers} />;
      case 'T/F':
        return <RadioModule
          inputLabel={'Select answer'}
          stepIndex={stepIndex}
          onChanged={value => onChanged("validationField", value)}
        />;
      case 'imageSelection':
        return <CheckboxesImageModule
          randomizeAnswer={currentStep.randomizeAnswer}
          stepIndex={stepIndex}
          onChanged={value => onChanged("validationField", value)}
          options={currentStep.answers} />;
      default:
        return;
    }
  }

  return (
    <>
      {checkQuestionType(currentStep.questionType)}
    </>
  );
}

export default QuestionType;
