import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Box, Typography, Link, Grid } from '@material-ui/core';
import gamsLogo from '../../../assets/images/logo/new-gams-logo.png';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  profilePaper: {
    margin:'auto',
    overflow: 'hidden',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom:32,
    padding: theme.spacing(3),
  },
  topProfile: {
    height:'auto',
    position: 'relative',
  },
  avatar: {
    position:'relative',
    width: 60,
    height:60,
    margin: 'auto',
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[300],
    border: `solid 1px ${theme.palette.grey[300]}`,
    '& > img': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: 60,
      height: 60,
      marginTop: -30,
      marginLeft: -30,
    }
  },
  placeholderAvatar: {
    fill: theme.palette.grey[700],
    position: 'absolute',
    top: '60%',
    left: '50%',
    width: 60,
    height: 60,
    marginTop: -30,
    marginLeft: -30,
  },
  username: {
    textAlign: 'center',
    marginTop: 6,
    marginBottom: 10,
    '& > h3': {
      fontSize: theme.typography.pxToRem(21),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: 5,
    },
    '& > h4': {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.common.gamsOrange,
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  badgeTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    marginTop: 5,
  },
  badgesBox: {
    paddingTop: 6,
    '& div': {
      width: 45,
      height: 45,
      margin: '0 4px',
      '& img': {
        width: '100%',
        height: 'auto',
      }
    }
  },
  logoBox: {
    maxWidth:190,
    margin: 'auto',
    paddingTop:10,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  certification: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 12,
    '& > div': {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3
    },
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3
    }
  },
  date: {
    textAlign: 'center',
    '& > span': {
      fontWeight: theme.typography.fontWeightMedium,
    }
  },
  gridRoot: {
    borderBottom:`solid 1px ${theme.palette.grey[300]}`,
    paddingBottom:14,
  },
  gridItem: {
    minHeight:205,
  },
  partnerBox: {
    '& p': {
      marginTop: 3,
      marginRight: 6,
      lineHeight: 1,
    }
  },
  partnerImg: {
    maxWidth:52,
    margin: '0 6px',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
}));

const PdfPscProfile = ({ expirationDate, serialNumber, username, badges, photo }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.profilePaper} elevation={2}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridRoot}
      >
        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.topProfile}>
            <div className={classes.avatar}>
              {photo ?
                <img src={photo} alt={username} />
              :
                <svg className={`${classes.placeholderAvatar}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 333" fillRule="evenodd" clipRule="evenodd" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality">
                  <path d="M283 254c-12-14-59-39-76-43 0 0-17-11-7-28 14-24 31-44 31-74 0-104-130-104-130 0 0 24 11 48 25 68 17 24-2 33-2 33-20 7-58 27-73 44 63 65 168 66 232 0z"/>
                </svg>
              }
            </div>
          </Box>

          <Box className={classes.username}>
            <Typography variant="h3">{username}</Typography>
            <Typography variant="h4">GAMS Talent Hub</Typography>
          </Box>

          <Box>
            <Typography className={classes.badgeTitle} component="h3">Like-a-pro Badges</Typography>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.badgesBox}
            >
            {badges.map((badge, index) => {
              return (
                <div key={index}>
                  <img src={badge} alt="Gams Badge" />
                </div>
              )
            })}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <Box className={classes.logoBox}>
            <Link
              href="https://gamsplatform.com"
              rel="noopener noreferrer"
              target="_blank">
              <img src={gamsLogo} alt="GAMS Logo" />
            </Link>
          </Box>
          <Box className={classes.certification}>
            <Typography component="div">Certification Number</Typography>
            <Typography component="span">{serialNumber}</Typography>
          </Box>
          <Box className={classes.date}>
            <Typography component="span">Exp. date {format(new Date(expirationDate), 'd MMM yyyy')}</Typography>
          </Box>
        </Grid>

        {/* <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          className={classes.partnerBox}>
          <Typography component="p">Accredited by</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center">
            <Box className={classes.partnerImg}>
              <img src={gamsPartnerImg} alt={'gams partner'} />
            </Box>
            <Box className={classes.partnerImg}>
              <img src={gamsPartnerImg} alt={'gams partner'} />
            </Box>
            <Box className={classes.partnerImg}>
              <img src={gamsPartnerImg} alt={'gams partner'} />
            </Box>
          </Box>
        </Box> */}
      </Grid>

    </Paper>
  );
}

export default PdfPscProfile;
