
import React from "react";
import { Formik } from "formik";
import { withRouter } from "react-router";
import PlacementForm from "../../../placement/PlacementForm";
import SecondStepChallengeLayout from "../SecondStepChallengeLayout";
import {
  evaluateSecondStep,
  evaluateThirdStep
} from "../../../../settings/commonFunctions";

import * as Yup from 'yup';
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import { GET_CHALLENGE_INSTANCE } from "../queries";
import { SecureQuery } from "../../../../settings/graphqlHelper";

const placementSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a name.'),
  size: Yup.string()
    .required('Please select a size.'),
});

class AdvertisersAdServerStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, isClicked: false };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then(res => {
        this.setState({
          type: res.data.challengeInstance.challengeType
        });
      }).catch(error => {
        console.log(error);
      });
  }

  coreSubmit = async values => {
    const { id } = this.props.match.params;
    const action = {
      actionNeeded: "complete",
      target: JSON.stringify({
        fields:{
          placementName: values.name.trim(),
          placementSize: values.size
        }
      })
    };
    let res = await evaluateSecondStep(action, id);
    let value = res.data.challengeStepEvaluator;
    if (value) {
      let finalTarget = await evaluateThirdStep(id);
      if (finalTarget) this.props.history.push(finalTarget + id);
    }
  };

  submit = values => {
    if(!this.state.isClicked){
      this.setState({isClicked: true}, () => {
        this.coreSubmit(values).then(() => {
          setTimeout(() => {
            this.setState({isClicked: false})
          }, 800);
        })
      });
    }
  };

  render() {
    if (!this.state.type)
      return <LoadingAnimation />;

    return (
      <SecondStepChallengeLayout pageName={"Placements"} challengeType={this.state.type}>
        <Formik
          initialValues={{ name: "", size:"" }}
          //validate={validatePlacement}
          validationSchema={placementSchema}
          onSubmit={this.submit}
        >
          {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit, errors, touched }) => (
            <PlacementForm
              values={values}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleChangeSize={this.handleChangeSize}
              isSubmitting={isSubmitting}
              size="Size"
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
            />
          )}
        </Formik>
      </SecondStepChallengeLayout>
    );
  }
}

export default withRouter(AdvertisersAdServerStep2);
