import gql from "graphql-tag";

export const GET_SANDBOX_BY_SLUG= gql`
  query sandboxes($slug: StringFilterInput) {
    sandboxes(filters: {slug: $slug}) {
      data {
        attributes {
          name
          slug
          openToolLink
          isExternal
          shortDescription
          longDescription
          sandboxIcon {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            iconGradient
          }
          resources {
            listItem {
              title
              link
              IsExternal
            }
          }
          connectedCareersPaths {
            listItem {
              title
              link
              IsExternal
            }
          }
          sandboxGallery {
            data {
              attributes {
                name
                url
              }
            }
          }
          obtainableBadges {
            data {
              attributes {
                name
                url
              }
            }
          }
        }
      }
    }
  }
`;
