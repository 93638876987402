import gql from "graphql-tag";

export const CREATE_CHECKOUT_SESSION = gql`
  mutation createCheckoutSession($priceId: String!,  $host: String!) {
    createCheckoutSession(priceId: $priceId, host: $host )
  }
`;

export const CUSTOMER_PORTAL = gql`
  mutation customerPortal($host: String!){
      customerPortal(host: $host)
  }
`;
