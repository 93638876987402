import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    borderRadius: 8,
  },
  dialogTitle: {
    position: 'relative',
    margin: 0,
    padding: theme.spacing(2),
    '& h5': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3,
    }
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: 0,
  },
  iframeContainer: {
    background: theme.palette.common.black,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
    '@media (max-width: 1535.98px) and (min-aspect-ratio: 16/9)': { // fix 16:9 screens
      paddingTop: 'calc(56.25% - 100px)',
    }
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%"
  }
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const VideoDialog = ({ dialogOpen, dialogClose, dialogTitle, dialogVideoUrl }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={dialogOpen}
      onClose={dialogClose}
      classes={{
        paper: classes.dialogRoot
      }}
    >
      <DialogTitle onClose={dialogClose}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          className={classes.iframeContainer}
        >
          <iframe
            title="Video Frame"
            className={classes.iframe}
            src={dialogVideoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-presentation"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default VideoDialog;
