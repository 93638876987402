import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'
import NumberFormatCustom from '../../base/NumberFormatCustom';
import blue from '@material-ui/core/colors/blue';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 23
  },
  textField: {
    '& label.Mui-focused': {
      color: blue[900],
    }
  },
}))

const TextInputModule = props => {
  const {
    inputLabel,
    onChanged,
    inputPlaceholder,
    stepIndex,
    valueType
  } = props

  const [FirstMount, setFirstMount] = useState(stepIndex)
  const [defaultValue, setValue] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (FirstMount !== stepIndex) {
      setValue("")
      setFirstMount(stepIndex)
    }
  }, [FirstMount, stepIndex]);

  const onNumberChange = (value) => {
    const res = value.replace(".", ",")
    onChange(res);
  }
  const onChange = (value) => {
    onChanged(value)
    setValue(value)
  }

  let adornment = null;
  if (valueType === 'euros') {
    adornment = <InputAdornment position="start">€</InputAdornment>;
  } else if (valueType === 'percentage') {
    adornment = <InputAdornment position="start">%</InputAdornment>;
  }

  let stepInput = null;
  if (valueType === 'euros' || 'percentage' || 'number') {
    stepInput = (
      <TextField
        id="myInput"
        name="myInput"
        value={defaultValue}
        //type="number"
        onChange={(event) => onNumberChange(event.target.value)}
        label={inputLabel}
        placeholder={inputPlaceholder}
        //helperText="Type a number"
        variant="outlined"
        fullWidth
        className={classes.textField}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: adornment,
        }}
      />
    );
  }
  if (valueType === 'text'){
    stepInput = (
      <TextField
        id="myInput"
        name="myInput"
        value={defaultValue}
        type="text"
        onChange={(event) => onChange(event.target.value)}
        label={inputLabel}
        placeholder={inputPlaceholder}
        //helperText="Type a text"
        variant="outlined"
        fullWidth
        className={classes.textField}
      />
    );
  }

  return (
    <div className={classes.root}>
        {stepInput}
    </div>
  );
}

export default  TextInputModule;
