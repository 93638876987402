import { useCallback, useState } from "react";

export const useCenteredTree = () => {
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      //const { width, height } = containerElem.getBoundingClientRect();  // default value

      const { width } = containerElem.getBoundingClientRect();
      const newHeight = 110;

      setTranslate({
        x: width / 2,
        //y: height / 2   // default value
        y: newHeight
      });
    }
  }, []);
  return [translate, containerRef];
};
