import React from 'react';
import Button from '@material-ui/core/Button';
import Layout from '../../common/Layout';
import { Formik } from 'formik';
import { Box, Checkbox, Chip, FormControlLabel, Input, InputLabel, makeStyles, MenuItem, Select, Switch, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Query } from '@apollo/client/react/components';
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';
import { useMutation } from '@apollo/client';
import { SECTIONS_AVAILABLE } from '../Navigation';
import * as Yup from 'yup';
import { useTheme } from '@material-ui/styles';
import { UPDATE_BANNER } from './graphql/mutations';
import { GET_BANNER } from './graphql/queries';


const BannerSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  body: Yup.string()
    .min(2, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Required'),
  isEnabled: Yup.boolean().required('Required'),
  expirationDate: Yup.date().required('Required'),
  sections: Yup.array().min(1, 'Have to select at least one').of(Yup.string()).required('Required')
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const getStyles = (name, sectionName, theme) => {
  return {
    fontWeight:
    sectionName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UpdateForm(props) {
  const id = !props.match?.params?.id ? null : props.match.params.id;
  const [updateBanner] = useMutation(UPDATE_BANNER);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Query 
      query={GET_BANNER} 
      variables={{id: id}}
      fetchPolicy="network-only"
    >
      {({loading, error, data}) => {
        if (loading) return <LoadingSpinner styles={{ paddingTop: 70 }} />;
        if (error) return <p>Error :(</p>;
        const banner = data.banner;
        return (
          <Layout name="Update Banner">
            <Formik
              validationSchema={BannerSchema}
              initialValues={{
                  _id: id,
                  title: banner.title,
                  isEnabled: banner.isEnabled,
                  body: banner.body,
                  expirationDate: banner.expirationDate,
                  sections: banner.sections
              }}
              onSubmit={(values, actions) => {
                  setTimeout(() => {
                    actions.setSubmitting(false);
                    updateBanner({
                      variables: {
                        input: values
                      }
                    }).then(() =>
                      Swal.fire({
                        type: 'success',
                        title: 'Updated',
                        showConfirmButton: false,
                        timer: 1500
                      }).then(()=>props.history.push(`/admin-settings/banners`)),
                      (error) => {
                        Swal.fire({
                        type: 'error',
                        title: 'Error updating banner',
                        showConfirmButton: false,
                        timer: 1500
                        })
                        console.log(error);
                      }
                    )
                }, 1000);
              }}
            >
              {propsFormik => (
                <form onSubmit={propsFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-3 d-flex align-items-stretch flex-wrap">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Title</h5>
                        </div>
                        <div className="card-body">
                          <h3>
                            <b>
                              <div className="form-group bmd-form-group">
                                <TextField
                                  error={propsFormik.errors.title}
                                  helperText={propsFormik.touched.title && propsFormik.errors.title}
                                  defaultValue={propsFormik.values.title}
                                  type="text"
                                  className="form-control"
                                  name="title"
                                  onChange={propsFormik.handleChange}
                                  onBlur={propsFormik.handleBlur}
                                  fullWidth
                                />
                              </div>
                            </b>
                          </h3>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Expiration Date POOEEE</h5>
                        </div>
                        <div className="card-body">
                          <TextField
                            error={propsFormik.errors.expirationDate}
                            helperText={propsFormik.touched.expirationDate && propsFormik.errors.expirationDate}
                            name="expirationDate"
                            label="Change Expiration date"
                            type="date"
                            onChange={(event) => {
                                propsFormik.setFieldValue("expirationDate", event.target.value)
                            }}
                            defaultValue={moment(propsFormik.values.expirationDate).format('YYYY-MM-DD')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md d-flex align-items-stretch flex-wrap">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Body</h5>
                        </div>
                        <div className="card-body align-items-center justify-content-center">
                          <div className="form-group bmd-form-group align-items-center">
                            <TextField
                              error={propsFormik.errors.body}
                              helperText={propsFormik.touched.body && propsFormik.errors.body}
                              defaultValue={propsFormik.values.body}
                              type="text"
                              className="form-control"
                              name="body"
                              onChange={propsFormik.handleChange}
                              onBlur={propsFormik.handleBlur} 
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Sections</h5>
                        </div>
                        <div className="card-body align-items-center justify-content-center">
                          <div className="form-group bmd-form-group align-items-center">
                            <Select
                              labelId='sections-label'
                              error={propsFormik.errors.sections}
                              name="sections"
                              placeholder="Insert Section separated with space to create an array"
                              multiple
                              value={propsFormik.values.sections}
                              onChange={(event) => {
                                if (event.target.value[event.target.value.length - 1] === "all") {
                                  const allValue = SECTIONS_AVAILABLE.map((item)=>item.value)
                                  const allOptions = propsFormik.values.sections.length === SECTIONS_AVAILABLE.length 
                                    ? [] 
                                    : allValue;
                                  return propsFormik.setFieldValue("sections", allOptions);
                                }
                                propsFormik.setFieldValue("sections", event.target.value)
                              }}
                              fullWidth
                              input={<Input />}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              <MenuItem value="all" style={getStyles('all', propsFormik.values.sections, theme)}>
                                <Checkbox
                                  checked={propsFormik.values.sections.length > 0 && propsFormik.values.sections.length === SECTIONS_AVAILABLE.length}
                                  indeterminate={propsFormik.values.sections.length > 0 && propsFormik.values.sections.length < SECTIONS_AVAILABLE.length}
                                />
                                Select All
                              </MenuItem>
                              {SECTIONS_AVAILABLE.map((name) => (
                                <MenuItem key={name.text} value={name.value} style={getStyles(name, propsFormik.values.sections, theme)}>
                                  {name.text}
                                </MenuItem>
                              ))}
                            </Select>
                            <InputLabel class="error" id='sections-label'>{propsFormik.errors.sections}</InputLabel>
                            {/* <ChipInput
                              error={propsFormik.errors.sections}
                              helperText={propsFormik.touched.sections && propsFormik.errors.sections}
                              name="sections"
                              placeholder="Insert Section separated with space to create an array"
                              value={propsFormik.values.sections}
                              //dataSourceConfig={SECTION_AVAILABLE}
                              newChipKeyCodes={[32]}
                              // newChipKeys={['space']}
                              onAdd={(chip)=>{
                                propsFormik.values.sections.push(chip);
                                propsFormik.setFieldValue("sections", propsFormik.values.sections);
                              }}
                              onDelete={(chip, index) => {
                                const clonedSections = [...propsFormik.values.sections];
                                clonedSections.splice(index,1);
                                propsFormik.setFieldValue("sections", clonedSections);
                              }}
                              onChange={propsFormik.handleChange}
                              onBlur={propsFormik.handleBlur}
                              fullWidth
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 d-flex align-items-stretch">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">Status</h5>
                        </div>
                        <div className="card-body">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={propsFormik.values.isEnabled}
                                onChange={(value)=>propsFormik.setFieldValue("isEnabled", value.target.checked)}
                                name="isEnabled"
                                color="primary"
                              />
                            }
                            label="Enabled"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ justifyContent: "center" }}>
                    <Button className="btn btn-fill btn-rose" type="submit" variant="contained" size="large">
                      <SaveIcon />
                      Save
                    </Button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Box  style={{  display: "flex", justifyContent: "space-around", marginTop: "0.5rem"}}>
                        <Button className="btn btn-primary" onClick={()=>props.history.push(`/admin-settings/banners`)}>Cancel</Button>
                      </Box>
                    </div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6" style={{ justifyContent: "flex-end", alignContent: "flex-end" }}></div>
                  </div>
                </form>
              )}
            </Formik>
          </Layout>
          );
      }}
    </Query>
  );
}