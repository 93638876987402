import {client} from './apollo';
import {GET_CHALLENGE_INSTANCE} from "../components/challenges/AdServer/queries";

export const SecureQuery = {
  query(options) {
    return client.query({
      query: options.query,
      variables: options.variables
    });
  },
  async asyncMutation(options){
    return await client.mutate({mutation: options.mutation, variables:options.variables,});
  },
  
  writeQuery(options){
    return client.writeQuery(options);
  },

  mutate(options){
    return client.mutate({mutation: options.mutation, variables:options.variables,});
  }
};

export const Query = {
  query(query,variables) {
    return client.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: {id: this.state.id}
    });
  },
};
