import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box, Typography } from '@material-ui/core';
import { crmData } from "../../../../utils/data/sandboxes";
import { entryNodes, actionNodes, conditionNodes } from "../../../../utils/data/crmAutomation";
import { GET_CRM_AUTOMATION, GET_CRM_CONTACTS_LISTS } from "../../graphql/queries";
import { UPDATE_CRM_AUTOMATION, CREATE_CRM_ENTRY_NODE, CREATE_CRM_CONDITION_NODE, CREATE_CRM_ACTION_NODE, UPDATE_CRM_AUTOMATION_NODE, DELETE_CRM_AUTOMATION_NODE, ACTIVATE_CRM_AUTOMATION, DEACTIVATE_CRM_AUTOMATION } from "../../graphql/mutations";
import { useQuery, useMutation } from '@apollo/client';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxDialog from "../../../../common/components/Dialogs/SandboxDialog/SandboxDialog";
import WorkflowTitle from '../../../../common/components/WorkflowTitle/WorkflowTitle';
import AutomationTree from '../../../../common/components/AutomationTree/AutomationTree';
import AutomationTreeDialog from '../../../../common/components/Dialogs/AutomationTreeDialog/AutomationTreeDialog';
import AutomationNodeDialog from '../../../../common/components/Dialogs/AutomationNodeDialog/AutomationNodeDialog';
import ListNodeButtons from '../../../../common/components/AutomationTree/ListNodeButtons/ListNodeButtons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  actionBtn: {
    minWidth: 180,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    letterSpacing: 0.2,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  },
  goBackBtn: {
    marginBottom:6,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  workflowActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a:last-child': {
      marginLeft: 10
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'right',
      '& button': {
        marginBottom: 6
      },
    },
  },
  dialogDefaultBtn: {
    '&:focus': {
      outline: 'none',
    }
  },
  dialogCancelBtn: {
    color: theme.palette.grey[500],
  }
}));

// Data of nodes used for the dialogs to add/edit nodes on the Automation Tree
const stepDialogNodes = actionNodes.nodes.concat(conditionNodes.nodes[0].items);
const entryDialogNodes = entryNodes.nodes;
const allDialogNodes = entryDialogNodes.concat(stepDialogNodes);

const CrmAutomationWorkflow = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [activationDialogOpen, setActivationDialogOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [selectEntryDialogOpen, setSelectEntryDialogOpen] = useState(false);
  const [selectStepDialogOpen, setSelectStepDialogOpen] = useState(false);
  const [addNodeDialogOpen, setAddNodeDialogOpen] = useState(false);
  const [deleteNodeDialogOpen, setDeleteNodeDialogOpen] = useState(false);

  const [currentNodeId, setCurrentNodeId] = useState('');
  const [selectedDialogId, setSelectedDialogId] = useState();
  const [actionType, setActionType] = useState('');
  const [workflowState, setWorkflowState] = useState(false);

  const [updateCrmAutomation] = useMutation(UPDATE_CRM_AUTOMATION);
  const [createCrmEntryNode, { loading: ccenLoading }] = useMutation(CREATE_CRM_ENTRY_NODE);
  const [createCrmActionNode, { loading: ccanLoading }] = useMutation(CREATE_CRM_ACTION_NODE);
  const [createCrmConditionNode, { loading: cccnLoading }] = useMutation(CREATE_CRM_CONDITION_NODE);
  const [updateCrmAutomationNode, { loading: ucanLoading }] = useMutation(UPDATE_CRM_AUTOMATION_NODE);
  const [deleteCrmAutomationNode, { data: dcanData, loading: dcanLoading, reset: dcanReset }] = useMutation(DELETE_CRM_AUTOMATION_NODE);
  const [activateAutomation, { loading: aaLoading }] = useMutation(ACTIVATE_CRM_AUTOMATION);
  const [deactivateAutomation, { loading: daLoading }] = useMutation(DEACTIVATE_CRM_AUTOMATION);

  const { loading, error, data, refetch } = useQuery(GET_CRM_AUTOMATION, {
    variables: { id: props.match.params.id },
    fetchPolicy: "cache-and-network"
  });
  const { loading: loadingLists, error: errorLists, data: dataLists } = useQuery(GET_CRM_CONTACTS_LISTS, {
    fetchPolicy: "cache-and-network",
  });

  const handleActivateWorkflow = (workflow) => {
    if (workflow === false) {
      activateAutomation({
        variables: {
          id: props.match.params.id
        }
      })
      .then(({ data }) => {
        if (data.activateAutomation === true) {
          refetch();
          setWorkflowState(true);
          setActivationDialogOpen(true);
        } else {
          setErrorDialogOpen(true);
          setErrorMsg('Sorry. Something went wrong.');
        }
      })
      .catch(error => {
        setErrorDialogOpen(true);
        setErrorMsg(error.toString());
      });
    } else if (workflow === true) {
      deactivateAutomation({
        variables: {
          id: props.match.params.id
        }
      })
      .then(({ data }) => {
        if (data.deactivateAutomation === true) {
          refetch();
          setWorkflowState(false);
          setActivationDialogOpen(true);
        } else {
          setErrorDialogOpen(true);
          setErrorMsg('Sorry. Something went wrong.');
        }
      })
      .catch(error => {
        setErrorDialogOpen(true);
        setErrorMsg(error.toString());
      });
    }
  }

  const handleEditTitle = (value) => {
    updateCrmAutomation({
      variables: {
        id: props.match.params.id,
        input: {
          name: value,
        }
      }
    })
    .then(({ data }) => {
      if (data.updateCrmAutomation === true) {
        setSuccessDialogOpen(true);
        setSuccessMsg('Workflow title updated!');
        refetch();
      } else {
        setErrorDialogOpen(true)
        setErrorMsg('Sorry. Something went wrong.');
      }
    })
    .catch(error => {
      setErrorDialogOpen(true)
      setErrorMsg(error.toString());
    });
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  const handleActivationDialogClose = () => {
    setActivationDialogOpen(false);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const handleSelectEntry = () => {
    setSelectEntryDialogOpen(true)
  }

  const handleSelectEntryDialogClose = () => {
    setSelectEntryDialogOpen(false);
  };

  const handleAddEntry = (dialogNodeId, action) => {
    setActionType(action);
    setSelectEntryDialogOpen(false);
    setSelectedDialogId(dialogNodeId);
    setAddNodeDialogOpen(true);
  };

  const handleEditEntry = (nodeTitle, nodeId, action) => {
    const node = entryDialogNodes.find((item) => {
      return item.title === nodeTitle
    });
    setCurrentNodeId(nodeId);
    setActionType(action);
    setSelectedDialogId(node.id);
    setAddNodeDialogOpen(true);
  };

  const handleSelectNode = (event, nodeId, action) => {
    setCurrentNodeId(nodeId);
    setAddNodeDialogOpen(false);

    if (actionType === 'add entry') {
      setSelectEntryDialogOpen(true);
    } else if (action === 'select node') {
      setSelectStepDialogOpen(true);
    }
  };

  const handleSelectStepDialogClose = () => {
    setSelectStepDialogOpen(false);
  };

  const handleAddStep = (dialogNodeId, action) => {
    setSelectStepDialogOpen(false);
    setActionType(action);
    setSelectedDialogId(dialogNodeId);
    setAddNodeDialogOpen(true);
  };

  const handleEditStep = (nodeTitle, nodeId, action) => {
    const node = stepDialogNodes.find((item) => {
      return item.title === nodeTitle
    });
    setCurrentNodeId(nodeId);
    setActionType(action);
    setSelectedDialogId(node.id);
    setAddNodeDialogOpen(true);
  };

  const handleAddNodeDialogClose = () => {
    setActionType('');
    setAddNodeDialogOpen(false);
  };

  const handleDeleteNode = (id, action) => {
    setActionType(action);
    setCurrentNodeId(id);
    setDeleteNodeDialogOpen(true);
  };

  const handleDeleteNodeDialogClose = () => {
    setActionType('');
    setDeleteNodeDialogOpen(false);
  };

  const deleteAutomationNode = () => {
    if (actionType === 'delete entry' && workflowState === true) {
      setDeleteNodeDialogOpen(false);
      setErrorDialogOpen(true);
      setErrorMsg('To delete the entry point you must deactivate the workflow.');
    } else {
      deleteCrmAutomationNode({ variables: { id: currentNodeId } })
      .then(({ data }) => {
        setDeleteNodeDialogOpen(false);
        refetch();
      })
      .catch(error => {
        setDeleteNodeDialogOpen(false);
        setErrorDialogOpen(true);
        setErrorMsg(error.toString());
      });
    }
  };

  const getMutationByActionType = (action) => {
    let mutation = null;
    switch (action) {
      case 'edit step':
        mutation = updateCrmAutomationNode;
        break;
      case 'edit entry':
        mutation = updateCrmAutomationNode;
        break;
      case 'add action':
        mutation = createCrmActionNode;
        break;
      case 'add condition':
        mutation = createCrmConditionNode;
        break;
      case 'add entry':
        mutation = createCrmEntryNode;
        break;
      default:
        mutation = null;
    }
    return mutation;
  };

  const automation = data?.crmAutomation;
  const nodeDialogMutationLoading = ccenLoading || ccanLoading || cccnLoading || ucanLoading;

  useEffect(() => {

    if (successDialogOpen === true) {
      const delay = setTimeout(() => {
        setSuccessDialogOpen(false);
      }, 1200);
      return () => clearTimeout(delay);
    }

    if (activationDialogOpen === true) {
      const delay = setTimeout(() => {
        setActivationDialogOpen(false);
      }, 1200);
      return () => clearTimeout(delay);
    }

    setWorkflowState(automation?.activated);

  }, [successDialogOpen, activationDialogOpen, automation?.activated]);

  return (
    <SandboxLayout layoutData={crmData} name="automation">
      <SandboxHeader
        customTitle={
          <WorkflowTitle
            loading={loading}
            error={error}
            allowEdit={true}
            preTitle={'Workflow: '}
            title={automation?.name}
            handleAction={handleEditTitle}
          />
        }
        description={<>In this section you can add new workflow by starting from adding an entry point. When done, press <strong>Activate Workflow</strong> and <strong>Watch the Simulation</strong>. <strong>Drag</strong> the image to navigate and use the <strong>mouse wheel</strong> to zoom.</>}
        extraStyles={{ marginBottom: 0 }}
        action={
          <Box className={classes.workflowActions}>
            <Button
              onClick={(e) => handleActivateWorkflow(workflowState)}
              className={classes.actionBtn}
              color="default"
              variant="contained"
              disabled={!data?.crmAutomation?.nodes.length || aaLoading || daLoading}
              style={workflowState ? { backgroundColor: theme.palette.grey[800] } : null}
            >{!workflowState ? 'Activate workflow' : 'Deactivate workflow'}
            </Button>
            <Button
              className={classes.actionBtn}
              color="default"
              variant="contained"
              component={RouterLink}
              to={`/crm/automation/${data?.crmAutomation?._id}/simulation`}
              disabled={!data?.crmAutomation?.activated}
            >Watch Simulation
            </Button>
          </Box>
        }
      />

      <Box textAlign="right" mr="-8px">
        <Button
          startIcon={<ArrowBackIcon />}
          component={RouterLink}
          to={'/crm/automation'}
          className={classes.goBackBtn}
        >Back to Automation</Button>
      </Box>

      <AutomationTree
        loading={loading}
        error={error}
        workflowState={workflowState}
        entryNodes={entryNodes}
        actionNodes={actionNodes}
        conditionNodes={conditionNodes}
        tree={automation}
        handleSelectEntry={handleSelectEntry}
        handleSelectStep={handleSelectNode}
        handleEditEntry={handleEditEntry}
        handleEditStep={handleEditStep}
        handleDeleteNode={handleDeleteNode}
      />

      <SandboxDialog
        id="successWorkflowDialog"
        successIcon={true}
        dialogClose={handleSuccessDialogClose}
        dialogOpen={successDialogOpen}
        dialogTitle={successMsg}
      />

      <SandboxDialog
        id="errorWorkflowDialog"
        errorIcon={true}
        dialogClose={handleErrorDialogClose}
        dialogOpen={errorDialogOpen}
        dialogTitle="Error"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">{errorMsg}</Typography>
          </Box>
        }
        dialogActions={
          <Button
            className={classes.dialogDefaultBtn}
            color="default"
            variant="outlined"
            size="large"
            onClick={handleErrorDialogClose}
          >Cancel
          </Button>
        }
      />

      <SandboxDialog
        id="deleteNodeDialog"
        warningIcon={true}
        dialogClose={handleDeleteNodeDialogClose}
        dialogOpen={deleteNodeDialogOpen}
        dialogOnExited={() => dcanReset()}
        dialogTitle="Delete Step / Entry point"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">Are you sure you want to delete this workflow entry point or step? You will lose every related steps children.</Typography>
          </Box>
        }
        dialogActions={
          <>
            <Button
              className={`${classes.dialogDefaultBtn} ${classes.dialogCancelBtn}`}
              color="default"
              variant="outlined"
              size="large"
              onClick={handleDeleteNodeDialogClose}
            >Cancel
            </Button>
            <Button
              className={classes.dialogDefaultBtn}
              color="default"
              variant="contained"
              size="large"
              onClick={deleteAutomationNode}
              disabled={dcanLoading || dcanData?.deleteCrmAutomationNode === true}
            >Delete item
            </Button>
          </>
        }
      />

      <SandboxDialog
        id="activationWorkflowDialog"
        icon={workflowState ? 'play_circle' : 'highlight_off'}
        iconStyles={workflowState ? {color: theme.palette.success.main} : {color: theme.palette.warning.main}}
        dialogClose={handleActivationDialogClose}
        dialogOpen={activationDialogOpen}
        dialogTitle={workflowState ? 'Workflow Activated!' : 'Workflow Deactivated!'}
        dialogMessage={
          <Box textAlign="center" component="p" m="12px 80px" lineHeight="1.6">
            {workflowState ?
              'Congratulation! Your workflow is active. Now you can watch the result of your automation on the watch simulation section.'
              :
              'You deactivated the workflow, now you are not able to watch the simulation.'
            }
          </Box>
        }
      />

      <AutomationTreeDialog
        dialogClose={handleSelectEntryDialogClose}
        dialogOpen={selectEntryDialogOpen}
        dialogTitle={'Select an entry point to your workflow'}
        dialogContent={
          <Box
            maxWidth="500px"
            margin="auto"
            pb="8px"
          >
            <ListNodeButtons
              action="add entry"
              dialogListName={entryNodes.name}
              dialogListNodes={entryNodes.nodes}
              onNodeButtonClick={handleAddEntry}
            />
          </Box>
        }
      />

      <AutomationTreeDialog
        dialogClose={handleSelectStepDialogClose}
        dialogOpen={selectStepDialogOpen}
        dialogTitle={'Select a step to your workflow'}
        dialogContent={
          <Box
            maxWidth="500px"
            margin="auto"
            pb="8px"
          >
            <ListNodeButtons
              action="add action"
              dialogListName={actionNodes.name}
              dialogListNodes={actionNodes.nodes}
              onNodeButtonClick={handleAddStep}
            />
            <ListNodeButtons
              action="add condition"
              dialogListName={conditionNodes.name}
              dialogListNodes={conditionNodes.nodes}
              onNodeButtonClick={handleAddStep}
            />
          </Box>
        }
      />

      <AutomationNodeDialog
        loadingLists={loadingLists}
        errorLists={errorLists}
        dataLists={dataLists}
        setErrorDialogOpen={setErrorDialogOpen}
        setErrorMsg={setErrorMsg}
        automationId={props.match.params.id}
        nodeId={currentNodeId}
        actionType={actionType}
        setActionType={setActionType}
        refetch={refetch}
        selectedId={selectedDialogId}
        mutationLoading={nodeDialogMutationLoading}
        mutation={getMutationByActionType(actionType)}
        dialogListNodes={allDialogNodes}
        dialogClose={handleAddNodeDialogClose}
        dialogOpen={addNodeDialogOpen}
        dialogBack={handleSelectNode}
      />

    </SandboxLayout>
  );
};

export default CrmAutomationWorkflow;
