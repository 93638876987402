import React from 'react';
import ImageModule from '../modules/ImageModule';
import VideoModule from '../modules/VideoModule';

const MediaType = props => {
  const {
    currentStep
  } = props

  const checkMediaType = (currentStep) => {
    if (currentStep.imageLink) {
      return <ImageModule
        imgSrc={currentStep.imageLink}
        imgAlt="Question img"
      />;
    } else if (currentStep.videoLink) {
      return <VideoModule currentStep={currentStep} />;
    }
  }

  return (
    <>
      {checkMediaType(currentStep)}
    </>
  );
}

export default MediaType;
