import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import { TableSortLabel } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';

const DataTableHeader = props => {
  const {
    columns,
    columnsToHide,
    order,
    orderBy,
    handleSort,
    numSelected,
    rowCount,
    onSelectAllClick,
    checkboxes
  } = props;

  return (
    <TableHead>
      <TableRow>
        {checkboxes ? (
          <TableCell style={{width: 60}} padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
        ) : null}
        {columns.map((columnName, index, array) => {
          if(columnsToHide.includes(columnName)) return null;
          return (
            //console.log(order),
            columnName === 'actions' ?
              <TableCell
                key={columnName}
                align="right"
              >
                {columnName}
              </TableCell>
              :
              <TableCell
                key={columnName}
                onClick={handleSort}
              >
                <TableSortLabel
                  //accessKey={columnName}
                  active={columnName===orderBy}
                  direction={columnName===orderBy ? order : 'asc'}>
                </TableSortLabel>
                {columnName}
              </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

DataTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  order: PropTypes.string,
  handleSort: PropTypes.func.isRequired
};


DataTableHeader.defaultProps = {
  columnsToHide: []
}

export default DataTableHeader;
