import React from 'react';
import { Form,  ErrorMessage } from 'formik';
import { movingTab, width100 } from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";

class ChangePasswordForm extends React.Component {
  requiredText = 'Field required';

  constructor(props) {
    super(props);

    if (props.update) {
      this.requiredText = '';
    }
    this.state = props;
  }

  render() {
    const { values } = this.props;
    return (
      <Form>
        <div className="card-header text-center">
          <h3 className="card-title">
            Change Password
          </h3>
        </div>
        <div className="wizard-navigation">
          <div className="moving-tab" style={movingTab}>
            Password
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="input-group form-control-lg">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">vpn_key</i>
                </span>
              </div>
              <div className="form-group bmd-form-group">
                <TextField
                  required
                  type="password"
                  label="Insert the new password"
                  name="password"
                  value={values.password}
                  onChange={this.state.handleChange}
                  margin="normal"
                  style={width100}
                />
                <ErrorMessage name="password" component="div" />
              </div>
              <div className="input-group form-control-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="material-icons">vpn_key</i>
                  </span>
                </div>
                <div className="form-group bmd-form-group">
                  <TextField
                    required
                    type="password"
                    label="Confirm new password"
                    value={values.password2}
                    onChange={this.state.handleChange}
                    name="password2"
                    margin="normal"
                    style={width100}
                  />
                  <ErrorMessage name="password2" component="div" />
                </div>
              </div>
              <div className="form-group bmd-form-group">
                <button type="submit" disabled={this.state.isSubmitting}
                  className="btn btn-next btn-fill btn-rose btn-wd" name="save" value="Save">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default ChangePasswordForm;
