import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Button, Popover } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  solutionBtn: {
    textTransform: 'none',
    lineHeight:1,
    textDecoration: 'underline',
    padding:0,
    '&:hover': {
      background:'none',
      textDecoration:'underline',
      color:theme.palette.text.secondary
    },
    '&:focus': {
      outline: 'none',
    },
  },
  tableCellSolution: {
    width: 175,
    [theme.breakpoints.down('sm')]: {
       width: 138,
    },
  },
  popover: {
    maxWidth:940,
    padding:theme.spacing(2)
  }
}));

const ResultStepsAnswers = ({ answers }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  function createData(field, answer, result, solution) {
    return { field, answer, result, solution };
  }

  const rows = answers.map((answer) => {
    return createData(answer.key, answer.value, answer.isCorrect, answer.correctAnswer)
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePopoverClick = (event, index) => {
    setPopoverId(index);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverId(null)
    setAnchorEl(null);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Fields</TableCell>
            <TableCell align="right">Answers</TableCell>
            <TableCell align="right">Results</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
            const solution = row.solution && (
              <>
                <Button
                  className={classes.solutionBtn}
                  aria-describedby={popoverId}
                  onClick={(e) => handlePopoverClick(e, index)}
                >Show answer</Button>
                <Popover
                  id={popoverId}
                  open={popoverId === index}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  classes={{
                    paper: classes.popover
                  }}
                >
                  <Typography component="div">{row.solution}</Typography>
                </Popover>
              </>
            );

            return (
              <TableRow key={row.field}>
                <TableCell component="th" scope="row">
                  {row.field}
                </TableCell>
                <TableCell align="right">{row.answer}</TableCell>
                <TableCell align="right">{row.result ? 'Correct' : 'Wrong'}</TableCell>
                <TableCell className={classes.tableCellSolution} align="right">{solution}</TableCell>
              </TableRow>
            )
          })}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        className={classes.tablePagination}
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        labelDisplayedRows={({ from, to, count }) => (`Showing ${from} to ${to} of ${count !== -1 ? count : null} Fields`)}
      />
    </TableContainer>
  );
};

export default ResultStepsAnswers;
