import React, { Component } from 'react';
import Layout from '../../common/Layout';
import PropTypes from 'prop-types';
import { Query } from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import plusButtonStyle from '../../settings/otherStyles';
import {client} from '../../settings/apollo';
import AdminSettingsNavigation from '../adminSettings/AdminSettingsNavigation';
import Select from 'react-select';
import { GET_USER_GROUPS } from "../userGroup/queries";
import './ChallengeListResults.css';
import { MY_DATA, GET_RESULTS, GET_CHALLENGE_DEFINITION_NAMES } from "./graphql/queries";
import moment from 'moment';
import { Redirect } from 'react-router';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import Swal from 'sweetalert2';
import { DELETE_CHALLENGE } from './graphql/mutations';
import { SecureQuery } from '../../settings/graphqlHelper';


const columns = ["name", "surname", "challengeTitle", "scores", "actions"];
const columnsToHide = ["userExpirationDate"];
class ChallengeListResults extends Component {
  static propTypes = {
    page: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      page: props.match.params.page ? parseInt(props.match.params.page) : 0,
      selectedIndexes: [],
      users:[],
      challenges: [],
      challengeList: [],
      userGroups: [],
      noChallenges: false,
    };
    this.handleSelectedIndexes = this.handleSelectedIndexes.bind(this);
    this.handleDeleteAction = this.handleDeleteAction.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._add = this._add.bind(this);
    this._first = this._first.bind(this);
    this._last = this._last.bind(this);
    this._first = this._first.bind(this);
    this._keyPress = this._keyPress.bind(this);
    this.today = moment();
    this.client = client;
    this.results = null;
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_DEFINITION_NAMES,
    }).then((res) => {
      this.setState({
        challenges: res.data.challengeDefinitionNames,
      });
    }).catch((error) => {
      console.log(error);
    });

    SecureQuery.query({
      query: GET_USER_GROUPS
    }).then((res) => {
      this.setState({
        userGroups: res.data.userGroups.records,
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  filteredChallenge = (coachChallenges) => {
    if(!this.state.challenges || this.state.challenges.length === 0 || coachChallenges.length === 0) return [];
    let challenges = this.state.challenges.map((item) => {
      if (coachChallenges.length > 0 && coachChallenges.includes(String(item.id))) return { value: item.id, label: item.title }
      if (coachChallenges.length === 0) return { value: item.id, label: item.title }
      return null
    })
    return challenges.filter((item) => item)
  }

  _previousPage(e) {
    e.preventDefault();

    if (this.state.page >1) {
      this.setState({ page: this.state.page - 1 });
      this.props.history.push('/admin-settings/results/page/'+(parseInt(this.state.page) - 1) )

    }
    else {
      this.setState({ page: 0});
      this.props.history.push('/admin-settings/results' )
    }
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
    this.props.history.push('/admin-settings/results/page/'+(parseInt(this.state.page) + 1) )

  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0} );
    this.props.history.push('/admin-settings/results')
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
    this.props.history.push('/admin-settings/results/page/'+(pages - 1))
  }

  _add(event) {
    this.props.history.push("/admin-settings/register");
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({
        name: e.target.value,
        page: 0
      });
    }
  }

  isSelected(user){
    return user._id.this.state.selectedIndexes
  }

  handleSelectedIndexes = (selectedIndexes, records) => {
   selectedIndexes.map((i) => console.log('selected record', records[i]))
   this.setState({selectedIndexes: selectedIndexes });
   this.setState({users: records});
  }

  _deleteRow = ({ id }) => {
    const { error } = this.client.mutate({
      mutation: DELETE_CHALLENGE,
      variables: { input: id.id },
    });
    if (error) 
      Swal.fire({
        type: "error",
        title: "Error while reset Challenge.",
        showConfirmButton: false,
        timer: 1500
      });
    else
      Swal.fire({
        type: "success",
        title: "Challenge Reset successfull.",
        showConfirmButton: false,
        timer: 1500
      })
  } 

  handleDeleteAction = (values) => {
    return true;
  }

  highlightRecord (record) {
    return (moment(record.userExpirationDate).isBefore(this.today))
  }


  render() {
    if(this.props.location.pathname.includes('page') &&
    !this.props.match.params.page)
    return (
      <Redirect
      to={{
        pathname: "../results",
      }}
    />
    )
    return (
      <Layout name="Results">
        <div className="result-list">
          <Query query={MY_DATA} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner styles={{ paddingTop: 30 }}/>;
              if (error) return `Error! ${error.message}`;
              const userData = data.me;
              const UserGroupList = []
              const coachGroups = []
              let coachChallenges = [];
              const userGroups = this.state.userGroups.map((item) => ({ value: item._id, label: item.name, groupChallenges: item.groupChallenges })) 
              const courseGroups = userData.courseGroups.map((item) => item)
              courseGroups.map((item) => {
                UserGroupList.push(item._id); 
                coachGroups.push({ value: item._id, label: item.name, groupChallenges: item.groupChallenges }); 
                coachChallenges = coachChallenges.concat(item.groupChallenges);
                return null;
              })
              coachChallenges = coachChallenges.filter((item,index)=> coachChallenges.indexOf(item) === index)
              let filteredChallenges = this.filteredChallenge(coachChallenges);
              if (filteredChallenges && filteredChallenges.length > 0) {
                // If there are challenges and a challenge isn't already selected, select the first one.
                if (!this.state.challenge) {
                  this.setState(prevState => {
                    if(prevState.challenge){
                      // If a challenge is already selected, do nothing.
                      return null;
                    }
                    // If no challenge is selected, select the first one.
                    return {
                      challenge: filteredChallenges[0].value,
                      page: 0,
                      challengeList: filteredChallenges,
                      noChallenges: false
                    };
                  });
                }
              } else {
                // If there are no challenges, update the state to reflect this
                this.setState(prevState => {
                  if(prevState.noChallenges){
                    // If there are already no challenges, do nothing
                    return null;
                  }
                  // If there are challenges, set noChallenges to true
                  return { noChallenges: true };
                });
              }              
              return (
                <div className="wizard-container"> 
                  {userData.userRole === "superuser" ?
                    <Fab onClick={this._add} color="primary" aria-label="Add" className={plusButtonStyle.fab}>
                      <AddIcon />
                    </Fab>

                    : null}
                  <AdminSettingsNavigation path={this.props.match.path} />

                  <div className="row justify-content-end result-list__controls">
                    {(<div className="col-xl-3 col-lg-6">
                          <Select
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            onChange={(values) => {
                              if (values != null) {
                                this.setState({ userGroup: values.value, page: 0, challengeList: this.filteredChallenge(values.groupChallenges) });
                              } else this.setState({ userGroup: null, page: 0, challengeList: this.filteredChallenge(coachChallenges) })
                            }}
                            options={userData.userRole === 'superuser' ? userGroups : coachGroups}
                            isClearable={true}
                            placeholder={"Select course groups"}
                          />
                      </div>
                    )} 
                    
                    {this.state.noChallenges ? `No challenges available` :
                      (<div className="col-xl-3 col-lg-6">
                          <Select
                            styles={{
                              menu: provided => ({ ...provided, zIndex: 9999 })
                            }}
                            defaultValue={this.state.challengeList[0]}
                            onChange={(values) => values != null ? this.setState({ challenge: values.value, page: 0 }) : this.setState({ challenge: null, page: 0 })}
                            options={this.state.challengeList}
                            placeholder={"Select challenge"}
                          />
                        </div>
                      )}               
                  </div>


                  <Query
                    query={GET_RESULTS}
                    fetchPolicy="network-only"
                    variables={{
                      page: this.state.page,
                      courseGroups: UserGroupList,
                      userGroup: this.state.userGroup,
                      challenge: this.state.challenge
                      }}
                  >
                    {({ loading, error, data, previousData }) => {
                      if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 15, marginBottom: 30 }} />;
                      if (error) return `Error! ${error.message}`;
                      if (userData.userRole !== "superuser" && userData.userRole !== "coach") {
                        return <b>This feature is available only to Administrators and Coaches</b>;
                      }

                      if (!data || !data.challengeListResults || !data.challengeListResults.pages) {
                        return <b>No challenges for Challenge Results</b>;
                      }

                      this.results = data.challengeListResults
                      const editUrl = {
                        url: '/challenge/end/',
                        editUrlKey: 'editUrlParameter',
                        editUrlParameter: 0
                      }
                      return (
                        <DataTable
                          header='Challenge Results'
                          editUrl={editUrl}
                          pages={data.challengeListResults.pages}
                          columns={columns}
                          columnsToHide={columnsToHide}
                          records={this.results}
                          deleteRow={this._deleteRow}
                          keyColumn='_id'
                          page={this.state.page}
                          first={this._first}
                          last={this._last.bind(this, data.challengeListResults.pages)}
                          next={this._nextPage}
                          previous={this._previousPage}
                          checkboxes={true}
                          selectedIndexes={this.state.selectedIndexes}
                          onSelectedIndex={this.handleSelectedIndexes}
                          showDeleteIcon={this.handleDeleteAction}
                          customDeleteIcon={'rotate_left'}
                          highlightRecord={this.highlightRecord}
                        />
                      );
                    }}
                  </Query>
                </div>
              )
            }}
          </Query>
        </div>
      </Layout>
    );
  }
}

export default ChallengeListResults;