import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "@apollo/client/react/components";
import DataTable from "../../../common/components/data-table/DataTable";
import LoadingAnimation from "../../../common/components/base/LoadingAnimation";
import ChallengePanel from "../ChallengePanel";
import { GET_CHALLENGE } from "./queries";
import TimeFrameSimulation from "./../TimeFrameSimulation";

class ThisStepQuery extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { tableHeader, tableColumns, challengeType, editUrl, handleEditClick, handleTimeframeChange, CustomComponent, customComponentProps, timeframe, initialTimeframeValue } = this.props;
    return (
      <Query
        query={GET_CHALLENGE}
        fetchPolicy="network-only"
        variables={{
          id: challengeType
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingAnimation />;
          if (error) return `Error! ${error.message}`;

          let challenge = JSON.parse(data.challenge);
          let records = [];
          let currentStep = this.props.currentStep - 1;
          let StepCountDownTime = challenge.steps[currentStep].countdownTime;
          if (challenge.records) {
            records = challenge.records
          }
          return (
            <div>
              {(() => {
                if (CustomComponent)
                  return <CustomComponent {...customComponentProps}/>;
                else
                  return <DataTable
                    header={tableHeader}
                    editUrl={editUrl || ""}
                    columns={tableColumns}
                    records={{records: records}}
                    keyColumn="_id"
                    handleEditClick={handleEditClick}
                         />;
              })()}
              {
                timeframe && <div className="col-sm-2 pt-2">
                  <h5>Selected timeframe</h5>
                  <TimeFrameSimulation values = {[{value:'72', label: '72'}, {value: '168', label: '168'}]} placeholder = {initialTimeframeValue} onChange={handleTimeframeChange}></TimeFrameSimulation>
                 </div>
              }
              <ChallengePanel
                time={StepCountDownTime}
                description={challenge.challengeDescription}
                coach={challenge.coach}
                challengeTitle={challenge.challengeTitle}
                challengeIsValidation={challenge.isValidation}
                challengeDifficulty={challenge.challengeDifficulty}
                challengeAverageTime={challenge.averageTime}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

ThisStepQuery.propTypes = {
  tableHeader: PropTypes.string.isRequired,
  tableColumns: PropTypes.array.isRequired,
  editUrl: PropTypes.string,
  currentStep: PropTypes.string
};

ThisStepQuery.defaultTypes = {
  tableHeader: 'Table',
  tableColumns: [],
  editUrl: ''
};

export default ThisStepQuery;
