import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Divider, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign:'center'
  },
  containerXl: {
    maxWidth:700
  },
  successIcon: {
    color: theme.palette.success.main
  },
  title: {
    fontSize: theme.typography.pxToRem(48),
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom:25
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(36),
    fontWeight: theme.typography.fontWeightRegular,
  },
  description: {
    marginBottom:25
  },
  divider: {
    marginTop:35,
    marginBottom:35,
  },
  thkBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const Message = ({ icon, title, subTitle, description, btnLink, btnText }) => {
  const classes = useStyles();

  return(
    <Container
      maxWidth="xl"
      className={classes.root}
      classes={{ maxWidthXl: classes.containerXl }}>
      { icon }
      <Typography className={classes.title} variant="h1" component="h1" color="textPrimary">
        { title }
      </Typography>
      <Typography className={classes.subTitle} variant="h2" component="h2" color="textSecondary">
        { subTitle }
      </Typography>
      <Divider className={classes.divider}/>
      <Typography className={classes.description} variant="body1" component="div" color="textSecondary">
        { description }
      </Typography>
      { btnLink ?
        <Button
          component={RouterLink}
          to={btnLink}
          variant="contained"
          size="large"
          color="default"
          className={classes.thkBtn}
        >{btnText}
        </Button>
      : null }
    </Container>
  );
}

export default Message;
