import React from 'react';

import { placementColumns } from "../../../../constants/columns";
import FirstStepQuery from "../FirstStepQuery";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";

const Challenge02Step1 = ({ _add, type }) => {
  return (
    <TableLayout name={"Placements"} _add={_add}>
      <FirstStepQuery
        tableHeader={"Placements"}
        tableColumns={placementColumns}
        challengeType={type}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "new-placement"
};

export default withChallengeInstance(Challenge02Step1, action);
