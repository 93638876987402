import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, Select, MenuItem, InputLabel, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles(theme => ({
  selectControl: {
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.common.gamsDarkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsDarkBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    '&:focus':{
      backgroundColor:'white'
    }
  },
}));

const SelectFilter = ({ onSelectChange, count, selectList, selectLabel, onSetPage, selectValue }) => {
  const classes = useStyles();

  const handleSelectChange = (event) => {
    onSetPage(0);
    onSelectChange(event);
  };

  return (
    <Box display="flex">
      <FormControl
        variant="outlined"
        size="small"
        className={classes.selectControl}>
        <InputLabel id="select-list-label">{selectLabel}</InputLabel>
        <Select
          labelId="select-list-label"
          id="select-list"
          value={selectValue}
          onChange={handleSelectChange}
          label={selectLabel}
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null
          }}
          classes={{
            root: classes.selectRoot,
          }}
        >
          {selectList.map(menuItem => {
            return (
              <MenuItem
                key={menuItem._id}
                value={menuItem._id}
              >{menuItem.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" pl="8px">
        <PersonIcon />
        <Typography variant="body1" color="textSecondary" component="div">{count}</Typography>
      </Box>
    </Box>
  );
}

export default SelectFilter;
