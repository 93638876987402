import React from "react";
import { campaignsColumns } from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignUpdate from './ReportCampaignForm';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep10 = ({ _add, type }) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        currentStep = {10}
        CustomComponent={ReportCampaignUpdate}
        customComponentProps={{
          handleNewLineItem: _add,
          editRow: () => { },
          currentTab: 1,
          items: [
            {
              name: "skinius_lineitem_thissummer_banner_300x250",
              lineitemID: "LI10002",
              lineitemType: "standard campaign",
              scheduleDuration: "168",
              lineitemStatus: "active",
              budgetPlanned: "1500",
              impressionsPlanned: "3000000",
              CPM: "0,50",
              impressionsDelivered: "1290000",
              budgetDelivered: "",
              clicks: "103200",
              CTR: "",
              conversions: "51600",
              conversionRate: "",
              placementID: "PL109",
              deliveryType: "ASAP",
              frequency: "3-lifetime",
              recency: "24-hours",
              adName: "skinius_thissummer_banner_300x250",
              adSize: "300x250",
              creativeID: "CR004",
              status: "Active"
            },
            {
              name: "skinius_lineitem_thissummer_banner02_300x250",
              lineitemID: "LI10003",
              lineitemType: "standard-campaign",
              scheduleDuration: "168",
              lineitemStatus: "paused",
              budgetPlanned: "1320",
              impressionsPlanned: "6000000",
              CPM: "0,22",
              impressionsDelivered: "710000",
              budgetDelivered: "",
              clicks: "4302",
              CTR: "",
              conversions: "129",
              conversionRate: "",
              placementID: "PL103",
              deliveryType: "ASAP",
              frequency: "1-lifetime",
              recency: "72-hours",
              adName: "skinius_thissummer_banner02_300x250",
              adSize: "300x250",
              creativeID: "CR004",
              status: "Paused"
            },
            {
              "name": "notblank",
              "lineitemType": "standard banner",
              "budgetPlanned": "2450",
              "impressionsPlanned": "1900000",
              "CPM": "0,50",
              "placementID": "notblank",
              "deliveryType": "ASAP",
              "frequency": "7 lifetime",
              "recency": "24",
              "adName": "notblank",
              "adSize": "300x250",
              "creativeID": "CR004",
              "adStatus": "active"
            }
          ]
        }}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "new-lineitem"
};

export default withChallengeInstance(ReportAdServerStep10, action);
