import React from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';


class TimeFrameSimulation extends React.Component {
    state = {
      selectedOption: this.props.selectedValue,
      options: this.props.values,
      placeholder: this.props.placeholder,
    }
    handleChange = (selectedOption) => {
      this.setState({ selectedOption });
      this.props.onChange(selectedOption)
      Swal.fire({
        className: "grey-bg",
        type: 'success',
        title: 'TIME MACHINE',
        text: 'You are now redirected to the next 7 days',
      })
    }
    render() {
      const { selectedOption } = this.state;
  
      return (
        <div>
            <Select
            value={selectedOption}
            onChange={this.handleChange}
            options={this.state.options}
            placeholder = {this.state.placeholder}
            />
        </div>
      );
    }
  }
  
  export default TimeFrameSimulation;