export const automationTableColumns = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'name',
    label: 'Name',
    align: 'right',
    style: { minWidth: 170 }
  },
  {
    id: 'creationDate',
    label: 'Creation Date',
    align: 'right',
    style: { minWidth: 170 }
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center',
    style: { minWidth: 100 }
  },
];

export const entryNodes = {
  name: null,
  nodes: [
    {
      id: 1,
      title: "Contact attributes condition",
      description: "Start this workflow when a contact's attributes match a condition.",
      icon: "account_box",
      type: "entry",
      formLabel: null,
      formType: "select",
      formFields: {
        "filterFields": [
          "age",
          "country",
          "action"
        ],
        "filterValues": [
          [
            ""
          ],
          [
            "Italy",
            "England",
            "Netherlands",
            "Spain",
            "Germany",
            "France"
          ],
          [
            "Page View",
            "Search",
            "Add to Cart",
            "Conversion"
          ]
        ],
        "filterConditions": [
          [
            "is",
            "is not",
            "is less than",
            "is more than"
          ],
          [
            "is",
            "is not"
          ],
          [
            "is",
            "is not"
          ]
        ],
        "filterValuesInputType": [
          "text",
          "multipleSelect",
          "multipleSelect"
        ]
      },
      items: null
    },
    {
      id: 2,
      title: "A Contact Submits a Form",
      description: "Start this workflow when a contact submits a form.",
      icon: "mail",
      type: "entry",
      formLabel: null,
      formType: "select",
      formFields: {
        selectFields: ['Newsletter form', 'Holiday', 'Promo form', 'Discount form']
      },
      items: null
    },
  ]
};

export const actionNodes = {
  name: "Actions",
  nodes: [
    {
      id: 3,
      title: "Send an Email",
      description: "Select an email template.",
      icon: "mail",
      type: "action",
      formLabel: null,
      formType: "select",
      formFields: {
        selectFields: ["Email", "Holiday Promo Mail", "Discount Mail"]
      },
      items: null
    },
    {
      id: 4,
      title: "Add the contact into a list",
      description: "The contact will be added into the selected list.",
      icon: "group_add",
      type: "action",
      formLabel: null,
      formType: "select",
      formFields: {
        selectFields: [""]
      },
      items: null
    },
     {
      id: 5,
      title: "Remove the contact from a list",
      description: "The contact will be removed from the selected list.",
      icon: "person_off",
      type: "action",
      formLabel: null,
      formType: "select",
      formFields: {
        selectFields: [""]
      },
      items: null
    },
  ]
};

export const conditionNodes = {
  name: "Conditions",
  nodes: [
    {
      id: 6,
      title: "If/Else Condition",
      description: null,
      icon: "sync_alt",
      type: "condition",
      formLabel: null,
      formType: null,
      formFields: null,
      items: [
        {
          id: 7,
          title: "Contact attributes condition",
          description: "The flow will be split into two branches (Yes / No) depending on the condition.",
          formLabel: "Start this workflow when a contact's attributes match a condition:",
          formType: "conditional-select",
          icon: "account_box",
          type: "condition",
          formFields: {
            "filterFields": [
              "age",
              "country",
              "action"
            ],
            "filterValues": [
              [
                ""
              ],
              [
                "Italy",
                "England",
                "Netherlands",
                "Spain",
                "Germany",
                "France"
              ],
              [
                "Page View",
                "Search",
                "Add to Cart",
                "Conversion"
              ]
            ],
            "filterConditions": [
              [
                "is",
                "is not",
                "is less than",
                "is more than"
              ],
              [
                "is",
                "is not"
              ],
              [
                "is",
                "is not"
              ]
            ],
            "filterValuesInputType": [
              "text",
              "multipleSelect",
              "multipleSelect"
            ]
          }
        },
        {
          id: 8,
          title: "A contact is in a list",
          description: "The flow will be split into two branches (Yes / No) depending on the condition.",
          formLabel: "The contact is in a list:",
          formType: "select",
          icon: "recent_actors",
          type: "condition",
          formFields: {
            selectFields: ['']
          },
        },
        {
          id: 9,
          title: "A Contact Submits a Form",
          description: "The flow will be split into two branches (Yes / No) depending on the condition.",
          formLabel: "Start this workflow when a contact submits a form:",
          formType: "select",
          icon: "contact_mail",
          type: "condition",
          formFields: {
            selectFields: ['Newsletter form', 'Holiday', 'Promo form', 'Discount form']
          },
        },
        {
          id: 10,
          title: "User Actions",
          description: "The flow will be split into two branches (Yes / No) depending on the condition.",
          formLabel: "The contact completed this action:",
          formType: "select",
          icon: "person_outline",
          type: "condition",
          formFields: {
            selectFields: ['Page view', 'Search', 'Add to cart', 'Conversion']
          },
        },
      ]
    }
  ]
};

export const timeFrameList = [
  {
    id: 0,
    name: 'Day 0',
  },
  {
    id: 1,
    name: 'Day 1',
  },
  {
    id: 3,
    name: 'Day 3',
  },
  {
    id: 7,
    name: 'Day 7',
  },
  {
    id: 30,
    name: 'Day 30',
  },
];
