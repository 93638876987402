import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  formField: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  selectControl: {
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.common.gamsDarkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsDarkBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
    '&:focus':{
      backgroundColor:'white'
    }
  },
}));

const SandboxFormField = ({ extraStyle, fieldType, fieldName, fieldLabel, fieldValue, fieldHandleChange, fieldError, fieldHelperText, fieldGrid, fieldSelectList }) => {
  const classes = useStyles();

  const checkFieldType = (param) => {
    switch(param) {
      case 'text':
        return (
          <Grid item xs={12} sm={fieldGrid} style={extraStyle}>
            <TextField
              className={classes.formField}
              fullWidth
              id={fieldName}
              name={fieldName}
              autoComplete={fieldName}
              label={fieldLabel}
              variant="outlined"
              value={fieldValue}
              onChange={fieldHandleChange}
              error={fieldError}
              helperText={fieldHelperText}
              inputProps={{className: classes.input}}
              InputLabelProps={{className:classes.label}}
            />
          </Grid>
        );
      case 'select':
        return (
          <Grid item xs={12} style={extraStyle}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.selectControl}
              error={fieldError}
            >
              <InputLabel
                htmlFor={fieldName}
                error={fieldError}
                className={classes.label}
              >{fieldLabel}</InputLabel>
              <Select
                labelId={fieldName}
                id={fieldName}
                name={fieldName}
                value={fieldValue}
                onChange={fieldHandleChange}
                label={fieldLabel}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  },
                  getContentAnchorEl: null
                }}
                classes={{
                  root: classes.selectRoot,
                }}
              >
                {fieldSelectList.map(selectItem => {
                  return (
                    <MenuItem
                      key={selectItem.title}
                      value={selectItem.value}
                    >{selectItem.title}
                    </MenuItem>
                  )
                })}
              </Select>
              <FormHelperText
                id={fieldName}
                error={fieldError}
              >{fieldHelperText}
              </FormHelperText>
            </FormControl>
          </Grid>
        );
      case 'conditions':
        return (
          <Grid item xs={12} sm={fieldGrid} style={extraStyle}>
            conditions field
          </Grid>
        );
      default:
        return;
    }
  }

  return checkFieldType(fieldType);
}

export default SandboxFormField;
