import React from "react";
import { Formik } from "formik";
import gql from "graphql-tag";
import { Mutation, Query } from "@apollo/client/react/components";
import CreativeForm from "./CreativeForm";
import { withRouter } from "react-router-dom";
import validateAdvertiser from "../Validations";
import { creativeImages, creativeSizes } from "../../constants/constants";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const GET_CREATIVE = gql`
  query creative($id: String!) {
    creative(id: $id) {
      _id
      name
      size
      type
      fileName
      url
    }
  }
`;

const UPDATE_CREATIVE = gql`
  mutation updateCreative($input: CreativeUpdateInput) {
    updateCreative(input: $input)
  }
`;

class CreativeUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, logo: true };
  }

  render() {
    return (
      <SandboxLayout name="Creative" layoutData={campaignAdsManagerData}>
        <div class="wizard-container">
          <div class="col-md-8 col-12 mr-auto ml-auto">
            <div
              class="card card-wizard active"
              data-color="rose"
              id="wizardProfile"
            >
              <Query query={GET_CREATIVE} variables={{ id: this.state.id }}>
                {({ loading, error, data }) => {
                  if (loading) return <LoadingSpinner styles={{ paddingTop: 60 }} />;
                  if (error) return <p>Error :(</p>;

                  return (
                    <Mutation mutation={UPDATE_CREATIVE}>
                      {updateAdvertiser => (
                        <Formik
                          initialValues={{ ...data.creative , type: data.creative.type, size: creativeImages[data.creative.type], fileName: creativeImages[data.creative.type]}}
                          validate={validateAdvertiser}
                          onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            updateAdvertiser({
                              variables: {
                                input: {
                                  _id: data.creative._id,
                                  username: "",
                                  name: values.name,
                                  size: values.size,
                                  type: values.type
                                }
                              }
                            }).then(() =>
                              this.props.history.push("/creatives")
                            );
                          }}
                        >
                          {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit }) => (
                            <CreativeForm
                              logo={true}
                              update="True"
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              isSubmitting={isSubmitting}
                              values={values}
                              creativeImages={creativeImages}
                              creativeSizes={creativeSizes}
                              handleSubmit={handleSubmit}
                            />
                          )}
                        </Formik>
                      )}
                    </Mutation>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(CreativeUpdate);
