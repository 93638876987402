import React, { useState } from 'react';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Formik } from 'formik';
import Layout from '../../common/Layout';
import { Link } from 'react-router-dom';
import ItemsGrid from "../../common/components/widgets/ItemsGrid";
import { GET_USER_GROUPS } from "./../userGroup/queries";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import CSVService from '../CSVservice'
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import { Box } from '@material-ui/core';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { useMutation, useQuery } from '@apollo/client';
import UploadPhotoDialog from './UploadPhotoDialog';
import { env } from '../../env';


const columns = ["challengeType", "challengeTitle", "scores", "action"]
const columnsName = ["challenge Nr", "title", "scores", "action"]

const GET_CHALLENGE_DEFINITIONS = gql`
query challengeDefinitions{
  challengeDefinitions {
    id
    challengeName
    challengeTitle
    challengeDifficulty
    totalObtainableScore
  }
  }
`;

const MY_DATA = gql`
query me {
  me {
        userRole
        courseGroups{
            _id
            name
            groupChallenges
          }
 }
}
`;


const DELETE_CHALLENGES = gql`
  mutation deleteChallenges($input: ChallengeInput) {
    deleteChallenges(input: $input)
  }
`;

const GET_USER_CHALLENGES = gql`
 query userChallenges( $page: Int, $name: String, $username: String) {
    userChallenges(page: $page, name: $name, username: $username) {
      records {
        _id
        challengeType
        challengeTitle
        challengeLearningTopic
        obtainableScores
        scores
        stepTime
        time
        username
        stepMetaSkill
        stepHardSkill
      }
      pages
      totalCount
    }
  }
`;
const GET_USER_DATA = gql`
 query user ($id: String) {
  user(id: $id) {
        phone
        birthDate
        userRole
        country
        city
        name
        surname
        photo
        sigla
        email
        description
        password
        courseGroups{
            _id
            name
            groupChallenges
          }
        privacyPolicy
        profileScorecard
        profileScoreCardSerialNumber
        publishAchievement
        expirationDate
        linkedin
        verifiedMail
 }
}
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UserUpdateInput){
    updateUser(input: $input)
  }
`;
const DELETE_USER = gql`
  mutation deleteUser($id: String){
    deleteUser(id: $id)
  }
`;

const UPLOAD_PHOTO = gql`
    mutation($id: String, $file: Upload!) {
        uploadPhotoById(id: $id, file: $file)
    }
`;

const REMOVE_PHOTO = gql`
    mutation($id: String) {
        removePhotoById(id: $id)
    }
`;

const VERIFY_MAIL_BY_ID = gql`
    mutation($id: String!) {
        verifyMailById(id: $id)
    }
`;


const UserProfile =  (props) => {

    const id = props.match.params.id;
    const [userChallengesList, setUserChallengesList] = useState([]);
    const { loading, error, data } = useQuery(MY_DATA);
    const userDataQuery = useQuery(GET_USER_DATA,{variables: {id}});
    const userGroupsQuery= useQuery(GET_USER_GROUPS);
    const challengeDefinitionsQuery = useQuery (GET_CHALLENGE_DEFINITIONS);
    const userChallengesQuery = useQuery(GET_USER_CHALLENGES, {variables: {
        username: id
    }});
    const [uploadDialog, setUploadDialog] = useState(false);
    const [deleteUserMutation] = useMutation(DELETE_USER, {
        variables: {
                id
            },
        onError: (error) => {Swal.fire(error.toString(), '', 'error')},
        onCompleted: () =>
        {   Swal.fire('User deleted!', '', 'success');
            props.history.push("/admin-settings/users")
        }
        },
    );
    const [updateUserMutation] = useMutation(UPDATE_USER);

    const [deleteChallengesMutation] = useMutation(DELETE_CHALLENGES,
        {
        refetchQueries: [{query: GET_USER_CHALLENGES,
            variables: {
                username: id
            }}],
        onCompleted: () =>
        {   setUserChallengesList([]);
            Swal.fire('Challenge has been reset!', '', 'success');  }
    });
    const backToUsers = () => {
        props.history.push("/admin-settings/users")
    }
    const deleteChallenges = (values) =>  {
        if (window.confirm('Are you sure you wish to reset this challenge?')) {
            deleteChallengesMutation({variables: {
                input: {
                    username: values.username,
                    challengeType: values.challengeType
                }
            },

        })
        }
    }

    const [fileUpdateById] = useMutation(UPLOAD_PHOTO, {
        variables:{ id },
        refetchQueries: [{ query: GET_USER_DATA,
            variables: { id }
        }],

        onError: (error) => {Swal.fire(error.toString(), '', 'error')},
        onCompleted: () =>
        {   
            Swal.fire({
                type: 'success',
                title: 'Saved',
                showConfirmButton: false,
                timer: 1500
            })

        }
    });

    const [removePhotoById] = useMutation(REMOVE_PHOTO, {
        variables:{ id },
        refetchQueries: [{ query: GET_USER_DATA,
            variables: { id }
        }],
        onError: (error) => {Swal.fire(error.toString(), '', 'error')},
        onCompleted: () =>
        {   
            Swal.fire({
                type: 'success',
                title: 'Photo Removed',
                showConfirmButton: false,
                timer: 1500
              })
        }
    });

    const [verifyMailById] = useMutation(VERIFY_MAIL_BY_ID);

    const getTotalScore = (challengesList) => {
        return _.sum(challengesList.map((item) => _.sum(item.scores.map((item) => parseInt(item)))));
    }
    const getTotalObtainableScore = (challengesList) => {
        return _.sum(challengesList.map((item) => _.sum(item.obtainableScores.map((item) => parseInt(item)))));
    }

    const getUserChallengeData = (challengesList,userData, userCourseGroups)=>{
        const userChallengeData= [];
        const totalUserScore = getTotalScore(challengesList);
        const totalObtainableScore= getTotalObtainableScore(challengesList)
        for(var i=0; i< challengesList.length; i++){
            const item =challengesList[i];
            console.log('file: UserProfile.jsx:245 ~ getUserChallengeData ~ item:', item);
            for(var k=0; k< item.scores.length; k++){
                const userChallengeDataEntry = {}
                userChallengeDataEntry.Name= userData.name;
                userChallengeDataEntry.SchoolGroups= userCourseGroups.map((item) => item.label);
                userChallengeDataEntry.Email= userData.email;
                userChallengeDataEntry.PrivacyPolicy= userData.privacyPolicy;
                userChallengeDataEntry.ProfileScorecard= userData.profileScorecard;
                userChallengeDataEntry.LearningTopic = item.challengeLearningTopic
                userChallengeDataEntry.PublishAchievement= userData.publishAchievement;
                userChallengeDataEntry.startDate = new Date(item.time).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  });                  
                userChallengeDataEntry.TotalGainedScore= totalUserScore;
                userChallengeDataEntry.TotaleObtainableScore= i===0 && k===0 ? totalObtainableScore : "";
                userChallengeDataEntry.ChallengeNumber= item.challengeType;
                userChallengeDataEntry.ChallengeScore= k===0 ? _.sum(item.scores.map(function (item) {
                    return parseInt(item, 10);
                })) : "";
                userChallengeDataEntry.ChallengeObtainableScore= k===0 ? _.sum(item.obtainableScores.map(function (item) {
                    return parseInt(item, 10);
                })) : "";
                userChallengeDataEntry.StepNumber= k+1;
                userChallengeDataEntry.StepScoreGained = item.scores[k]
                userChallengeDataEntry.StepObtainableScore = item.obtainableScores[k]
                userChallengeDataEntry.StepTime= k>0? moment.utc(moment(item.stepTime[k]).diff(moment(item.stepTime[k - 1]))).format("mm:ss") : moment.utc(moment(item.stepTime[k]).diff(moment(item.time))).format("mm:ss");
                userChallengeDataEntry.stepMetaSkill= item.stepMetaSkill[k];
                userChallengeDataEntry.stepHardSkill= item.stepHardSkill[k];
                userChallengeDataEntry.ChallengeTime= k===0 ? moment.utc(moment(item.stepTime[item.stepTime.length - 1]).diff(moment(item.time))).format("mm:ss") : "";
                userChallengeData.push(userChallengeDataEntry);
            }
        }
        return userChallengeData;
    }

    if (loading || challengeDefinitionsQuery.loading || userDataQuery.loading
        || userGroupsQuery.loading || userChallengesQuery.loading)
        return <LoadingSpinner styles={{ paddingTop: 30 }} />

    if (error) return `Error! ${error.message}`;
    if (userDataQuery.error) return `Error! ${userDataQuery.error.message}`;
    if (userGroupsQuery.error) return `Error! ${userGroupsQuery.error.message}`;
    if (challengeDefinitionsQuery.error) return `Error! ${challengeDefinitionsQuery.error.message}`;

    const handleRemovePhoto = async (id) => {
        try{
            await removePhotoById({
                variables:{ id } 
            });
        }
        catch(error)
        {
          console.log(error)
        }
    }

    const handleVerifyEmail = async (id) => {
        try{
            await verifyMailById({ 
                variables:{ id: id }, 
                refetchQueries: [{ query: GET_USER_DATA,
                variables: { id }
                }],
            })
            .then(() => Swal.fire({
                type: 'success',
                title: 'Email Verified',
                showConfirmButton: false,
                timer: 1500
            }),
            (error) => Swal.fire(error.toString(), '', 'error')
        )
        }
        catch(error)
        {
          console.log(error)
        }
    }

    const handleUpload = async (file, id) =>  {
        if(file.size > 2097152){
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: "Max Image Size: 2 MB ",
              showConfirmButton: false,
              timer: 2500
            })
          setUploadDialog(false)
          return
        }
        try{
            await fileUpdateById({
              variables:{ id, file },
            });
        }
        catch(error)
        {
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: error,
                showConfirmButton: false,
                timer: 1500
              })
        }
        setUploadDialog(false)
    }

    const challengeDefinitions = challengeDefinitionsQuery.data.challengeDefinitions;
    const myData = data.me;
    const userData = userDataQuery.data.user;
    const isDisabled = myData.userRole !== 'superuser';
    const separateData = userData.courseGroups.map((item) => item)
    const userCourseGroups = []
    separateData.map((item) => userCourseGroups.push({ value: item._id, label: item.name }))
    let userChallengeData = []
    const challengesList = [];
    const groupsChallenges = userData.courseGroups.map(
        item => item.groupChallenges
    );
    groupsChallenges.map(item =>
        item.forEach(item => {
            challengesList.push(item);
        })
    );
    const userChallenges = [];
    const UniqueChallengeList = [
        ...new Set(challengesList.sort((a, b) => a - b))
    ];
    UniqueChallengeList.map(item =>
        userChallenges.push(
            challengeDefinitions.find(i => i.id === parseInt(item))
        )
    );
    const totalScore = ((userChallenges.map((item) => item.totalObtainableScore)).reduce((a, b) => a + b, 0))


    const challenges = userChallengesQuery.data.userChallenges.records

    const userChallengesListTemp = _.uniqBy(challenges, 'challengeType')
    if(userChallengesListTemp.length!==userChallengesList.length){
        setUserChallengesList(userChallengesListTemp);
    }

    userChallengeData=getUserChallengeData(userChallengesList, userData, userCourseGroups);
    const totalUserScore = getTotalScore(userChallengesList)

    return (
            <Layout name="User Profile">
                                                <Formik
                                                    initialValues={{
                                                        _id: id,
                                                        name: userData.name,
                                                        surname: userData.surname,
                                                        sigla: userData.sigla,
                                                        email: userData.email,
                                                        userRole: userData.userRole,
                                                        birthDate: userData.birthDate,
                                                        expirationDate: userData.expirationDate,
                                                        country: userData.country,
                                                        phone: userData.phone,
                                                        city: userData.city,
                                                        description: userData.description,
                                                        courseGroups: userData.courseGroups.map((item) => item._id),
                                                        linkedin: userData.linkedin
                                                    }}
                                                    onSubmit={(values, actions) => {
                                                        setTimeout(() => {
                                                            actions.setSubmitting(false);
                                                            updateUserMutation({
                                                                variables: {
                                                                    input: values
                                                                }
                                                            }).then(() =>
                                                                Swal.fire({
                                                                    type: 'success',
                                                                    title: 'Saved',
                                                                    showConfirmButton: false,
                                                                    timer: 1500
                                                                  }),
                                                                  (error) => {
                                                                    Swal.fire({
                                                                    type: 'error',
                                                                    title: 'Error saving user',
                                                                    showConfirmButton: false,
                                                                    timer: 1500
                                                                  })
                                                                  console.log(error);
                                                                  }
                                                            )

                                                        }, 1000);
                                                    }}
                                                >
                                                    {propsFormik => (

                                                        <form onSubmit={propsFormik.handleSubmit}>
                                                            <div className="row">
                                                                <div className="col-md-4 d-flex align-items-stretch flex-wrap">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Profile picture</h5>
                                                                        </div>
                                                                        <div className="card-body" style={{ flex: '0 1 auto' }}>
                                                                        <img src={userData.photo ? userData.photo : env.PUBLIC_URL + '/assets/img/default-avatar.png'}
                                  alt="Profile gams" className="rounded-circle img-fluid float-left w-50" />

                                                                            <h5>
                                                                                {/* jpg, png - max dimensions 150x150px, max weight 3 MB */}
                                                                            </h5>

                                                                            <Button className="btn btn-outline-primary" value="upload" onClick={() => setUploadDialog(true)}>Upload your photo</Button>
                                                                            <Button
                                                                            className="btn btn-outline-primary"
                                                                            value="upload"
                                                                            onClick={() => {
                                                                                    Swal.fire({
                                                                                            title: 'Are you sure you want to remove your photo?',
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: `Yes`,
                                                                                            }).then((result) => {
                                                                                            if (result.value) {
                                                                                            handleRemovePhoto(id);
                                                                                            }
                                                                                            })}
                                                                                }>Remove photo
                                                                            </Button>

                                                                            <UploadPhotoDialog
                                                                            title="Upload your photo"
                                                                            handleUndo={()=>setUploadDialog(false)}
                                                                            handleClose={()=>setUploadDialog(false)}
                                                                            idUser={id}
                                                                            handleUpload={handleUpload}
                                                                            open={uploadDialog}
                                                                            saveName="Save"
                                                                            button={true}
                                                                            > Select your photo. The optimal size is 360x360px
                                                                            </UploadPhotoDialog>

                                                                        </div>
                                                                        <div style={{ marginTop:12 }}>
                                                                          <div className="card-header">
                                                                            <h5 className="card-title">User Profile Scorecard</h5>
                                                                          </div>
                                                                          <div className="card-body" style={{ paddingTop:5 }}>
                                                                            <Button
                                                                              variant="outlined"
                                                                              size="medium"
                                                                              style={{ color: '#616161' }}
                                                                              href={'/psc/'+userData.profileScoreCardSerialNumber}
                                                                              rel="noopener noreferrer"
                                                                              target="_blank">
                                                                              Go to Public Profile Scorecard
                                                                            </Button>
                                                                          </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Profile External Link</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h3>
                                                                            <b>
                                                                            <div className="form-group bmd-form-group">
                                                                                <input defaultValue={propsFormik.values.linkedin}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="linkedin"
                                                                                    onChange={propsFormik.handleChange}
                                                                                    onBlur={propsFormik.handleBlur} />
                                                                            </div>                                  </b>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    { userData?.verifiedMail === false && (
                                                                        <div className="card">
                                                                            <div className="card-header">
                                                                                <h5 className="card-title">Verify Email</h5>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <Button
                                                                                    className="btn btn-outline-primary"
                                                                                    onClick={() => Swal.fire({
                                                                                                    title: 'Are you sure you want to verify email user?',
                                                                                                    showCancelButton: true,
                                                                                                    confirmButtonText: `Yes`,
                                                                                                    }).then((result) => {
                                                                                                        if (result.value) handleVerifyEmail(id);
                                                                                                    })
                                                                                        }>Verify Email
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-3 d-flex align-items-stretch flex-wrap">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Name</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h3>
                                                                                <b>
                                                                                    <div className="form-group bmd-form-group">
                                                                                        <input defaultValue={propsFormik.values.name}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="name"
                                                                                            onChange={propsFormik.handleChange}
                                                                                            onBlur={propsFormik.handleBlur} />
                                                                                    </div>
                                                                                </b>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Last name</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h3>
                                                                                <b>
                                                                                    <div className="form-group bmd-form-group">
                                                                                        <input defaultValue={propsFormik.values.surname}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="surname"
                                                                                            onChange={propsFormik.handleChange}
                                                                                            onBlur={propsFormik.handleBlur} />
                                                                                    </div>
                                                                                </b>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Sigla</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h3>
                                                                                <b>
                                                                                    <div className="form-group bmd-form-group">
                                                                                        <input defaultValue={propsFormik.values.sigla}
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            name="sigla"
                                                                                            onChange={propsFormik.handleChange}
                                                                                            onBlur={propsFormik.handleBlur} />
                                                                                    </div>
                                                                                </b>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Birthdate</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h3>
                                                                                <b>
                                                                                    <div className="form-group bmd-form-group">
                                                                                        <h5 className="card-title">{moment(propsFormik.values.birthDate).format('DD-MM-YYYY')}</h5>
                                                                                    </div>
                                                                                </b>
                                                                            </h3>
                                                                        </div>
                                                                    </div>
                                                                    {myData.userRole === "superuser" ?
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Expiration Date {myData.userRole} </h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                          <TextField
                                                                            name="expirationDate"
                                                                            label="Change Expiration date"
                                                                            type="date"
                                                                            onChange={(event) => {
                                                                                propsFormik.setFieldValue("expirationDate", event.target.value)
                                                                            }}
                                                                            defaultValue={moment(propsFormik.values.expirationDate).format('YYYY-MM-DD')}
                                                                            InputLabelProps={{
                                                                              shrink: true,
                                                                            }}
                                                                            fullWidth
                                                                            disabled={isDisabled}
                                                                          />
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                </div>
                                                                <div className="col-md d-flex align-items-stretch flex-wrap">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">email</h5>
                                                                        </div>
                                                                        <div className="card-body align-items-center justify-content-center">
                                                                            <div className="form-group bmd-form-group align-items-center">
                                                                                <input defaultValue={propsFormik.values.email}
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    name="email"
                                                                                    onChange={propsFormik.handleChange}
                                                                                    onBlur={propsFormik.handleBlur} />
                                                                                <Link to={{
                                                                                    pathname: `/password/${id}`,
                                                                                    params: { id: id }
                                                                                }} className="btn btn-outline-primary">
                                                                                    Edit Password
                                                                    </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {myData.userRole === "superuser" ?
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Access Rights</h5>
                                                                        </div>
                                                                        <div className="card-body align-items-center justify-content-center">
                                                                            <div className="form-group bmd-form-group align-items-center">
                                                                                <RadioGroup name="userRole" value={propsFormik.values.userRole} onChange={propsFormik.handleChange}>
                                                                                    <FormControlLabel value="student" control={<Radio />} label="Student" />
                                                                                    <FormControlLabel value="coach" control={<Radio />} label="Coach" />
                                                                                    {myData.userRole === "superuser" ?
                                                                                        <FormControlLabel value="superuser" control={<Radio />} label="SuperUser" />
                                                                                        : null}
                                                                                </RadioGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-7 d-flex align-items-stretch">
                                                                    <div className="card">
                                                                        <div className="card-header card-header-warning">
                                                                            <h5 className="card-title">Contacts: </h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="form-group">
                                                                                <label htmlFor="cityInput" className="bmd-label-floating">City</label>
                                                                                <h5 className="card-title">{propsFormik.values.city}</h5>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="bmd-label-floating">Country</label>
                                                                                <h5 className="card-title">{propsFormik.values.country}</h5>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <label className="bmd-label-floating">Phone</label>
                                                                                <h5 className="card-title">{propsFormik.values.phone}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-5 d-flex align-items-stretch">
                                                                    <div className="card">
                                                                        <div className="card-header card-header-warning">
                                                                            <h5 className="card-title">School Group</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <Select
                                                                                defaultValue={userCourseGroups}
                                                                                name="ourseGroups"
                                                                                onChange={(values) => propsFormik.setFieldValue("courseGroups", (values.map((item) => item.value)))}
                                                                                options={userGroupsQuery.data.userGroups.records.map((item) => ({ value: item._id, label: item.name }))}
                                                                                placeholder={"Select course groups"}
                                                                                isMulti={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="col-md d-flex align-items-stretch">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h4 className="card-title">{propsFormik.values.name} SCORE</h4>
                                                                        </div>
                                                                        <div className="card-body d-flex align-items-center justify-content-center">
                                                                            <h3>{totalUserScore}/{totalScore}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ItemsGrid columns={columns} columnsName={columnsName} title={`${propsFormik.values.name} Challenges`} items={userChallengesList}
                                                                    reset={(index, item) => deleteChallenges(item)} edit={(index, item) => props.history.push(`/challenge/end/${item._id}/0`)

                                                                    } pagination={true}
                                                                />
                                                                <CSVService label={"Download Challenge User Data"}
                                                                    fileName={userData.name + ".csv"}
                                                                    csvData={userChallengeData} />
                                                            </div>

                                                            <div>

                                                            </div>
                                                                <div className="row" style={{ justifyContent: "center" }}>
                                                                    <Button className="btn btn-fill btn-rose" type="submit" variant="contained" size="large">
                                                                        <SaveIcon />
                                                                        Save
                                                                        </Button>
                                                                </div>
                                                            <div className="row">
                                                            <div className="col-md-12">

                                                            <Box  style={{  display: "flex", justifyContent: "space-around"}}>
                                                            <Button className="btn btn-primary" onClick={backToUsers}>Back to Users</Button>
                                                            <Button className="btn  btn-primary" onClick={() => {
                                                            Swal.fire({
                                                                    title: 'Are you sure you want to delete the user '+id+' ?',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: `Yes`,
                                                                    }).then((result) => {
                                                                    if (result.value) {
                                                                        deleteUserMutation(id)
                                                                    }
                                                                    })}
                                                            }>Delete User</Button>

                                                            </Box>
                                                            </div>
                                                                <div className="col-md-6">
                                                                </div>
                                                                <div className="col-md-6" style={{ justifyContent: "flex-end", alignContent: "flex-end" }}>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
            </Layout>
        );
    }

export default UserProfile;
