import React from "react";

import { advertiserColumns } from "../../../../constants/columns";
import FirstStepQuery from "../FirstStepQuery";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";

const AdvertisersAdServerStep1 = ({ _add, type }) => {
  return (
    <TableLayout name={"Advertisers"} _add={_add}>
      <FirstStepQuery
        tableHeader={"Advertisers"}
        tableColumns={advertiserColumns}
        challengeType={type}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "new-advertiser"
};

export default withChallengeInstance(AdvertisersAdServerStep1, action);
