import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import TopicBlock from './TopicBlock/TopicBlock';
import MetaSkills from './MetaSkills/MetaSkills';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TopicBlock>
          {children}
        </TopicBlock>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  careerNavTab:{
    borderRadius: 6,
    backgroundColor: '#fff',
    overflow: 'hidden'
  },
  tabRoot: {
    '& button': {
      padding: '14px 17px',
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& button:focus': {
      outline: 'none',
    },
    '& button.Mui-selected': {
      color: theme.palette.common.gamsPetroleumBlue
    }
  },
  tabIndicator: {
    backgroundColor: theme.palette.common.gamsPetroleumBlue,
    height: 4
  }
}));

const LearningTopics = ({ careerPaths, urlPath }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let tabPanelList = 'No tab panel...';
  let filteredScores = [];
  if (careerPaths) {
    // console.log( 'careerPaths => + ',careerPaths)
    careerPaths.forEach(career => {
      if (career.scores.length !== 0) {
        filteredScores.push(career);
      }
    });
    tabPanelList = filteredScores.map((filteredScores, index) => {
      // console.log('index => ',index, 'value => ', value)
      return <TabPanel key={index} value={value} index={index} >
        <MetaSkills scores={filteredScores.scores} urlPath={urlPath} />
      </TabPanel>;
    })
  }

  let tabList = 'No tabs...';
  if (careerPaths) {
    tabList = filteredScores.map((filteredScores, index) => {
      return <Tab key={index} label={filteredScores.name} {...a11yProps(index)} />;
    })
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.careerNavTab} position="static" color="default" elevation={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
          classes={{
            root: classes.tabRoot,
            indicator: classes.tabIndicator,
          }}
        >
          {tabList}
        </Tabs>
      </AppBar>
      {tabPanelList}
    </div>
  );
}

export default LearningTopics;
