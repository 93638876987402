import React from 'react';
import Select from 'react-select';


class SimpleSelect extends React.Component {
  state = {
    selectedOption: this.props.selectedValue,
    options: this.props.values,
    placeholder: this.props.placeholder

  }
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange(selectedOption)
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.state.options}
        placeholder={this.state.placeholder}
      />
    );
  }
}

export default SimpleSelect