import {Query} from "@apollo/client/react/components";
import LoadingAnimation from "../../common/components/base/LoadingAnimation";
// import Autocomplete from "../../common/components/base/Autocomplete";
import SimpleDropDown from "../../common/components/base/SimpleDropDown";
import React, {Component} from 'react';
// import {ApolloClient} from "@apollo/client";
// import {apolloSecureConfig} from '../../settings/apollo';
import DialogView from "../../common/components/DialogView";
import {Form} from "formik";
import {Builder} from "../../settings/builder";
import {width100} from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import {errorMessage} from "../../settings/otherStyles";
import {GET_CREATIVES, GET_CREATIVES_SIMULATION} from './queries';
import Button from "@material-ui/core/Button";
import SimpleSelect from "../../common/components/base/SimpleSelect";
import { creativesVar } from "../../settings/cache";

class CreativeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {...props, validated: false, open: false};
    this.handleChangeAdsName = this.handleChangeAdsName.bind(this);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
    this.handleCreative = this.handleCreative.bind(this);
    this.handleChangeCreativeType = this.handleChangeCreativeType.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.validate = this.validate.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
  }

  handleChangeAdsName(event) {
    this.setState({adsName: event.target.value});
  }

  handleChangeUrl(event) {
    this.setState({landingUrl: event.target.value});
  }

  handleCreative(event) {
    this.setState({creative: event.label});
  }

  handleChangeCreativeType(event) {
    this.setState({type: event.currentTarget.textContent});
  }

  handleAdd() {
    let item = Builder.createCreative(this.state);
    let results = creativesVar()
    results.push(item);
    creativesVar(results);
    this.setState({type: '', adsName: '', status: '', creative: ''});
    this.state.handleAdd();
  }

  validate() {
    this.setState({validated: true});
    return this.state.adsName &&
      this.state.landingUrl &&
      this.state.type &&
      this.state.creative;
  }

  handleClickOpen = () => {
    this.setState({open: true, validated: false});
  };

  handleClose = () => {
    let isValid = this.validate();
    if (isValid) {
      this.handleAdd();
      this.setState({open: false});
    }
  };

  handleUndo = () => {
    this.setState({open: false});
  };

  render() {
    let query;
    if (this.state.simulation) {
      query = GET_CREATIVES_SIMULATION;
    } else {
      query = GET_CREATIVES;
    }
    return (
      <div>
        <Button className="btn btn-fill btn-info"  onClick={this.handleClickOpen}>Associate ADS</Button>
        <DialogView title="ASSOCIATE ADS" handleUndo={this.handleUndo} handleClose={this.handleClose}
                    handleSave={this.handleClose} open={this.state.open}>
          <Form>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="form-group bmd-form-group">
                  <TextField
                    required
                    label="Ads Name"
                    name="adsName"
                    value={this.state.adsName}
                    onChange={this.handleChangeAdsName}
                    margin="normal"
                    style={width100}
                  />
                  {(this.state.validated & !this.state.adsName) ?
                    <Typography style={errorMessage}>Please set a name</Typography> : ""}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group bmd-form-group">
                  <Query query={query} fetchPolicy="network-only">
                    {({loading, error, data}) => {
                      if (data!=null && data.creativesProjected!=null)
                        return (
                          <SimpleSelect
                            onChange={this.handleCreative}
                            selectedValue={this.state.creative}
                            values={data.creativesProjected.map(value => ({
                              value: value._id,
                              label: value.name
                            }))} placeholder="Creative"
                          />
                        );
                        if (data!=null && data.creativesSimulation!=null)
                        return (
                          <SimpleSelect
                            onChange={this.handleCreative}
                            selectedValue={this.state.creative}
                            values={data.creativesSimulation.map(value => ({
                              value: value._id,
                              label: value.name
                            }))} placeholder="Creative"
                          />
                        );
                      if (loading) return (<LoadingAnimation/>);
                      if (error) return `Error! ${error.message}`;
                    }}
                  </Query>
                  {(this.state.validated & !this.state.creative) ?
                    <Typography style={errorMessage}>Please set a creative</Typography> : ""}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group bmd-form-group">
                  <SimpleDropDown items={['Active', 'Paused']}
                                  caption={this.state.type || 'Status'}
                                  placeholder="Status"
                                  handleChange={this.handleChangeCreativeType}/>
                  {(this.state.validated & !this.state.type) ?
                    <Typography style={errorMessage}>Please set a type</Typography> : ""}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group bmd-form-group">
                  <TextField
                    required
                    label="Landing Url"
                    name="landingUrl"
                    value={this.state.url}
                    onChange={this.handleChangeUrl}
                    margin="normal"
                    style={width100}
                  />
                  {(this.state.validated & !this.state.url) ?
                    <Typography style={errorMessage}>Please set an url</Typography> : ""}
                </div>
              </div>
            </div>
          </Form>
        </DialogView>
      </div>
    );
  }
}

export default CreativeItem;
