import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, SvgIcon, Link, Avatar } from '@material-ui/core';
import { ReactComponent as resultIcon } from '../../../../assets/images/challenge-results/result-icon.svg';
import avatarDefault from '../../../../assets/images/avatar/riccardo-guggiola.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      bottom: 10,
      left: 170,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  icon: {
    marginTop: -28,
    color: theme.palette.common.yellow[600],
    filter: 'drop-shadow(1px 1px 3px rgb(0 0 0 / 0.3))',
    marginBottom:10,
  },
  coachAvatar: {
    width:42,
    height:42,
    marginRight:8,
    border:`solid 1px ${theme.palette.grey[400]}`,
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginBottom:5
    },
  },
  title: {
    marginTop: 21,
    fontSize: theme.typography.pxToRem(19),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    '& a': {
      textDecoration:'underline',
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
      }
    }
  }
}));

const ResultTitle = ({ coach }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Avatar
          src={coach.photo ? coach.photo : avatarDefault}
          alt={coach.name ? coach.name : 'Riccardo Guggiola'}
          className={classes.coachAvatar}
        />
        <Typography className={classes.title}  variant="h1">
          <Link
            href={coach.linkedin ? coach.linkedin : 'https://it.linkedin.com/in/riccardoguggiola'}
            rel="noopener noreferrer"
            target="_blank"
          >{coach ? `${coach.name} ${coach.surname}` : 'Riccardo Guggiola'}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default ResultTitle;
