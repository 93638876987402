import gql from "graphql-tag";

export const GET_USER_DATA = gql`
  query me {
    me {
      email
      userType
      trialExpires
      premiumPeriodEnd
      expirationDateExtended
    }
  }
`;

export const GET_GLOBALCONTENT = gql`
  query globalContent {
    globalContent {
      data {
        attributes {
          pricingPlan {
            title
            planType
            description
            discountText
            b2bLicenceText
            featureList {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_SUBSCRIPTION_PAGE = gql`
  query subscriptionPage {
    subscriptionPage {
      data {
        attributes {
          title
          description
          titleTrial
          descriptionTrial
          faqTitle
          ctaFaqs {
            text
            textLink
            link
          }
          faqs {
            question
            answer
          }
          supportMessage {
            message
            textLink
            link
          }
          TrialExpiredAlert {
            title
            subTitle
            textButton
          }
          PremiumExpiredAlert {
            title
            subTitle
            textButton
          }
          PremiumAlert {
            title
            subTitle
            textButton
          }
          PremiumCanceledAlert {
            title
            subTitle
            textButton
          }
        }
      }
    }
  }
`;
