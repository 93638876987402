import React, { Component } from 'react';
//import Layout from '../../common/Layout';
import { Query } from "@apollo/client/react/components";
import DataTable from '../../common/components/data-table/DataTable';
//import PropTypes from "prop-types";
import SimpleSearch from "../../common/components/data-table/SimpleSearch";
import AddItemButton from "../../common/components/base/AddItemButton";
import { SecureQuery } from "../../settings/graphqlHelper";
import { DELETE_CAMPAIGN, GET_CAMPAIGNS } from "./queries";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const columns = ["name", "advertiser", "duration", "actions"];

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0 ,
      id: props.match.params.id,
      time: 120 };
    this._first = this._first.bind(this);
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._add = this._add.bind(this);
    this._keyPress = this._keyPress.bind(this);
  }

  _previousPage(e) {
    e.preventDefault();
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0 })
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
  }

  _deleteRow(e) {
    SecureQuery.mutate({
      mutation: DELETE_CAMPAIGN,
      variables: { input: e.id.id },
    }).catch(err => console.log(err))
      .then(() => this.forceUpdate());
  }

  _add() {
    this.props.history.push("/campaign");
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({ name: e.target.value,
                      page: 0 });

    }
  }

  render() {
    return (
      <SandboxLayout name="Campaigns" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <AddItemButton OnClick={this._add} />
          <div className="row">
            <div className="col-sm-12 col-md-10" />
            <div className="col-sm-12 col-md-2">
              <SimpleSearch keyPress={this._keyPress} />
            </div>
          </div>
          <Query query={GET_CAMPAIGNS}
            fetchPolicy="network-only"
            variables={{
              name: this.state.name,
              page: this.state.page
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 30 }} />;
              if (error) return `Error! ${error.message}`;
              if (!data || data.campaigns.records.length === 0)
                return (
                  <DataTable
                    header="Campaigns"
                    columns={columns}
                    records={{}}>

                  </DataTable>
                  )
              return (
                <DataTable header="Campaigns"
                  editUrl="/campaign/update/"
                  columns={columns}
                  records={data.campaigns}
                  deleteRow={this._deleteRow}
                  keyColumn='_id'
                  page={this.state.page}
                  first={this._first}
                  next={this._nextPage}
                  last={this._last.bind(this, data.campaigns.pages)}
                  previous={this._previousPage}
                />

              );
            }}
          </Query>
        </div>
      </SandboxLayout>
    );
  }
}

export default Campaigns;
