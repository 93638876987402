export const crmContactsSelectMenu = [
  {
    title: "All contacts",
    value: "all-contacts"
  },
]

export const contactsTableColumns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    id: 'surname',
    label: 'Surname',
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email',
    align: 'left',
  },
  {
    id: 'country',
    label: 'Country',
    align: 'left',
  },
  {
    id: 'age',
    label: 'Age',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center',
    style: { width: 170 }
  },
];
