import gql from "graphql-tag";

export const GET_CAREERLIST_PAGE = gql`
  query {
    careerListPage {
      data {
        id
        attributes {
          pageHeader {
            title
            description
          }
        }
      }
    }
  }
`;

export const GET_CAREERS = gql`
  query careers {
    careerPaths {
          name
          slug
          description
          isComingSoon
          status
          progress
          totalChallenges
          totalFinishedChallenges
          toolsUsed {
            title
            icon {
              data {
                attributes {
                  url
                }
              }
            }
          }
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
`;
