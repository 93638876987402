import React, { useState } from "react";
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Grid, Avatar, Link, Tooltip, SvgIcon } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { useQuery } from "@apollo/client";
import gql from 'graphql-tag';
import avatarDefault from '../../assets/images/avatar/riccardo-guggiola.jpg';
import { ReactComponent as validationIcon } from '../../assets/images/career-single/validation-icon.svg';

const GET_USER_DATA = gql`
  query user ($id: String) {
    user(id: $id) {
      name
      surname
      photo
      linkedin
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& .MuiPaper-root': {
      overflowY: 'initial',
    }
  },
  dialogTitle: {
    textAlign: 'center',
    borderBottom: `solid 1px ${theme.palette.grey[200]}`
  },
  coachAvatar: {
    margin:'auto',
    width:64,
    height:64,
    position:'absolute',
    top:-32,
    left:0,
    right:0,
    boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.5)',
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    }
  },
  coachTitle: {
    fontSize: theme.typography.pxToRem(21),
    paddingTop:20,
    '& a': {
      textDecoration:'underline',
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
      }
    }
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  textLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom:theme.spacing(2),
  },
  richText: {
    '& p': {
      marginBottom: theme.spacing(2)
    },
    '& ul': {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0 ,0, 0, 4)
    },
    '& li': {
      marginBottom: theme.spacing(1.5)
    },
    '& a': {
      color: theme.palette.common.gamsPetroleumBlue,
      '&:hover': {
        color: theme.palette.common.blue[400],
        textDecoration: 'underline'
      }
    },
    '& strong': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  textList: {
    listStyle:'none',
    padding:0,
    margin:0,
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingTop: theme.spacing(2.5),
      borderTop: `solid 1px ${theme.palette.grey[100]}`,
    },
    '& li': {
      marginBottom:15,
      '& h3': {
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom:1,
      },
      '& div:last-child': {
      }
    },
  },
  validationIcon: {
    position:'absolute',
  },
  dialogActions: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2.5, 3, 2.5, 3),
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden'
  },
  gridRoot: {
    maxWidth: 760,
    margin:'auto',
  },
  gridItem: {
    '&:first-child': {
      borderRight: `solid 1px ${theme.palette.grey[100]}`,
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        borderRight: 'none',
        paddingRight: 0,
        borderBottom: `solid 1px ${theme.palette.grey[100]}`,
        paddingBottom: theme.spacing(2),
      },
    }
  },
  buttonOutlined: {
    borderColor: blue[900],
    color: blue[900],
    borderRadius: 17,
    '&:hover': {
      borderColor: blue[800],
    },
    '&:focus': {
      outline: 'none',
    }
  },
  dialogBtnDefault: {
    color: theme.palette.common.gamsDarkBlue,
    '&:focus': {
      outline: 'none',
    }
  },
  dialogBtnContained: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue
    }
  },
}));

const ChallengeDialog = props => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const {
    title,
    coach,
    brief,
    challengeTitle,
    challengeIsValidation,
    challengeDifficulty,
    challengeAverageTime,
    handleUndo,
    handleExit,
    handleClose,
    handleSave,
    handleSkip,
    open,
    skipName,
    finishName,
    exitName,
    undoName,
    saveName,
    button,
    handleFinish,
    step,
    challengeInfo,
    challengeDashboard
  } = props;

  const {data, loading, error} = useQuery(GET_USER_DATA, {variables: {id: coach}})
  if(loading) return 'loading';
  if(error) return "error";
  const userData = data.user;

  async function skip() {
    await props.handleSkip()
    await setModal(false)
  }

  const description = brief && parse(brief);

  let dialogTitle;
  if (title) {
    dialogTitle = (
      <Box>
        {title}
      </Box>
    );
  } else if (userData) {
    dialogTitle = (
      <>
        <Avatar
          src={userData.photo ? userData.photo : avatarDefault}
          alt={userData.name ? userData.name : 'Riccardo Guggiola'}
          className={classes.coachAvatar}
        />
        <Box className={classes.coachTitle}>
          <Link
            href={userData.linkedin ? userData.linkedin : 'https://it.linkedin.com/in/riccardoguggiola'}
            rel="noopener noreferrer"
            target="_blank"
          >
          {userData.name ? `${userData.name} ${userData.surname}` : 'Riccardo Guggiola'}
          </Link> Challenges you!
        </Box>
      </>
    );
  }

  return (
    <div>
      <Button
        hidden={button}
        variant="outlined"
        color="primary"
        onClick={() => setModal(true)}
        className={challengeInfo ? classes.buttonOutlined : null}
        style={{
          marginLeft: challengeInfo ? "0" : "16px",
          marginBottom: challengeInfo ? "0" : "2px"
        }}
      >{challengeInfo ? 'Info Challenge' : 'Show Info Challenge'}</Button>

      <Dialog
        open={open || modal}
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth
        className={classes.dialogRoot}
      >
        <DialogTitle
          id="form-dialog-title"
          className={classes.dialogTitle}
        >{dialogTitle}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid className={classes.gridRoot} container spacing={0}>
            <Grid className={classes.gridItem} item sm={12} md={9}>
              <Typography variant="body1" component="div" className={classes.textLabel}>Brief</Typography>
              {brief && (
                <Typography variant="body1" component="div" className={classes.richText}>
                  {description}
                </Typography>
              )}
            </Grid>
            <Grid className={classes.rootItem} item sm={12} md={3}>
              <ul className={classes.textList}>
                <li>
                  <Typography variant="body1" component="h3">Challenge title</Typography>
                  <Box position="relative">
                    <Typography variant="body1" component="span">
                      {challengeTitle}
                    </Typography>
                    { challengeIsValidation === true ?
                    <Tooltip title="This challenge is valid for the Profile Scorecard!" enterTouchDelay={0} arrow>
                      <SvgIcon className={classes.validationIcon} component={validationIcon} viewBox="0 0 24 24" style={{ fontSize: 24, marginLeft:2 }}/>
                    </Tooltip>
                    : null }
                  </Box>
                </li>
                <li>
                  <Typography variant="body1" component="h3">Average duration</Typography>
                  <Typography variant="body1" component="div">{challengeAverageTime}</Typography>
                </li>
                <li>
                  <Typography variant="body1" component="h3">Difficulty</Typography>
                  <Typography variant="body1" component="div">{challengeDifficulty}</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          {handleSkip ?
            <Button
              onClick={() => skip()}
              className={classes.dialogBtnDefault}
              color="primary"
              size="large"
            >{skipName || "Skip step"}</Button>
          : null}

          {step < 1 ? (
            handleExit ? (
              <Button
                onClick={handleExit}
                className={classes.dialogBtnDefault}
                color="primary"
                size="large"
              >{exitName || "Exit Challenge"}</Button>
            ) : null
          ) : handleFinish ? (
            <Button
              onClick={handleFinish}
              className={classes.dialogBtnDefault}
              color="primary"
              size="large"
            >{finishName || "Finish Challenge"}</Button>
          ) : null}

          <Button
            onClick={handleUndo ? handleUndo : () => setModal(false)}
            color="primary"
            size="large"
            className={classes.dialogBtnDefault}
          >{undoName || "Cancel"}</Button>

          {handleSave ? (
            <Button
            onClick={() => {
              let name = sessionStorage.getItem('challengeName');
              if(name){
                console.log(name);
                sessionStorage.removeItem('challengeName');
              }
              sessionStorage.setItem('challengeName', challengeTitle);
              handleSave();
            }}
              color="primary"
              variant="contained"
              size="large"
              className={challengeDashboard ? classes.dialogBtnContained : null }
            >{saveName || "Save"}</Button>
          ) : null }
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default ChallengeDialog;
