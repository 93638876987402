import React, { Component } from "react";
import Layout from "../../../common/Layout";
import PropTypes from "prop-types";
import { Query } from "@apollo/client/react/components";
import { client } from "../../../settings/apollo";
import AddItemButton from "../../../common/components/base/AddItemButton";
import { GET_CHALLENGE_DEFINITIONS, getChallengeDefinitions, getChallenge} from "./services"
import ItemsGrid from "../../../common/components/widgets/ItemsGrid";
import {downloadJson} from './../../../common/components/base/JsonDownload'
import UploadDialog from "./uploadDialog";
import gql from "graphql-tag";
import Swal from 'sweetalert2';
import LoadingSpinner from "../../../common/components/LoadingSpinner/LoadingSpinner";

const columns = ["challengeName", "challengeTitle",  "action"];
const columnsName = ["name", "title",  "action" ]

const DELETE_CHALLENGE_DEFINITION= gql`
mutation deleteChallengeDefinition($id: Int!) {
  deleteChallengeDefinition(id: $id)
}
`;
class ChallengesList extends Component {
  static propTypes = {
    page: PropTypes.number,
    name: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      name: "",
      uploadDialog: false,
      uploadItem:'',
      challengesToDo:[],
      downloadData:[]
    };
    this._add = this._add.bind(this);
    this._download = this._download.bind(this);
    this._remove = this._remove.bind(this);
    this.removeChallenge=this.removeChallenge.bind(this);
  }

  async componentDidMount() {
    getChallengeDefinitions(client).then(async (value) => {
      const clonedValue=[...value];
      const sortedChallengeDefinitions=clonedValue.sort((a, b) => a.challengeName > b.challengeName ? 1 : -1);
      this.setState({challengesToDo: sortedChallengeDefinitions})
    }).catch((e)=> console.log(e))
  }
  _add() {
    this.setState({uploadDialog: true});
  }

  _download(item) {
    getChallenge(item.id, client).then(async (value) => {
      //await this.setState({downloadData: value})
      if (value)
      downloadJson(value, "challenge"+value.id)
    })
    .catch((e)=> console.log(e))
    .then(()=>this.setState({downloadModal: true}))
  }
  removeChallenge(id){
    const index= this.state.challengesToDo.findIndex(function(item) {
      return (item.id === id);
    });
    const clonedChallenges=[...this.state.challengesToDo];
    clonedChallenges.splice(index,1);
                 // .sort((a, b) => a.challengeName > b.challengeName ? 1 : -1);
    this.setState({challengesToDo: clonedChallenges})
  }

   _remove(item) {


    Swal.fire({
      title: 'Are you sure you want to delete this challenge?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.value===true) {
        client
        .mutate({ mutation: DELETE_CHALLENGE_DEFINITION, variables: { id: item.id } })
        .catch(error => console.log(error))
        .then(() => {
          this.removeChallenge(item.id)
          Swal.fire('Challenge deleted!', '', 'success');
        });

      }
    })
  }



  render() {
    return (
      <Layout name="Challenges List">
        <div className="wizard-container">
          <AddItemButton OnClick={this._add} />
          <div className="row">
            <div className="col-sm-12 col-md-10" />
            <div className="col-sm-12 col-md-2">
              {/* <SimpleSearch keyPress={this._keyPress} /> */}
            </div>
          </div>
          <Query
            query={GET_CHALLENGE_DEFINITIONS}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (loading) return <LoadingSpinner boxed={true} styles={{ paddingTop: 40, marginTop: 15, marginBottom: 30 }} />;
              if (error) return `Error! ${error.message}`;
              if (!data)
                return <div>No Challenges available</div>;
              else
                return (
                  <div>
                    <ItemsGrid
                      columns={columns}
                      download={(index, value)=>this._download(value)}
                      upload={(index, value)=>this.setState({uploadDialog: true, uploadItem: value})}
                      columnsName={columnsName}
                      remove={(index, value)=>this._remove(value)}
                      title={ "Challenges"}
                      items={this.state.challengesToDo}
                      pagination={true}
                      />
                    <UploadDialog
                    title={this.state.uploadItem ? "Update Challenge" : "Upload Challenge" }
                    handleUndo={()=>this.setState({uploadDialog: false})}
                    handleClose={()=>this.setState({uploadDialog: false})}
                    uploadItem={this.state.uploadItem}
                    open={this.state.uploadDialog}
                    saveName="Save"
                    button={true}
                    >
                      { this.state.uploadItem ?
                            "Select .json challenge file  to replace current challenge"
                         :
                         "Select .json challenge file  to insert new challenge"
                      }
                    </UploadDialog>
                  </div>
                );
            }}
          </Query>
        </div>
      </Layout>
    );
  }
}

export default ChallengesList;
