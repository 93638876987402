import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardHeader, CardContent, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  cardContent: {
    padding:0,
    paddingBottom:'0 !important',
  },
  textList: {
    listStyle: 'none',
    padding:0,
    margin:0,
    '& li': {
      padding: theme.spacing(1.5, 3),
      borderBottom:`solid 1px ${theme.palette.grey[200]}`,
      '&:last-child': {
        borderBottom:'none',
        padding: theme.spacing(1.5, 3, 2, 3),
      },
      '& a': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightRegular,
        textDecoration:'none',
        textTransform: 'capitalize',
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.common.gamsPetroleumBlue
        }
      }
    }
  }
}));

const AdditionalInfo = ({ title, infoItems }) => {
  const classes = useStyles();

  return (
    <Card elevation={3}>
      <CardHeader
        title={title}
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
        }}
      />
      <CardContent className={classes.cardContent}>
        <ul className={classes.textList}>
          {infoItems.map((item) => {
            let itemLink = null;
            if (item.link.includes("http")) {
              itemLink = <Link href={item.link} target="_blank">
                {item.title}
              </Link>
            } else {
              itemLink = <Link component={RouterLink} to={`/${item.link}`}>
                {item.title}
              </Link>
            }
            return (
              <li key={`sdText-${item.title}`}>
                {itemLink}
              </li>
            )
          })}
        </ul>
      </CardContent>
    </Card>
  );
}

export default AdditionalInfo;
