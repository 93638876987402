import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop:34,
    marginBottom:34,
    padding:'0px 20px',
  },
  icon: {
    position: "absolute",
    right: 24.333,
    top: 28,
  },
  list: {
    margin:0,
    padding:0,
    marginLeft:-21,
    marginRight:-21,
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    listStyle:'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listItem: {
    width:'100%',
    maxWidth: 286,
    height: 114,
    paddingLeft:21,
    paddingRight:21,
    [theme.breakpoints.down('md')]: {
      paddingLeft:12,
      paddingRight:12,
    },
    [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        height: 114,
        marginBottom:18
    },
  },
  listItemBlock: {
    width:'100%',
    boxShadow:
    '0px 16px 40px 0px rgba(2, 136, 209, 0.08), 0px 8px 16px 0px rgba(4, 63, 84, 0.04)',
    height:'100%',
    borderRadius: 16,
    padding:'15px 20px',
    position:'relative',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  listItemText: {
    position: 'absolute',
    top:75,
    left:20,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  listItemResult: {
    position: 'absolute',
    top:15,
    left:20,
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.primary,
    '& .step-time-number': {
      paddingLeft:6,
    },
    '& .step-time-text': {
      fontSize: theme.typography.pxToRem(19),
      //paddingLeft:0.5,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  }
}));

const ResultTotal = ({ totalSteps, totalTime, totalScore }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ul className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.listItemBlock}>
            <Typography className={classes.listItemResult} variant="body1" component="div">
              {totalSteps}
            </Typography>
            <Typography className={classes.listItemText} variant="body1" component="div">Steps</Typography>
              <svg className={classes.icon} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.55 5.25C15.65 5.25 16.55 4.35 16.55 3.25C16.55 2.15 15.65 1.25 14.55 1.25C13.45 1.25 12.55 2.15 12.55 3.25C12.55 4.35 13.45 5.25 14.55 5.25ZM10.95 19.15L11.95 14.75L14.05 16.75V22.75H16.05V15.25L13.95 13.25L14.55 10.25C15.85 11.75 17.85 12.75 20.05 12.75V10.75C18.15 10.75 16.55 9.75 15.75 8.35L14.75 6.75C14.35 6.15 13.75 5.75 13.05 5.75C12.75 5.75 12.55 5.85 12.25 5.85L7.05001 8.05V12.75H9.05001V9.35L10.85 8.65L9.25001 16.75L4.35001 15.75L3.95001 17.75L10.95 19.15Z" fill="black" fill-opacity="0.38"/>
              </svg> 
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.listItemBlock}>
            <Typography className={classes.listItemText} variant="body1" component="div">Time</Typography>
            <Typography className={classes.listItemResult} variant="body1" component="div">
              <span className={'step-time-number'}>{totalTime.minutes}</span><span className={'step-time-text'}>m</span>
              <span className={'step-time-number'}>{totalTime.seconds}</span><span className={'step-time-text'}>s</span>
            </Typography>
              <svg className={classes.icon} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.3333 1.5H9.33331V3.5H15.3333V1.5ZM11.3333 14.5H13.3333V8.5H11.3333V14.5ZM19.3633 7.89L20.7833 6.47C20.3533 5.96 19.8833 5.48 19.3733 5.06L17.9533 6.48C16.4033 5.24 14.4533 4.5 12.3333 4.5C7.36331 4.5 3.33331 8.53 3.33331 13.5C3.33331 18.47 7.35331 22.5 12.3333 22.5C17.3133 22.5 21.3333 18.47 21.3333 13.5C21.3333 11.38 20.5933 9.43 19.3633 7.89ZM12.3333 20.5C8.46331 20.5 5.33331 17.37 5.33331 13.5C5.33331 9.63 8.46331 6.5 12.3333 6.5C16.2033 6.5 19.3333 9.63 19.3333 13.5C19.3333 17.37 16.2033 20.5 12.3333 20.5Z" fill="black" fill-opacity="0.38"/>
              </svg> 
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.listItemBlock}>
            <Typography className={classes.listItemText} variant="body1" component="div">Score</Typography>
            <Typography className={classes.listItemResult} variant="body1" component="div">
              {totalScore}
            </Typography>
              <svg className={classes.icon} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.6666 5H17.6666V3H7.66663V5H5.66663C4.56663 5 3.66663 5.9 3.66663 7V8C3.66663 10.55 5.58663 12.63 8.05663 12.94C8.68663 14.44 10.0366 15.57 11.6666 15.9V19H7.66663V21H17.6666V19H13.6666V15.9C15.2966 15.57 16.6466 14.44 17.2766 12.94C19.7466 12.63 21.6666 10.55 21.6666 8V7C21.6666 5.9 20.7666 5 19.6666 5ZM5.66663 8V7H7.66663V10.82C6.50663 10.4 5.66663 9.3 5.66663 8ZM12.6666 14C11.0166 14 9.66663 12.65 9.66663 11V5H15.6666V11C15.6666 12.65 14.3166 14 12.6666 14ZM19.6666 8C19.6666 9.3 18.8266 10.4 17.6666 10.82V7H19.6666V8Z" fill="black" fill-opacity="0.38"/>
              </svg> 
          </div>
        </li>
      </ul>
    </Box>
  );
};

export default ResultTotal;
