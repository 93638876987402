import React from 'react';
import { Formik } from 'formik';
import gql from "graphql-tag";
import {Mutation, Query } from "@apollo/client/react/components";
import RegisterForm from '../components/main/RegisterForm';
import { withRouter } from "react-router-dom";
import { validateRegistration } from '../../components/Validations';
import { ApolloProvider } from "@apollo/client";
import { GET_USER_GROUPS } from "../../components/userGroup/queries";
import Swal from 'sweetalert2';
import LoadingAnimation from '../../common/components/base/LoadingAnimation';
import Layout from "../Layout";
import ExcelReader from "../../common/components/base/ExcelReader";
import {client} from "../../settings/apollo";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const CREATE_USER = gql`
  mutation createUser($input:UserInput){
    createUser(input:$input){
      token
    }
  }
`;
const GET_USER_DATA = gql`
 query me {
  me {
        userRole
        courseGroups{
          _id
          name
          groupChallenges
        }
 }
}
`;

const REGISTER_USERS = gql`
mutation createUsers($users:[Users]){
  createUsers(users:$users)
}
`;

class AdminRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
    this.registerUsers = this.registerUsers.bind(this);
  }
  async registerUsers (value){
    const userList = []
    value.map((item)=>userList.push({
              name : item.NAME,
              surname: item.SURNAME,
              sigla: item.SIGLA,
              email: item.EMAIL_LOGIN,
              password: item.PSW,
              userRole: item.USER_ROLE.toLowerCase(),
              expirationDate: item.EXPIRATION_DATE  }))
    await client.mutate({mutation: REGISTER_USERS, variables:{users: userList},})
    .catch((error) => {
      Swal.fire({
        type: 'error',
        text: 'Error registering userS.',
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close'
      })
    }).then(
      Swal.fire({
        type: 'success',
        title: 'Saved',
        showConfirmButton: false,
        timer: 1500
      })
    );
  }

  render() {
    return (
      <Layout name="Register User">
        <Query query={GET_USER_DATA}
          fetchPolicy="network-only"
        >
          {({ loading, error, data }) => {
            if (loading) return <LoadingSpinner styles={{ paddingTop: 60 }} />;
            if (error) return `Error! ${error.message}`;
            const userRole = data.me.userRole;
            const courseList = data.me.courseGroups;
            return (
              <Query query={GET_USER_GROUPS} fetchPolicy="network-only"
                variables={{ idList: courseList.map(item => item._id) }}
              >
                {({ loading, error, data }) => {
                  if (loading) return (<LoadingAnimation />);
                  if (error) return `Error! ${error.message}`;
                  const courseGroups = data.userGroups.records.map((item) => ({ value: item._id, label: item.name }))
                  return (
                    <div className="wizard-container">
                      <div className="col-md-8 col-12 mr-auto ml-auto">
                        <div className="card card-wizard active" data-color="rose" id="wizardProfile">

                          <ApolloProvider client={client}>
                            <Mutation mutation={CREATE_USER}
                                      onError={() => {
                                        Swal.fire({
                                        type: 'error',
                                        text: 'Error registering user.',
                                        showConfirmButton: false,
                                        showCancelButton: true,
                                        cancelButtonText: 'Close'
                                      })}}
                                      onCompleted={() => {
                                        Swal.fire({
                                        type: 'success',
                                        text: 'User registered.',
                                        showConfirmButton: false,
                                        timer: 1500
                                      })
                                      this.props.history.push('/admin-settings/users')
                                    }}

                            >
                              {(createUser, { data }) => (
                                <Formik
                                  initialValues={{ name: '', surname: '', sigla: '', username: '', password: '', userRole: 'student', courseGroups: [], privacyPolicy: true, expirationDate: '' }}
                                  validate={validateRegistration}
                                  onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    createUser({ variables: { input: {
                                                    name: values.name,
                                                    surname: values.surname,
                                                    sigla: values.sigla,
                                                    email: values.username,
                                                    password: values.password,
                                                    userRole: values.userRole,
                                                    courseGroups: values.courseGroups,
                                                    privacyPolicy: values.privacyPolicy,
                                                    expirationDate: values.expirationDate
                                                  } } });
                                  }}
                                >
                                  {({ values, isSubmitting, handleChange, setFieldValue }) => (
                                    <RegisterForm values={values}
                                      isSubmitting={isSubmitting}
                                      handleChange={handleChange}
                                      setFieldValue={setFieldValue}
                                      userRole={userRole}
                                      courseGroups={courseGroups}
                                      open={()=>{this.setState({open: false})}}/>
                                  )}
                                </Formik>
                              )}
                            </Mutation>
                          </ApolloProvider>

                          <ExcelReader
                          label={"Select list of users"}
                          onLoad={(value)=>this.registerUsers(value)}
                          buttonLabel={"Register"}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Query>
            )
          }}
        </Query>
      </Layout>
    );
  }
}

export default withRouter(AdminRegister);
