import React from 'react';
import { Grid } from '@material-ui/core';
import Layout from '../../common/Layout';
import PageHeader from '../../common/components/PageHeader/PageHeader';
import SandboxToolBlock from '../../common/components/SandboxToolBlock/SandboxToolBlock';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { useQuery } from '@apollo/client';
import { GET_SANDBOX_TOOLS, GET_SANDBOXLIST_PAGE } from './graphql/queries';

const SandboxToolsList = props => {
  const {data, loading, error} = useQuery(GET_SANDBOX_TOOLS);

  const {
    loading: sspLoading,
    error: sspError,
    data: sspData
  } = useQuery(GET_SANDBOXLIST_PAGE);

  const sandboxListPage = sspData?.sandboxListPage?.data?.attributes;
  const sandboxes = data?.sandboxes?.data;

  return (
    <Layout>
      {loading || sspLoading ? (
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      ) : error || sspError ? (
        <div>Error! {error && error.message} {sspError && sspError.message}</div>
      ) : (
        <>
          <PageHeader
            title={sandboxListPage.pageHeader.title}
            subTitle={sandboxListPage.pageHeader.description}
          />
          <Grid container spacing={4}>
            {sandboxes.map((sandboxTool, index) => {
              return (
                <Grid key={`sdb-${index}`} item xs={12} sm={6} md={6} lg={4} xl={4}>
                  <SandboxToolBlock sandboxTool={sandboxTool} />
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </Layout>
  );
}

export default SandboxToolsList;
