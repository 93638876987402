import React, { Component } from "react";
//import Layout from "../../common/Layout";
import LineChart from "../../common/components/charts/LineChart";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { campaignReportFields } from "../../settings/dataSets";
import { margin50 } from "../../settings/otherStyles";
import { client } from "../../settings/apollo";
import { campaignVar } from "../../settings/cache";
import Autocomplete from "../../common/components/base/Autocomplete";
import Typography from "@material-ui/core/Typography";
import { getCampaign, updateCampaign } from "../campaign/services";
import { CSVLink } from "react-csv";
import UpdateReportDialog from "../campaign/updateReportDialog"
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

class CampaignReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      currentLineItem: -1,
      campaign: [],
      lineItems: [],
      reportLineItems: [],
      selectedField: campaignReportFields[0].value,
      campaignId: props.match.params.id,
      // campaign: {
      //   lineItems: []
      // }

    };
    this.changeState = this.changeState.bind(this);
    this.renderChartData = this.renderChartData.bind(this);
    this.changeChartFilter = this.changeChartFilter.bind(this);
    this.handleLineItems = this.handleLineItems.bind(this);
  }

  changeState = (key, value) => {
    this.setState({ [key]: value });
  };

  UNSAFE_componentWillMount() {
    this.getCampaignData()
    let campaign = campaignVar();
    this.setState({ campaign });
    let numbers = [];
    let days = 8
    for (let i = 1; i <= days; i++) {
      numbers.push(i);
    }
    this.setState({ labels: numbers }, () => this.renderChartData());
  }

  renderChartData = filter => {
    const { lineItems, currentLineItem } = this.state
    let values = []

    // If clearing the lineitems filter, we empty the values
    if (currentLineItem === -1) {
      values = Array.from({ length: 10 }, () => Math.floor(Math.random() * 10));
    } else if (lineItems !== undefined && lineItems.length !== 0){
      //let max = 1999999;
      //let min = 1000;
      if (filter === "impressionsDelivered") {
        values = lineItems[currentLineItem][filter]
        //max = 9000000;
        //min = 1000;
      }
      if (filter === "budgetDelivered") {
        values = lineItems[currentLineItem][filter]
        //max = 1000;
        //min = 0;
      }
      if (filter === "clicks") {
        values = lineItems[currentLineItem][filter]
        //max = 100000;
        //min = 100;
      }
      if (filter === "conversions") {
        values = lineItems[currentLineItem][filter]
        //max = 1000;
        //min = 0;
      }
    }

    if (lineItems === undefined || lineItems.length === 0) {
      values = Array.from({ length: 10 }, () => Math.floor(Math.random() * 10));
    }

    let data = [];
    this.state.labels.map((item, index) => {
      data.push(values[index])
      return null;
    })
    this.setState({ series: data });
  };

  changeChartFilter = filter => {
    this.changeState("selectedField", filter);
    this.renderChartData(filter);
  };

  handleLineItems = item => {
    if (item === null || item === undefined) {
      this.setState({ currentLineItem: -1 })
    } else {
      this.setState({ currentLineItem: item.value })
    }
  };

  getCampaignData() {
    getCampaign(this.state.campaignId, client)
      .then((res) => {
        let reportLineItems = []
        this.setState({ lineItems: res.lineItems, campaign: res })
        res.lineItems.map((item) => {
          if (item.schedule)
            for (let i = 0; i < item.schedule; i++) reportLineItems.push(
              {
                "day": i + 1,
                "name": item.name,
                "budgetPlanned": item.budgetPlanned,
                "budgetDelivered": item.budgetDelivered[i],
                "impressionsPlanned": item.impressionsPlanned,
                "impressionsDelivered": item.impressionsDelivered[i],
                "clicks": item.clicks[i],
                "conversions": item.conversions[i],
                "type": item.type,
                "cpm": item.cpm,
                "placement": item.placement,
                "delivery": item.delivery,
                "schedule": item.schedule,
                "tag": item.tag,
                "recency": item.recency,
                "status": item.status,
                "creatives": item.creatives
              }
            )
          else
            reportLineItems.push(
              {
                "day": 1,
                "name": item.name,
                "budgetPlanned": item.budgetPlanned,
                "budgetDelivered": item.budgetDelivered,
                "impressionsPlanned": item.impressionsPlanned,
                "impressionsDelivered": item.impressionsDelivered,
                "clicks": item.clicks,
                "conversions": item.conversions,
                "type": item.type,
                "cpm": item.cpm,
                "placement": item.placement,
                "delivery": item.delivery,
                "schedule": item.schedule,
                "tag": item.tag,
                "recency": item.recency,
                "status": item.status,
                "creatives": item.creatives
              }
            )
            return null;
        })
        this.setState({ reportLineItems: reportLineItems })
      })

      .then(() => {
        let i = this.state.campaign.duration.split(" ")
        let duration = parseInt(i[0])
        let numbers = []
        for (let i = 1; i <= duration; i++) {
          numbers.push(i);
        }
        this.setState({ labels: numbers }, () => this.renderChartData());
      }
      )

  }



  updateCampaignLineItems(data) {
    const listOfListItems = [];
    let indexOfListItems = 0;

    data.map((item) => {
      let isPresent = false;

      if(listOfListItems.length === 0) {
        listOfListItems.push({...item})
        delete listOfListItems[indexOfListItems].day;
        listOfListItems[indexOfListItems].impressionsDelivered = []
        listOfListItems[indexOfListItems].budgetDelivered = []
        listOfListItems[indexOfListItems].clicks = []
        listOfListItems[indexOfListItems].conversions = []
      }

      listOfListItems.forEach(element => {
        if(element.name === item.name) {
          isPresent = true
        }
      });

      if(!isPresent) {
        listOfListItems.push({...item});
        indexOfListItems = indexOfListItems + 1;
        delete listOfListItems[indexOfListItems].day;
        listOfListItems[indexOfListItems].impressionsDelivered = []
        listOfListItems[indexOfListItems].budgetDelivered = []
        listOfListItems[indexOfListItems].clicks = []
        listOfListItems[indexOfListItems].conversions = []
      }

      if(isPresent) {
        listOfListItems[indexOfListItems].impressionsDelivered.push(item.impressionsDelivered)
        listOfListItems[indexOfListItems].budgetDelivered.push(item.budgetDelivered)
        listOfListItems[indexOfListItems].clicks.push(item.clicks)
        listOfListItems[indexOfListItems].conversions.push(item.conversions)
      } else if (!isPresent) {
        listOfListItems[indexOfListItems].impressionsDelivered.push(item.impressionsDelivered)
        listOfListItems[indexOfListItems].budgetDelivered.push(item.budgetDelivered)
        listOfListItems[indexOfListItems].clicks.push(item.clicks)
        listOfListItems[indexOfListItems].conversions.push(item.conversions)
      }
      return null;
    })

    updateCampaign(this.state.campaignId, listOfListItems, client).then(window.location.reload())
  }

  render() {
    const id = this.state.campaignId;
    return (
      <SandboxLayout name="Campaign Report" layoutData={campaignAdsManagerData}>
        <Grid container>
          <Grid item xs={9}>
            <div>
              {campaignReportFields.map(item => {
                return (
                  <span
                    underline={
                      item.value === this.state.selectedField
                        ? "always"
                        : "hover"
                    }
                    style={margin50}
                    onClick={() => this.changeChartFilter(item.value)}
                    component="button"
                  >
                    {item.label}
                  </span>
                );
              })}
            </div>
            <div id="roundedLineChart" className="ct-chart">
              <LineChart data={this.state.series} labels={this.state.labels} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5">Filters</Typography>
            {this.state.campaign.lineItems && (
              <Autocomplete
                key="21"
                values={this.state.campaign.lineItems.map((value, index) => ({
                  value: index,
                  label: value.name
                }))}
                // defaultValue={this.state.lineItems[0] ? this.state.lineItems[0].name : false}
                placeholder="All line Items"
                handleChange={item => {
                  this.handleLineItems(item)
                }
                }
              />
            )}
          </Grid>
        </Grid>
        <Grid>
          <Link
            to={{
              pathname: `/campaign/update/${id}`,
              params: { id }
            }}
            className="btn btn-link"
          >
            <button className="btn btn-fill btn-rose">BACK TO CAMPAIGN</button>
          </Link>

          <CSVLink data={this.state.reportLineItems} separator={";"} filename={`${this.state.campaign.name}_report.csv`}>
            <button className="btn btn-fill btn-rose">Export Report</button>
          </CSVLink>
          <button className="btn btn-fill btn-rose" onClick={() => this.setState({ updateDialog: true })}>Upload Simulation Data</button>
          <UpdateReportDialog
            open={this.state.updateDialog}
            handleUndo={() => this.setState({ updateDialog: false })}
            handleSave={(data) => this.updateCampaignLineItems(data)}
            dialogTitle={"Update campaign report"}
          >
            Update LineItems Report
            </UpdateReportDialog>
        </Grid>
      </SandboxLayout>
    );
  }
}

export default CampaignReport;
