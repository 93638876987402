import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputLabel, Select, FormHelperText, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  label: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  selectControl: {
    minWidth: 240,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.grey[800],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[800],
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
    '&:focus':{
      backgroundColor:'white'
    }
  },
}));

const SelectField = ({ fieldName, fieldLabel, fieldValue, fieldHandleChange, fieldError, fieldHelperText, fieldSelectList }) => {
  const classes = useStyles();

  return (
    <Box>
      <FormControl
        variant="outlined"
        fullWidth
        className={classes.selectControl}
        error={fieldError}
      >
        <InputLabel
          htmlFor={fieldName}
          error={fieldError}
          className={classes.label}
        >{fieldLabel}</InputLabel>
        <Select
          labelId={fieldName}
          id={fieldName}
          name={fieldName}
          value={fieldValue}
          onChange={fieldHandleChange}
          label={fieldLabel}
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null
          }}
          classes={{
            root: classes.selectRoot,
          }}
        >
          {fieldSelectList.map(selectItem => {
            return (
              <MenuItem
                key={selectItem._id ? selectItem._id : selectItem}
                value={selectItem.name ? selectItem.name : selectItem}
              >{selectItem.name ? selectItem.name : selectItem}
              </MenuItem>
            )
          })}
        </Select>
        <FormHelperText
          id={fieldName}
          error={fieldError}
        >{fieldHelperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

export default SelectField;
