import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, useMediaQuery } from '@material-ui/core';
import gql from "graphql-tag";
import { useQuery } from '@apollo/client';
import { Cookies } from "react-cookie";
import {client} from "../../settings/apollo";
import { withRouter } from "react-router";
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import SubscriptionButton from '../../common/components/SubscriptionButton/SubscriptionButton';
import SubscriptionDialog from '../../common/components/Dialogs/SubscriptionDialog/SubscriptionDialog';
import HideView from '../../common/components/HideView/HideView';
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';

const GET_USER_DATA = gql`
  query me {
    me {
      userType
      trialExpires
    }
  }
`;

const cookie = new Cookies();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: 30,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    width: 'calc(100% - 240px)',
    maxWidth:1570 + theme.spacing(4) * 2,
    minHeight: 'calc(100vh - 64px)',
    margin: 'auto',
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingBottom:120,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

const SandboxLayout = (props) => {
  const { window, history, name, isValidation, challengeMode, layoutData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const mediaQuerySm = setIsMobile
  const mediaQueryMd = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const {
    loading,
    error,
    data
  } = useQuery(GET_USER_DATA);

  const me = data?.me;
  const enablePrice = me ? (me.userType === 'b2c-trial' || me.userType === 'b2b-expired') : null;
  const isTrial = me ? me.userType === 'b2c-trial' : null;

  const setIsMobile = () => {
    const userAgent = window.navigator.userAgent;
    if (/mobile|tablet/i.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  const subscriptionButtonAction = () => {
    setDialogOpen(true);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleLogout = () => {
    cookie.remove("jwt");
    client.resetStore();
  }

  let subscriptionEl = null;
  if (loading) {
    subscriptionEl = <LoadingSpinner />;
  } else if (error) {
    subscriptionEl = `Error! ${error.message}`;
  } else {
    if(isTrial) {
      subscriptionEl = (
        <SubscriptionButton
          textButton={<>{me.trialExpires} left to complete Digital Marketing Essentials. Want more Challenges?<strong style={{ marginLeft: 4, textDecoration: 'underline' }}>TRY PREMIUM!</strong></>}
          action={subscriptionButtonAction}
        />
      )
    }
  }

  // Hide content on mobile/tablet in the page specified on the page array,
  // validation challenges and b2b challenge section.
  let content = null;
  const pages = ['Campaigns', 'Creatives', 'Advertiser', 'Advertisers', 'Placements', 'Placement', 'Lists', 'automation'];
  if (
    (name === 'Challenges' && mediaQuerySm) ||
    (name === 'Challenge' && mediaQuerySm && isValidation === true) ||
    (pages.includes(name) && mediaQuerySm)
  ) {
    content = props.children;
  } else {
    content = props.children;
  }

  // Specific styles added for the Workflow Tree in Automation feature, look on /crm/automation/:id/edit - /crm/automation/:id/simulation
  const currentRoute = props.location.pathname;
  const isWorkflowPage = currentRoute.includes('crm/automation') && currentRoute.includes('edit');
  const isSimulationPage = currentRoute.includes('crm/automation') && currentRoute.includes('simulation');
  let styles = null;
  if (isWorkflowPage || isSimulationPage) {
    styles = { height: '100%' };
  } else {
    styles = null;
  }

  return(
    <div className={classes.root} style={styles}>
      <Header
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
        title={layoutData.title}
        media={{ src: layoutData.media.src, radialGradient: layoutData.media.radialGradient }}
        challengeMode={challengeMode}
        subscriptionEl={mediaQueryMd ? null : subscriptionEl}
      />
      <Sidebar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        window={window}
        title={layoutData.title}
        items={layoutData.items}
        challengeMode={challengeMode}
        history={history}
        btnRoute={layoutData.sdbInfoRoute}
      />
      <main className={classes.content} style={styles}>
        <div className={classes.appBarSpacer} />
        {mediaQueryMd &&
          <Box display="flex" justifyContent="flex-end" mb={1}>
            {subscriptionEl}
          </Box>
        }
        {content}
      </main>
      <SubscriptionDialog
        dialogClose={handleDialogClose}
        dialogOpen={dialogOpen}
        dialogLocation={history.location}
        enablePrice={enablePrice}
      />
    </div>
  );
};

export default withRouter(SandboxLayout);
