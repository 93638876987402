import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { GoogleLogin } from '@react-oauth/google';
import { Cookies } from "react-cookie";
import { ApolloClient } from "@apollo/client";
import { apolloConfig } from "../../settings/apollo";

const client = new ApolloClient(apolloConfig);

const LOGIN_WITH_GOOGLE = gql`
  mutation loginWithGoogle($googleLoginResponse: GoogleLoginResponseInput!) {
    loginWithGoogle(googleLoginResponse: $googleLoginResponse) {
      _id
      userRole
      token
    }
  }
`;

const LOGGED_USER = gql`
  query loggedUser {
    user {
      username,
      role
    }
  }
`;

const Google = () => {
  const [loginWithGoogle] = useMutation(LOGIN_WITH_GOOGLE, {client});

  
  const handleGoogleLogin = (googleLoginResponse) => {
    // Perform the login with Google
    loginWithGoogle({
      variables: {
        googleLoginResponse: googleLoginResponse,
      },
    })
      .then(async (result) => {
        // Handle the login success
        const user = result.data.loginWithGoogle;
        await afterLogin(
          {username: user._id,
          role: user.userRole}, 
          user.token)      })
      .catch((error) => {
        // Handle the login error
        console.error('Login with Google error:', error);
      });
  };

  const afterLogin = async function({username,role}, token){
    const cookies = new Cookies();
    cookies.set('jwt', token);
    client.writeQuery({
      query : LOGGED_USER,
      data: {
        user: {
          username,
          role,
        },
      },
    });
    window.location.assign('/')
  }
  

  return (
    <GoogleLogin
      clientId="918629219103-d59k7qnkieum6rejoin797p9dbk2vsvu.apps.googleusercontent.com"
      buttonText="Sign in with Google"
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleLogin}
      cookiePolicy="single_host_origin"
    />
  );
};

export default Google;
