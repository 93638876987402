import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Icon } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    padding: theme.spacing(2.5, 0, 3.5, 0),
    borderRadius: 8,
  },
  dialogTitle: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1.2),
    '& h2': {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3,
    }
  },
  dialogContent: {
    padding: theme.spacing(0, 2, 0, 2)
  },
  dialogActions: {
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(3),
    //paddingBottom: theme.spacing(3.5),
  },
  icon: {
    fontSize: theme.typography.pxToRem(70),
  },
  successGreenColor: {
    color: theme.palette.success.main
  },
  warningColor: {
    color: theme.palette.warning.main
  },
  errorColor: {
    color: theme.palette.error.main
  },
  workflowIcon: {
    fontSize: theme.typography.pxToRem(64),
    color: theme.palette.common.gamsDarkBlue
  },
  submitBtn: {
    margin:'0 auto',
    marginTop:25,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    textTransform:'none',
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
}));

const SandboxDialog = ({ dialogOpen, dialogClose, dialogTitle, dialogMessage, dialogActions, successIcon, id, warningIcon, errorIcon, workflowIcon, maxWidth, icon, iconStyles, children, dialogOnExited }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth ? maxWidth : 'sm'}
      id={id}
      open={dialogOpen}
      TransitionProps={{
        onExited: dialogOnExited
      }}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{paper: classes.dialogRoot}}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box>
            {successIcon && <CheckCircleIcon className={`${classes.icon} ${classes.successGreenColor}`}/>}
            {warningIcon && <WarningIcon className={`${classes.icon} ${classes.warningColor}`}/>}
            {errorIcon && <ErrorIcon className={`${classes.icon} ${classes.errorColor}`}/>}
            {workflowIcon && <AutorenewIcon className={`${classes.workflowIcon}`}/>}
            {icon && <Icon className={`${classes.icon}`} style={iconStyles}>{icon}</Icon>}
          </Box>
          <Typography variant="h2" component="h2">{dialogTitle}</Typography>
        </Box>
      </DialogTitle>
      {children}
      {dialogMessage &&
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" component="div">{dialogMessage}</Typography>
      </DialogContent>}
      {dialogActions &&
      <DialogActions className={classes.dialogActions}>
        {dialogActions}
      </DialogActions>}
    </Dialog>
  );
}

export default SandboxDialog;
