import React from 'react';
//import Layout from '../../common/Layout';
import {Formik} from 'formik';
import {Mutation} from "@apollo/client/react/components";
import PlacementForm from './PlacementForm';
import {Query} from "@apollo/client/react/components";
import {withRouter} from 'react-router';
//import {validatePlacement} from '../Validations';
import {UPDATE_PLACEMENT, GET_PLACEMENT} from "./queries";
import * as Yup from 'yup';
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const placementSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a name.'),
  size: Yup.string()
    .required('Please select a size.'),
});

class PlacementUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id
    }
  }

  render() {
    const id = {id: this.state.id};

    return (
      <SandboxLayout name="Placements" layoutData={campaignAdsManagerData}>
        <div class="wizard-container">
          <div class="col-md-8 col-12 mr-auto ml-auto">
            <div class="card card-wizard active" data-color="rose" id="wizardProfile">
              <Query query={GET_PLACEMENT} fetchPolicy="network-only" variables={id}>
                {({loading, error, data}) => {
                  if (loading) return <LoadingSpinner styles={{ paddingTop: 60 }} />;
                  if (error) return <p>Error :(</p>;
                  return (
                    <Mutation mutation={UPDATE_PLACEMENT}>
                      {updatePlacement => (
                        <Formik
                          initialValues={{...data.placement}}
                          //validate={validatePlacement}
                          validationSchema={placementSchema}
                          onSubmit={(values, {setSubmitting}) => {
                            console.log(this.state.size);
                            setSubmitting(false);
                            updatePlacement({
                              variables: {
                                input: {
                                  _id: data.placement._id,
                                  name: values.name,
                                  subPlacementName: values.subPlacementName,
                                  parent: values.parent,
                                  size: values.size
                                }
                              }
                            }).catch((error) => {
                              console.log(error);
                            }).then(() => this.props.history.push('/placements'));
                          }}
                        >
                          {({isSubmitting, values, handleChange, setFieldValue, errors, touched}) => (
                            <PlacementForm
                              update='True'
                              isSubmitting={isSubmitting}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              errors={errors}
                            />
                          )}
                        </Formik>
                      )}
                    </Mutation>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(PlacementUpdate);
