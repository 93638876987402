// import TableRow from "@material-ui/core/TableRow";
// import React from "react";
// import TableCell from "@material-ui/core/TableCell";

export const schedules = [
  {label: 'Standard Campaign'},
  {label: 'Tracking'},
  {label: 'Click Traking'}
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

export const durations = [
  {value: 1, label: '1 day'},
  {value: 3, label: '3 days'},
  {value: 7, label: '7 days'},
  {value: 14, label: '14 days'},
  {value: 30, label: '30 days'},
];

export const statusesEnum = {
  ACTIVE: 'Active',
  PAUSED: 'Paused',
  CLOSED: 'Closed'
}

export const statuses = [
  {label: statusesEnum.ACTIVE, color: "#008000"},
  {label: statusesEnum.PAUSED, color: "#FFD700"},
  {label: statusesEnum.CLOSED, color: "#FF0000"}
]

export const campaignReportFields = [
  {label: 'Imps Delivered', value: 'impressionsDelivered'},
  {label: 'Budget Delivered', value: 'budgetDelivered'},
  {label: 'Clicks', value: 'clicks'},
  {label: 'Conversions', value: 'conversions'}
]

export const campaignReportFilters = ['lineItems', 'creatives', 'placement']

export const delivery = ['ASAP', 'Evenly']

export const recency = [
  {value: 1, label: '1 hour'},
  {value: 3, label: '3 hours'},
  {value: 12, label: '12 hours'},
  {value: 24, label: '24 hours'},
  {value: 48, label: '48 hours'},
  {value: 'lifetime', label: 'lifetime'}
]

export const defaultActionColumn ='action'

export const lineItemGridColumns = ['name', 'budgetPlanned', 'budgetDelivered', 'impressionsDelivered', 'clicks', 'conversions', 'status', defaultActionColumn]

//export const challengesToDoColumns = ['label', 'name', 'difficulty']
export const challengesToDoColumns = ['challengeTitle', 'challengeDifficulty']

export const challngesToDoNameColumns = ['Title', 'Difficulty']

export const challengesExpiredColumns = ['number', 'name', 'difficulty']

export const challengesCompletedColumns = ['name', 'date', 'assignedBy', 'score']

export const creativesGridColumns = ['adsName', 'creative', 'status', 'url', defaultActionColumn]

export const tagBaseUrl = 'http://gams.swordprinciples.com/lineitem'

export const demoPaths = [
  {
    name: "Create advertiser",
    url: "/advertisers?appcue=-Lh5Jpvnn-Edsih5u9wE"
  },
  {
    name: "Create campaign",
    url: "/campaigns?appcue=-LiA6_1IX2_XqBYIvy40"
  }
]

