import gql from "graphql-tag";

export const GET_CAREERSINGLE_PAGE = gql`
  query {
    careerSinglePage {
      data {
        attributes {
          subPageHeader {
            title
            subTitle
          }
          subscriptionAlert {
            title
            subTitle
            textButton
          }
        }
      }
    }
  }
`;

export const GET_CAREER_BY_SLUG = gql`
  query career($slug: String!) {
    careerPathBySlug(slug:$slug){
          name
          startLevel
          status
          description
          cover {
            data {
              attributes {
                name
                url
              }
            }
          }
          aboutCareer {
            content
          }
          keySkills {
            content
          }
          videoLink
          tags {
            name
          }
          badges
          totalLearningTopics
          totalFinishedLearningTopics
          totalChallenges
          totalFinishedChallenges
          obtainableScore
          score
          toolsUsed {
            title
            link
            description
            icon {
              data {
                attributes {
                  name
                  url
                }
              }
            }
          }
          learningTopics {
            name
            level
            levelName
            challenges {
              challengeSortNumber
              challengeName
              challengeTitle
              challengeDifficulty
              challengeTags
              id
              status
              canRestart
              isValidation
              averageTime,
              timeToRestart,
              coach {
                name
                surname
                email
                photo
                linkedin
              }
            }
          }
        }
      }
`;

export const GET_USER_DATA = gql`
  query me {
    me {
      userType
      trialExpires
    }
  }
`;

export const GET_GLOBALCONTENT = gql`
  query globalContent {
    globalContent {
      data {
        attributes {
          subscriptionDialog {
            title
            description
            ctaFaqs {
              link
              text
              textLink
            }
          }
          pricingPlan {
            title
            planType
            description
            discountText
            b2bLicenceText
            featureList {
              text
            }
          }
        }
      }
    }
  }
`;
