import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_RANKING_CHALLENGE, GET_RANKING_GROUP, GET_RANKING_OVERALL, GET_USER_DATA, GET_USER_STATISTICS, GET_CHALLENGE_DEFINITIONS_ASSIGNED, GET_GLOBALCONTENT } from "./graphql/queries";
import Layout from '../../common/Layout';
import UserHeader from '../../common/components/UserHeader/UserHeader';
import RankingBlock from '../../common/components/RankingBlock/RankingBlock';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import SubscriptionDialog from '../../common/components/Dialogs/SubscriptionDialog/SubscriptionDialog';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom:50
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginBottom:20,
  },
}));

const GridWithXXL = ({ xxl, ...other }) => { // Used to add extra breakpoint to Grid
  const xxlClass = `MuiGrid-grid-xxl-${xxl}`;
  return <Grid className={xxlClass} {...other} />;
};

const Dashboard = props => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    loading: cdaLoading,
    error: cdaError,
    data: cdaData
  } = useQuery(GET_CHALLENGE_DEFINITIONS_ASSIGNED);
  const challengeValue = cdaData?.challengeDefinitionsAssigned[0]?.id;

  const {
    loading: uLoading,
    error: uError,
    data: uData
  } = useQuery(GET_USER_DATA);
  const careerValue = uData?.me.courseGroups[0]?.name;

  const {
    loading: rcLoading,
    error: rcError,
    data: rcData,
    refetch: rcRefetch,
  } = useQuery(GET_RANKING_CHALLENGE, {
    skip: !challengeValue,
    variables: { challengeType: challengeValue },
  });

  const {
    loading: rgLoading,
    error: rgError,
    data: rgData,
    refetch: rgRefetch,
  } = useQuery(GET_RANKING_GROUP, {
    skip: !careerValue,
    variables: { groupName: careerValue },
  });

  const {
    loading: usLoading,
    error: usError,
    data: usData
  } = useQuery(GET_USER_STATISTICS, {
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: roLoading,
    error: roError,
    data: roData
  } = useQuery(GET_RANKING_OVERALL);

  const {
    loading: gcLoading,
    error: gcError,
    data: gcData
  } = useQuery(GET_GLOBALCONTENT);

  const globalContent = gcData?.globalContent?.data?.attributes;
  const userData = uData && uData.me;
  const userStatistics = usData && usData.myStatistics;
  const rankingOverall = roData && roData.rankingOverall;
  const rankingChallenge = rcData && rcData.rankingChallenge;
  const rankingGroup = rgData && rgData.rankingGroup;

  const isTrial = userData ? userData.userType === 'b2c-trial' : null;
  const enablePrice = userData ? (userData.userType === 'b2c-trial' || userData.userType === 'b2b-expired') : null;

  const menuListChallenge = cdaData && cdaData.challengeDefinitionsAssigned.map(item => {
    return {
      value: item.id,
      title: item.challengeTitle
    }
  })

  const menuListCareer = userData && userData.courseGroups.map(item => {
    return {
      value: item.name,
      title: item.name
    }
  })

  const changeChallenge = (event) => {
    rcRefetch({ challengeType: event.target.value })
  };

  const changeGroup = (event) => {
    rgRefetch({ groupName: event.target.value})
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Layout>
      {uLoading || usLoading || gcLoading ? (
        <LoadingSpinner boxed={true} styles={{ paddingTop: 30 }} />
      ) : uError || usError || gcError ? (
        <div>Error! {uError && uError.message} - {usError && usError.message} - {gcError && gcError.message}</div>
      ) : (
      <>
        {isTrial ?
          <SubscriptionDialog
            dialogClose={handleDialogClose}
            dialogOpen={dialogOpen}
            enablePrice={enablePrice}
            title={globalContent.subscriptionDialog.title}
            subTitle={globalContent.subscriptionDialog.description}
            pricingPlan={globalContent.pricingPlan}
            ctaFaqs={globalContent.subscriptionDialog.ctaFaqs}
          />
        : null}
        <UserHeader
          name={userData.name}
          photo={userData.photo}
          overall={userStatistics.totalScore}
          totalChallenges={userStatistics.totalFinishedChallenges}
          totalLearningTopics={userStatistics.totalFinishedLearningTopics}
          totalCareers={userStatistics.totalFinishedCareers}
          badgeEarned={userStatistics.gainedBadges}
        />
      </>
      )}

      <section className={classes.section}>
        <Typography className={classes.sectionTitle} variant="h3">
          Rankings
        </Typography>
        <Grid container spacing={3}>

          {roLoading ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <LoadingSpinner boxed={true} styles={{ paddingTop: 30 }} />
            </GridWithXXL>
          ) : roError ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <div>Error! {roError.message}</div>
            </GridWithXXL>
          ) : (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <RankingBlock
                title="Overall Ranking"
                menu={false}
                dataTable={rankingOverall}
                openDialog={handleDialogOpen}
                isTrial={isTrial}
              />
            </GridWithXXL>
          )}

          {rcLoading || cdaLoading ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <LoadingSpinner boxed={true} styles={{ paddingTop: 30 }} />
            </GridWithXXL>
          ) : rcError || cdaError ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <div>Error! {rcError.message} - {cdaError.message}</div>
            </GridWithXXL>
          ) : (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <RankingBlock
                title="Challenge Ranking"
                menu={true}
                dataMenu={menuListChallenge}
                dataTable={rankingChallenge}
                onSelectChange={changeChallenge}
                openDialog={handleDialogOpen}
                isTrial={isTrial}
              />
            </GridWithXXL>
          )}

          {rgLoading || uLoading ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <LoadingSpinner boxed={true} styles={{ paddingTop: 30 }} />
            </GridWithXXL>
          ) : rgError || uError ? (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <div>Error! {rgError.message} - {uError.message}</div>
            </GridWithXXL>
          ) : (
            <GridWithXXL item xs={12} lg={6} xxl={4}>
              <RankingBlock
                title="Career Ranking"
                menu={true}
                dataMenu={menuListCareer}
                dataTable={rankingGroup}
                onSelectChange={changeGroup}
                openDialog={handleDialogOpen}
                isTrial={isTrial}
              />
            </GridWithXXL>
          )}

        </Grid>
      </section>
    </Layout>
  )

}

export default Dashboard;
