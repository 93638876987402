import React, { useState } from 'react';
import MainMenu from './MainMenu';
import gql from "graphql-tag";
import ReactMarkdown from 'react-markdown';
import { useQuery } from '@apollo/client';
import { Cookies } from "react-cookie";
import {client} from "../settings/apollo";
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SubscriptionButton from '../common/components/SubscriptionButton/SubscriptionButton';
import SubscriptionDialog from '../common/components/Dialogs/SubscriptionDialog/SubscriptionDialog';
import HideView from '../common/components/HideView/HideView';
import { withRouter } from "react-router";
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import Banner from './components/Banner/Banner';
import { SECTIONS_AVAILABLE } from '../components/Navigation';
import { SHOW_BANNER } from '../components/Banners/graphql/queries';

const GET_USER_DATA = gql`
  query me {
    me {
      email
      userType
      trialExpires
    }
  }
`;

const GET_GLOBALCONTENT = gql`
  query globalContent {
    globalContent {
      data {
        attributes {
          trialBanner {
            textBanner
          }
          subscriptionDialog {
            title
            description
            ctaFaqs {
              link
              text
              textLink
            }
          }
          pricingPlan {
            title
            planType
            description
            discountText
            b2bLicenceText
            featureList {
              text
            }
          }
        }
      }
    }
  }
`;

const cookie = new Cookies();

const Layout = (props) => {
  const section = SECTIONS_AVAILABLE.find(element => props.match.path.includes(element.value))
  const sectionBanner = props.match.path !== '/' ? (section ? section.value : '') : '/';
  const theme = useTheme();
  const mediaQuery = setIsMobile;
  const [dialogOpen, setDialogOpen] = useState(false);
  const cookieBanners = cookie.get('banners');

  const {
    location,
    hideMenu,
    name,
    isValidation,
  } = props;

  const setIsMobile = () => {
    const userAgent = window.navigator.userAgent;
    if (/mobile|tablet/i.test(userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  const { loading, error, data } = useQuery(GET_USER_DATA);

  const bannerData = useQuery(SHOW_BANNER, { variables: { section: sectionBanner } });

  const { loading: gcLoading, error: gcError, data: gcData } = useQuery(GET_GLOBALCONTENT);

  const globalContent = gcData?.globalContent?.data?.attributes;
  const me = data?.me;
  const enablePrice = me ? (me.userType === 'b2c-trial' || me.userType === 'b2b-expired') : null;
  const isTrial = me ? me.userType === 'b2c-trial' : null;

  const subscriptionButtonAction = () => {
    setDialogOpen(true);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  let subscriptionEl = null;
  if (loading || gcLoading) {
    subscriptionEl = '';
  } else if (error || gcError) {
    subscriptionEl = `Error! ${error && error.message} ${gcError && gcError.message}`;
  } else {
    if(isTrial) {
      subscriptionEl = (
        <SubscriptionButton
          textButton={<>{me.trialExpires}<ReactMarkdown children={globalContent.trialBanner.textBanner} /></>}
          action={subscriptionButtonAction}
        />
      )
    }
  }

  // Hide content on mobile/tablet in the page specified on the page array,
  // validation challenges and b2b challenge section.
  let content = null;
  const pages = ['Campaigns', 'Creatives', 'Advertiser', 'Advertisers', 'Placements', 'Placement'];
  if (
    (name === 'Challenges' && mediaQuery) ||
    (name === 'Challenge' && mediaQuery && isValidation === true) ||
    (pages.includes(name) && mediaQuery)
  ) {
    content = props.children;
  } else {
    content = props.children;
  }

  const logout = function() {
    window.FB.logout();
    cookie.remove("jwt");
    client.resetStore();
  }

  const routePathname = props.history.location.pathname.split("/").filter(x => x);

  return (
    <div className="wrapper">
      {hideMenu === true ? null
      : <MainMenu onLogout={logout} /> }
      <div className="main-panel" style={hideMenu === true ? { width: '100%'} : null}>
        { bannerData.data &&
          bannerData.data.showBanner.map((item) => {
            if (cookieBanners && cookieBanners.includes(item._id)) return '';
            return (<Banner
              text={item.body}
              id={item._id}
            />)
          })
        }
        <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute /*fixed-top*/">
          <div className="container-fluid" style={ mediaQuery ? { justifyContent: 'flex-end' } : null }>
            <div className="navbar-wrapper">
            {name === 'Challenge' ?
              null :
              <div className="navbar-minimize">
                <button id="minimizeSidebar" className="btn btn-just-icon btn-white btn-fab btn-round">
                  <i className="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                  <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
              </div>
            }
              <b>{name === 'Challenge' ? '' : name}</b>
            </div>
            { mediaQuery ? // show the subscription element on mobile
              subscriptionEl
            : null }
            {hideMenu === true ? null :
            <button className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
            </button>}
            <div className="collapse navbar-collapse justify-content-end">
              <ul className="navbar-nav">
                <li className="nav-item">
                { mediaQuery ? null : // show the subscription element on desktop
                  subscriptionEl
                }
                </li>
                {hideMenu === true ? null :
                <li className="nav-item">
                  <Link className="nav-link" component={RouterLink} to="/help">
                    <i className="material-icons">help_outline</i>
                    <p className="d-lg-none d-md-block">Help</p>
                  </Link>
                </li>}
                {hideMenu === true ? null :
                <li className="nav-item dropdown">
                  <a className="nav-link" href="#pablo" id="navbarDropdownProfile" data-toggle="dropdown" aria-expanded="false">
                    <i className="material-icons">person</i>
                    <p className="d-lg-none d-md-block">Account</p>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <a className="dropdown-item" href="/profile">Profile</a>
                    <a className="dropdown-item" href="/settings">Settings</a>
                    <a className="dropdown-item" href="/subscription">Subscription</a>
                    <div className="dropdown-divider" />
                    <a className="dropdown-item" href="/login" onClick={logout}>Log out</a>
                  </div>
                </li>}
              </ul>
            </div>
          </div>
        </nav>
        <div className={name === 'Challenge' ? "content_challenge" : "content"}>
          <div className={name === 'Challenge' ? "container-fluid_challenge" : "container-fluid gams-container"}>
            {routePathname.includes('ads') || routePathname.includes('end') || routePathname.length === 0 ? null : <Breadcrumbs />}
            {content}
          </div>
        </div>
      </div>
      <footer className="footer"></footer>
      { loading || gcLoading ? (null) : error || gcError ? (
        <div>Error! {error && error.message} {gcError && gcError.message}</div>
      ) : (
        <SubscriptionDialog
          dialogClose={handleDialogClose}
          dialogOpen={dialogOpen}
          dialogLocation={location}
          enablePrice={enablePrice}
          title={globalContent.subscriptionDialog.title}
          subTitle={globalContent.subscriptionDialog.description}
          pricingPlan={globalContent.pricingPlan}
          ctaFaqs={globalContent.subscriptionDialog.ctaFaqs}
        />
      )}
    </div>
  );
}

export default withRouter(Layout);
