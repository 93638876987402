import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopicBlock from './TopicBlock/TopicBlock';
import MetaSkills from './MetaSkills/MetaSkills';
import PdfPscProfile from '../PscProfile/PdfPscProfile';
import PartnersBlock from '../../../common/components/PartnersBlock/PartnersBlock';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop:30,
    pageBreakInside: 'avoid',
  },
}));

const PdfLearningTopics = ({ careerPaths, urlPath, expirationDate, serialNumber, username, badges, photo }) => {
  const classes = useStyles();

  let careerPathBlock = 'No Career Path...';
  if (careerPaths) {
    careerPathBlock = careerPaths.map((careerPath, index) => {
      return (
        <div className={classes.root} key={`pdfTp-${index}`}>
          <PdfPscProfile
            expirationDate={expirationDate}
            serialNumber={serialNumber}
            username={username}
            badges={badges}
            photo={photo} />
          <TopicBlock careerpathName={careerPath.name}>
            <MetaSkills scores={careerPath.scores} urlPath={urlPath} inPdf={true} />
          </TopicBlock>
          <Divider style={{ textAlign:'center', maxWidth: 960, margin:'0 auto', marginTop:110, marginBottom:50 }} />
          <PartnersBlock small={true} />
        </div>
      );
    })
  }

  return (
    <>
      {careerPathBlock}
    </>
  );
}

export default PdfLearningTopics;
