import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { useCookies } from "react-cookie";
import gql from "graphql-tag";
import { useLazyQuery } from '@apollo/client';
import {LinkedInCallback} from 'react-linkedin-login-oauth2';
//import Gams from "./Gams";
import CampaignAdServerStep1 from "./challenges/AdServer/Campaign/CampaignAdServerStep1";
import CampaignAdServerStep2 from "./challenges/AdServer/Campaign/CampaignAdServerStep2";
import CampaignAdServerStep3 from "./challenges/AdServer/Campaign/CampaignAdServerStep3";
import CampaignAdServerStep4 from "./challenges/AdServer/Campaign/CampaignAdServerStep4";
import CampaignAdServerStep5 from "./challenges/AdServer/Campaign/CampaignAdServerStep5";
import Login from "../components/Login/Login";
import Error from "../common/pages/Error";
import AdminRegister from "../common/pages/Register";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import Calendar from "../common/components/main/Calendar";
import EditUserProfile from "../common/pages/EditUserProfile";
import EditUserSettings from "../common/pages/EditUserSettings";
import Sample1 from "../common/components/charts/BarChart";
//import Home from "../common/pages/Home";
import CampaignInsert from "./campaign/CampaignInsert";
import Campaigns from "./campaign/CampaignList";
import CampaignUpdate from "./campaign/CampaignUpdate";
import AdvertiserInsert from "./advertiser/AdvertiserInsert";
import AdvertiserUpdate from "./advertiser/AdvertiserUpdate";
import CreativeList from "./creative/CreativeList";
import CreativeInsert from "./creative/CreativeInsert";
import CreativeUpdate from "./creative/CreativeUpdate";
import PlacementInsert from "./placement/PlacementInsert";
import PlacementList from "./placement/PlacementList";
import PlacementUpdate from "./placement/PlacementUpdate";
import UserGroupInsert from "./userGroup/UserGroupInsert";
import UserGroupList from "./userGroup/UserGroupList";
import UserGroupUpdate from "./userGroup/UserGroupUpdate";
import React, { useState } from "react";
import App from "../App";
import CampaignReport from "./reports/CampaignReport";
import AdvertisersContainer from "./advertiser/AdvertiserList.container";
import AdvertisersAdServer from "./challenges/AdServer/Advertisers/AdvertisersAdServerStep1";
import AdvertisersAdServerStep1 from "./challenges/AdServer/Advertisers/AdvertisersAdServerStep2";
import CreativesAdServerStep1 from "./challenges/AdServer/Creatives/CreativesAdServerStep1";
import CreativeAdServerStep2 from "./challenges/AdServer/Creatives/CreativeAdServerStep2";
import Users from "./users/UserList";
import ChangePassword from "./users/ChangePassword";
import ReportAdServerStep1 from "./challenges/AdServer/Report/ReportAdServerStep1";
import ReportAdServerStep2 from "./challenges/AdServer/Report/ReportAdServerStep2";
import ReportAdServerStep3 from "./challenges/AdServer/Report/ReportAdServerStep3";
import ReportAdServerStep4 from "./challenges/AdServer/Report/ReportAdServerStep4";
import ReportAdServerStep5 from "./challenges/AdServer/Report/ReportAdServerStep5";
import ReportAdServerStep6 from "./challenges/AdServer/Report/ReportAdServerStep6";
import ReportAdServerStep7 from "./challenges/AdServer/Report/ReportAdServerStep7";
import ReportAdServerStep8 from "./challenges/AdServer/Report/ReportAdServerStep8";
import ReportAdServerStep9 from "./challenges/AdServer/Report/ReportAdServerStep9";
import ReportAdServerStep10 from "./challenges/AdServer/Report/ReportAdServerStep10";
import ReportAdServerStep11 from "./challenges/AdServer/Report/ReportAdServerStep11";
import ReportAdServerStep12 from "./challenges/AdServer/Report/ReportAdServerStep12";
import ReportAdServerStep13 from "./challenges/AdServer/Report/ReportAdServerStep13";
import ReportAdServerStep14 from "./challenges/AdServer/Report/ReportAdServerStep14";
import Challenge02Step1 from "./challenges/AdServer/Placements/Challenge02Step1";
import Challenge02Step2 from "./challenges/AdServer/Placements/Challenge02Step2";
import AppraiseResult from "./challenges/AdServer/Appraise/AppraiseResult";
import UserProfile from "./users/UserProfile";
import ChallengesList from "./challenges/challengesToDo/challengesList"
import AdminSettings from "./adminSettings/AdminSettings"
//import SandboxTools from "./Gams";
import ProfileScoreCard from "./profileScoreCard/ProfileScoreCard";
import PublicProfileScoreCard from "./profileScoreCard/PublicProfileScoreCard";
import PdfProfileScoreCard from "./profileScoreCard/PdfProfileScoreCard";
import ImgProfileScoreCard from "./profileScoreCard/ImgProfileScoreCard";
import CareerList from "./CareerList/CareerList";
import CareerSingle from "./CareerSingle/CareerSingle";
//import SubscriptionFlow from "../common/components/Stripe/Subscription";
//import Billing from "../common/components/Stripe/Billing";
import Subscription from "./Subscription/Subscription";
import ThankYouPage from "./ThankYouPage/ThankYouPage";
import EmailConfirmed from "./EmailConfirmed/EmailConfirmed";
import HelpSupport from "./HelpSupport/HelpSupport";
import ChallengeContext from '../store/challenge-context';
import SignUp from "./SignUp/SignUp";
import ChallengeResults from "./ChallengeResults/ChallengeResults";
import Coaches from "./CoachList/CoachList";
import SandboxToolsList from "./SandboxToolsList/SandboxToolsList";
import SandboxToolsSingle from "./SandboxToolsSingle/SandboxToolsSingle";
import Dashboard from "./Dashboard/Dashboard";
import CrmContactsPage from "./Crm/CrmContacts/CrmContactsPage/CrmContactsPage";
import CrmContactAdd from "./Crm/CrmContacts/CrmContactAdd/CrmContactAdd";
import CrmContactEdit from "./Crm/CrmContacts/CrmContactEdit/CrmContactEdit";
import CrmListsPage from "./Crm/CrmLists/CrmListsPage/CrmListsPage";
import CrmListAdd from "./Crm/CrmLists/CrmListAdd/CrmListAdd";
import CrmListEdit from "./Crm/CrmLists/CrmListEdit/CrmListEdit";
import SignUpReferral from "./SignUp/SignUpReferral";
import BannersList from "./Banners/BannersList";
import BannerForm from "./Banners/BannerForm";
import UpdateForm from "./Banners/UpdateForm";
import CrmAutomationPage from "./Crm/CrmAutomation/CrmAutomationPage/CrmAutomationPage";
import CrmAutomationWorkflow from "./Crm/CrmAutomation/CrmAutomationWorkflow/CrmAutomationWorkflow";
import CrmAutomationSimulation from "./Crm/CrmAutomation/CrmAutomationSimulation/CrmAutomationSimulation";
import ChallengeListResults from "./ChallengeListResults/ChallengeListResults";
import RefreshAccount from "./RefreshAccount/RefreshAccount";
import errorPage from "./errorPage/errorPage";
import errorPageChallenge from "./errorPage/errorPageChallenge"

const GET_USER_DATA = gql`
  query me {
    me {
      userType
      trialExpires
      premiumPeriodEnd
    }
  }
`;

export const SECTIONS_AVAILABLE = [
  { text: 'root', value: 'root' },
  { text: 'admin-settings', value: '/admin-settings' },
  { text: 'ads', value: '/ads' },
  { text: 'challenge', value: '/challenge' },
  { text: 'rankings', value: '/rankings' },
  { text: 'psc', value: '/psc' },
  { text: 'crm', value: '/crm' },
  { text: 'campaign', value: '/campaign' },
  { text: 'creative', value: '/creative' },
  { text: 'advertiser', value: '/advertiser' },
  { text: 'placements', value: '/placements' },
  { text: 'profile', value: '/profile' },
  { text: 'sandbox-tools', value: '/sandbox-tools' },
  { text: 'career-paths', value: '/career-paths' },
  { text: 'subscription', value: '/subscription'},
]

const Navigation = props => {
  const [challengeOrigineRoute, setChallengeOrigineRoute] = useState('');

  const ProtectedRoute = ({
    component: Component,
    ...rest
  }) => {
    const [
      cookies,
      // setCookie,
      // removeCookie
    ] = useCookies(['jwt']);

    const [loadMe, {
      loading,
      error,
      data
    }] = useLazyQuery(GET_USER_DATA);
    if (loading) return null;
    if (error) return `Error! ${error.message}`;


    return (
      <Route
        {...rest}
        render={props => {
          const AuthStatus = cookies.jwt;
          if (AuthStatus) {
            if(!data) loadMe();
            const isRedirectToSubscription= data && (data.me.userType==="b2c-trial-expired" || data.me.userType==="b2b-expired" || data.me.userType==="b2c-premium-expired");

            if(isRedirectToSubscription  && props.location.pathname!=='/subscription')
              return (
                <Redirect
                  to={{
                    pathname: "/subscription",
                    state: {
                      from: props.location
                    }
                  }}
                />
              );
            return <Component {...props} setChallengeOrigineRoute={setChallengeOrigineRoute} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: {
                    from: props.location
                  }
                }}
              />
            );
          }
        }}
      />
    );
  };

  return (
    <Router>
      <App>
        <ChallengeContext.Provider value={{
          challengeOrigineRoute : challengeOrigineRoute
        }} >
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={SignUp} />
            <Route exact path="/register/:id" component={SignUpReferral} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:id" component={ResetPassword} />
            <Route exact path="/refresh-account/:id" component={RefreshAccount} />
            <Route exact path="/errorPage" component={errorPage} />
            <Route exact path="/errorPageChallenge" component={errorPageChallenge} />
            <ProtectedRoute exact path="/admin-settings" component={AdminSettings} />
            <ProtectedRoute exact path="/admin-settings/users/:id/" component={UserProfile} />
            <ProtectedRoute exact path="/admin-settings/users/page/:page" component={Users} />
            <ProtectedRoute exact path="/admin-settings/users/page" component={Users} />
            <ProtectedRoute exact path="/admin-settings/users" component={Users} />
            <ProtectedRoute exact path="/admin-settings/results/page/:page" component={ChallengeListResults} />
            <ProtectedRoute exact path="/admin-settings/results/page" component={ChallengeListResults} />
            <ProtectedRoute exact path="/admin-settings/results" component={ChallengeListResults} />
            <ProtectedRoute exact path="/admin-settings/coaches/:page" component={Coaches} />
            <ProtectedRoute exact path="/admin-settings/coaches" component={Coaches} />
            <ProtectedRoute exact path="/admin-settings/ChallengesToDo" component={ChallengesList} />
            <ProtectedRoute exact path="/admin-settings/banners" component={BannersList} />
            <ProtectedRoute exact path="/admin-settings/banners/create" component={BannerForm} />
            <ProtectedRoute exact path="/admin-settings/banners/:id" component={UpdateForm} />
            <ProtectedRoute exact path="/sandbox-tools" component={SandboxToolsList} />
            <ProtectedRoute exact path="/sandbox-tools/:slug" component={SandboxToolsSingle} />
            {/* <ProtectedRoute exact path="/sandbox-tools-old" component={SandboxTools} /> */}
            <ProtectedRoute exact path="/ads/campaign/step1/:id" component={CampaignAdServerStep1} />
            <ProtectedRoute exact path="/ads/campaign/step2/:id" component={CampaignAdServerStep2} />
            <ProtectedRoute exact path="/ads/campaign/step3/:id" component={CampaignAdServerStep3} />
            <ProtectedRoute exact path="/ads/campaign/step4/:id" component={CampaignAdServerStep4} />
            <ProtectedRoute exact path="/ads/campaign/step5/:id" component={CampaignAdServerStep5} />
            <ProtectedRoute exact path="/challenge/end/:id" component={ChallengeResults} />
            <ProtectedRoute exact path="/challenge/end/:id/:showDialog" component={ChallengeResults} />
            <ProtectedRoute exact path="/challenge-results" component={ChallengeResults} />
            <ProtectedRoute exact path="/" component={CareerList} />
            <ProtectedRoute exact path="/rankings" component={Dashboard} />
            <Route exact path="/error" component={Error} />
            <ProtectedRoute exact path="/admin-settings/register" component={AdminRegister} />
            <ProtectedRoute exact path="/calendar" component={Calendar} />
            <ProtectedRoute exact path="/profile" component={EditUserProfile} />
            <ProtectedRoute exact path="/settings" component={EditUserSettings} />
            <ProtectedRoute exact path="/chart1" component={Sample1} />
            <ProtectedRoute exact path="/campaign" component={CampaignInsert} />
            <ProtectedRoute exact path="/campaigns" component={Campaigns} />
            <ProtectedRoute exact path="/campaign/update/:id" component={CampaignUpdate} />
            <ProtectedRoute exact path="/campaign/report/:id" component={CampaignReport} />
            <ProtectedRoute exact path="/advertiser" component={AdvertiserInsert} />
            <ProtectedRoute exact path="/advertisers" component={AdvertisersContainer} />
            <ProtectedRoute exact path="/ads/advertisers/:id" component={AdvertisersAdServer} />
            <ProtectedRoute exact path="/ads/advertiser/:id" component={AdvertisersAdServerStep1} />
            <ProtectedRoute exact path="/advertiser/update/:id" component={AdvertiserUpdate} />
            <ProtectedRoute exact path="/creatives" component={CreativeList} />
            <ProtectedRoute exact path="/ads/creatives/:id" component={CreativesAdServerStep1} />
            <ProtectedRoute exact path="/ads/creative/:id" component={CreativeAdServerStep2} />
            <ProtectedRoute exact path="/creative" component={CreativeInsert} />
            <ProtectedRoute exact path="/creative/update/:id" component={CreativeUpdate} />
            <ProtectedRoute exact path="/placement" component={PlacementInsert} />
            <ProtectedRoute exact path="/placements" component={PlacementList} />
            <ProtectedRoute exact path="/ads/placements/:id" component={Challenge02Step1} />
            <ProtectedRoute exact path="/ads/placement/:id" component={Challenge02Step2} />
            <ProtectedRoute exact path="/placement/update/:id" component={PlacementUpdate} />
            <ProtectedRoute exact path="/password/:id" component={ChangePassword} />
            <ProtectedRoute exact path="/password" component={ChangePassword} />
            <ProtectedRoute exact path="/admin-settings/userGroup" component={UserGroupInsert} />
            <ProtectedRoute exact path="/admin-settings/userGroups" component={UserGroupList} />
            <ProtectedRoute exact path="/admin-settings/userGroups/update/:id" component={UserGroupUpdate} />
            <ProtectedRoute exact path="/admin-settings/userGroups/update" component={UserGroupList} />
            <ProtectedRoute exact path="/psc" component={ProfileScoreCard} />
            <Route exact path="/psc/:id" component={PublicProfileScoreCard} />
            <Route exact path="/psc/pdf/:id" component={PdfProfileScoreCard} />
            <Route exact path="/psc/img/:id" component={ImgProfileScoreCard} />
            <ProtectedRoute exact path="/career-paths" component={CareerList} />
            <ProtectedRoute exact path="/career-paths/:slug" component={CareerSingle}/>
            <Route exact path="/linkedin/authorization" component={LinkedInCallback} />
            {/* <ProtectedRoute exact path="/payment" component={SubscriptionFlow} /> */}
            {/* <ProtectedRoute exact path="/billing" component={Billing} /> */}
            <ProtectedRoute exact path="/success-subscription/:id" component={ThankYouPage} />
            <Route exact path="/verify-mail/:id" component={EmailConfirmed} />
            <ProtectedRoute exact path="/subscription" component={Subscription} />
            <ProtectedRoute exact path="/help" component={HelpSupport} />
            <ProtectedRoute exact path="/ads/5/campaigns/:id" component={ReportAdServerStep1} />
            <ProtectedRoute exact path="/ads/5/campaign/:id" component={ReportAdServerStep2} />
            <ProtectedRoute exact path="/ads/5/campaign/report/:id" component={ReportAdServerStep3} />
            <ProtectedRoute exact path="/ads/5/campaign/2/:id" component={ReportAdServerStep4} />
            <ProtectedRoute exact path="/ads/5/campaign/3/:id" component={ReportAdServerStep5} />
            <ProtectedRoute exact path="/ads/5/campaign/4/:id" component={ReportAdServerStep6} />
            <ProtectedRoute exact path="/ads/5/campaign/5/:id" component={ReportAdServerStep7} />
            <ProtectedRoute exact path="/ads/5/campaign/6/:id" component={ReportAdServerStep8} />
            <ProtectedRoute exact path="/ads/5/campaign/7/:id" component={ReportAdServerStep9} />
            <ProtectedRoute exact path="/ads/5/campaign/8/:id" component={ReportAdServerStep10} />
            <ProtectedRoute exact path="/ads/5/campaign/9/:id" component={ReportAdServerStep11} />
            <ProtectedRoute exact path="/ads/5/campaign/10/:id" component={ReportAdServerStep12} />
            <ProtectedRoute exact path="/ads/5/campaign/11/:id" component={ReportAdServerStep13} />
            <ProtectedRoute exact path="/ads/5/campaign/report/2/:id" component={ReportAdServerStep14} />
            <ProtectedRoute exact path="/ads/challengeTemplate/:type/:id" component={AppraiseResult} />
            <ProtectedRoute exact path="/crm/contacts" component={CrmContactsPage} />
            <ProtectedRoute exact path="/crm/contacts/edit/:id" component={CrmContactEdit} />
            <ProtectedRoute exact path="/crm/contacts/add" component={CrmContactAdd} />
            <ProtectedRoute exact path="/crm/lists" component={CrmListsPage} />
            <ProtectedRoute exact path="/crm/lists/edit/:id" component={CrmListEdit} />
            <ProtectedRoute exact path="/crm/lists/add" component={CrmListAdd} />
            <ProtectedRoute exact path="/crm/automation" component={CrmAutomationPage} />
            <ProtectedRoute exact path="/crm/automation/:id/edit" component={CrmAutomationWorkflow} />
            <ProtectedRoute exact path="/crm/automation/:id/simulation" component={CrmAutomationSimulation} />
          </Switch>
        </ChallengeContext.Provider>
      </App>
    </Router>
  )
};

export default Navigation;
