import React, { Component } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "@apollo/client/react/components";
import DataTable from "../../common/components/data-table/DataTable";
import { client } from "../../settings/apollo";
import AdvertiserList from "./AdvertiserList";
import { advertiserColumns } from "../../constants/columns"
import {DEFAULT_USER_EMAIL} from "../../common/components/Constants"
import LoadingSpinner from '../../common/components/LoadingSpinner/LoadingSpinner';

const DELETE_ADVERTISER = gql`
  mutation deleteAdvertiser($input: String) {
    deleteAdvertiser(input: $input)
  }
`;

const GET_ADVERTISERS = gql`
  query advertisers($name: String, $page: Int!) {
    advertisers(name: $name, page: $page) {
      records {
        _id
        username
        name
        defaultLandingUrl
        category
      }
      pages
      totalCount
    }
  }
`;

class AdvertisersContainer extends Component {
  static propTypes = {
    page: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = { name: "", page: 0 };
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._add = this._add.bind(this);
    this._first = this._first.bind(this);
    this._last = this._last.bind(this);
    this._first = this._first.bind(this);
    this._keyPress = this._keyPress.bind(this);

  }

  _previousPage(e) {
    e.preventDefault();
    if (this.state.page > 0) {
      this.setState({ page: this.state.page - 1 });
    }
  }

  _nextPage(e) {
    e.preventDefault();
    this.setState({ page: this.state.page + 1 });
  }

  _first(e) {
    e.preventDefault();
    this.setState({ page: 0} );
  }

  _last(pages, e) {
    e.preventDefault();
    this.setState({ page: pages - 1 });
  }

  _deleteRow(event) {
    client
      .mutate({
        mutation: DELETE_ADVERTISER,
        variables: { input: event.id.id }
      })
      .catch(error => console.log(error))
      .then(() => this.forceUpdate());
  }

  _add(event) {
    this.props.history.push("/advertiser");
  }

  _keyPress(e) {
    if (e.keyCode === 13) {
      this.setState({ name: e.target.value,
                      page: 0 });
    }
  }

  _showEditIcon(record){
    return (record.username!== DEFAULT_USER_EMAIL);
  }
  _showDeleteIcon(record){
    return (record.username!== DEFAULT_USER_EMAIL);
  }

  render() {
    return (
      <AdvertiserList _add={this._add} _keyPress={this._keyPress}>
        <Query
          query={GET_ADVERTISERS}
          fetchPolicy="network-only"
          variables={{
            name: this.state.name,
            page: this.state.page
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <LoadingSpinner styles={{ paddingTop: 30 }} />;
            if (error) return `Error! ${error.message}`;

            return (
              <DataTable
                header="Advertisers"
                editUrl="/advertiser/update/"
                pages={data.advertisers.pages}
                columns={advertiserColumns}
                records={data.advertisers}
                deleteRow={this._deleteRow}
                keyColumn="_id"
                page={this.state.page}
                first={this._first}
                last={this._last.bind(this, data.advertisers.pages)}
                next={this._nextPage}
                previous={this._previousPage}
                columnsToHide={["username"]}
                showEditIcon={this._showEditIcon}
                showDeleteIcon={this._showDeleteIcon}
              />
            );
          }}
        </Query>
      </AdvertiserList>
    );
  }
}

export default AdvertisersContainer;
