import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iframeContainer: {
    background: theme.palette.common.black,
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '56.25%',
    '@media (max-width: 1535.98px) and (min-aspect-ratio: 16/9)': { // fix 16:9 screens
      paddingTop: 'calc(56.25% - 100px)',
    }
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%"
  }
}));

const VideoFrame = props => {
  const { src, styles } = props;
  const classes = useStyles();

  return (
    <div className={classes.iframeContainer} style={styles}>
      <iframe
        title="Video Frame"
        className={classes.iframe}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        sandbox="allow-scripts allow-same-origin allow-presentation"
      />
    </div>
  );
};

export default VideoFrame;
