import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Box, Typography, Divider } from '@material-ui/core';
import CircularProgressWithLabel from '../base/CircularProgressWithLabel/CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius:6,
  },
  paperTop:{
    padding: theme.spacing(0,2.5),
    paddingTop:24,
    paddingBottom:18,
  },
  paperBottom:{
    paddingTop:10,
    paddingBottom:25,
  },
  gridRoot: {},
  gridItem: {
    width:'auto',
    margin:'0 auto',
    textAlign:'center',
    '@media (min-width: 1280px) and (max-width: 1536px)' : {
      maxWidth: '100%',
      flexBasis: '100%',
    }
  },
  progressBox: {
    '& h3': {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom:10,
    },
    '@media (min-width: 1280px) and (max-width: 1536px)' : {
      marginBottom:10,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom:10,
    },
  },
  infoList: {
    listStyle:'none',
    margin:0,
    padding:0,
    textAlign:'left',
    maxWidth:212,
    '& li': {
      marginBottom:6,
    },
    '& li span': {
      display:'inline-block',
      border: `solid 1px ${theme.palette.common.gamsPetroleumBlue}`,
      color: theme.palette.common.gamsPetroleumBlue,
      borderRadius: 15,
      padding: '0 5px',
      marginRight: 4,
      marginTop: 3,
      marginBottom: 3,
      fontSize: theme.typography.pxToRem(14),
      '&:last-child': {
        marginRight:0,
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth:'100%',
    },
    '@media (min-width: 1280px) and (max-width: 1536px)' : {
      maxWidth:'100%',
    }
  },
  badgeTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
  },
  badgesBox: {
    paddingTop: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 5,
    },
    '& div': {
      width: 50,
      height: 50,
      margin: '0 4px',
      '& img': {
        width: '100%',
        height: 'auto',
      }
    }
  }
}));

const CareerInfo = ({ careerProgress, careerObtainableScore, careerScore, totalFinishedLearningTopics, totalLearningTopics, careerTags, careerBadges }) => {
  const classes = useStyles();

  let tags = <Typography variant="body2" color="textSecondary">no tags</Typography>;
  if (careerTags) {
    tags = careerTags.map((tag, index) => {
      return <span key={`career-tag-${index}`}>{tag.name}</span>
    })
  }

  let badges = null;
  if (careerBadges) {
    badges = (
      <>
        <Divider />
        <Box className={classes.paperBottom}>
          <Typography className={classes.badgeTitle} component="h3">Badges</Typography>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.badgesBox}
          >
          {careerBadges.map((badge, index) => {
            return <div key={`badge-${index}`}>
              <img src={badge} alt={`Gams Badge ${index}`} />
            </div>
          })}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Paper className={classes.paper}>
      <Box className={classes.paperTop}>
        <Grid container className={classes.gridRoot}>
          <Grid className={classes.gridItem} item xs={12} sm={6}>
            <Box className={classes.progressBox}>
              <Typography variant="h3" component="h3">Career Progress</Typography>
              <CircularProgressWithLabel value={careerProgress} size={120} />
            </Box>
          </Grid>
          <Grid className={classes.gridItem} item xs={12} sm={6}>
            <ul className={classes.infoList}>
              <li><Typography variant="body1">Career path score: <strong>{careerScore}/{careerObtainableScore}</strong></Typography></li>
              <li><Typography variant="body1">Learning topics: <strong>{totalFinishedLearningTopics}/{totalLearningTopics}</strong></Typography></li>
              <li>
                <Typography variant="body1" component="div">
                  Career tags: <br></br>
                  {tags}
                </Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      {badges}
    </Paper>
  );
}

export default CareerInfo;
