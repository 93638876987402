import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Icon, IconButton, Typography, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  root: {
    width:280,
    height:110,
    borderRadius: 6,
    backgroundColor: theme.palette.grey[100],
    margin: 'auto',
    position: 'relative',
  },
  starter: {
    width:280,
    height:114,
    borderRadius: 6,
    backgroundColor: theme.palette.grey[100],
    margin: 'auto',
    cursor: 'pointer',
    '& h3': {
      paddingTop: 14,
    },
    '&:hover': {
      borderColor: `${theme.palette.grey[700]} !important`
    }
  },
  starterBtn: {
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
    letterSpacing: 0.2,
    borderRadius: '50%',
    marginTop: 8,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.3, 0.5, 0, 1),
    minHeight: 29
  },
  icon: {
    lineHeight: 0,
    '& span': {
      fontSize: 21
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionBtn: {
    color: theme.palette.grey[600],
    '& svg': {
      fontSize: 21,
    },
    '&:hover': {
      background: 'none',
      color: theme.palette.grey[900],
    },
    '&:focus': {
      outline: 'none',
    },
    '& path': {
      stroke: 'none'
    }
  },
  content: {
    textAlign: 'center',
    padding: theme.spacing(0, 1)
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    marginTop:3,
    marginBottom:4,
  },
  description: {
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  label: {
    width: 40,
    height: 26,
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.grey[50],
    '& div': {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
      textAlign: 'center',
      paddingTop: 2
    }
  },
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    letterSpacing: 0.2,
    borderRadius: 20,
    textTransform: 'none',
    paddingTop:6.5,
    paddingBottom: 6.5,
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
    '&:focus': {
      outline: 'none',
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    },
    '& path': {
      stroke: 'none'
    }
  },
  add: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  contactsCount: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.common.white,
    borderRadius: 8,
    position: 'absolute',
    width: 110,
    height: 20,
    margin: 'auto',
    textAlign: 'center',
    top: -10,
    left: 0,
    right: 0
  },
}));

const TreeNode = ({ iconName, onEdit, onDelete, title, description, type, onSelectEntry, onSelectStep, id, contactsCount, isSimulation }) => {
  const classes = useStyles();
  const theme = useTheme();

  const nodeType = type;
  let color;
  switch (nodeType) {
    case 'entry':
      color = theme.palette.automation.darkBlue[500];
      break;
    case 'action':
      color = theme.palette.automation.blue[500];
      break;
    case 'condition':
      color = theme.palette.automation.green[500];
      break;
    case 'starter':
      color = theme.palette.grey[500];
      break;
    default:
      color = theme.palette.grey[400];
  }

  if (type === 'starter') {
    return (
      <Box className={classes.starter} style={{ border: `solid 2px ${color}` }} onClick={onSelectEntry}>
        <Box className={classes.content}>
          <Typography className={classes.title} variant="body1" component="h3">{title}</Typography>
          <Typography className={classes.description} variant="body1" component="div">{description}</Typography>
          <IconButton className={classes.starterBtn} size="small">
            <AddIcon style={{ fontSize: 18 }} />
          </IconButton>
        </Box>
      </Box>
    )
  }

  if (type === 'label') {
    return (
      <Box className={classes.label}>
        <Typography variant="body1" component="div">{title}</Typography>
      </Box>
    )
  }

  if (type === 'button') {
    return (
      <Box className={classes.add}>
        <Button
          disableElevation
          onClick={(event) => onSelectStep(event, id, 'select node')}
          className={classes.addBtn}
          variant="contained"
          startIcon={<AddIcon style={{ fontSize: 24 }}/>}
        >Add Step
        </Button>
      </Box>
    )
  }

  const editAction = type === 'entry' ? 'edit entry' : ['action', 'condition'].includes(type) ? 'edit step' : null;
  const deleteAction = type === 'entry' ? 'delete entry' : null;

  return (
    <Box className={classes.root} style={{ border: `solid 2px ${color}`, marginTop: `${isSimulation ? 8 : 0}px` }}>
      {isSimulation ? <Typography className={classes.contactsCount} variant="body1" component="div" style={{ backgroundColor: color }}>{contactsCount} contacts</Typography> : null }
      <Box className={classes.head}>
        {iconName &&
        <div className={classes.icon}>
          <Icon style={{ color: color }}>{iconName}</Icon>
        </div>
        }
        {onEdit &&
        <div className={classes.actions}>
          <IconButton className={classes.actionBtn} onClick={(e) => onEdit(title, id, editAction)} size="small">
            <EditIcon />
          </IconButton>
          <IconButton className={classes.actionBtn} onClick={(e) => onDelete(id, deleteAction)} size="small">
            <DeleteIcon />
          </IconButton>
        </div>
        }
      </Box>
      <Box className={classes.content}>
        <Typography className={classes.title} variant="body1" component="h3">{title}</Typography>
        {description &&
        <Typography className={classes.description} variant="body1" component="div">{description}</Typography>
        }
      </Box>
    </Box>
  );
}

export default TreeNode;
