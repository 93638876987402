import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PSC_BY_ID } from "./apolloQueries";
import PscProfile from '../../common/components/PscProfile/PscProfile';
import ImgLearningTopics from '../../common/components/LearningTopics/ImgLearningTopics';
import CourtesyPage from './CourtesyPage';
import gamsLogo from '../../assets/images/logo/new-gams-logo.png';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Container, Box, Grid, Link, IconButton, Icon, Button, Divider } from '@material-ui/core';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import { apolloConfig } from "../../settings/apollo";
import { ApolloClient } from '@apollo/client/core';
import PartnersBlock from '../../common/components/PartnersBlock/PartnersBlock';

const client = new ApolloClient(apolloConfig);

const useStyles = makeStyles((theme) => ({
  rootContent: {
    minHeight: 'calc(100vh - 88px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 210px)',
    },
  },
  container: {
    paddingBottom:70,
  },
  containerXl: {
    maxWidth:1600
  },
  toolbar: {
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom:25,
  },
  logoBox: {
    maxWidth:200,
    margin: 'auto',
    paddingTop:10,
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  pscRoot: {
    width: '100%',
    marginBottom: 40
  },
  pscTitle: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 7.5
  },
  footer: {
    height: 88,
    padding: theme.spacing(2.5, 0),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    webkitPrintColorAdjust: 'exact',
    [theme.breakpoints.down('sm')]: {
      height: 210,
    },
  },
  footerBox: {
    maxWidth:600,
    margin: 'auto',
    color: theme.palette.common.white,
    '& p': {
      margin: theme.spacing(2, 0),
    }
  },
  copyText: {
    padding:theme.spacing(0,4),
    textAlign: 'left',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingTop:15,
      paddingBottom:15,
    },
  },
  btnLink: {
    border: `solid 1px ${theme.palette.common.white}`,
    margin:'auto',
    textAlign: 'center',
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.gamsDarkBlue,
    },
    '& a': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      marginBottom: 15,
    },
  },
  socialBox: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      justifyContent: 'center',
    },
  },
  iconBtn: {
    color: theme.palette.common.white,
    margin: theme.spacing(0),
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
    },
    '& svg': {
      width: 24,
      height: 24,
    }
  },
}));

const ImgProfileScoreCard = props => {
  const classes = useStyles();
  const {
    loading,
    error,
    data
  } = useQuery(GET_PSC_BY_ID, {
    variables: {
      id: props.match.params.id,
    },
    fetchPolicy: "cache-and-network",
    client
  });

  if (loading) return "loading...";
  if (error) return `Error! ${error.message}`;

  const psc = data.profileScoreCardById;

  const pscDateCheck = (firstDate, secondDate) => {
    if(firstDate.setHours(0,0,0,0) <= secondDate.setHours(0,0,0,0)) {
      return true;
    }
    return false;
  }

  const pscDate = new Date(psc.expirationDate);
  const today = new Date();
  if (pscDateCheck(pscDate, today)) return <CourtesyPage/>;

  return (
    <>
      <div className={classes.rootContent}>
        <Container
          maxWidth="xl"
          className={classes.container}
          classes={{ maxWidthXl: classes.containerXl }}
        >
          <Toolbar className={classes.toolbar}>
            <Box className={classes.logoBox}>
              <Link
                href="https://gamsplatform.com"
                rel="noopener noreferrer"
                target="_blank">
              <img src={gamsLogo} alt="GAMS Logo" />
              </Link>
            </Box>
          </Toolbar>

          <Box className={classes.pscRoot}>
            <Box pr={3}>
              <Typography className={classes.pscTitle} variant="h1">
                Profile Scorecard: Digital Meta-Skills Dynamic Certification
              </Typography>
            </Box>
          </Box>

          <main>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <PscProfile
                  expirationDate={psc.expirationDate}
                  serialNumber={psc.serialNumber}
                  username={psc.username}
                  badges={psc.badges}
                  photo={psc.photo}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <ImgLearningTopics careerPaths={psc.careerPaths} urlPath={props.match.path}/>
              </Grid>
            </Grid>
          </main>
          <Divider style={{ textAlign:'center', maxWidth: 960, margin:'0 auto', marginTop:110, marginBottom:50 }} />
          <PartnersBlock />
        </Container>
      </div>

      <footer className={classes.footer}>
        <Grid
          className={classes.footerGrid}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.copyText} variant="body1">
              {'Copyright © '}
              {new Date().getFullYear()}
              {' GAMS'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box textAlign="center">
              <Button
                className={classes.btnLink}
                variant="outlined"
                color="default"
                href="https://gamsplatform.com"
                rel="noopener noreferrer"
                target="_blank"
                size="large">
                gamsplatform.com
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box display="flex" className={classes.socialBox}>
              <IconButton
                className={classes.iconBtn}
                href="https://www.linkedin.com/company/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-linkedin" style={{ color: '#fff' }}></Icon>
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.facebook.com/GAMSPlatform/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon className="fa fa-facebook-f" style={{ color: '#fff' }} />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.instagram.com/gamsplatform/"
                rel="noopener noreferrer"
                target="_blank">
                <InstagramIcon />
              </IconButton>
              <IconButton
                className={classes.iconBtn}
                href="https://www.youtube.com/channel/UC3yjGdmVYQktS8LmW_RW0Rg"
                rel="noopener noreferrer"
                target="_blank">
                <YouTubeIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}

export default ImgProfileScoreCard;
