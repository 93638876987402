import {GET_CAMPAIGN, UPDATE_CAMPAIGN_LINE_ITEMS} from './queries'

export const getCampaign = async (id, client)=> {
  const res =  await client.query({
        query: GET_CAMPAIGN,
        variables: { id: id }
      }).catch((e)=>console.log(e)
      );
      return res.data.campaign
}

export const updateCampaign = async (id, data, client) => {
    await client.mutate({mutation: UPDATE_CAMPAIGN_LINE_ITEMS, variables:{id: id, input: data},}).catch((error) => {
      }).then();
}
