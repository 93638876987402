import gql from "graphql-tag";

// Queries for the contacts section
// -----------------------------------------------------
export const GET_CRM_CONTACTS = gql`
  query crmContacts($search: String, $page: Int, $listId: ID) {
    crmContacts(search: $search, page: $page, listId: $listId) {
      records {
        actions
        name
        surname
        email
        country
        age
        username
        _id
      }
      lists {
        _id
        name
        contactsTotalCount
      }
      totalCount
      pages
      rowsPerPage
    }
  }
`;

export const GET_CRM_CONTACT = gql`
  query crmContact($id: ID!){
    crmContact(id: $id){
      actions
      _id
      name
      surname
      email
      country
      age
      username
    }
  }
`;

// Queries for the lists section
// -----------------------------------------------------
export const GET_CRM_LISTS= gql`
  query crmLists($search: String, $page: Int,) {
    crmLists(search: $search, page: $page) {
      records {
        _id
        name
        numberId
        username
        contactsTotalCount
      }
      totalCount
      pages
      rowsPerPage
    }
  }
`;

export const GET_CRM_LIST = gql`
  query crmList($id: ID!){
    crmList(id: $id){
      _id
      name
      andConditions {
        filterField
        filterValue
        filterCondition
      }
      orConditions {
        filterField
        filterValue
        filterCondition
      }
      newItems {
        filterFields
        filterValues
        filterConditions
        filterValuesInputType
      }
    }
  }
`;

export const GET_CRM_LIST_NEW_ITEM = gql`
  query crmListNewItem {
    crmListNewItem {
      filterFields
      filterValues
      filterConditions
      filterValuesInputType
    }
  }
`;

// Queries for the Automation section
// -----------------------------------------------------
export const GET_CRM_AUTOMATIONS = gql`
  query crmAutomations($search: String, $page: Int,) {
    crmAutomations(search: $search, page: $page) {
      records {
        _id
        name
        numberId
        creationDate
      }
      totalCount
      pages
      rowsPerPage
    }
  }
`;

export const GET_CRM_AUTOMATION = gql`
  query crmAutomation($id: ID!) {
    crmAutomation(id: $id) {
      _id,
      name,
      activated,
      nodes{
        _id
        title
        description
        icon
        type
        children
      }
    }
  }
`;

export const GET_CRM_CONTACTS_LISTS = gql`
  query crmContacts($search: String, $page: Int, $listId: ID) {
    crmContacts(search: $search, page: $page, listId: $listId) {
      lists {
        _id
        name
      }
    }
  }
`;

export const GET_CRM_SIMULATION = gql`
  query crmSimulation($id: ID!, $days: Int!) {
    crmSimulation(id: $id, days: $days) {
      activated
      name
      nodes{
        _id
        title
        description
        icon
        type
        contactsCount
        children
      }
    }
  }
`;

