import React, { Component } from 'react';
import { Formik } from "formik";
//import Layout from '../../../../common/Layout';
import { Query } from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import { GET_CHALLENGE, EVALUATE_STEP, GET_CHALLENGE_INSTANCE } from '../queries';
import CampaignFormSimulation from "../CampaignFormSimulation";
import { SecureQuery } from '../../../../settings/graphqlHelper';
import ChallengePanel from '../../ChallengePanel';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../../../utils/data/sandboxes";

class CampaignAdServerStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, name: '' };
    this.handleTab = this.handleTab.bind(this);
  }

  componentDidMount() {
    SecureQuery.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then((res) => {
      this.setState({
        type: res.data.challengeInstance.challengeType,
        isSubmitting: false,
      });
    }).catch((error) => {
      console.log(error);
    });
  }


  async handleTab(values) {
    this.setState({ isSubmitting: true })
    const action = {
      actionNeeded: 'complete',
      target: JSON.stringify({
        "type": "campaign",
        "fields":
        {
          "name": values.name,
          "advertiser": values.advertiser,
          "scheduleDuration": values.scheduleDuration
        }
      })
    };
    let res = await SecureQuery.asyncMutation({ mutation: EVALUATE_STEP, variables: { id: this.state.id, action: action } });
    let value = res.data.challengeStepEvaluator;
    if (value !== '')
      this.props.history.push(value + this.props.match.params.id);
  };

  render() {
    if (!this.state.type)
      return <LoadingAnimation />;

    return (<SandboxLayout name="Campaigns" challengeMode={true} layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
        <div className="col-md-12 col-12 mr-auto ml-auto">
          <div className="card card-wizard active" data-color="rose" id="wizardProfile">
            <div>
              <Query query={GET_CHALLENGE}
                fetchPolicy="network-only"
                variables={{
                  id: this.state.type
                }}
              >
                {({ loading, error, data }) => {
                  if (loading) return (<LoadingAnimation />);
                  if (error) return `Error! ${error.message}`;
                  if (!data || data.challenge === null)
                    return <div>No Campaigns available</div>;
                  const challenge = JSON.parse(data.challenge);
                  const StepCountDownTime = challenge.steps[1].countdownTime;

                  return (
                    <div>
                      <Formik
                        initialValues={{ name: "", advertiser: "", scheduleDuration: "" }}
                      >
                        {({ isSubmitting, values, handleChange, setFieldValue }) => (
                          <CampaignFormSimulation handleAdvertiser={this.handleAdvertiser}
                            handleDuration={this.handleDuration}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleTab={this.handleTab}
                            caption="Campaign"
                            id={this.state.id}
                            values={values}
                            isSubmitting={this.state.isSubmitting}
                          />)}
                      </Formik>
                      <ChallengePanel time={StepCountDownTime}
                        description={challenge.challengeDescription}
                        coach={challenge.coach}
                        challengeTitle={challenge.challengeTitle}
                        challengeIsValidation={challenge.isValidation}
                        challengeDifficulty={challenge.challengeDifficulty}
                        challengeAverageTime={challenge.averageTime}
                      />
                    </div>
                  );
                }}
              </Query>
            </div>
          </div>
        </div>
      </div>
    </SandboxLayout>
    );
  }
}

export default CampaignAdServerStep2;

