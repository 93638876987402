import React, { Component } from 'react';
//import Layout from '../../../../common/Layout';
import { Query } from "@apollo/client/react/components";
import DataTable from '../../../../common/components/data-table/DataTable';
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import SimpleSearch from "../../../../common/components/data-table/SimpleSearch";
import AddItemButton from "../../../../common/components/base/AddItemButton";
import ChallengePanel from '../../ChallengePanel';
import { GET_CHALLENGE, GET_CHALLENGE_INSTANCE } from '../queries';
import { evaluateStep } from "../simulation";
import { client } from "../../../../settings/apollo";
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../../../utils/data/sandboxes";

const columns = ["name", "advertiser", "duration", "actions"];

class CampaignAdServerStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.match.params.id, time: 120 };
    this._nextPage = this._nextPage.bind(this);
    this._previousPage = this._previousPage.bind(this);
    this._deleteRow = this._deleteRow.bind(this);
    this._add = this._add.bind(this);
    this._keyPress = this._keyPress.bind(this);
  }

  _previousPage(e) {

  }

  _nextPage(e) {

  }

  _deleteRow(e) {

  }

  async _add() {
    const action = {
      actionNeeded: 'click',
      target: 'button',
      parameter: 'new-campaign'
    };
    const res = await evaluateStep(this.state.id, action, client);
    let value = res.data.challengeStepEvaluator;
    if (value !== '')
      this.props.history.push(value + this.props.match.params.id);
  }

  _keyPress(e) {

  }

  componentDidMount() {
    client.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: { id: this.state.id }
    }).then(res => {
      this.setState({
        type: res.data.challengeInstance.challengeType,
        currentStep: res.data.challengeInstance.currentStep
      });
    });
  }

  render() {
    if (!this.state.type)
      return (<LoadingAnimation />);

    return (<SandboxLayout name="Campaigns" challengeMode={true} layoutData={campaignAdsManagerData}>
      <div className="wizard-container">
        <AddItemButton OnClick={this._add} />
        <div className="row">
          <div className="col-sm-12 col-md-10" />
          <div className="col-sm-12 col-md-2">
            <SimpleSearch keyPress={this._keyPress} />
          </div>
        </div>
        <Query query={GET_CHALLENGE}
          fetchPolicy="network-only"
          variables={{
            id: this.state.type
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return (<LoadingAnimation />);
            if (error) return `Error! ${error.message}`;

            let challenge = JSON.parse(data.challenge);
            let StepCountDownTime = challenge.steps[0].countdownTime;;
            if (!('campaigns' in challenge)) {
              challenge['campaigns'] = [];
              challenge.campaigns['records'] = [];
            }
            return (
              <div>
                <DataTable header='Campaigns'
                  editUrl="/campaign/update/"
                  columns={columns}
                  records={challenge.campaigns}
                  deleteRow={this._deleteRow}
                  keyColumn='_id'
                  first={e => e.preventDefault()}

                />
                <ChallengePanel
                  step={0}
                  time={StepCountDownTime}
                  description={challenge.challengeDescription}
                  coach={challenge.coach}
                  challengeTitle={challenge.challengeTitle}
                  challengeIsValidation={challenge.isValidation}
                  challengeDifficulty={challenge.challengeDifficulty}
                  challengeAverageTime={challenge.averageTime}
                />
              </div>
            );
          }}
        </Query>
      </div>
    </SandboxLayout>
    );
  }

}

export default CampaignAdServerStep1;
