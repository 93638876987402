export const advertiserColumns = [
  "username",
  "name",
  "defaultLandingUrl",
  "category",
  "actions"
];

export const creativeColumns = ["name", "size", "type", "actions"]
export const campaignsColumns = ["name", "advertiser", "duration", "actions"];
export const placementColumns = ["name", "advertiser", "duration", "actions"];
