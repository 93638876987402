import React from 'react';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as PropTypes from "prop-types";
import {flexEnd} from "../../../settings/otherStyles";
import {Link} from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { Tooltip } from '@material-ui/core';

const StyledIconButton = withStyles({
  root: {
    color: '#999999',
  }
})(IconButton);

let _getValuesFromRecord = (record, columns, columnsToHide, highlightRecord) => { 
  return Object.keys(record).map((key, index) => {
    let result;
    if (!columnsToHide.includes(key) && (columns.includes(key) || key.localeCompare('action') >= 0)) {
      let style=null;
      if(highlightRecord(record)) style={color: "red"};
      result = <TableCell key={index} style={style}>{record[key]}</TableCell>;
    }
    return result;
  })
}

const editUrlBuilder = (editUrl, parameterRecord) => {
  if (typeof editUrl === 'object') {
    const editUrlParameter = editUrl[editUrl.editUrlKey];
    return `${editUrl.url}${parameterRecord}/${editUrlParameter}`;
  }
  return `${editUrl}${parameterRecord}`;
}

let _getActionButtonsFromRecord = (keyColumn, editUrl, record, canBeDeleted, canBeEdited, handleDeleteClick, handleEditClick, hasCustomAction, handleCustomActionClick, customDeleteIcon) => {
  if (keyColumn && editUrl) {
    const parameterRecord = record[keyColumn];
    const id = {id: parameterRecord};
    const recordEditUrl = editUrlBuilder(editUrl, parameterRecord);
    return _addActionsButtons(recordEditUrl, id, canBeDeleted, canBeEdited, handleDeleteClick, handleEditClick, hasCustomAction, handleCustomActionClick, customDeleteIcon);
  }
  return null;
}

let _addActionsButtons = (editUrl, id, canBeDeleted, canBeEdited, handleDeleteClick, handleEditClick, hasCustomAction, handleCustomActionClick, customDeleteIcon) => {

  if(!canBeEdited && !canBeDeleted) return <TableCell style={{ height: 52 }} />;

  return (
    <TableCell style={flexEnd}>

      {(() => {
        if(hasCustomAction){ return(
          <StyledIconButton onClick={(e) => handleCustomActionClick(id, e)} aria-label="custom action">
            <CompareArrowsIcon />
          </StyledIconButton>
        )}
      })()}

      {(() => {
        if(!canBeEdited) return null;
        if (handleEditClick)
          return (<div key={id} onClick={(e) => handleEditClick(id, e)}
                    className="btn btn-link btn-warning btn-just-icon edit">
                    <i key={id} className="material-icons">edit</i>
                  </div>);
        else
          return (<Link to={{
            pathname: `${editUrl}`,
            params: {id}
          }} className="btn btn-link tn-warning btn-just-icon edit">
                    <i className="material-icons">edit</i>
                  </Link>
                );


      })()}
      {(() => {
        if(canBeDeleted){ return(
          <div key={id} onClick={(e) => handleDeleteClick(id, e)}
            className="btn btn-link btn-danger btn-just-icon remove">
            <i key={id} className="material-icons">{customDeleteIcon ? customDeleteIcon: 'delete'}</i>
          </div>
        )}
        })()}
    </TableCell>
  );
}

const DataTableBody = props => {
  const {
    records,
    columns,
    columnsToHide,
    keyColumn,
    editUrl,
    showEditIcon,
    showDeleteIcon,
    handleDeleteClick,
    handleEditClick,
    onSelectClick,
    showCustomAction,
    handleCustomActionClick,
    highlightRecord,
    status,
    customDeleteIcon,
  } = props;

  const selected = props.selected !==undefined ?  props.selected : [];
  const checkboxes = props.checkboxes===true ? true : false;

  const isSelected = (id) => {
     return selected.indexOf(id) !== -1;
  }

  const onlineActivityStatus = (statusRecord) => {
    switch (statusRecord) {
      case 0:
        return 'Online';
      case false:
        return 'Offline'
      default:
        return `Offline for ${statusRecord}`;
    }
  }

  return (
    <TableBody>
      {
        records.map((record, index) => {
          const statusRecord = status(record);
          return (
            <TableRow
              role="checkbox"
              aria-checked={isSelected(index)}
              tabIndex={-1}
              key={index}
              selected={isSelected(index)}
            >
              {checkboxes === true ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => onSelectClick(event, index, record)}
                    checked={isSelected(index)}
                    color="primary"
                  />
                  {statusRecord !== null && 
                    <Tooltip title={onlineActivityStatus(statusRecord)} enterTouchDelay={0} arrow>
                      <span key={index} className="material-icons" style={{fontSize: 10, color: statusRecord === 0 ? 'green': 'red'}}>circle</span>
                    </Tooltip>
                  }
                </TableCell>
              ) : null}
              {_getValuesFromRecord(record, columns, columnsToHide, highlightRecord)}
              {_getActionButtonsFromRecord(keyColumn, editUrl, record, showDeleteIcon(record), showEditIcon(record), handleDeleteClick, handleEditClick, showCustomAction, handleCustomActionClick, customDeleteIcon)}
            </TableRow>
          );
        })
      }
    </TableBody>
  )
}

DataTableBody.propTypes = {
  records: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  keyColumn: PropTypes.string.isRequired,
  editUrl: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired
};


DataTableBody.defaultProps = {
  columnsToHide: [],
  customDeleteIcon: false,
  showEditIcon: (record) => { return true; },
  showDeleteIcon: (record) => { return true; },
  showCustomAction: false,
  highlightRecord: (record) => {return false}
}

export default DataTableBody;
