import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonGroup, Box, Button, Typography, Paper } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { env } from '../../../env';
// import { Link } from '@material-ui/core'
// import { HashLink } from 'react-router-hash-link';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    width:940,
    margin:'0 auto',
    display:'flex',
    flexDirection:'row',
    marginTop:48,
    [theme.breakpoints.down('md')]: {
      width:'auto',
      flexDirection:'column-reverse',
      alignItems:'center',
    },
  },
  paper: {
    borderRadius: 8,
    padding:theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom:32,
    },
  },
  pricingHead: {
    marginBottom:30,
    '& h2': {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightBold,
      [theme.breakpoints.down(460)]: {
        marginBottom:10,
      },
    },
    '& .planSwitchBtn button': {
      backgroundColor: theme.palette.common.orange[300],
      color: theme.palette.common.white,
      textTransform:'none',
      '&:hover': {
        color: theme.palette.common.white,
      },
      '&:focus': {
        outline: 'none',
        color: theme.palette.common.white,
      },
      '&:not(:last-child)': {
        borderRight: `solid 1px rgba(0,0,0,0.39)`,
      }
    },
    '& p': {
      color: theme.palette.text.secondary,
      marginTop:20,
    }
  },
  pricingAction: {
    marginBottom:68,
    position:'relative',
    '& .price': {
      display:'flex',
      justifyContent:'space-between',
      alignItems:'flex-start',
      flexWrap: 'wrap',
      marginBottom:15,
      '& .price__number': {
        fontSize: theme.typography.pxToRem(70),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight:1,
        [theme.breakpoints.down(460)]: {
          marginBottom:10,
        },
      },
      '& .price__number--decimal': {
        '& span': {
          fontSize: theme.typography.pxToRem(32),
          position:'relative'
        }
      },
      '& .price__number--top': {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightRegular,
        color: `${theme.palette.grey[300]} !important`,
        textDecoration: 'line-through',
        position:'absolute',
        top: -19
      },
      '& .price__number--text': {
        fontSize: theme.typography.pxToRem(19),
        fontWeight: theme.typography.fontWeightMedium,
        marginTop:1
      }
    },
    '& .price__info': {
      position:'absolute',
      bottom:-32,
      left:0,
      [theme.breakpoints.down(460)]: {
        bottom:-48,
      },
    },
  },
  pricingBtn: {
    width:210,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(18),
    paddingTop:17,
    paddingBottom:17,
    textTransform:'none',
    lineHeight:1.2,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsOrange,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
    },
    '&.Mui-disabled': {
      //backgroundColor: theme.palette.common.white,
    }
  },
  btnPremiumDisabled: {
    backgroundColor: `${theme.palette.common.white} !important`
  },
  pricingFooter: {
    '& .intro-list': {
      fontSize: theme.typography.pxToRem(21),
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom:12,
    },
    '& .pricingPlan': {
      padding:0,
      margin:0,
      marginBottom:20,
      listStyle: 'none',
      color: theme.palette.text.secondary,
    },
    '& .pricingPlan__item': {
      display:'flex',
      justifyContent:'flex-start',
      alignItems:'flex-start',
      marginBottom:6,
    },
    '& .pricingPlan__icon': {
      marginRight:5,
    },
    '& .pricingPlan__text': {}
  },
  trial: {
    backgroundColor: '#F8F8F8',
    border:`solid 1px ${theme.palette.grey[300]}`,
    width:480,
    zIndex:10,
    paddingRight:42,
    [theme.breakpoints.down('md')]: {
      width:470
    },
    [theme.breakpoints.down('xs')]: {
      width:'100%',
    },
  },
  premium: {
    width:470,
    marginLeft:-10,
    position:'relative',
    zIndex:20,
    boxShadow: '-2px 0 8px 0 rgba(0,0,0,0.40)',
    color:theme.palette.common.white,
    backgroundImage: 'linear-gradient(90deg, #005D7B 0%, #043F54 100%)',
    backgroundColor: theme.palette.common.gamsPetroleumBlue,
    '& .pricingHead__subtitle': {
      minHeight: 72,
      '& p': {
        color:theme.palette.common.white,
      }
    },
    '& .pricingHead__subtitle, & .price .price__number, & .intro-list, & .pricingPlan': {
      color: theme.palette.common.white,
    },
    '& .pricingPlan__icon': {
      marginRight:8,
    },
    [theme.breakpoints.down('md')]: {
      width:470,
      marginLeft:0,
    },
    [theme.breakpoints.down('xs')]: {
      width:'100%',
    },
  },
  faqLink: {
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  faqMsg: {
    '& p': {
      color: theme.palette.common.white
    },
    '& a': {
      color: theme.palette.common.white,
      textDecoration: 'underline'
    }
  },
}));

const GET_AMOUNT = gql`
  query priceAmount($priceId: String!) {
    priceAmount(priceId: $priceId) {
        interval
        unit_amount
        month_amount
    }
  }
`;

const GET_CURRENT_SUBSCRIPTION = gql`
  query currentUserSubscription {
    currentUserSubscription {
      interval
      unit_amount
      month_amount
    }
  }
`;


const Pricing = ({ selectPlan, createCheckoutSession, enablePrice, me, dialogLocation, dialogClose, pricingPlan }) => {
  const annuallyBtnRef = useRef();
  const monthlyBtnRef = useRef();
  const classes = useStyles();
  const theme = useTheme();
  const orangeDark = theme.palette.common.orange[600];
  const orangeLight = theme.palette.common.orange[300];
  const usertype = me?.userType;

  const [annualPrice, setAnnualPrice] = useState(0);
  const [monthPrice, setMonthPrice] = useState(0);
  const [currentPrice, setCurrentPrice] = useState({price: 0, interval: ''});
  const [selectedTab, setSelectedTab] = useState('annual');
  const [price, setPrice] = useState();

  let freePlan;
  let premiumPlan;
  const handlePricingData = (pricingPlan) => {
    pricingPlan.forEach(item => {
      if (item.planType === 'freePlan') {
        freePlan = {
          title: item.title,
          description: item.description,
          featureList: item.featureList,
          planType: item.planType
        }
      } else if (item.planType === 'premiumPlan') {
        premiumPlan = {
          title: item.title,
          description: item.description,
          discountText: item.discountText,
          b2bLicenceText: item.b2bLicenceText,
          featureList: item.featureList,
          planType: item.planType
        }
      }
    })
  };
  if (pricingPlan) {
    handlePricingData(pricingPlan);
  }

  const showPrice = (selectedPrice, reccurent = false) =>{

    return (
      <Box display="flex" flexDirection="column" position="relative">
        <Typography component="span" className={'price__number price__number--decimal'}>€{Math.floor(selectedPrice)}<span style={{ marginLeft:-5 }}>,{Math.floor((selectedPrice % 1) * 100)}</span></Typography>
        {reccurent && <Typography component="span" className={'price__number price__number--text'} style={{ textTransform: 'capitalize' }}>{reccurent}</Typography>}
      </Box>
    );
  }

  const actualPrice = showPrice(currentPrice.price, currentPrice.interval);

  // let anchorLink = null;
  // if (dialogLocation === '/subscription') {
  //   anchorLink = <Link className={classes.faqLink} component="button" onClick={dialogClose}>FAQs here.</Link>;
  // } else {
  //   anchorLink = <Link component={HashLink} to={'/subscription#faq'} smooth>FAQs here.</Link>;
  // }

  const annualPriceLicense = useQuery(GET_AMOUNT, { variables: { priceId: env.REACT_APP_PRICE_ID_YEAR },
    onCompleted: () => {
      setAnnualPrice(annualPriceLicense?.data.priceAmount ? annualPriceLicense.data.priceAmount.month_amount / 100 : 0);
      const priceAnnually = showPrice(annualPriceLicense?.data.priceAmount ? annualPriceLicense.data.priceAmount.month_amount / 100 : 0);
      setPrice({
        number: priceAnnually,
        text: premiumPlan.discountText,
        monthly: false,
      })
    }
  });
  const monthPriceLicense = useQuery(GET_AMOUNT, { variables: { priceId: env.REACT_APP_PRICE_ID_MONTH },
    onCompleted: () => {
      setMonthPrice(monthPriceLicense?.data.priceAmount ? monthPriceLicense.data.priceAmount.month_amount / 100 : 0);
    }
  });

  const currentPriceLicense = useQuery(GET_CURRENT_SUBSCRIPTION, {
    onCompleted: () => {
      setCurrentPrice({
        price: currentPriceLicense?.data.currentUserSubscription ? currentPriceLicense.data.currentUserSubscription.month_amount / 100 : 0,
        interval: currentPriceLicense?.data.currentUserSubscription ? currentPriceLicense.data.currentUserSubscription.interval : ''
      });
    },
    skip: (usertype !== 'b2c-premium')
  });

  //const emailLink = <Link target="_blank" rel="noopener noreferrer" className={classes.faqLink} href="mailto:learn@gamsplatform.com">learn@gamsplatform.com</Link>;

  const handleAnnualy = () => {
    setSelectedTab('annual');
    const priceAnnually = showPrice(annualPrice)
    setPrice({
      number: priceAnnually,
      text: premiumPlan.discountText,
      monthly: false,
    })
    selectPlan(env.REACT_APP_PRICE_ID_YEAR) // Annually plan
  }

  const handleMonthly = () => {
    setSelectedTab('monthly');
    const priceMonthly = showPrice(monthPrice)
    setPrice({
      number: priceMonthly,
      text: "",
      monthly: true,
    })
    selectPlan(env.REACT_APP_PRICE_ID_MONTH) // Monthly plan
  }

  const handleBuyNow = () => {
    createCheckoutSession() // Create Stripe checkout session by priceId
  }

  useEffect(() => {
    if (enablePrice && price) {
      if(selectedTab === 'annual') {
        annuallyBtnRef.current.style.backgroundColor = orangeDark;
        monthlyBtnRef.current.style.backgroundColor = orangeLight;
      } else if (selectedTab === 'monthly') {
        monthlyBtnRef.current.style.backgroundColor = orangeDark;
        annuallyBtnRef.current.style.backgroundColor = orangeLight;
      }
    }
  }, [price, selectedTab, enablePrice, orangeDark, orangeLight]);

  return (
    <Box className={classes.root}>
      <Paper className={`${classes.paper} ${classes.trial}`}>
        <Box className={classes.pricingHead}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h2">{freePlan?.title}</Typography>
          </Box>
          <Typography variant="body1" component="div">
           <ReactMarkdown children={freePlan?.description} />
          </Typography>
        </Box>

        <Box className={classes.pricingAction} style={{ marginBottom: 86 }}>
          <Box className={'price'}>
            <Typography component="span" className={'price__number'}>€0</Typography>
            { enablePrice && price &&
              <Button
                variant="contained"
                color="default"
                className={classes.pricingBtn}
                disabled={true}>
                Current plan
              </Button>
            }
          </Box>
        </Box>

        <Box className={classes.pricingFooter}>
          {/* <Typography variant="body1" component="div" className={'intro-list'}>
            The trial includes the basics:
          </Typography> */}
          <ul className={'pricingPlan'}>
            {freePlan?.featureList.map((item, index) => {
              return (
                <li key={`price-${index}`} className={'pricingPlan__item'}>
                  <div className={'pricingPlan__icon'}>
                    <DoneIcon/>
                  </div>
                  <div className={'pricingPlan__text'}>
                    <Typography variant="body1" component="span">{item.text}</Typography>
                  </div>
                </li>
              )
            })}
          </ul>
        </Box>
      </Paper>

      <Paper className={`${classes.paper} ${classes.premium}`}>
        <Box className={classes.pricingHead}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography variant="h2">{premiumPlan?.title}</Typography>
            { enablePrice && price &&
            <ButtonGroup
              className={'planSwitchBtn'}
              size="small"
              variant="contained"
              color="primary"
            >
              <Button
                onClick={handleAnnualy}
                ref={annuallyBtnRef}>Pay Annually</Button>
              <Button
                onClick={handleMonthly}
                ref={monthlyBtnRef}>Pay Monthly</Button>
            </ButtonGroup>
            }
          </Box>
          <Typography className={'pricingHead__subtitle'} variant="body1" component="div">
            <ReactMarkdown children={premiumPlan?.description} />
          </Typography>
          {usertype === 'b2b' ?
            <Box className={classes.faqMsg}>
            <Typography variant="body1" component="div">
              {/* This license is offered by your Academy, contact us at {emailLink} to learn more or check {anchorLink} */}
              <ReactMarkdown children={premiumPlan?.b2bLicenceText} />
            </Typography>
          </Box>
        : null}

        </Box>

        <Box className={classes.pricingAction}>
          <Box className={'price'}>
            { enablePrice && price ?
              price.number
            :
              usertype === 'b2c-premium' ?
              <Typography component="span" className={'price__number'}>{actualPrice}</Typography>
              :
              null
            }
            <Button
              variant="contained"
              color="default"
              className={classes.pricingBtn}
              disabled={!enablePrice}
              onClick={handleBuyNow}
              classes={{
                disabled: classes.btnPremiumDisabled
              }}>
              {enablePrice ? 'Buy now' : 'Current plan'}
            </Button>
          </Box>
          { enablePrice && price &&
          <Typography variant="body2" component="div" className={'price__info'}>
            {price.text}
          </Typography>
          }
        </Box>

        <Box className={classes.pricingFooter}>
          <Typography variant="body1" component="div" className={'intro-list'}>
            The Premium includes:
          </Typography>
          <ul className={'pricingPlan'}>
            {/* <li className={'pricingPlan__item'}>
              <div className={'pricingPlan__icon'}>
                <DoneOutlineIcon style={{ color:'#67C868'}}/>
              </div>
              <div className={'pricingPlan__text'}>
                <Typography variant="body1" component="span">{'Everything in MadTech Essentials'}</Typography>
              </div>
            </li> */}
            {premiumPlan?.featureList.map((item, index) => {
              return (
                <li key={`price-${index}`} className={'pricingPlan__item'}>
                  <div className={'pricingPlan__icon'}>
                    <DoneOutlineIcon style={{ color:'#67C868'}}/>
                  </div>
                  <div className={'pricingPlan__text'}>
                    <Typography variant="body1" component="span">{item.text}</Typography>
                  </div>
                </li>
              )
            })}
          </ul>
        </Box>
      </Paper>
    </Box>);
}

export default Pricing;
