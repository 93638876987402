import React from 'react';
import ReactMarkdown from 'react-markdown';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import blueGrey from '@material-ui/core/colors/blueGrey';
import ShareButtonGroup from '../ShareButtonGroup/ShareButtonGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const styles = theme => ({
  root: {
    width: '100%',
    height: 284,
    backgroundColor: blueGrey[100],
    paddingTop: 22,
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
     paddingTop: 18,
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: 'rgba(30, 70, 89, 0.34)',
    display: 'inline-block',
    padding: '9px 21px',
    borderRadius: 9,
    letterSpacing: '0.3px'
  },
  pscRoot: {
    width: '100%',
    marginBottom: 40
  },
  phRoot: {
    maxWidth: '800px',
    marginBottom: 35,
    [theme.breakpoints.down('sm')]: {
     maxWidth: '100%',
    },
  },
  phTitle: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 9
  },
  phSubTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.4,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20
    },
    '& p': {
      marginBottom:6
    }
  }
});

const PageHeader = ({ classes, data, pscTitle, pscSubTitle, publicLink, pdfLink, imgLink, title, subTitle,psc }) => {
  const theme = useTheme();
  const mediaQuerySm = useMediaQuery(theme.breakpoints.down('sm'));

  let pageHeader = null;
  if (data) { // Used in Challenge (06 to 10)
    pageHeader =
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h1">
        {data.challengeTitle}
      </Typography>
    </Box>;
  } else if (pscTitle) { // Used in Profile Scorecard
    pageHeader =
    <Box
      display={ mediaQuerySm ? "block" : "flex" }
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.pscRoot}>
      <Box pr={3} maxWidth="800px">
        <Typography className={classes.phTitle} variant="h1">
          {pscTitle}
        </Typography>
        <Typography className={classes.phSubTitle} variant="h2">
          {pscSubTitle}
        </Typography>
      </Box>
      <Box>
        <ShareButtonGroup
          publicLink={publicLink}
          pdfLink={pdfLink}
          imgLink={imgLink} 
          psc={psc}
          />
      </Box>
    </Box>;
  } else {
    pageHeader =
    <Box
      display={ mediaQuerySm ? "block" : "flex" }
      justifyContent="space-between"
      alignItems="flex-end"
      className={classes.phRoot}>
      <Box pr={3}>
        <Typography className={classes.phTitle} variant="h1">
          {title}
        </Typography>
        <Typography className={classes.phSubTitle} variant="h2" component="div">
          <ReactMarkdown children={subTitle} />
        </Typography>
      </Box>
    </Box>;
  }

  return (
    <>
      {pageHeader}
    </>
  )
}

export default withStyles(styles)(PageHeader);
