import React, { Component } from 'react';
import { width100 } from '../../../../settings/otherStyles';
import Autocomplete from "../../../../common/components/base/Autocomplete";
import { durations } from "../../../../settings/dataSets";
import { Query } from "@apollo/client/react/components";
import LoadingAnimation from "../../../../common/components/base/LoadingAnimation";
import TextField from '@material-ui/core/TextField';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { GET_ADVERTISERS } from '../../../campaign/queries';
import ReportLineItemContainer from "./ReportLineItemContainer";
import Grid from "@material-ui/core/Grid";
import { CardActions } from "@material-ui/core";
import { lineItemsVar } from '../../../../settings/cache';

class ReportCampaignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      ...props,
      advertiser: {
        ID: "CA1001",
        advertisername: "Skinius-Cosmetics"
      },
      duration: "168",
      name: "skinius_campaign_this_summer",
      items: props.items
    };
    this.deleteRow = this.deleteRow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.openReport = this.openReport.bind(this);
    this.resetLineItemForm = this.resetLineItemForm.bind(this);
    this.ClearLineItemForm = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.name)
      this.setState({ name: nextProps.name });
  }

  handleChange = (event, currentTab) => {
    this.setState({ currentTab });
    if (currentTab === 1) this.state.handleLineItems();
  };

  deleteRow(index) {
    this.state.items.splice(index);
    lineItemsVar(this.state.items);
    this.setState({ items: this.state.items });
  }

  handleTab = () => {
    const newTabIndex = this.state.currentTab === 0 ? 1 : 0;
    this.setState({
      currentTab: newTabIndex
    });
    if (this.state.currentTab === 1) this.state.handleLineItems();
  };

  openReport = () => {
    this.props.history.push("/campaign");
  }

  resetLineItemForm = () => {
    document.getElementById("LineItemFormInput").reset();
    this.ClearLineItemForm.current.clearLineItemForm();
    if (this.state.handleNewLineItem)
      this.state.handleNewLineItem();
  }

  submit() {
    if(!this.state.isSubmitting) {
      this.setState({isSubmitting: !this.state.isSubmitting})
      this.state.submit();
      setTimeout(
        () => {
          this.setState({isSubmitting: false})
        },
        1000
      );
    }
  }


  render() {
    const { currentTab } = this.state;
    const { duration, advertiser } = this.props;
    return (
      <div>
        {currentTab === 1 && <Grid container>
          <Grid item xs={6}>
            <p style={{ margin: 0, lineHeight: 3 }}>{this.state.name}</p>
          </Grid>
        </Grid>}
        <Card>
          <div>
            <AppBar position="static" color="default">
              <Tabs value={currentTab} onChange={this.handleChange}>
                <Tab disableRipple label="Campaign" />
                <Tab disableRipple label="Line Items" disabled={!this.state.name} />
              </Tabs>
            </AppBar>
          </div>
          <CardContent>
            {currentTab === 0 && <div className="row justify-content-center">
              <Grid container justify="space-around">
                <Grid item xs={10}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="campaignName"
                      label="Name"
                      value={this.state.name}
                      onChange={this.state.handleName}
                      placeholder="campaignname_brand_period"
                      margin="normal"
                      style={width100}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      selectedValue={duration ? { id: duration, label: duration } : ""}
                      handleChange={this.state.handleDuration}
                      values={durations}
                      placeholder="Schedule Duration"
                      defaultValue={this.state.duration} />
                  </Grid>
                  <Grid item xs={12}>
                    <Query query={GET_ADVERTISERS} fetchPolicy="network-only">
                      {({ loading, error, data }) => {
                        if (data && data['advertisersProjected'])
                          return (
                            <Autocomplete key="3" placeholder="Advertiser"
                              handleChange={this.state.handleAdvertiser}
                              selectedValue={advertiser ? { id: advertiser, label: advertiser } : ""}
                              values={data.advertisersProjected.map(value => ({
                                value: value._id,
                                label: value.name
                              }))}
                              defaultValue = {this.state.advertiser.advertisername}
                              />
                          );
                        if (loading) return (<LoadingAnimation />);
                        if (error) return `Error! ${error.message}`;
                      }}
                    </Query>
                  </Grid>
                </Grid>
              </Grid>
            </div>}

            {currentTab === 1 &&
              <div>
                <ReportLineItemContainer items={this.state.items} editRow={this.state.editRow} initialValues={this.state.initialValues} deleteRow={this.deleteRow} saveLineItem={this.state.saveLineItem} ref={this.ClearLineItemForm} />
              </div>
            }
          </CardContent>

          <CardActions>
            <Grid container
              direction="row"
              justify={"space-around"}
              alignItems="center">
              <Grid>
                {
                  this.state.currentTab === 0 &&
                  <button className="btn btn-fill btn-rose" disabled={!this.state.name}
                    onClick={this.handleTab}>Next</button>
                }
                {
                  this.state.currentTab === 1 &&
                  <button className="btn btn-fill btn-rose" onClick={this.handleTab}>Previous</button>
                }
              </Grid>
              <Grid>
                <button disabled={this.state.isSubmitting} className="btn btn-fill btn-rose" onClick={this.submit.bind(this)}>
                  Save Campaign 
                </button>  
              </Grid>
              <Grid>
                <button className="btn btn-fill btn-rose" onClick={this.state.handleReport}>
                  Open Report
                  </button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  }
}

export default ReportCampaignForm;
