import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';
import { crmData } from "../../../../utils/data/sandboxes";
import { timeFrameList } from "../../../../utils/data/crmAutomation";
import { GET_CRM_SIMULATION } from "../../graphql/queries";
import { useQuery } from '@apollo/client';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import WorkflowTitle from '../../../../common/components/WorkflowTitle/WorkflowTitle';
import AutomationTree from '../../../../common/components/AutomationTree/AutomationTree';
import SelectInputField from '../../../../common/components/base/SelectInputField/SelectInputField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(theme => ({
  goBackBtn: {
    marginBottom:6,
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  workflowActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button:last-child': {
      marginLeft: 10
    }
  }
}));

const CrmAutomationSimulation = (props) => {
  const classes = useStyles();
  const automationId = props.match.params.id;

  const [selectValue, setSelectValue] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_CRM_SIMULATION, {
    variables: { id: automationId, days: selectValue },
    fetchPolicy: "cache-and-network"
  });

  const handleTimeframeListChange = (event) => {
    refetch({ id: automationId, days: event.target.value });
    setSelectValue(event.target.value);
  };

  const simulation = data?.crmSimulation;

  return (
    <SandboxLayout layoutData={crmData} name="automation">
      <SandboxHeader
        customTitle={
          <WorkflowTitle
            loading={loading}
            error={error}
            isSimulation={true}
            preTitle={'Workflow Simulation of '}
            title={simulation?.name}
            allowEdit={false}
          />
        }
        description={<>You can check the <strong>different time frames</strong> by clicking the <strong>selector on the right</strong> and adjust the simulation.</>}
        extraStyles={{ marginBottom: 0 }}
        action={
          <Box className={classes.workflowActions}>
            <SelectInputField
              selectValue={selectValue}
              selectList={timeFrameList}
              selectLabel="Select time frame"
              onSelectChange={handleTimeframeListChange}
            />
          </Box>
        }
      />

      <Box textAlign="right" mr="-8px">
        <Button
          startIcon={<ArrowBackIcon />}
          component={RouterLink}
          to={`/crm/automation/${automationId}/edit`}
          className={classes.goBackBtn}
        >Back to Workflow</Button>
      </Box>

      <AutomationTree
        error={error}
        loading={loading}
        isSimulation={true}
        tree={simulation}
      />
    </SandboxLayout>
  );
};

export default CrmAutomationSimulation;
