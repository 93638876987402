import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Link, Box, Typography } from '@material-ui/core';
import { crmData } from "../../../../utils/data/sandboxes";
import { listsTableColumns } from "../../../../utils/data/crmLists";
import { GET_CRM_LISTS } from "../../graphql/queries";
import { DELETE_CRM_LIST } from "../../graphql/mutations";
import { useQuery, useMutation } from '@apollo/client';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxTable from "../../../../common/components/SandboxTable/SandboxTable";
import SandboxTableHeader from "../../../../common/components/SandboxTable/SandboxTableHeader/SandboxTableHeader";
import SandboxTableBody from '../../../../common/components/SandboxTable/SandboxTableBody/SandboxTableBody';
import SearchFilter from '../../../../common/components/SandboxTable/SandboxTableFilters/SearchFilter/SearchFilter';
import SandboxDialog from "../../../../common/components/Dialogs/SandboxDialog/SandboxDialog";
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  actionBtn: {
    width:160,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    padding: theme.spacing(1.1, 3.5, 1.1, 3.5),
    fontSize: theme.typography.pxToRem(18),
    letterSpacing: 0.2,
    borderRadius:6,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  },
  dialogBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
  dialogDefaultBtn: {
    '&:focus': {
      outline: 'none',
    }
  },
  textLink: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration:'none',
    textTransform: 'capitalize',
    color: theme.palette.common.gamsDarkBlue,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue
    }
  }
}));

const CrmListsPage = props => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { loading, error, data, refetch } = useQuery(GET_CRM_LISTS, {
    variables: { page: 0 },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });
  const [deleteCrmList] = useMutation(DELETE_CRM_LIST);

  const handleSearchChange = (event) => {
    refetch({ page: 0, search: event.target.value })
  };

  const handlePageChange = (event, page) => {
    refetch({ page: page});
    setPage(page);
  }

  const handleEdit = (id) => {
    props.history.push(`/crm/lists/edit/${id}`);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  const deleteItem = () => {
    deleteCrmList({ variables: { id: deleteId } })
    .then(({ data }) => {
      setDeleteDialogOpen(false);
      // Refresh page after delete mutation
      props.history.push({ pathname: "/crm/empty" });
      props.history.replace({ pathname: "/crm/lists" });
    })
    .catch(error => {
      setDeleteDialogOpen(false);
      setErrorDialogOpen(true);
      setErrorMsg(error.toString());
    });
  }

  const createData = (_id, id, name, totalListCount, _username) => {
    return { _id, id, name, totalListCount, _username };
  }

  const rows = data && data.crmLists.records.map((item) => {
    return createData(item._id, item.numberId, item.name, item.contactsTotalCount, item.username);
  });

  return (
    <SandboxLayout layoutData={crmData} name="Lists">
      <SandboxHeader
        title="Lists"
        subTitle={<>You can find and manage your lists in the table hereby, all lists are used to filter the users in the <Link className={classes.textLink} component={RouterLink} to={`/crm/contacts`}>contacts section</Link>.</>}
        extraStyles={{ marginBottom: 50 }}
        action={
          <Button
            component={RouterLink}
            to={'/crm/lists/add'}
            className={classes.actionBtn}
            color="default"
            variant="contained"
            startIcon={<AddIcon style={{ fontSize: 24 }}/>}>
            Add List
          </Button>
        }
      />

      {loading ? (
        <LoadingSpinner boxed={true} styles={{ paddingTop: 70}}/>
      ) : error ? (
        <div>Error! {error.message}</div>
      ) : (
        <SandboxTable
          page={page}
          onPageChange={handlePageChange}
          count={data.crmLists.totalCount}
          rowPerPage={data.crmLists.rowPerPage}
          tableToolbar={
            <SearchFilter
              searchLabel="Search..."
              onSearchChange={handleSearchChange}
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              onSetPage={setPage}
            />
          }
        >
          <SandboxTableHeader
            tableColumns={listsTableColumns}
            tableCellAlign="right"
            lastTableCellAlign="center"
          />
          <SandboxTableBody
            tableColumns={listsTableColumns}
            rows={rows}
            tableCellAlign="right"
            lastTableCellAlign="center"
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </SandboxTable>
      )}

      <SandboxDialog
        id="deleteListDialog"
        warningIcon={true}
        dialogClose={handleDeleteDialogClose}
        dialogOpen={deleteDialogOpen}
        dialogTitle="Delete list"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">Are you sure you want to delete this list?</Typography>
          </Box>
        }
        dialogActions={
          <>
            <Button
              className={classes.dialogDefaultBtn}
              color="default"
              variant="outlined"
              size="large"
              onClick={handleDeleteDialogClose}
            >Cancel
            </Button>
            <Button
              className={classes.dialogBtn}
              color="default"
              variant="contained"
              size="large"
              onClick={deleteItem}
            >Delete item
            </Button>
          </>
        }
      />
      <SandboxDialog
        id="errorListDialog"
        errorIcon={true}
        dialogClose={handleErrorDialogClose}
        dialogOpen={errorDialogOpen}
        dialogTitle="Error"
        dialogMessage={
          <Box textAlign="center">
            <Typography variant="body1" component="p">{errorMsg}</Typography>
          </Box>
        }
        dialogActions={
          <Button
            className={classes.dialogDefaultBtn}
            color="default"
            variant="outlined"
            size="large"
            onClick={handleErrorDialogClose}
          >Cancel
          </Button>
        }
      />

    </SandboxLayout>
  );
}

export default CrmListsPage;
