import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { IconButton, Collapse } from '@material-ui/core';
import { Cookies } from "react-cookie";

const useStyles = makeStyles(theme => ({
  collapse:{},
  alert: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 25px 0 30px',
    borderRadius: 0,
    backgroundColor: theme.palette.common.blue[400],
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px 0 20px',
    }
  },
  alertIcon: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& svg':{
      fontSize:28,
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        fontSize:24,
      }
    }
  },
  alertText:{
    [theme.breakpoints.down('sm')]: {
      fontSize:14
    },
    '& p':{
      margin: 'auto',
    },
    '& a': {
      margin: 'auto',
      color: theme.palette.grey[800],      
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.grey[500],
      }
    } 
  },
  closeIcon: {
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize:24,
    }
  },
}));


const Banner = ({ id, text}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const cookie = new Cookies();
  const bannerText = text && parse(text);
  const cookieBanners = cookie.get('banners');

  useEffect(() => {
    if (cookieBanners) setOpen(!cookieBanners.includes(id));
  }, [cookieBanners, id]);
  
  const setClose = (id) => {
    let array = cookieBanners || [];
    array.push(id);
    return JSON.stringify(array);
  }

  return (
    <Collapse
      className={classes.collapse}
      in={open}
    >
      <Alert
        classes={{
          root: classes.alert,
          icon: classes.alertIcon,
          message: classes.alertText
        }}
        icon={<ErrorOutlineIcon />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={() => {
              setOpen(false);
              const add = setClose(id);
              cookie.set('banners', add);
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        }
      >
        {bannerText}
      </Alert>
    </Collapse>
  )
}

export default Banner;
