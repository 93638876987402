import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    padding: theme.spacing(2.5, 0, 3.5, 0),
    borderRadius: 8,
  },
  dialogTitle: {
    paddingTop: 0,
    '& h2': {
      fontSize: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: 1.3,
    }
  },
  dialogActions: {
    padding: theme.spacing(0, 3),
  },
  icon: {
    fontSize: theme.typography.pxToRem(70),
  },
  successGreenColor: {
    color: theme.palette.success.main
  },
  Submitbtn: {
    margin:'0 auto',
    marginTop:25,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    textTransform:'none',
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    }
  },
}));

const AuthDialog = ({ dialogOpen, dialogClose, dialogTitle, dialogMessage, dialogBtnRouter, dialogBtnLink, dialogBtnText, dialogActions, successIcon, emailIcon, id }) => {
  const classes = useStyles();

  return (
    <Dialog
      id={id}
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{
        paper: classes.dialogRoot
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Box>
            { successIcon ?
              <CheckCircleIcon className={`${classes.icon} ${classes.successGreenColor}`}/>
            : null}
            { emailIcon ?
              <EmailIcon className={`${classes.icon} ${classes.successGreenColor}`}/>
            : null}
          </Box>
          <Typography variant="h2" component="h2">{dialogTitle}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1" component="div">{dialogMessage}</Typography>
      </DialogContent>
      { dialogActions ?
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.Submitbtn}
          color="default"
          variant="contained"
          size="large"
          fullWidth
          {...(dialogBtnRouter ? {component: RouterLink} : {})}
          {...(dialogBtnLink ? {to: dialogBtnLink} : {})}
        >
          {dialogBtnText}
        </Button>
      </DialogActions>
      : null}
    </Dialog>
  );
}

export default AuthDialog;
