import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Box, Typography } from "@material-ui/core";
import CheckSharpIcon from '@material-ui/icons/CheckSharp';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: '100%',
    top: -36,
  },
  progressRoot: {
    height: 4,
    borderRadius: 6,
  },
  progressColorPrimary: {
    backgroundColor: '#b0bec5',
  },
  progressBar: {
    borderRadius: 6,
    backgroundColor: '#2A5B77',
  },
  icon: {
    color: '#2A5B77',
    marginRight: 2
  }
}));

const StepProgressBar = props => {
  const classes = useStyles();

  let min = 0;
  let max = props.steps.length;
  const normalise = value => (value - min) * 100 / (max - min);

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" m={'3px'}>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Start
          </Typography>
        </Box>
        <Box width="100%" m={1}>
          <LinearProgress
            variant="determinate"
            value={normalise(props.stepIndex + 1)}
            classes={{
              root: classes.progressRoot,
              colorPrimary: classes.progressColorPrimary,
              bar: classes.progressBar,
            }}/>
        </Box>
        <Box display="flex" alignItems="center">
          <CheckSharpIcon className={classes.icon}/>
          <Typography variant="body2" color="textSecondary">
            End
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default StepProgressBar;
