import Layout from "../Layout";
import React from 'react';
import UserSettings from '../components/main/UserSettings';

const EditUserSettings = () => (
  <Layout name="Dashboard">
    <div className="wizard-container">
      <UserSettings/>
    </div>
  </Layout>
);

export default EditUserSettings;
