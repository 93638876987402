import React, { Component } from 'react';
//import Layout from '../../common/Layout';
import CampaignForm from './CampaignForm';
import { client } from "../../settings/apollo";
import LoadingAnimation from "../../common/components/base/LoadingAnimation";
import { Builder } from "../../settings/builder";
import { GET_CAMPAIGN, UPDATE_CAMPAIGN } from "./queries";
import { campaignVar, lineItemVar } from '../../settings/cache';
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

class CampaignUpdate extends Component {
  constructor(props) {
    super(props);

    this.handleAdvertiser = this.handleAdvertiser.bind(this);
    this.handleDuration = this.handleDuration.bind(this);
    this.handleName = this.handleName.bind(this);
    this.submit = this.submit.bind(this);
    this.state = { id: props.match.params.id };
  }

  async componentDidMount() {
    await client.query({
      query: GET_CAMPAIGN,
      variables: { id: this.state.id }
    }).then((res) => {
      let campaign = Builder.createCampaign(res.data.campaign);
      let lineItems = res.data.campaign.lineItems;
      lineItems.forEach((property) => {
        delete property.__typename
      })
      campaignVar(campaign)
      lineItemVar(lineItems)
      this.setState({
        _id: campaign._id,
        name: campaign.name,
        duration: campaign.duration,
        advertiser: campaign.advertiser,
        lineItems: res.data.campaign.lineItems
      });
    });
  }

  handleAdvertiser(item) {
    this.setState({ advertiser: item != null ? item.label : item });
  }

  handleName(e) {
    this.setState({ name: e.target.value });
  }

  handleDuration(item) {
    this.setState({ duration: item != null ? item.label : item });
  }

  async submit() {
    if (this.state.name && this.state.name !== '' && this.state.duration !== null) {
      const input=  {
        _id: this.state._id,
        name: this.state.name,
        advertiser: this.state.advertiser,
        duration: this.state.duration,
        lineItems: this.state.lineItems
      }
      await client.mutate({
           mutation: UPDATE_CAMPAIGN,
           variables: {
             input: input
           },
         }).catch((error) => {
           console.log(error);
         }).then(() => window.location.assign('/campaigns'));
     }
  }

  render() {
    if (this.state._id)
      return (
        <SandboxLayout name="Campaign" layoutData={campaignAdsManagerData}>
          <CampaignForm handleAdvertiser={this.handleAdvertiser}
            handleDuration={this.handleDuration}
            handleName={this.handleName}
            advertiser={this.state.advertiser}
            duration={this.state.duration}
            name={this.state.name}
            items={this.state.lineItems}
            caption="Category"
            submit={this.submit}
            id={this.state.id}
          />
        </SandboxLayout>
      );
    else {
      return <LoadingAnimation />;
    }
  }
}

export default CampaignUpdate;
