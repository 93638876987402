import ItemsGrid from "../../common/components/widgets/ItemsGrid";
import {lineItemGridColumns} from "../../settings/dataSets";
import {Formik} from "formik";
import {validateLineItem} from "../Validations";
import LineItemForm from "./LineItemForm";
import React, {Component} from "react";
import * as PropTypes from "prop-types";
import {lineItemsInitValues} from "../placement/InitValues";
import { creativesVar, deliveryVar, frequencyCAPVar, placementVar, recencyVar, scheduleVar, typeVar } from "../../settings/cache";

class LineItemContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      initialValues : lineItemsInitValues,
      editMode: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.ResetLineItemForm = React.createRef();
    this.editLineItem = this.editLineItem.bind(this);
  }

  handleSubmit = async(values, setSubmitting, resetForm) => {
    if ( this.state.editMode === true) {
      await this.props.updateRow(this.state.index, values)
    }
    else {
      await this.state.saveLineItem(values, setSubmitting);
    }
    this.setState({ editMode: false })
    resetForm(lineItemsInitValues);
    this.clearLineItemForm();
  }

  editLineItem = (index, item) => {
    this.setState({ editMode: true})
    this.setState({ initialValues: item})
    this.setState({ index: index})
  }

  clearLineItemForm = () => {
    placementVar("");
    frequencyCAPVar("");
    scheduleVar("");
    deliveryVar("");
    recencyVar("");
    typeVar("");
    creativesVar({});
    this.ResetLineItemForm.current.resetLineItemForm()
  }

  render() {
    
    const {items, deleteRow} = this.props;
    return (
      <div>
        <ItemsGrid columns={lineItemGridColumns} title="Line Items" items={items}
                   remove={deleteRow} edit={this.editLineItem}/>
        <Formik
          initialValues={this.state.initialValues}
          validate={validateLineItem}
          onSubmit={(values, {setSubmitting, resetForm}) => this.handleSubmit(values, setSubmitting, resetForm)}
          enableReinitialize = { this.state.editMode }

        >
          {({values, errors, handleChange, touched, setFieldValue}) => (
            <LineItemForm values={values} errors={errors} handleChange={handleChange} touched={touched} ref={this.ResetLineItemForm} setFieldValue={setFieldValue} />
          )}
        </Formik>
      </div>
    )
  }
}

LineItemContainer.propTypes = {
  items: PropTypes.array.isRequired,
  deleteRow: PropTypes.func.isRequired,
  updateRow: PropTypes.func,
  saveLineItem: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};


export default LineItemContainer;
