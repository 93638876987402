import Autocomplete from "../../common/components/base/Autocomplete";
import React from "react";
import gql from "graphql-tag";
import {Query} from "@apollo/client/react/components";
import LoadingAnimation from "../../common/components/base/LoadingAnimation";

const GET_PLACEMENTS = gql`
    query{
        placementsProjected{
            _id
            name
        }
    }
`;

const GET_PLACEMENTS_SIMULATION = gql`
    query{
      placementsSimulation{
        _id
        name
      }
    }
`;

const PlacementAutocomplete = (props)=>{

    let query = null;
    if(props.simulation){
      query = GET_PLACEMENTS_SIMULATION;
    }else{
      query = GET_PLACEMENTS;
    }
    return (
        <Query query={query} fetchPolicy="network-only">
            {({loading, error, data}) => {
              if (loading) return (<LoadingAnimation/>);
              if (error) return `Error! ${error.message}`;
              if(!props.simulation){
                if (data!=null)
                  return (
                    <Autocomplete handleChange={props.handleChange}
                                  single={props.single}
                                  selectedValue={props.selectedValue}
                                  values={data.placementsProjected.map(value=>({value:value.name, label:value.name}))}
                                  placeholder={props.placeholder}/>
                  );
              }
                else{
                  if (data["placementsSimulation"])
                    return (
                      <Autocomplete handleChange={props.handleChange}
                                    single={props.single}
                                    selectedValue={props.selectedValue}
                                    values={data.placementsSimulation.map(value=>({value:value.name, label:value.name}))}
                                    placeholder={props.placeholder}/>
                    );
                }
            }}
        </Query>
    );
};

export default PlacementAutocomplete;
