import React from 'react';
import SignUp from './SignUp';

const SignUpReferral = props => {
  const referral = props.match.params.id;

  return (
    <>
      <SignUp referral={referral}>
      </SignUp>
    </>
  );
}

export default SignUpReferral;
