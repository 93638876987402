import React from "react";
//import Layout from "../../common/Layout";
import { Formik } from "formik";
import gql from "graphql-tag";
import { Mutation } from "@apollo/client/react/components";
import CreativeForm from "./CreativeForm";
import { withRouter } from "react-router-dom";
import validateAdvertiser from "../Validations";
import { creativeSizes, creativeImages } from "../../constants/constants";
import SandboxLayout from "../../hoc/SandboxLayout/SandboxLayout";
import { campaignAdsManagerData } from "../../utils/data/sandboxes";

const ADD_CREATIVE = gql`
  mutation createCreative($input: CreativeInsertInput) {
    createCreative(input: $input) {
      _id
      username
      name
      size
      type
    }
  }
`;

class CreativeInsert extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  render() {
    return (
      <SandboxLayout name="Creative" layoutData={campaignAdsManagerData}>
        <div className="wizard-container">
          <div className="col-md-8 col-12 mr-auto ml-auto">
            <div
              className="card card-wizard active"
              data-color="rose"
              id="wizardProfile"
            >
              <Mutation mutation={ADD_CREATIVE}>
                {addCreative => (
                  <Formik
                    initialValues={{ name: "", type: "", size:"", fileName: creativeImages[1]}}
                    validate={validateAdvertiser}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      addCreative({
                        variables: {
                          input: {
                            name: values.name,
                            size: values.size,
                            type: values.type,
                          }
                        }
                      })
                        .catch(error => {
                          console.log(error);
                        })
                        .then(() => this.props.history.push("/creatives"));
                      console.log(values);
                    }}
                  >
                    {({ isSubmitting, values, handleChange, setFieldValue, handleSubmit }) => (
                      <CreativeForm
                        logo={false}
                        caption="Category"
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        isSubmitting={isSubmitting}
                        values={values}
                        creativeImages={creativeImages}
                        creativeSizes={creativeSizes}
                        handleSubmit={handleSubmit}

                      />
                    )}
                  </Formik>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </SandboxLayout>
    );
  }
}

export default withRouter(CreativeInsert);
