import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardHeader, CardActions, CardContent, Typography, Box, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    minHeight: 320,
    borderRadius: 6,
    height: '100%',
    position:'relative',
    '@media print': { // styles for pdf psc
      minHeight: 290,
    }
  },
  cardHeader: {
    paddingBottom:0
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `solid 1px ${theme.palette.common.gamsOrange}`,
    paddingBottom: 8,
    '@media print': { // styles for pdf psc
      borderBottom: 'none',
      paddingBottom: 2,
    }
  },
  cardTitleNs: {
    color: theme.palette.grey[400],
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
    '@media print': { // styles for pdf psc
      borderBottom:'none',
    }
  },
  cardTitleStatus: {
    color: theme.palette.grey[400],
  },
  cardContent: {
    paddingTop: theme.spacing(1.6),
  },
  cardAction: {
    backgroundColor: theme.palette.grey[200],
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1.3, 2, 2.1, 2),
  },
  skillBox: {
    marginBottom:10,
    '&:last-child': {
      marginBottom:0,
    }
  },
  skillTitle: {
    lineHeight:1.3,
    marginBottom:2
  },
  skillTitleNs: {
    color: theme.palette.grey[400]
  },
  skillPrgRoot: {
    height: 8,
  },
  skillPrgColorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  skillPrgBar: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
  },
  skillPrgBarNs: {
    backgroundColor: theme.palette.grey[400],
  },
  skillPrgBarPs: {
    backgroundColor: theme.palette.common.blue[500],
  },
  overallTitle: {
    lineHeight:1.3,
    marginBottom:2
  },
  overallTitleNs: {
    color: theme.palette.grey[400],
  },
  overallPrgRoot: {
    height: 8,
  },
  overallPrgColorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  overallPrgBar: {
    backgroundColor: theme.palette.common.gamsOrange,
  },
  overallPrgBarNs: {
    backgroundColor: theme.palette.grey[400],
  },
  overallPrgBarPs: {
    backgroundColor: theme.palette.common.orange[500],
  },
}));

const MetaSkills = ({ scores, urlPath, inPdf }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={inPdf ? 1 : 3}>
      {scores.map((score, index) => {
        let min = 0;
        let max = score.overallObtainableScore;
        const normalise = value => (value - min) * 100 / (max - min);
        const statusCaption = score.status === "finished" ? null : <Typography variant="body2" component="span" className={classes.cardTitleStatus}>({score.status})</Typography>;
        return (
          <Grid key={index} item xs={12} sm={inPdf ? 3 : 6} md={6} lg={urlPath ? 4 : 6} xl={4}>
            <Card className={classes.cardRoot} variant="outlined">
              <CardHeader
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box width={inPdf ? '58%' : 'auto'}>{score.challengeLearningTopic}</Box>
                    <Box width={inPdf ? '42%' : 'auto'} textAlign="right">{statusCaption}</Box>
                  </Box>}
                titleTypographyProps={{ variant: 'h3', component: 'h3'}}
                classes={{ root: classes.cardHeader, title: `${classes.cardTitle} ${score.status === "not started" ? classes.cardTitleNs : null}` }}
              />
              <CardContent className={classes.cardContent}>
                {score.learningTopicScores.map((topic, index) => {
                  let min = 0;
                  let max = topic.obtainableScore;
                  const normalise = value => (value - min) * 100 / (max - min);
                  return (
                    <Box key={index} className={classes.skillBox}>
                      <Typography variant="body2" component="h4" className={`${classes.skillTitle} ${score.status === "not started" ? classes.skillTitleNs : null}`}>{topic.metaSkill}</Typography>
                      <LinearProgress
                        variant="determinate"
                        value={normalise(topic.score)}
                        classes={{
                          root: classes.skillPrgRoot,
                          colorPrimary: classes.skillPrgColorPrimary,
                          bar: `${classes.skillPrgBar} ${score.status === "not started" ? classes.skillPrgBarNs : null} ${score.status === "in progress" ? classes.skillPrgBarPs : null}`,
                        }}/>
                    </Box>
                  )
                })}
              </CardContent>
              <CardActions className={classes.cardAction}>
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      variant="body1"
                      component="h4"
                      className={`${classes.overallTitle} ${score.status === "not started" ? classes.overallTitleNs : null}`}>
                        Overall
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={`${classes.overallTitle} ${score.status === "not started" ? classes.overallTitleNs : null}`}>
                      {Math.round(normalise(score.overallScore))}%
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={normalise(score.overallScore)}
                    classes={{
                      root: classes.overallPrgRoot,
                      colorPrimary: classes.overallPrgColorPrimary,
                      bar: `${classes.overallPrgBar} ${score.status === "not started" ? classes.overallPrgBarNs : null} ${score.status === "in progress" ? classes.overallPrgBarPs : null}`,
                    }}/>
                </Box>
              </CardActions>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  );
}

export default MetaSkills;
