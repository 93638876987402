import gql from "graphql-tag";

export const GET_BANNER = gql`
  query banner($id: String!){
    banner(id: $id){
      isEnabled,
      title,
      body,
      expirationDate,
      sections,
    }
  }
`;

export const SHOW_BANNER = gql`
  query showBanner($section: String!){
    showBanner(section: $section){
      _id,
      body
    }
  }
`;

export const GET_BANNERS_DEFINITIONS = gql`
  query bannerDefinitions{
    bannerDefinitions {
      _id,
      isEnabled,
      title,
      body,
      expirationDate,
      sections,
    }
  }
`;

export async function getBannerDefinitions(client) {
  try {
    let data = await client.query({
      query: GET_BANNERS_DEFINITIONS,
    });
    let res = data.data;
    return res;
  } catch (e) {
    console.log(e);
  }
}