import React from 'react';
import {isObject} from "../../../settings/commonFunctions";

const dropStlyle = {
  position: "absolute ",
  top: "41px",
  left: "1px",
  willChange: "top, left"
 };

class SimpleDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleObjectChange = this.handleObjectChange.bind(this);
    this.resetSimpleDropdown = this.resetSimpleDropdown.bind(this);
    const items = props.items.map((value) => {
        if (isObject(value))
          return <div key={value.value} className="dropdown-item" onClick={() => this.handleObjectChange(value)}>{value.label}</div>
        else
          return <div key={value} className="dropdown-item" onClick={this.handleChange}>{value}</div>
      }
      )
    ;
    this.state = {value: '', ...props, };
    this.initState = {value: props.initialValue ? props.initialValue : '', header: props.header, caption: props.initialValue ? props.initialValue : props.caption, items, handleChange: props.handleChange, resetSimpleDropdown: props.resetSimpleDropdown}
    this.state = this.initState

  }

  handleChange(event) {
     this.setState({value: event.currentTarget.textContent, caption: event.currentTarget.textContent});
     //console.log(event.currentTarget.textContent);
     this.state.handleChange(event);
  }

  handleObjectChange(item) {
    this.setState({value: item.value, caption: item.label});
    this.state.handleChange(item);
  }

  resetSimpleDropdown(){
    this.setState(this.initState);
  }

  render() {
    return <div className="dropdown">
      <button className="dropdown-toggle btn btn-primary btn-round btn-block" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {this.state.caption}
        <div className="ripple-container">
        </div>
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={dropStlyle}>
        <h6 className="dropdown-header">{this.state.header}</h6>
        {this.state.items}
      </div>
    </div>
  }
}

export default SimpleDropDown;
