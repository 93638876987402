import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formRoot: {
    //maxWidth: 630,
    margin:'0 auto',
    borderRadius: 6,
    padding: theme.spacing(3),
  },
  formBlock: {
    marginBottom:0
  },
  gridRoot: {
    '& .MuiGrid-item': {
      marginBottom:9,
      [theme.breakpoints.down('xs')]: {
        marginBottom:2,
      },
    }
  },
}));

const SandboxForm = ({ children, formHandleSubmit }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.formRoot} elevation={3}>
      <form className={classes.formBlock} onSubmit={formHandleSubmit}>
        <Grid className={classes.gridRoot} container spacing={2}>
          {children}
        </Grid>
      </form>
    </Paper>
  )
}

export default SandboxForm;
