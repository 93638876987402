import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, Card, CardHeader, CardContent, MenuItem, FormControl, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardHeaderTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium,
    minHeight: 32,
    lineHeight: '32px',
  },
  cardHeaderAction: {
    marginTop:0,
    marginRight:0
  },
  selectMenu: {
    border:`solid 1px ${theme.palette.common.gamsDarkBlue}`,
    paddingTop:5,
    paddingBottom:5,
    color: theme.palette.common.gamsDarkBlue,
  },
  formControl: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsDarkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsDarkBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.gamsDarkBlue,
    }
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0 !important'
  },
  userOverallBox: {
    width: 362,
    margin: '0 auto',
    padding: '7px 10px',
    border: `solid 1px ${theme.palette.grey[300]}`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderRadius: '35px',
    marginTop:8,
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      padding: '10px 10px 12px 10px',
      marginRight: 16,
      marginLeft: 16,
      borderRadius: '45px',
    },
  },
  userOverallAvatar: {
    alignItems: 'center',
    display: 'flex',
    '& div': {
      marginRight: 5
    },
    '& h4': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      width: 105,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      marginBottom:10
    },
  },
  userOverallText: {
    '& div': {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      textAlign: 'center',
      padding:'0px 10px',
    },
    '& div:first-child': {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1,
      color: theme.palette.text.primary
    },
    '& div:last-child': {
      lineHeight: 1,
      color: theme.palette.grey[500]
    },
  },
  userOverallPromotion: {
    width: '100%',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'underline',
    textAlign: 'center',
    color: theme.palette.common.gamsDarkBlue,
    margin: '0 auto',
    paddingTop: 5,
    '&:focus': {
      outline: 'none',
    },
  },
  cellPosition: {
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    width: 40,
    height: 40,
    borderRadius:'50%',
    overflow:'hidden',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    '& span': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  tableHead: {
    backgroundColor: theme.palette.common.gamsGrey[300],
    borderTop: `solid 1px ${theme.palette.grey[300]}`,
    '& .MuiTableCell-head': {
      color: theme.palette.text.secondary,
      paddingTop: theme.spacing(1.15),
      paddingBottom: theme.spacing(1.15),
    }
  },
  tableContainer: {
    marginTop: 25,
  },
  cellTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: 'capitalize'
  },
  cellAvatar: {
    marginRight:7,
    '& img': {
      imageRendering: 'optimize-contrast',
      interpolationMode: 'nearest-neighbor',
    }
  },
  cellScore: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  tablePagination: {
    marginTop: 8,
    marginBottom: 8,
    '& .MuiButtonBase-root':{
      outline: 'none',
    }
  },
}));

const RankingBlock = ({ title, menu, dataTable, dataMenu, onSelectChange, isTrial, openDialog}) => {
  const classes = useStyles();
  const [item, setItem] = useState(dataMenu && dataMenu[0] ? dataMenu[0].value: '');
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const createData = (position, photo, name, score) => {
    return {position, photo, name, score};
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectChange = (event) => {
    setItem(event.target.value);
    onSelectChange(event);
  };

  const userRanking = dataTable && dataTable.userRanking;

  let ranking;
  let rows;
  if(!dataTable || dataTable === null) {
    rows = [createData(null, null, 'No result found', null)];
  } else if (dataTable) {
    ranking = dataTable.ranking;
    rows = ranking.map((user) => { // generate table row content
      return createData(user.pos, user.user.photo, user.user.name, user.score )
    });
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        classes={{
          title: classes.cardHeaderTitle,
          action: classes.cardHeaderAction
        }}
        action={menu &&
          <>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}>
              <Select
                id="demo-simple-select-outlined"
                value={item}
                onChange={handleSelectChange}
                IconComponent={ExpandMoreIcon}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right"
                  },
                  getContentAnchorEl: null
                }}
                classes={{
                  root: classes.selectRoot,
                  select: classes.select,
                  selectMenu: classes.selectMenu
                }}
              >
                {dataMenu.map((menuItem, index) => {
                  return (
                    <MenuItem
                      key={menuItem.title}
                      value={menuItem.value}
                    >
                      {menuItem.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        }
      />
      <CardContent className={classes.cardContent}>
        {userRanking && userRanking.user ?
          <Box className={classes.userOverallBox}>
            <Box className={classes.userOverallAvatar}>
              <Avatar
                src={userRanking.user.photo ? userRanking.user.photo : null}
              />
              <Typography variant="body1" component="h4">{userRanking.user.name}</Typography>
            </Box>
            <div style={{ flexGrow: 1}} />
            <Box
              alignItems='center'
              display='flex'
              width={{ xs: '100%', sm: 'auto' }}
              justifyContent={{ xs: 'center', sm: 'flex-start' }}
            >
              <div className={classes.userOverallText}>
                <Typography variant="body1" component="div">{userRanking.pos || '--'}</Typography>
                <Typography variant="body1" component="div">Your ranking</Typography>
              </div>
              <div className={classes.userOverallText}>
                <Typography variant="body1" component="div">{userRanking.score || '--'}</Typography>
                <Typography variant="body1" component="div">Your score</Typography>
              </div>
            </Box>
          </Box>
        : null}
        { userRanking && userRanking.user && isTrial ?
          <Link
          className={classes.userOverallPromotion}
          component="button"
          variant="body1"
          onClick={openDialog}
        >Improve you score by solving Challenges
        </Link> 
        : null }
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Ranking</TableCell>
                <TableCell align="left">Student</TableCell>
                <TableCell align="right">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow key={`${row.name}-${index}`}>
                    <TableCell component="th" scope="row">
                    <Box className={classes.cellPosition} style={row.position === null ? { backgroundColor: 'white' } : null}>
                      <span>{row.position}</span>
                    </Box>
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        alignItems='center'
                        display='flex'
                      >
                        {dataTable !== null ?
                          <Avatar
                            src={row.photo ? row.photo : null}
                            className={classes.cellAvatar}
                          />
                        : null}
                        <Box className={classes.cellTitle}>
                          {row.name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box className={classes.cellScore}>
                        {row.score}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 73 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) => (`Showing ${from} to ${to} of ${count !== -1 ? count : null} results`)}
        />
      </CardContent>
    </Card>
  )

}

export default RankingBlock;
