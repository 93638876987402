import React, { useState } from 'react';
import './PopHoverLeft.css';

const PopHoverLeft = ({ text, children }) => {
  const [showHover, setShowHover] = useState(false);

  const handleMouseEnter = () => {
    setShowHover(true);
  };

  const handleMouseLeave = () => {
    setShowHover(false);
  };

  return (
    <div
      className="pop-hover-container-left"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showHover && <div className="pop-hover-text-left">{text}</div>}
    </div>
  );
};

export default PopHoverLeft;
