import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';
import ResultTitle from './ResultTitle/ResultTitle';
import ResultTotal from './ResultTotal/ResultTotal';
import ResultSolution from './ResultSolution/ResultSolution';
import './box-blu.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `solid 3px ${theme.palette.common.yellow[600]}`,
    borderRadius: 20,
    padding:theme.spacing(0, 2, 4, 2),
    marginBottom:80,
  }
}));

const ChallengeResultHead = ({ coach, totalSteps, totalTime, totalScore, solutionDesc, solutionSrc }) => {
  const classes = useStyles();
  let challengeName = sessionStorage.getItem('challengeName');
  if (challengeName) {
    if (challengeName.includes('-')) {
      challengeName = challengeName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else{
      challengeName = challengeName.charAt(0).toUpperCase() + challengeName.slice(1);
    }
  } 

  return (
    <Box>
      <Box className='center'>
        <Box className="challenge-box">
          <p className="centered-text">🙌 Well done! You have completed the challenge: {challengeName}</p>
        </Box>
      </Box>
      <ResultTitle coach={coach} />
      <ResultTotal
        totalSteps={totalSteps}
        totalTime={totalTime}
        totalScore={totalScore}
      />
      <ResultSolution
        description={solutionDesc}
        src={solutionSrc}
      />
    </Box>
  );
};

export default ChallengeResultHead;
