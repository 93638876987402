import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent, DialogActions } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 0,
  },
  dialogContent: {
    maxWidth:560,
    margin:'auto',
    textAlign: 'center',
    padding: theme.spacing(0, 2, 1, 2),
    marginBottom:60
  },
  dialogActions: {
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const SandboxDialogForm = ({ dialogContent, dialogActions, formHandleSubmit}) => {
  const classes = useStyles();

  return (
    <form className={classes.root} onSubmit={formHandleSubmit}>
      <DialogContent className={classes.dialogContent}>
        {dialogContent}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {dialogActions}
      </DialogActions>
    </form>
  );
}

export default SandboxDialogForm;
