import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, OutlinedInput, IconButton, InputAdornment, FormControl, InputLabel, FormHelperText, FormGroup, FormControlLabel, Checkbox, Grid } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  formField: {
    '& label.Mui-focused': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.common.gamsPetroleumBlue,
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  input: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
  },
  label: {
    [theme.breakpoints.down('xs')]: {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  formControl: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    '& .MuiFormHelperText-root': {
      textAlign:'center',
      paddingLeft:0,
      marginTop:0
    }
  },
  formControlLabel: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginBottom:0
  },
  formCheckbox: {
    color: `${theme.palette.common.gamsDarkBlue} !important`,
  },
  formCheckHelperText: {
    paddingLeft: 31
  }
}));

const AuthFormField = ({ extraStyle, fieldType, fieldName, fieldLabel, fieldLabelWidth, fieldValue, fieldHandleChange, fieldError, fieldHelperText, fieldGrid }) => {
  const classes = useStyles();
  const [values, setValues] = useState({ showPassword: false });

  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkFieldType = (param) => {
    switch(param) {
      case 'text':
        return (
          <Grid item xs={12} sm={fieldGrid} style={extraStyle}>
            <TextField
              className={classes.formField}
              fullWidth
              id={fieldName}
              name={fieldName}
              autoComplete={fieldName}
              label={fieldLabel}
              variant="outlined"
              value={fieldValue}
              onChange={fieldHandleChange}
              error={fieldError}
              helperText={fieldHelperText}
              inputProps={{className: classes.input}}
              InputLabelProps={{className:classes.label}}
            />
          </Grid>
        );
      case 'password':
        return (
          <Grid item xs={12} style={extraStyle}>
            <FormControl
              variant="outlined"
              className={classes.formField}
              fullWidth
            >
              <InputLabel
                htmlFor={fieldName}
                error={fieldError}
                className={classes.label}
              >{fieldLabel}</InputLabel>
              <OutlinedInput
                id={fieldName}
                name={fieldName}
                autoComplete={fieldName}
                type={values.showPassword ? 'text' : 'password'}
                value={fieldValue}
                onChange={fieldHandleChange}
                error={fieldError}
                inputProps={{className: classes.input}}
                labelWidth={fieldLabelWidth ? fieldLabelWidth : 70}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                id={fieldName}
                error={fieldError}
              >{fieldHelperText}</FormHelperText>
            </FormControl>
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid item xs={12} style={extraStyle}>
            <FormControl className={classes.formControl} component="fieldset">
              <FormGroup className={classes.formGroup}>
                <FormControlLabel
                  className={classes.formControlLabel}
                  label={fieldLabel}
                  control={
                    <Checkbox
                      id={fieldName}
                      name={fieldName}
                      color="primary"
                      value={fieldValue}
                      onChange={fieldHandleChange}
                      classes={{ checked: classes.formCheckbox }} />
                    }
                />
                <FormHelperText
                  className={classes.formCheckHelperText}
                  id={fieldName}
                  error={fieldError}
                >{fieldHelperText}</FormHelperText>
              </FormGroup>
            </FormControl>
          </Grid>
        );
      default:
        return;
    }
  }

  return (
    <>
      {checkFieldType(fieldType)}
    </>
  );
}

export default AuthFormField;
