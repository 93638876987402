import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding:theme.spacing(1,1.5),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    fontSize: theme.typography.pxToRem(13),
    lineHeight:1.5,
    border: `solid 2px ${theme.palette.warning.light}`
  },
}));

const calculateTimeLeft = (date) => {
  const now = moment();
  const dateCondition = moment(date);
  let difference = dateCondition.diff(now);

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60))),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }

  return timeLeft;
}

const formatTimeleft = (timeLeft, interval, timerComponents) => {
  let stamp = '';
  !timeLeft[interval] 
    ? stamp = '00' 
    : (timeLeft[interval] < 10 
        ? stamp = `0${timeLeft[interval]}`
        : stamp = `${timeLeft[interval]}`);
  interval !== 'seconds' && (stamp = `${stamp}:`);
    
  timerComponents.push(stamp);
  return timerComponents;
}


const CountdownTimer = ({ startDate, titleToolTip, textExpiredTime }) => {
  const classes = useStyles();
  const timerComponents = [];
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(startDate));
    }, 1000);
  
    return () => clearTimeout(timer);
  });

  Object.keys(timeLeft).forEach((interval) => {
    formatTimeleft(timeLeft, interval, timerComponents);
  });
  
  return (
    <Tooltip
    title={titleToolTip}
    classes={{
      tooltip: classes.tooltip
    }}>
      <div>
        { timerComponents.length 
            ? timerComponents 
            : textExpiredTime
        }
      </div>  
    </Tooltip>
  );
}

export default CountdownTimer ;
