import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from "prop-types";
import { defaultActionColumn } from "../../../settings/dataSets";
import Icon from "@material-ui/core/Icon";
import StatusElement from "../base/StatusElement";
import TablePagination from '@material-ui/core/TablePagination';
import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PopHoverLeft from './PopOverLeft.jsx';



const ItemsGrid = ({ title, items, remove, columns, edit, rowColor, action, pagination, reset, columnsName, download, upload, response}) => {
  const theme = useTheme()
  var [page, setPage] = useState(0)


  if (!items)
    items = [];

  let getTableRow = item => {
    return columns.map(column => {
      let result;
      if (column !== defaultActionColumn) {
        if (column === 'status' && item[column])
          result = <TableCell key={column}><StatusElement status={item[column]} /></TableCell>
        else {
          result = <TableCell key={column} style={{ columnWidth: '180px', color: rowColor }}>{item[column]}</TableCell>
        }
      }
      return result;
    })
  }

  let getTableHeader = value => {
    return <TableCell align={value === defaultActionColumn ? 'right' : 'left'} key={value}>{value.charAt(0).toUpperCase() + value.slice(1)}</TableCell>;
  }

  let tableHeader =
    <TableRow>
      {columnsName ?
        columnsName.map(value => getTableHeader(value))
        :
        columns.map(value => getTableHeader(value))
      }
    </TableRow>

  
  let deleteButton = (index, item, response) =>
  (response ?
    <span>
      
      {edit &&
      <PopHoverLeft text="In this Challenge you cannot edit your lineitems. We want to test your ability to be precise at the first try">
          <IconButton disabled="true">
            <Icon
              className="pointer"
              style={{ color: `${theme.palette.common.gamsGrey[600]}` }}
              fontSize={"small"}
              onClick={edit.bind(this, index, item)}>
              edit_icon
            </Icon>
          </IconButton>
        </PopHoverLeft>
      }
      {remove &&
      <PopHoverLeft text="In this Challenge you cannot edit your lineitems. We want to test your ability to be precise at the first try">
        <IconButton disabled="true">
          <Icon
            className="pointer"
            key={`remove${index}`}
            onClick={remove.bind(this, index,item)}
            fontSize={"small"}
            style={{ color: `${theme.palette.common.gamsGrey[600]}` }}
            >
            delete_forever
          </Icon>
        </IconButton>
      </PopHoverLeft>
      }
    </span>
  : 
  <span>
      
  {edit &&
    <IconButton>
      <Icon
        className="pointer"
        style={{ color: `${theme.palette.common.pink[1400]}` }}
        fontSize={"small"}
        onClick={edit.bind(this, index, item)}>
        edit_icon
      </Icon>
    </IconButton>
  }
  {remove &&
    <IconButton>
      <Icon
        className="pointer"
        key={`remove${index}`}
        onClick={remove.bind(this, index,item)}
        fontSize={"small"}
        style={{ color: `${theme.palette.common.pink[1400]}` }}
        >
        delete_forever
      </Icon>
    </IconButton>
  }
</span> )
    
    
  let resetButton = (index, item) =>
      <IconButton>
        <Icon
          key={`reset${index}`}
          onClick={reset.bind(this, index, item)}
          fontSize={"small"}
          color={"secondary"}
        >
          rotate_left
       </Icon>
      </IconButton>
  let downloadButton = (index, item) =>
      <IconButton>
        <Icon
          key={`download${index}`}
          onClick={download.bind(this, index, item)}
          fontSize={"small"}
          color={"secondary"}>
          cloud_download
        </Icon>
      </IconButton>

  let uploadButton = (index, item) =>
      <IconButton>
        <Icon
          key={`upload${index}`}
          onClick={upload.bind(this, index, item)}
          fontSize={"small"}
          color={"secondary"}>
          cloud_upload
        </Icon>
      </IconButton>

  let tableBody = (pagination ?
    items.slice(page * 5, page * 5 + 5) : items
  ).map((item, index) =>
    action ?
      <TableRow key={`row${index}`} hover onClick={() => { action(item) }} className="btn btn-link">
        {getTableRow(item)}
        {(edit || remove) && deleteButton(index)}
      </TableRow>
      :
      <TableRow key={`row${index}`}>
        {getTableRow(item)}
        <TableCell align={"right"} >
        {(download) && downloadButton(index, item)}
        {(upload) && uploadButton(index, item)}
        {(edit || remove) && deleteButton(index, item, response)}
        {(reset) && resetButton(index, item)}
        </TableCell>
      </TableRow>
  );



  return (
    <div className="card">
      <div className="header card-header-rose">
        <span className="nav-tabs-title">{title}:</span>
      </div>
      <div className="card-body table-responsive">
        <Table className="table" width={100} >
          <TableHead >
            {tableHeader}
          </TableHead>
          <TableBody>
            {tableBody}
          </TableBody>
        </Table>
        {pagination ?
          <table>
            <tbody>
              <tr>
                <TablePagination
                  rowsPerPageOptions={[]}
                  //ycolSpan={3}
                  count={items.length}
                  rowsPerPage={5}
                  page={page}
                  onPageChange={(values, item) => setPage(page = item)}
                //nChangeRowsPerPage={handleChangeRowsPerPage}
                //ActionsComponent={console.log(item)}
                />
              </tr>
            </tbody>
          </table>
        : null}
      </div>
    </div>
  );
};

ItemsGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  action: PropTypes.func,
  columnsName: PropTypes.array
};

ItemsGrid.defaultProps = {
  columns: []
};

export default ItemsGrid;
