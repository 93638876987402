import React from 'react';

const SimpleSearch = ({keyPress}) => {
    return (
        <div id="datatables_filter" className="dataTables_filter"><label><span
            className="bmd-form-group bmd-form-group-sm"><input type="search"
                                                                className="form-control form-control-sm"
                                                                placeholder="Search records"
                                                                onKeyDown={(keyPress)}
                                                                aria-controls="datatables" /></span></label>
        </div>
    );
};

export default SimpleSearch;
