//import React from 'react';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import Swal from 'sweetalert2';
import { pdfCss, pdfFooter } from '../../../components/profileScoreCard/pdfHeaderFooter.jsx';

const GET_PDF = gql`
  query getPscPdf($link: String, $options: PdfInput){
    getPscPdf(link: $link, options: $options)
  }
`;

const DownloadPdf = (props) => {
  const {
    loading,
    error,
    data
  } = useQuery(GET_PDF, {
    variables: {
      link: props.link,
      options: {
        footer: pdfCss + pdfFooter,
        margin: {
          top: "20px",
          bottom: "150px",
          right: "30px",
          left: "30px",
        }
      }
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    Swal.fire({
      title: 'Generating your Profile Scorecard',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      }
    })
  }

  if (error) return `Error! ${error.message}`;

  if(data){
    Swal.fire({
      type: 'success',
      title: 'Done!',
      showConfirmButton: false,
      timer: 1500
    })
    window.location.href=data.getPscPdf;
    props.onSuccess();
  }
  return null;
}

export default DownloadPdf
