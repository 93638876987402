import React, { useState } from 'react';
import './button.css';
import logo from "../../settings/new-logo.png"

const errorPage = props => {

    const styleH1 = {
        color: "#054255",
        fontWeight: "500"
    }
    const styleLogo = {
        width: "385px",
        
    }
    const container = {
        textAlign: "center",
        marginTop: "10vh",
    }
    const card = {
        padding: " 20px",
        backgroundColor: "#f7f7f7",
        borderRadius: "9px",
        marginTop: "80px",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
        display: " inline-block",
        width: "66%",
        borderColor: "#054255"
    }

    return (
        <div className="row">
            <div className="col-12">
                <div style={container}>
                    <div className="row">
                        <div className="col-12">
                            <img style={styleLogo} src={logo} alt="Logo" />
                        </div>
                        <div className="col-12">
                            <div style={card}>
                                <h1 style={styleH1}>OPS! This should not happen.</h1>
                                <h3>We have registered your results but it seems there is a problem with your Challenge Results.</h3>
                                <a href="/"><button className="button1">Go back to Dashboard</button></a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default errorPage