import React from "react";
import {campaignsColumns} from "../../../../constants/columns";
import withChallengeInstance from "../withChallengeInstance";
import TableLayout from "../../../../common/TableLayout";
import ReportCampaignUpdate from './ReportCampaignForm';
import ThisStepQuery from "../ThisStepQuery";

const ReportAdServerStep2 = ({ _add, type, _skip}) => {
  return (
    <TableLayout name={"Campaigns"} _add={_add} _skip={_skip}>
      <ThisStepQuery
        editUrl={"/ads/5/campaign/"}
        tableHeader={"Campaigns"}
        tableColumns={campaignsColumns}
        challengeType={type}
        currentStep = {2}
        CustomComponent={ReportCampaignUpdate}
        customComponentProps={{
        handleReport: _add,
        handleLineItems: _skip
        }}
      />
    </TableLayout>
  );
};

const action = {
  actionNeeded: "click",
  target: "button",
  parameter: "view-report"
};

const skip = {
  actionNeeded: "click",
  target: "tab",
  parameter: "lineItems"
}

 const skipSteps = 1;
export default withChallengeInstance(ReportAdServerStep2, action, skip, skipSteps);
