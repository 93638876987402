import React from 'react';
import Radio from '@material-ui/core/Radio';
import {statuses} from "../../../settings/dataSets";
import * as PropTypes from "prop-types";

const StatusElement = props => {
  const {status} = props;

  let getStatusColor = status => {
    let selectedStatus = statuses.find(function (item) {
      return item.label === status
    })
    return selectedStatus.color
  }

  return (
    <Radio checked={true}
           value={status}
           name={status}
           disabled={true}
           onChange={props.onChange}
           style={{color: getStatusColor(status)}}/>
  )
}

StatusElement.propTypes = {
  status: PropTypes.string.isRequired
};

export default StatusElement;
