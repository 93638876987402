import gql from "graphql-tag";
import {GET_CHALLENGE, GET_CHALLENGE_INSTANCE} from "./queries";

export const Simulation = {
  getSimulatedLineItem: (item) => {
    let creative = null;
    if(item.creatives && item.creatives.length>0){
      creative = {
        adName: item.creatives[0].adsName,
        url: item.creatives[0].url,
        creative: item.creatives[0].creative,
        adStatus: item.creatives[0].status
      }
    }else{
      creative = {};
    }
    return {
      lineitemName: item.name,
      lineitemType: item.type,
      scheduleDuration: item.schedule,
      lineitemStatus: item.status,
      budgetPlanned: item.budgetPlanned,
      impressionsPlanned: item.impressionsPlanned,
      impressionDelivered: item.impressionDelivered,
      budgetDelivered: item.budgetDelivered,
      clicks: item.clicks,
      conversions: item.conversions,
      CPM: item.cpm,
      placementID: item.placement,
      deliveryType: item.delivery,
      frequency: item.frequencyCAP,
      recency: item.recency,
      adName: creative.adName,
      creativeName: creative.creative,
      adStatus: creative.adStatus
    };
  }
};

export async function saveChallengeInstance(type, client, restart) {
  let challenge={
    challengeType:type,
    resetPreviusChallenges: restart || false
  };
  const CREATE_CHALLENGE = gql`
      mutation createChallenge($input: ChallengeInput){
        createChallenge(input:$input){
          _id
        }
      }
  `;

  let res = await client.mutate({mutation: CREATE_CHALLENGE, variables:{input:challenge}});
  return res.data.createChallenge._id;
}

export async function getChallengeInstance(id, client) {

  try {
    let res = await client.query({
      query: GET_CHALLENGE_INSTANCE,
      variables: {id}
    })
    return res.data.challengeInstance;
  }catch (e) {
    console.log(e);
  }
}

export async function evaluateStep(id, action,client, challengeToDo)
{

    const EVALUATE_STEP = gql`
    mutation challengeStepEvaluator($id: String, $action: Action, $challengeToDo: ChallengeToDoInput){
      challengeStepEvaluator(id: $id, action: $action, challengeToDo: $challengeToDo)
      }
  `;
  if(challengeToDo===undefined){
    return await client.mutate({mutation: EVALUATE_STEP, variables:{id, action},});
  }
  return await client.mutate({mutation: EVALUATE_STEP, variables:{id, action, challengeToDo: challengeToDo},});

}

export async function getChallenge(id, client) {
  try {
    let res = await client.query({
      query: GET_CHALLENGE,
      variables: {id}
    });
    return JSON.parse(res.data.challenge);
  }catch (e) {
    console.log(e);
  }
}
