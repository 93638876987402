import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, MenuItem } from '@material-ui/core';
import ConditionalField from './ConditionalField/ConditionalField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  selectControl: {
    minWidth: 150,
    marginRight:10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& label.Mui-focused': {
      color: theme.palette.grey[800],
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[800],
      },
    },
    '& .MuiInputBase-root': {
      background: theme.palette.common.white,
    }
  },
  selectRoot: {
    [theme.breakpoints.down('xs')]: {
      paddingTop:15,
      paddingBottom:14
    },
    '&:focus':{
      backgroundColor:'white'
    }
  },
}));

const ConditionalFields = ({ newItemsLists, setFieldValue, getIn, touched, errors, fieldValue }) => {
  const classes = useStyles();

  const filterFieldsList = newItemsLists.filterFields;
  const filterConditionsList =  newItemsLists.filterConditions;
  const filterValuesList = newItemsLists.filterValues;
  const filterValuesInputTypeList = newItemsLists.filterValuesInputType;

  const selectedIndex = filterFieldsList.indexOf(fieldValue.node.description.filterField);

  const filterField = `node.description.filterField`;
  const touchedFilterField = getIn(touched, filterField);
  const errorFilterField = getIn(errors, filterField);

  const filterCondition = `node.description.filterCondition`;

  const filterValue = `node.description.filterValue`;

  const handleOnChange = (event) => {
    setFieldValue(filterField, event.target.value)
    setFieldValue(filterCondition, "");
    setFieldValue(filterValue, "");
  }

  return (
    <Box className={classes.conditionBlockLeft}>
      <TextField
        size="small"
        select
        className={classes.selectControl}
        id={filterField}
        name={filterField}
        value={fieldValue.node.description.filterField}
        onChange={handleOnChange}
        label={fieldValue.node.description.filterField === "" ? "Select field 1" : ""}
        error={Boolean(touchedFilterField && errorFilterField)}
        helperText={touchedFilterField && errorFilterField ? errorFilterField : ""}
        variant="outlined"
        InputLabelProps={{shrink: false}}
        SelectProps={{
          IconComponent: ExpandMoreIcon,
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
            getContentAnchorEl: null
          },
          classes: {root: classes.selectRoot}
        }}
      >
        {filterFieldsList.map((selectItem, index) => {
          return (
            <MenuItem
              key={`select-${index}`}
              value={selectItem}
            >{selectItem}
            </MenuItem>
          )
        })}
      </TextField>

      <ConditionalField
        labelText="Select field 2"
        name={filterCondition}
        filterList={filterConditionsList[selectedIndex]}
      />

      <ConditionalField
        labelText={filterValuesInputTypeList[selectedIndex] === 'text' ? 'Type age' : 'Select field 3'}
        name={filterValue}
        filterList={filterValuesList[selectedIndex]}
        filterValuesInputType={filterValuesInputTypeList[selectedIndex]}
      />
    </Box>
  );
};

export default ConditionalFields;
