import React, {Component} from 'react';
import {validateLineItem} from "../../Validations";
import {Formik} from "formik";
import {client} from "../../../settings/apollo";
import {EVALUATE_STEP} from "./queries";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ItemsGrid from "../../../common/components/widgets/ItemsGrid";
import LineItemForm from "../../campaign/LineItemForm";
import {Builder} from "../../../settings/builder";
import {Simulation} from "./simulation";
import {SecureQuery} from "../../../settings/graphqlHelper";
import {lineItemGridColumns} from "../../../settings/dataSets";
import { withRouter } from 'react-router-dom'
import {lineItemsInitValues} from "../../placement/InitValues";
import { creativesVar, lineItemsVar } from '../../../settings/cache';

export const dontshow = () => {return true};


class LineItemsSimulation extends Component {
  constructor(props) {
    super(props);
    if (props.items)
      this.state = {...props,
        currentTab: 1,
        editMode: false,
        initialValues: lineItemsInitValues,
        checkIndex: true
      };
    else
      this.state = {...props, items: [], currentTab: 1, name:'', index: true};
    this.deleteRow = this.deleteRow.bind(this);
    this.saveLineItem = this.saveLineItem.bind(this);
    this.resetLineItemForm = this.resetLineItemForm.bind(this);
    this.ResetLineItemForm = React.createRef();
    this.editLineItem=this.editLineItem.bind(this);
    this.updateRow = this.updateRow.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.name)
      this.setState({name: nextProps.name});
  }
  
  deleteRow(index) {

  }

  editLineItem = (index, item) => {
    console.log(item)
    this.setState({ editMode: true})
    this.setState({ initialValues: item})
    this.setState({ index: index})
  }

  resetLineItemForm(){
    document.getElementById("LineItemFormInput").reset();
    this.ResetLineItemForm.current.resetLineItemForm()
  }

  saveLineItem = async (values, setSubmitting) => {
    setSubmitting(false);
    let index = this.state.items.length
    let item = Builder.getLineItem(values, client);
    let creatives = creativesVar();
    item = {...item, label: item.name, secondaryLabel: "...", creatives};
    const data = Simulation.getSimulatedLineItem(item);
    if ( this.state.editMode === true ){
      lineItemsVar(this.state.items)
      this.state.items.splice(this.state.index, 1, values);
      this.setState({items: this.state.items});
      this.setState({editMode: false})
    }
    else{
      lineItemsVar(this.state.items)
      this.state.items.splice(index, 0, values)
      if (index === 1) {
        this.setState({checkIndex: false});
        console.log(this.state)
      }
      this.setState({items: this.state.items});
    }

    creativesVar([]);
    this.resetLineItemForm();
    const action={
      actionNeeded: 'complete',
      target: JSON.stringify({
        type: "lineitem",
        fields: data
      })

    };
    let res = await SecureQuery.asyncMutation({mutation: EVALUATE_STEP, variables:{id:this.state.id, action:action}});
    let value = res.data.challengeStepEvaluator;
    if(value !== ''){
      this.props.history.push(value + this.props.match.params.id);
    }
    this.setState({initialValues : lineItemsInitValues})
    this.props.changeTime()
  };

  updateRow(index, values) {
    lineItemsVar(this.state.items)
    this.state.items.splice(index, 1, values);
    this.setState({items: this.state.items});
  }

  submit() {
    if(!this.props.isSubmitting) {
      this.state.submit();
    }
  }

  response = "true";

  render() {
    const {currentTab} = this.state;
    return (
      <div>
        <Card>
          <div>
            <AppBar position="static" color="default">
              <Tabs value={currentTab} >
                <Tab disableRipple label="Campaign"/>
                <Tab disableRipple label="Line Items" disabled={!this.state.name}/>
              </Tabs>
            </AppBar>
          </div>
          <CardContent>
            {currentTab === 1 && <div>
              <div>
                <ItemsGrid columns={lineItemGridColumns} title="Line Items" items={this.state.items} remove={this.deleteRow} edit={this.editLineItem} response="true" />
                <Formik
                  initialValues={this.state.initialValues}
                  validate={validateLineItem}
                  onSubmit={(values, {setSubmitting}) => this.saveLineItem(values, setSubmitting)}
                  enableReinitialize
                >
                  {({values, errors, handleChange, touched, setFieldValue}) => (
                    <LineItemForm simulation={true} values={values} errors={errors} handleChange={handleChange} touched={touched} ref={this.ResetLineItemForm} setFieldValue={setFieldValue} />
                  )}
                </Formik>
              </div>
            </div>}
            <div className="card-footer">
              {/* <div className="ml-auto">
                {
                  this.state.currentTab === 1 &&
                  <button className="btn btn-fill btn-rose" >Previous</button>
                }
              </div> */}
              <div className="ml-auto mr-auto">
                <button disabled={this.state.checkIndex} className="btn btn-fill btn-rose" onClick={this.submit.bind(this)}>
                  Save Campaign
                </button>
              </div>
              <div className="clearfix"></div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

 export default withRouter(LineItemsSimulation);
