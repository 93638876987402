import { useQuery } from '@apollo/client';
import gql from "graphql-tag";

const SHARE_ARTICLE_ON_LINKEDIN = gql`
query shareArticleOnLinkedin ($url: String, $text: String, $description: String, $title: String){
  shareArticleOnLinkedin(url: $url, text: $text, description: $description, title: $title)
  }
`;

function LinkedinShare (props) {
    const {
        loading,
        error,
        data
      } = useQuery(SHARE_ARTICLE_ON_LINKEDIN, {
        variables: {
          url: props.url,
          text: props.text,
          description: props.description,
          title: props.title
        },
        fetchPolicy: "network-only",
      });
    if (loading) props.onLoading();
    if (error) props.onError(error.message)
    if(data){
      props.onSuccess(data);
    }
    return null;
}

export default LinkedinShare
