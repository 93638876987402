import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormGroup, FormControlLabel, Checkbox, Button, Box, Dialog, DialogContent, DialogActions, Grid } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import placeholderImg from '../../../../assets/images/challenges/challenge-placeholder.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
  },
  formControlRoot: {
    display:'block'
  },
  formGroup: {
    flexDirection:'row',
  },
  formControlLabel: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    margin:0,
    display: 'block'
  },
  checkbox: {
    width:54,
    height:54,
    position: 'absolute',
    zIndex:999,
    bottom:17,
    right:17,
    border: `solid 1px ${theme.palette.grey[400]}`,
    backgroundColor: `${theme.palette.common.white} !important`,
    boxShadow: theme.shadows[4],
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(32)
    }
  },
  imgBox: {
    border: `solid 2px ${theme.palette.grey[400]}`,
    overflow: 'hidden',
    position: 'relative',
    height: 290,
  },
  checkboxImg: {
    position: 'absolute',
    height: '100%',
    width: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  dialogImg: {
    backgroundColor: theme.palette.grey[300],
    width: '100%',
    height: 'auto',
  },
  zoomBtn: {
    position: 'absolute',
    width:54,
    height:54,
    minWidth:54,
    bottom:17,
    left:17,
    backgroundColor: `${theme.palette.common.white} !important`,
    borderRadius: '50%',
    boxShadow: theme.shadows[4],
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(32)
    },
    '&:focus': {
      outline: 'none'
    }
  },
  dialogContent: {
    padding:0,
    paddingTop:'0 !important',
    borderTop: `solid 13px ${theme.palette.common.white}`,
    borderLeft: `solid 13px ${theme.palette.common.white}`,
    borderRight: `solid 13px ${theme.palette.common.white}`,
  },
  dialogBtn: {
    border: `solid 1px ${theme.palette.common.gamsDarkBlue}`,
    color: theme.palette.common.gamsDarkBlue,
  },
  blue900: {
    color: `${blue[900]} !important`,
  },
}))

const CheckboxesImageModule = props => {
  const {
    randomizeAnswer,
    stepIndex,
    onChanged,
    options,
  } = props;

  const [FirstMount, setFirstMount] = useState(stepIndex);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
  const [checked, setChecked] = useState({})
  const [checkboxesList, setCheckboxList] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogImage, setDialogImage] = useState();
  const [checkImgLoaded, setCheckImgLoaded] = useState(false);
  const classes = useStyles();

  const handleClickOpenDialog = (value) => {
    setDialogImage(value);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    let checkedObject = {};
    for (let property in options) {
      checkedObject[property] = false
    }
    setChecked(checkedObject)
    if (FirstMount !== stepIndex) {
      setChecked(checkedObject)
      setSelectedCheckboxes([]);
      setFirstMount(stepIndex);
    }
  }, [FirstMount, stepIndex, options]);

  useEffect(() => {
    let checkboxeArray = null;
    if (randomizeAnswer === 'yes') {
      checkboxeArray = shuffle(Object.entries(options));
      setCheckboxList(checkboxeArray);
    } else {
      checkboxeArray = Object.entries(options);
      setCheckboxList(checkboxeArray);
    }
  }, [stepIndex, options, randomizeAnswer]);

  const onChange = (event) => {
    const selectedCheckboxesUpdate = selectedCheckboxes;
    const findName = selectedCheckboxesUpdate.indexOf(event.target.name);
    if (findName > -1) {
      selectedCheckboxesUpdate.splice(findName, 1);
    } else {
      selectedCheckboxesUpdate.push(event.target.name);
    }
    setSelectedCheckboxes(selectedCheckboxesUpdate);
    setChecked({ ...checked, [event.target.name]: event.target.checked });
    onChanged(selectedCheckboxes);
  }

  const shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  return (
    <div className={classes.root}>

      <FormControl component="div" className={classes.formControlRoot}>
        <FormGroup className={classes.formGroup}>

          <Grid container spacing={2} className={classes.gridContainer}>
          {checkboxesList.map(([key, value]) => {
            return (
              <Grid key={key} item xs={12} sm={6} className={classes.gridItem}>
                <Box className={classes.imgBox}>
                  <FormControlLabel
                    className={classes.formControlLabel}
                    label={
                      <>
                        <img
                          className={classes.checkboxImg}
                          src={checkImgLoaded ? value : placeholderImg}
                          alt={key}
                          onLoad={() => setCheckImgLoaded(true)}
                        />
                        <Button
                          className={classes.zoomBtn}
                          variant="outlined"
                          onClick={() => handleClickOpenDialog(value)}
                        >
                          <ZoomInIcon/>
                        </Button>
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(event) => onChange(event)}
                        type="checkbox"
                        name={key}
                        value={value}
                        checked={checked[key]}
                        classes={{
                          checked: classes.blue900,
                          root: classes.checkbox
                        }} />
                    }
                  />
                </Box>
              </Grid>
            )
          })}
          </Grid>

        </FormGroup>
      </FormControl>

      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
      >
        <DialogContent className={classes.dialogContent}>
          <img
            className={classes.dialogImg}
            src={dialogImage}
            alt="dialog answer"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="default"
            variant="outlined"
            className={classes.dialogBtn}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default CheckboxesImageModule;
