import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  circleBottom: {
    color: theme.palette.grey[300],
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  circleTop: {
    color: theme.palette.common.gamsPetroleumBlue,
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  circleText: {
    fontSize: theme.typography.pxToRem(28),
    color: theme.typography.primary,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: '0.2px'
  }
}));

const CircularProgressWithLabel = (props) => {
  const classes = useStyles();
  const { value, size } = props

  return (
    <Box className={classes.root} position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.circleBottom}
        size={size ? size : 110}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        className={classes.circleTop}
        size={size ? size : 110}
        thickness={3}
        classes={{
          circle: classes.circle,
        }}
        {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          className={classes.circleText}
          variant="caption"
          component="div">
          {`${Math.round(
            value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
