import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import placeholderImg from '../../../../assets/images/challenges/challenge-placeholder.png';

const useStyles = makeStyles((theme) => ({
  listBox: {
    maxWidth: 603,
    margin: '0 auto',
    marginBottom:30
  },
  listTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    marginBottom:6
  },
  imgList: {
    border: `solid 1px ${theme.palette.grey[300]}`,
    padding:'16px 12px 8px 12px',
    borderRadius: 6,
    display:'flex',
    justifyContent:'flex-start',
    flexWrap: 'wrap'
  },
  rootImageBlock: {
    marginRight:4,
    marginLeft:4,
    marginBottom:8,
  },
  imageBlock: {
    width:136,
    height:136,
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    backgroundImage: placeholderImg,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    border:`solid 1px ${theme.palette.grey[300]}`,
  },
  icon: {
    position:'absolute',
    top:-10,
    right:-8,
    width:24,
    height:24,
    zIndex:999,
  },
  correctIcon: {
    color:theme.palette.success.main
  },
  wrongIcon: {
    color:theme.palette.error.main
  },
  correctAnsBtn: {
    textTransform:'none',
    minWidth:'auto',
    padding:0,
    fontWeight: theme.typography.fontWeightRegular,
    color:theme.palette.text.primary,
    textDecoration:'underline',
    '&:hover': {
      background:'none',
      color: theme.palette.common.gamsPetroleumBlue,
      textDecoration:'underline',
    },
    '&:focus': {
      outline: 'none',
      background:'none',
    },
  }
}));

const ResultImageAnswers = ({ answers }) => {
  const classes = useStyles();
  let isFalse = false;

  const answerIcon = (isCorrect) => {
    if (isCorrect === true) {
      return <div className={`${classes.icon} ${classes.correctIcon}`}><DoneIcon /></div>;
    } else if (isCorrect === false) {
      return <div className={`${classes.icon} ${classes.wrongIcon}`}><CloseIcon /></div>;
    } else {
      return;
    }
  };

  return (
    <>
      <Box className={classes.listBox}>
        <Typography className={classes.listTitle} component="h3">Your answers</Typography>
        <div className={classes.imgList}>
          {answers.map((answer, index) => {
            if (answer.errorType === 'missed' && answer.value === null) {
              return null; // Skip rendering the box for missed placeholder answers
            }

            if (answer.isCorrect === false) {
              isFalse = true;
            }

            return (
              <Box className={classes.rootImageBlock} key={`image-answer-item-${index}`}>
                <div className={classes.imageBlock} style={{ backgroundImage: `url(${answer.value ? answer.value : placeholderImg})` }}>
                  {answerIcon(answer.isCorrect)}
                </div>
              </Box>
            );
          })}
        </div>
      </Box>


      { isFalse && (
        <Box className={classes.listBox}>
          <Typography className={classes.listTitle} component="h3">Correct answers</Typography>
          <div className={classes.imgList}>
            {answers.map((answer, index) => answer.correctAnswer !== null && (
              <Box className={classes.rootImageBlock} key={`image-answer-item-${index}`}>
                <div className={classes.imageBlock} style={{ backgroundImage: `url(${answer.correctAnswer ? answer.correctAnswer : placeholderImg})` }}>
                  {/* Increase the priority of the hero image */}
                  <img style={{ display: 'none' }} src={answer.correctAnswer ? answer.correctAnswer : placeholderImg} alt={'user answer'} />
                </div>
              </Box>
            ))}
          </div>
        </Box>
      )}
    </>
  );
};

export default ResultImageAnswers;
