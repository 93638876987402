//import React from 'react';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import Swal from 'sweetalert2';

const GET_IMAGE = gql`
query getPscSreenshot($link: String){
    getPscSreenshot(link: $link)
  }
`;

const DownloadImage = (props) => {
    const {
      loading,
      error,
      data
    } = useQuery(GET_IMAGE, {
      variables: {
        link: props.link
      },
      fetchPolicy: "network-only",
    });
    if (loading)  {
      Swal.fire({
        title: 'Generating your Profile Scorecard',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      })
    }
    if (error) return `Error! ${error.message}`;
    if(data){
      Swal.fire({
        type: 'success',
        title: 'Done!',
        showConfirmButton: false,
        timer: 1500
      })
      window.location.href=data.getPscSreenshot;
      props.onSuccess();
    }

  return null;
  }

  export default DownloadImage
