import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootBtn: {
    backgroundColor: theme.palette.common.yellow[500],
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight:1.2,
    textTransform:'none',
    borderRadius:15,
    '&:hover': {
      backgroundColor: theme.palette.common.yellow[400],
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom:10,
    },
    '& p':{
      marginBottom:0
    }
  },
}));

const SubscriptionButton = ({ textButton, action }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.rootBtn}
      onClick={action}>
      {textButton}
    </Button>
  )
}

export default SubscriptionButton;
