import React, { Component } from "react";
import PropTypes from "prop-types";
import { Query } from "@apollo/client/react/components";
import DataTable from "../../../common/components/data-table/DataTable";
import LoadingAnimation from "../../../common/components/base/LoadingAnimation";
import ChallengePanel from "../ChallengePanel";
import { GET_CHALLENGE } from "./queries";


class FirstStepQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tableHeader, tableColumns, challengeType, editUrl, handleEditClick, CustomComponent, customComponentProps } = this.props;
    return (
      <Query
        query={GET_CHALLENGE}
        fetchPolicy="network-only"
        variables={{
          id: challengeType
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingAnimation />;
          if (error) return `Error! ${error.message}`;

          let challenge = JSON.parse(data.challenge);
          let records = [];
          let StepCountDownTime = challenge.steps[0].countdownTime;
          if (challenge.records) {
            records = challenge.records
          }
          return (
            <div>
              {(() => {
                if (CustomComponent)
                  return <CustomComponent {...customComponentProps} />;
                else
                  return <DataTable
                    header={tableHeader}
                    editUrl={editUrl || ""}
                    columns={tableColumns}
                    records={{ records: records }}
                    keyColumn="_id"
                    handleEditClick={handleEditClick}
                    first={e => e.preventDefault()}
                    columnsToHide={["username"]}
                  />;
              })()}
              <ChallengePanel
                step={0}
                time={StepCountDownTime}
                description={challenge.challengeDescription}
                coach={challenge.coach}
                challengeTitle={challenge.challengeTitle}
                challengeIsValidation={challenge.isValidation}
                challengeDifficulty={challenge.challengeDifficulty}
                challengeAverageTime={challenge.averageTime}
              />
            </div>
          );
        }}
      </Query>
    );
  }
}

FirstStepQuery.propTypes = {
  tableHeader: PropTypes.string.isRequired,
  tableColumns: PropTypes.array.isRequired,
  editUrl: PropTypes.string
};

FirstStepQuery.defaultTypes = {
  tableHeader: 'Table',
  tableColumns: [],
  editUrl: ''
};

export default FirstStepQuery;
