import { createTheme } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const gamsOrange =  '#F39655';
const gamsPetroleumBlue = '#03506B';
const gamsDarkBlue =  '#043F54';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "xxl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1536,
      xxl: 1920,
    }
  },
  palette: {
    common: {
      gamsOrange: `${gamsOrange}`,
      gamsPetroleumBlue: `${gamsPetroleumBlue}`,
      gamsDarkBlue: `${gamsDarkBlue}`,
      bgAuth: '#F6F8FB',
      gamsGrey: {
        300: '#FBFBFC',
        600: '#757575'
      },
      blue: {
        400: '#2D819F',
        500: '#5F99C0',
        600: '#3F7AA2',
        700: '#022734'
      },
      orange: {
        300: '#FFBC8E',
        400: '#F9AC65',
        500: '#E9B384',
        600: '#F28A42'
      },
      yellow: {
        400: '#FEFE8D',
        500: '#FBFA73',
        600: '#F9DC66',
      },
      green: green,
      pink: {
        1400: '#f50057',
      },
    },
    automation: {
      darkBlue: {
        500: '#1B2D72',
      },
      blue: {
        500: '#26687E',
      },
      green: {
        500: '#127A35'
      }
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: 6
      }
    }
  }
});

export default theme;
