import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  preBtnText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    textAlign: 'center',
    marginBottom:15
  },
  Submitbtn: {
    fontSize: theme.typography.pxToRem(18),
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    paddingTop:11.5,
    paddingBottom:11.5,
    textTransform:'none',
    //marginBottom:15,
    [theme.breakpoints.down('xs')]: {
      paddingTop:8.5,
      paddingBottom:8.5,
    },
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '&:not(:last-child)': {
      borderRight: `solid 1px rgba(0,0,0,0.39)`,
    },
  },
  postBtnText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    textAlign: 'center',
    marginTop:15
  },
}));

const AuthFormSubmit = ({ preBtnText, textSubmit, postBtnText })=> {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        { preBtnText && (
        <Typography className={classes.preBtnText} component="div">
          {preBtnText}
        </Typography>
        )}
        <Button className={classes.Submitbtn} color="default" variant="contained" fullWidth type="submit">
          {textSubmit}
        </Button>
        { postBtnText && (
        <Typography className={classes.postBtnText} component="div">
          {postBtnText}
        </Typography>
        )}
      </Grid>
    </>
  );
}

export default AuthFormSubmit;
