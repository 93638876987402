import React, { useEffect, useState } from 'react';
import { GET_USER_DATA, GET_GLOBALCONTENT, GET_SUBSCRIPTION_PAGE } from "./graphql/queries";
import { CREATE_CHECKOUT_SESSION, CUSTOMER_PORTAL } from "./graphql/mutations";
import { useMutation, useQuery } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Link, Typography, Divider, Button } from '@material-ui/core';
import { HashLink } from 'react-router-hash-link';
import Layout from '../../common/Layout';
import SubLayout from '../../hoc/SubLayout/SubLayout';
import PageHeaderCentered from '../../common/components/PageHeaderCentered/PageHeaderCentered';
import Pricing from '../../common/components/Princing/Princing';
import FaqBlock from '../../common/components/FaqBlock/FaqBlock';
import SubscriptionAlert from '../../common/components/SubscriptionAlert/SubscriptionAlert';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { env } from '../../env';

const useStyles = makeStyles((theme) => ({
  faqMsg: {
    '& p': {
      color: theme.palette.text.secondary
    },
    '& a': {
      color: theme.palette.common.gamsPetroleumBlue
    }
  },
  divider: {
    marginTop:76,
    marginBottom:58,
  },
  faqTitle: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightMedium,
    color:theme.palette.text.primary,
    textAlign:'center',
    marginBottom:28,
  },
  customerBox: {
    maxWidth:600,
    margin:'auto',
    textAlign:'center',
    marginTop:28,
    marginBottom:30,
  },
  customerMsg: {
    marginBottom:10,
  },
  customerBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    textTransform:'none',
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
  subAlertMsg: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(1.5, 2),
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop:18,
  },
  subAlertMsgBtn: {
    backgroundColor: theme.palette.common.white,
    marginLeft: 15,
    '&:focus': {
      outline: 'none',
    },
  },
}));

const Subscription = props => {
  const classes = useStyles();
  const { loading: udLoading, error: udError, data: udData } = useQuery(GET_USER_DATA);
  const { loading: gcLoading, error: gcError, data: gcData } = useQuery(GET_GLOBALCONTENT);
  const { loading: spLoading, error: spError, data: spData } = useQuery(GET_SUBSCRIPTION_PAGE);
  const stripe = useStripe();
  const [priceId, setPriceId] = useState(env.REACT_APP_PRICE_ID_YEAR); // Annually plan
  const [createCheckoutSessionMutation, {data}] = useMutation(CREATE_CHECKOUT_SESSION);
  const [customerPortalMutation, {data: cpData}] = useMutation(CUSTOMER_PORTAL);

  useEffect(() => {
    if(data) {
      stripe.redirectToCheckout({
        sessionId: data.createCheckoutSession
      })
      .then("ok");
    };
    if(cpData) {
      window.location.href = cpData.customerPortal;
    };
  }, [data, cpData, stripe]);

  const createCheckoutSession = async function() {
    await createCheckoutSessionMutation({ variables: { priceId, host:window.location.origin } })
  }

  const customerPortal = async function() {
    await customerPortalMutation({ variables: { host:window.location.origin } })
  }

  const selectPlan = (priceId) => {
    setPriceId(priceId);
  }

  if (gcLoading || udLoading || spLoading ) {
    return (
      <Layout>
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      </Layout>
    )
  } else if (gcError || udError || spError) {
     return (
      <Layout>
        <div>Error! {gcError && gcError.message} {udError && udError.message} {spError && spError.message}</div>
      </Layout>
    )
  } else {

    const globalContent = gcData?.globalContent?.data?.attributes;
    const me = udData.me;
    const subscriptionPage = spData?.subscriptionPage?.data?.attributes;

    const isTrial = me ? me.userType === 'b2c-trial' : null;
    const isTrialExpired = me ? me.userType === 'b2c-trial-expired' : null;
    const isPremium = me ? me.userType === 'b2c-premium' : null;
    const isPremiumCanceled = me ? me.userType === 'b2c-premium-deleted' : null;
    const isPremiumExpired= me ? me.userType === 'b2c-premium-expired' : null;
    const isB2bExpired = me ? me.userType === 'b2b-expired' : null;
    const isB2b = me ? me.userType === 'b2b' : null;

    let enablePrice = true;
    if (isPremium) {
      enablePrice = false;
    } else if (isB2b) {
      enablePrice = false;
    } else if (isPremiumCanceled) {
      enablePrice = false;
    }

    const subAlertMsg = (
      <Box className={classes.subAlertMsg}>
        <Typography component="div" variant="body1">
          You haven't finished the Challenges and want to test yourself for another 15 days?
        </Typography>
        <Button
          variant="contained"
          className={classes.subAlertMsgBtn}
          href={`/refresh-account/${me.email}`}
        >Click here!</Button>
      </Box>
    );

    const pageContent = (
      <>
        <PageHeaderCentered
          title={isTrial ? subscriptionPage.titleTrial : subscriptionPage.title}
          subTitle={isTrial ? subscriptionPage.descriptionTrial : subscriptionPage.description}
          description={
            <Box className={classes.faqMsg}>
              <Typography variant="body1">
                {subscriptionPage.ctaFaqs.text} <Link component={HashLink} to={subscriptionPage.ctaFaqs.link} smooth>{subscriptionPage.ctaFaqs.textLink}</Link>
              </Typography>
            </Box>
          }
        />

        {isTrialExpired ?
          <SubscriptionAlert
            alertTitle={subscriptionPage.TrialExpiredAlert.title}
            alertSubtitle={subscriptionPage.TrialExpiredAlert.subTitle}
            alertMessage={!me.expirationDateExtended && subAlertMsg}
          />
        :
        isPremiumExpired === true || isB2bExpired === true ?
          <SubscriptionAlert
            alertTitle={subscriptionPage.PremiumExpiredAlert.title}
            alertSubtitle={subscriptionPage.PremiumExpiredAlert.subTitle}
            alertBtnAction={isB2bExpired === true ? false : customerPortal}
            alertBtnText={subscriptionPage.PremiumExpiredAlert.textButton}
            alertMessage={!me.expirationDateExtended && subAlertMsg}
          />
        :
        isPremium ?
          <SubscriptionAlert
            alertTitle={`${subscriptionPage.PremiumAlert.title} ${format(new Date(me.premiumPeriodEnd), 'd MMMM yyyy')}`}
            alertSubtitle={subscriptionPage.PremiumAlert.subTitle}
            alertBtnAction={customerPortal}
            alertBtnText={subscriptionPage.PremiumAlert.textButton}
          />
        :
        isPremiumCanceled ?
          <SubscriptionAlert
            alertTitle={`${subscriptionPage.PremiumCanceledAlert.title} ${format(new Date(me.premiumPeriodEnd), 'd MMMM yyyy')}`}
            alertSubtitle={subscriptionPage.PremiumCanceledAlert.subTitle}
            alertBtnAction={customerPortal}
            alertBtnText={subscriptionPage.PremiumCanceledAlert.textButton}
          />
        : null}

        <Pricing
          selectPlan={selectPlan}
          createCheckoutSession={createCheckoutSession}
          enablePrice={enablePrice}
          me={me}
          pricingPlan={globalContent.pricingPlan}
        />

        <Divider className={classes.divider} variant="middle" />

        <Typography className={classes.faqTitle} variant="h3" id="faq">
          {subscriptionPage.faqTitle}
        </Typography>

        <FaqBlock faqData={subscriptionPage.faqs} />

        <Box className={classes.customerBox}>
          <Typography className={classes.customerMsg} variant="body1" color="textPrimary">
              {subscriptionPage.supportMessage.message}
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Email us at:
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="default"
            className={classes.customerBtn}
            href={subscriptionPage.supportMessage.link}
            rel="noopener noreferrer"
            target="_blank"
          >{subscriptionPage.supportMessage.textLink}
          </Button>
        </Box>
      </>
    );

    if (isTrialExpired || isB2bExpired || isPremiumExpired) {
      return (
        <SubLayout>
          {pageContent}
        </SubLayout>
      )
    } else {
      return (
        <Layout location={props.location.pathname}>
          {pageContent}
        </Layout>
      );
    }

  }

}

export default Subscription;
