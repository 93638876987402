import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CAREERS, GET_CAREERLIST_PAGE } from "./graphql/queries";
import { Grid } from '@material-ui/core';
import Layout from '../../common/Layout';
import PageHeader from '../../common/components/PageHeader/PageHeader';
import CareerBlock from '../../common/components/CareerBlock/CareerBlock';
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";

const CareerList = props => {

  const {
    loading: cLoading,
    error: cError,
    data: cCareers
  } = useQuery(GET_CAREERS, {
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: cpLoading,
    error: cpError,
    data: cpData
  } = useQuery(GET_CAREERLIST_PAGE, {
    fetchPolicy: "cache-and-network",
  });

  const careersData = cCareers?.careerPaths;
  const careerListPageData = cpData?.careerListPage?.data?.attributes;

  return (
    <Layout>
      { cLoading || cpLoading ? (
        <LoadingSpinner styles={{ paddingTop: 60 }} />
      ) : cError || cpError ? (
        <div>Error! {cError && cError.message} {cpError && cpError.message}</div>
      ) : (
        <>
          <PageHeader
            title={careerListPageData.pageHeader.title}
            subTitle={careerListPageData.pageHeader.description}
          />
          <Grid container spacing={4}>
            {careersData.map((career, index) => {
              return (
                <Grid key={`mtc-${index}`} item xs={12} md={6} lg={6} xl={4}>
                  <CareerBlock career={career}/>
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </Layout>
  )
}

export default CareerList;
