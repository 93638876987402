import gql from "graphql-tag";

export const GET_USER_DATA = gql`
  query me {
    me {
      _id
      name
      photo
      userType
      courseGroups{
        name
      }
      profileScoreCardSerialNumber
    }
  }
`;

export const GET_GLOBALCONTENT = gql`
  query globalContent {
    globalContent {
      data {
        attributes {
          subscriptionDialog {
            title
            description
            ctaFaqs {
              link
              text
              textLink
            }
          }
          pricingPlan {
            title
            planType
            description
            discountText
            b2bLicenceText
            featureList {
              text
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_STATISTICS = gql`
  query myStatistics {
    myStatistics {
      totalLearningTopics
      totalFinishedLearningTopics
      totalChallenges
      totalFinishedChallenges
      totalCareers
      totalFinishedCareers
      totalBadges
      gainedBadges
      totalObtainableScore
      totalScore
    }
  }
`;
export const GET_PROFILE_USERNAME = gql`
  query profileUsername($username: String!) {
    profileScoreCardByUsername(username: $username) {
      badges

    }
  }
`;


export const GET_VALIDATION_CHALLENGE_DEFINITIONS_ASSIGNED = gql`
  query validationChallengeDefinitionsAssigned{
    validationChallengeDefinitionsAssigned {
      id
      challengeTitle
    }
  }
`;
export const GET_CHALLENGE_DEFINITIONS_ASSIGNED = gql`
  query challengeDefinitionsAssigned{
    challengeDefinitionsAssigned {
      id
      challengeTitle
    }
  }
`;

export const GET_RANKING_OVERALL = gql`
  query rankingOverall {
    rankingOverall {
      name
      type
      userRanking {
        pos
        score
        user {
          name
          surname
          photo
        }
      }
      ranking {
        pos
        score
        user{
          photo
          name
          surname
        }
      }
    }
  }
`;

export const GET_RANKING_CHALLENGE = gql`
  query rankingOverall($challengeType: Int!) {
    rankingChallenge(challengeType: $challengeType) {
      name
      type
      userRanking {
        pos
        score
        user {
          name
          surname
          photo
        }
      }
      ranking {
        pos
        score
        user {
          photo
          name
          surname
        }
      }
    }
  }
`;

export const GET_RANKING_GROUP = gql`
  query rankingOverall($groupName: String!) {
    rankingGroup(groupName: $groupName) {
      name
      type
      userRanking {
        pos
        score
        user {
          name
          surname
          photo
        }
      }
      ranking {
        pos
        score
        user {
          photo
          name
          surname
        }
      }
    }
  }
`;

