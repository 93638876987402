import React from "react";
import ChartistGraph from "react-chartist";
//import Chartist from "../../../../node_modules/chartist/dist/chartist";
//import * as ChartistTooltips from "./chartist-plugin-tooltips/dist/chartist-plugin-tooltip";
//import "./chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css";

export default class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...props };
  }

  render() {
    // Create a simple line chart
    const data = {
      // A labels array that can contain any sort of values
      labels: this.props.labels,
      // Our series array that contains series objects or in this case series data arrays
      series: [this.props.data]
    };

    // As options we currently only set a static size of 300x200 px
    var options = {
      width: "100%",
      height: "270px",
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 10
      },
      axisX: {
        labelOffset: {
          x: -10,
          y: 0
        }
      },
      axisY: {
        labelInterpolationFnc: function(value) {
          if (value > 10000) {
            return value / 1000 + "k";
          } else {
            return value;
          }
        }
      },
      //plugins: [Chartist.plugins.tooltip()]
    };

    var type = "Line";

    return (
      <div>
        <ChartistGraph data={data} options={options} type={type} />
      </div>
    );
  }
}
