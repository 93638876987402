import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingBottom: "56.25%" /* 16:9 */,
    paddingTop: 25,
    height: 0
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
}))

const VideoModule = props => {
  const classes = useStyles();

  if (!props.currentStep.videoLink) {
    return <div>Error! Video not loaded.</div>
  }

  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframe}
        title="Video Frame"
        id="video"
        src={props.currentStep.videoLink}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        sandbox="allow-scripts allow-same-origin allow-presentation"
      />
    </div>
  );
}

export default VideoModule;
