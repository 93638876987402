const plusButtonStyle = theme => ({
  fab: {
    margin: theme.spacing.unit,
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default plusButtonStyle;

export const movingTab = {
  width: '159.109px',
  transform: 'translate3d(-8px, 0px, 0px)',
  transition: 'transform 0s ease 0s'
};

export const width50 = {
  width: '50%'
};

export const width100 = {
  width: '100%'
};

export const displayNone = {
  display: 'none'
};

export const errorMessage = {
  color: '#B22222'
}

export const centerText = {
  textAlign: "center"
}

export const rightText = {
  textAlign: "right"
}

export const margin50 = {
  marginRight: 50
}

export const flexEnd = {
  display: 'flex',
  justifyContent: 'flex-end'
};

export const leftPadding10 = {
  paddingLeft: 10
}

export const rightPadding10 = {
  paddingRight: 10
}

export const padding20 = {
  padding: 20
}
