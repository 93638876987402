import React from 'react';
import { Form, Formik, getIn } from 'formik';
import * as yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Slide, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Box, Icon, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SelectField from './SelectField/SelectField';
import ConditionalFields from './ConditionalFields/ConditionalFields';
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import LoadingButton from "../../../../common/components/base/LoadingButton/LoadingButton";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    padding: 0,
    borderRadius: 8,
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    '& h5': {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(0.4, 0),
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
    }
  },
  dialogContent: {
    minHeight: 210,
    padding: theme.spacing(4, 3, 5, 3),
    '&:first-child': {
      paddingTop: theme.spacing(4),
    }
  },
  formDescription: {
    marginBottom: 20
  },
  formLabel: {
    marginBottom: 12
  },
  formRoot: {
    margin: 0,
    padding:0,
  },
  dialogActions: {
    '& > :not(:first-child)':{
      marginLeft: 12,
    },
    padding: theme.spacing(2, 3)
  },
  icon: {
    lineHeight: 0,
    marginRight: 5,
    '& span': {
      fontSize: 24
    }
  },
  automationActionBtn: {
    minWidth: 116,
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    letterSpacing: 0.2,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
    '& .MuiButton-startIcon':{
      marginRight:4
    }
  },
  automationBackBtn: {
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    '&:focus': {
      outline: 'none',
    },
  },
}));

const AutomationNodeDialog = ({ dialogOpen, dialogClose, dialogActions, dialogListNodes, selectedId, mutation, automationId, refetch, dialogBack, actionType, setActionType, nodeId, loadingLists, errorLists, dataLists, setErrorDialogOpen, setErrorMsg, mutationLoading }) => {
  const classes = useStyles();
  const theme = useTheme();

  const node = dialogListNodes.find((item) => {
    return item.id === selectedId;
  });

  const isConditionalFields = [1, 7].includes(selectedId);
  const isSelectField = [2, 3, 9, 10].includes(selectedId);
  const isListsField = [4, 5, 8].includes(selectedId);

  const isEditNode = ['edit entry', 'edit step'].includes(actionType);
  const isAddStep = ['add action', 'add condition'].includes(actionType);
  const isAddEntry = ['add entry'].includes(actionType);

  const textActionButton = actionType === 'edit entry' ? 'Edit Entry Point' :  actionType === 'edit step' ? 'Edit Step' : isAddEntry ? 'Add Entry Point' : isAddStep ? 'Add Step' : null;

  const nodeType = node?.type;
  let color;
  switch (nodeType) {
    case 'entry':
      color = theme.palette.automation.darkBlue[500];
      break;
    case 'action':
      color = theme.palette.automation.blue[500];
      break;
    case 'condition':
      color = theme.palette.automation.green[500];
      break;
    case 'starter':
      color = theme.palette.grey[500];
      break;
    default:
      color = theme.palette.grey[400];
  }

  let validationSchema;
  let initialValues;
  if (isConditionalFields) {
    validationSchema = yup.object().shape({
      node: yup.object().shape({
        description: yup.object().shape({
          filterField: yup.string().required("Filter required"),
          filterCondition: yup.string().required("Filter required"),
          filterValue: yup.lazy(val => (Array.isArray(val) ? yup.array().required("Filter required") : yup.number().positive('Please, re-enter a positive number').typeError('This field only accepts numbers').required("Filter required")))
        }),
      }),
    });
    initialValues = {
      node: {
        description: {
          filterField: ""
        },
      }
    }
  } else if (isSelectField || isListsField) {
    validationSchema = yup.object().shape({
      node: yup.object().shape({
        description: yup.string().required('The select field is required')
      }),
    });
    initialValues = {
      node: {
        description: "",
      }
    }
  }

  return (
    <Dialog
      TransitionComponent={Transition}
      fullWidth
      maxWidth={'sm'}
      open={dialogOpen}
      onClose={(event, reason) => reason ? null : dialogClose}
      classes={{paper: classes.dialogRoot}}
    >
      <DialogTitle disableTypography className={classes.dialogTitle} style={{ backgroundColor: color }}>
        <Box display="flex" alignItems="center">
          <div className={classes.icon}>
            <Icon>{node?.icon}</Icon>
          </div>
          <Typography variant="h5">{node?.title}</Typography>
        </Box>
        <IconButton aria-label="close" className={classes.closeButton} onClick={dialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const descriptionValue = isConditionalFields ? `${values.node.description.filterField} - ${values.node.description.filterCondition} - ${values.node.description.filterValue}` : values.node.description;
          const mutationVariables = {
            ...(isEditNode ? { automationId: automationId, nodeId: nodeId } : { id: automationId }),
            node: {
              title: node?.title,
              description: descriptionValue,
              icon: node?.icon
            },
            ...(isAddStep ? { parent: nodeId } : null),
          }
          // alert(JSON.stringify(mutationVariables, null, 2));
          mutation({
            variables: mutationVariables
          })
          .then(({ data }) => {
            refetch();
            setActionType('');
            dialogClose();
          })
          .catch(error => {
            dialogClose();
            setErrorDialogOpen(true);
            setErrorMsg(error.toString());
          });
       }}
    >
      {({ values, touched, errors, handleChange, setFieldValue }) => (
        <Form className={classes.formRoot}>

          <DialogContent className={classes.dialogContent} dividers>

            <Typography className={classes.formDescription} variant="body1" component="p">{node?.description}</Typography>
            <Typography className={classes.formLabel} variant="body1" component="p">{node?.formLabel}</Typography>

            { isConditionalFields ? (
              <ConditionalFields
                getIn={getIn}
                newItemsLists={node?.formFields}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                fieldValue={values}
              />
            ) : isSelectField ? (
              <SelectField
                fieldType={'select'}
                fieldName={'node.description'}
                fieldLabel={'Select a value'}
                fieldSelectList={node?.formFields?.selectFields}
                fieldValue={values.node.description}
                fieldHandleChange={handleChange}
                fieldError={touched?.node?.description && Boolean(errors?.node?.description)}
                fieldHelperText={touched?.node?.description && errors?.node?.description}
              />
            )
            : isListsField ?
                loadingLists ? (
                  <LoadingSpinner />
                ) : errorLists ? (
                  <div>
                    Error! {errorLists.message}
                  </div>
                ) : (
                  <SelectField
                    fieldType={'select'}
                    fieldName={'node.description'}
                    fieldLabel={'Select a value'}
                    fieldSelectList={dataLists.crmContacts.lists}
                    fieldValue={values.node.description}
                    fieldHandleChange={handleChange}
                    fieldError={touched?.node?.description && Boolean(errors?.node?.description)}
                    fieldHelperText={touched?.node?.description && errors?.node?.description}
                  />
                )
            : null }

          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            {dialogActions ?
              dialogActions
              :
              (<>
                { !actionType.includes('edit') &&
                <Button
                  className={classes.automationBackBtn}
                  variant="contained"
                  onClick={(event) => dialogBack(event, nodeId, 'select node')}
                  size="large"
                >Back
                </Button>
                }
                <LoadingButton
                  className={classes.automationActionBtn}
                  variant="contained"
                  type="submit"
                  size="large"
                  style={{ backgroundColor: color }}
                  loading={mutationLoading}
                  circleSize={26.25}
                >{textActionButton}
                </LoadingButton>
              </>)
            }
          </DialogActions>

        </Form>
      )}
      </Formik>

    </Dialog>
  );
}

export default AutomationNodeDialog;
