export const creativeTypes = {
  BANNER: "Banner",
  RICH_MEDIA: "Rich Media",
  VIDEO: "Video"
};

export const creativeSizes = {
  [creativeTypes.BANNER]: "300x250",
  [creativeTypes.RICH_MEDIA]: "1800x1000",
  [creativeTypes.VIDEO]: "640x480"
};

export const creativeImages = {
  [creativeTypes.BANNER]: "300x250b_banner.png",
  [creativeTypes.RICH_MEDIA]: "1800x1000_skin.jpg",
  [creativeTypes.VIDEO]: "640x480v_video.jpg"
};
