import React, { useState } from 'react';
import Layout from '../../common/Layout';
import { Formik } from 'formik';
import { withRouter } from 'react-router';
import { validateEditPassword } from '../Validations';
import ChangePasswordForm from "./ChangePasswordForm";
import gql from "graphql-tag";
import Swal from 'sweetalert2';
import { useMutation } from '@apollo/client';

const CHANGE_PASSWORD = gql`
      mutation changePassword($id:String , $password:String){
        changePassword(_id:$id , password:$password)
      }
  `;

const CHANGE_MY_PASSWORD = gql`
  mutation changeMyPassword($password:String){
    changeMyPassword(password:$password)
  }
`;  

function displayPasswordError(){
  Swal.fire({
    type: 'error',
    text: 'Error changing password.',
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: 'Close'
  })   
}
function displayPasswordChanged(){
  Swal.fire({
    type: 'success',
    title: 'Saved',
    showConfirmButton: false,
    timer: 1500
  })
}
const ChangePassword = (props) => {
  const [id] = useState(props.match.params.id);
  const [changeMyPasswordMutation] = useMutation(CHANGE_MY_PASSWORD,
    {
      onError: () => {  displayPasswordError(); },
      onCompleted: () => {
        displayPasswordChanged();
        props.history.push(`/profile`);
    }});

   const [changePasswordMutation] = useMutation(CHANGE_PASSWORD,
    {
      onError: () => {  displayPasswordError(); },
      onCompleted: () => {
        displayPasswordChanged();
     props.history.push(`/admin-settings/users/${id}`);
   }});

  const changePassword = async (values) => {
    if (values.password === values.password2) {
      if(id){
        changePasswordMutation({variables: { id: id, password: values.password }})
      }
      else {
        changeMyPasswordMutation({variables: { password: values.password }})
      }
    }
    else {
      alert('Incorect password')
    }
  }

    return (
      <Layout name="Change Password">
        <div class="wizard-container">
          <div class="col-md-8 col-12 mr-auto ml-auto">
            <div class="card card-wizard active" data-color="rose" id="wizardProfile">

              <Formik
                initialValues={{password:'',password2:''}}
                validate={validateEditPassword}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  changePassword(values)

                }}
              >
                {({ isSubmitting, values, handleChange }) =>(
                  <ChangePasswordForm
                    isSubmitting={isSubmitting}
                    values={values}
                    handleChange={handleChange}
                  />
                )}

              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

export default withRouter(ChangePassword);
