import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActions, Box, Typography, Button } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = ({imageLink,radialGradient}) => makeStyles(theme => ({
  cardRoot: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xl')]: {
      height: 410,
    },
    [theme.breakpoints.down('lg')]: {
      height: 440,
    },
    [theme.breakpoints.down('md')]: {
      height: 430,
    },
    [theme.breakpoints.down('sm')]: {
      height: 430,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    },
  },
  cardContent: {
    position: 'relative',
    padding: theme.spacing(3, 3, 0, 3),
  },
  cardMedia: {
    height: 147,
    marginBottom:17,
    position:'relative',
    backgroundImage: radialGradient,
    '& div': {
      width:100,
      height:100,
      position:'absolute',
      backgroundColor:theme.palette.common.white,
      top:0,
      bottom:0,
      left:0,
      right:0,
      margin:'auto',
      borderRadius:'100%',
      boxShadow:`${theme.shadows[1]}`,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      '& img':{
        width:60,
        height:'auto'
      }
    }
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.3px',
    marginBottom: 10,
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
  },
  cardDescription: {
    marginBottom: 0,
    display: '-webkit-box',
    lineClamp: 3,
    boxOrient: 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginBottom:25
    },
  },
  cardActions: {
    padding: theme.spacing(0, 3, 4, 3),
    marginTop: 'auto'
  },
  cardBtn: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const SandboxToolBlock = ({ sandboxTool }) => {
  const { name, slug, shortDescription, sandboxIcon } = sandboxTool.attributes;
  const imageLink = sandboxIcon.image.data.attributes.url;
  const radialGradient = sandboxIcon.iconGradient;
  const classes = useStyles({imageLink, radialGradient})();

  return (
    <Card className={classes.cardRoot}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardMedia}>
          <div>
            <img src={imageLink} alt={name} />
          </div>
        </Box>
        <Typography className={classes.cardTitle} gutterBottom variant="h5" component="h2">
          {name}
        </Typography>
        <Typography className={classes.cardDescription} variant="body1" component="p">
          {shortDescription}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button
          component={RouterLink}
          to={`/sandbox-tools/${slug}`}
          className={classes.cardBtn}
          color="default"
          variant="contained"
          size="large"
          endIcon={<ArrowForwardIcon />}
        >Go to Sandbox
        </Button>
      </CardActions>
    </Card>
  );
}

export default SandboxToolBlock;
