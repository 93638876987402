import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Typography, Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { SecureQuery } from "../../../settings/graphqlHelper";
import gql from "graphql-tag";
import ChallengeContext from '../../../store/challenge-context';

const DELETE_CHALLENGE = gql`
  mutation deleteChallenge($input: String) {
    deleteChallenge(input: $input)
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign:'center',
  },
  icon: {
    fontSize: theme.typography.pxToRem(32),
    color: theme.palette.warning.main,
  },
  textBox: {
    maxWidth:440,
    margin: '0 auto',
    marginTop:30,
    textAlign:'center',
    '& h1': {
      fontSize: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom:30,
    },
    '& p': {
      textAlign:'left',
      marginBottom:12,
    }
  },
  dialogBtnDefault: {
    backgroundColor: theme.palette.common.gamsDarkBlue,
    color: theme.palette.common.white,
    lineHeight:1.2,
    padding: '9px 30px',
    marginTop:20,
    '&:hover': {
      backgroundColor: theme.palette.common.gamsPetroleumBlue,
      color: theme.palette.common.white,
    },
    '&:focus': {
      outline: 'none',
      color: theme.palette.common.white,
    },
  },
}));

const HideView = ({ history }) => {
  const classes = useStyles();
  const context = useContext(ChallengeContext);

  const handleExit = () => {
    let id;
    if (window.location.pathname.split("/").pop()) {
      id = window.location.pathname.split("/").pop();
      SecureQuery
      .mutate({
        mutation: DELETE_CHALLENGE,
        variables: { input: id }
      })
      .catch(error => console.log(error))
      .then(data => {
        if (context.challengeOrigineRoute) {
          history.push(context.challengeOrigineRoute);
        } else {
          history.push('/');
        }
      });
    }
  };

  return (
    <Container className={classes.container} maxWidth="md">
      <Box className={classes.textBox}>
        <WarningIcon className={classes.icon}/>
        <Typography variant="h1">View not available on mobile devices</Typography>
        <Typography variant="body1">We are sorry. The view is not available on mobile devices, only on desktop.</Typography>
        <Typography variant="body1">Please, change device to be able to continue on this section.</Typography>
      </Box>
      <Button
        onClick={handleExit}
        className={classes.dialogBtnDefault}
        color="primary">
        Exit page
      </Button>
    </Container>
  );

};

export default HideView;
