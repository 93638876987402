import React from 'react';
import FullSimpleLayout from './FullSimpleLayout';
import { Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RegisterForm = (props) => (
    <FullSimpleLayout>
        <div className="row">
            <div className="col-md-10 ml-auto mr-auto">
                <div className="card card-signup">
                    <h2 className="card-title text-center">Register</h2>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-5 ml-auto">
                                <div className="info info-horizontal">
                                    <div className="icon icon-rose">
                                        <i className="material-icons">timeline</i>
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Marketing</h4>
                                        <p className="description">
                                            We've created the marketing campaign of the website. It was a very interesting collaboration.
                                        </p>
                                    </div>
                                    <br />
                                </div>
                            </div>
                            <div className="col-md-5 mr-auto">
                                <div className="social text-center">
                                    {/*<button className="btn btn-just-icon btn-round btn-twitter">
                                        <i className="fa fa-twitter"></i>
                                    </button>
                                    <button className="btn btn-just-icon btn-round btn-dribbble">
                                        <i className="fa fa-dribbble"></i>
                                    </button>
                                    <button className="btn btn-just-icon btn-round btn-facebook">
                                        <i className="fa fa-facebook"> </i>
                                    </button>*/}
                                    <h4 className="mt-3">  </h4>
                                </div>
                                <Form>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="material-icons">face</i>
                                                </span>
                                            </div>
                                            <Field className="form-control" aria-required="true" name="name" placeholder="Name" />
                                            <ErrorMessage name="name" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="material-icons">face</i>
                                                </span>
                                            </div>
                                            <Field className="form-control" aria-required="true" name="surname" placeholder="Last Name" />
                                            <ErrorMessage name="surname" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="material-icons">description</i>
                                                </span>
                                            </div>
                                            <Field className="form-control" aria-required="true" name="sigla" placeholder="Sigla" />
                                            <ErrorMessage name="surname" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="material-icons">mail</i>
                                                </span>
                                            </div>
                                            <Field className="form-control" aria-required="true" name="username" placeholder="Email" />
                                            <ErrorMessage name="username" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="material-icons">lock_outline</i>
                                                </span>
                                            </div>
                                            <Field className="form-control" type="password" aria-required="true" name="password" placeholder="Password" />
                                            <ErrorMessage name="password" component="div" />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Course groups: </label>
                                            <Select
                                                name="courseGroup"
                                                placeholder="Insert Course Group"
                                                options={props.courseGroups}
                                                onChange={(values) => props.setFieldValue("courseGroups", (values.map((item) => item.value)))}
                                                isMulti={true}
                                                component={Select}
                                                padding={20}
                                            />
                                    </div>
                                    <div className="form-group has-default">
                                        <div className="input-group">
                                            <label component="legend">User Role : </label>
                                            <RadioGroup name="userRole" aria-label="userRole" defaultValue={props.values.userRole} onChange={props.handleChange} >
                                                <FormControlLabel value="student" control={<Radio />} label="Student" />
                                                <FormControlLabel value="coach" control={<Radio />} label="Coach" />
                                                {props.userRole === "superuser" ?
                                                    <FormControlLabel value="superuser" control={<Radio />} label="SuperUser" />
                                                    : null}
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div className="form-group has-default">
                                      <div className="input-group">
                                        <label component="legend">Expiration date: </label>
                                        <Field  className="form-control" 
                                                type="date" 
                                                aria-required="true" 
                                                name="expirationDate" />                                   
                                      </div>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <Field name="privacyPolicy" className="form-check-input" type="checkbox" checked={props.values.privacyPolicy} aria-required="true" />
                                            <ErrorMessage name="privacyPolicy" component="div" />
                                            <span className="form-check-sign">
                                                <span className="check"></span>
                                            </span>
                                            I agree to the
                                            <a href="#something"> terms and conditions</a>.
                                        </label>
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-primary btn-round mt-4">Register user</button>
                                    </div>
                                </Form>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </FullSimpleLayout>
);

export default RegisterForm;
