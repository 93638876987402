import React from 'react';
import { ErrorMessage} from 'formik';
import SimpleDropDown from '../../common/components/base/SimpleDropDown';
import {movingTab, width100} from "../../settings/otherStyles";
import TextField from "@material-ui/core/TextField";

class PlacementForm extends React.Component {
  nameText = 'Placement Name (required)';
  subPlacementText = 'Sub placement name';
  countClick = 0;

  constructor(props) {
    super(props);

    if (props.update) {
      this.nameText = '';
      this.subPlacementText = '';
      this.countClick = 0;
    }
    this.state = { ...props, isClicked: false };
    this.handleClickSubmit = this.handleClickSubmit.bind(this);
  }

  handleClickSubmit(values){
    this.countClick++
    if (this.countClick === 1) {
      this.props.handleSubmit(values);
    }
    setTimeout(()=> (this.countClick = 0), 1000);
  }

  render() {
    const {values } = this.props;
    return (
      <>
        <div className="card-header text-center">
            <h3 className="card-title">
              Placement
            </h3>
          </div>
          <div className="wizard-navigation">
            <div className="moving-tab" style={movingTab}>
              Info
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ml-auto mr-auto">
            <div className="input-group form-control-lg">
              <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="material-icons">face</i>
                                </span>
              </div>
              <div className="form-group bmd-form-group">
                <TextField
                  //required
                  label={this.nameText}
                  name="name"
                  value={values.name}
                  onChange={this.state.handleChange}
                  margin="normal"
                  style={width100}
                />
                <ErrorMessage name="name" render={msg => <div style={{ color: '#f44336'}}>{msg}</div>}/>
              </div>
              <div className="container">
                <div className="form-group bmd-form-group ml-auto">
                  <SimpleDropDown key="2" handleChange={(event)=>this.props.setFieldValue("size", event.currentTarget.textContent)} id="size"
                                  name="size" items={['300x250', '640x480', '1800x1000']}
                                  caption={values.size ||"Size"} header='Type' />
                  <ErrorMessage name="size" render={msg => <div style={{ color: '#f44336'}}>{msg}</div>}/>
                </div>
              </div>
              <div className="form-group bmd-form-group ml-auto mr-auto">
                <button 
                  type="button"
                  onClick={this.handleClickSubmit}
                  disabled={ this.countClick >= 1}
                  className="btn btn-next btn-fill btn-rose btn-wd" name="save" value="save">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default PlacementForm;
