import React from "react";
import * as PropTypes from "prop-types";
//import {TableFooter} from "@material-ui/core";

export function hasNext(page,pages){
  return (pages > 1) && ((page+1) < pages);
}

export function showLast(page,pages){
  return  ((pages > (page+1)) && ((page+1)<pages)) ;
}

export const DataTableFooter = (props) => {
  let last = null;
  let next = null;
  let previous = null;
  let first = null;
  let showing = null;
  let current = null;

if (showLast(props.page,props.pages))  {
    last = <li className="paginate_button page-item last" id="datatables_last">
      <div href="#" onClick={props.last}
         aria-controls="datatables"
         data-dt-idx="7"
         tabIndex="0"
         className="page-link">
        Last
      </div>
    </li>;
  }
  if (props.page > 0) {
    previous = <li className="paginate_button page-item previous" id="datatables_previous">
      <div href="#" onClick={props.previous} aria-controls="datatables" data-dt-idx="1"
         tabIndex="0"
         className="page-link">
        Prev
      </div>
    </li>;
    first = <li className="paginate_button page-item first" id="datatables_first">
      <div href="#"
         onClick={props.first}
         aria-controls="datatables"
         data-dt-idx="0"
         tabIndex="0"
         className="page-link">
        First
      </div>
    </li>;
  }
  if (hasNext(props.page,props.pages)) {
    next = <li className="paginate_button page-item next" id="datatables_next">
      <div href='#' onClick={props.next}
         aria-controls="datatables" data-dt-idx="6" tabIndex="0"
         className="page-link">
        Next
      </div>
    </li>;
  }
  if(isNaN(props.page) || props.count==="0"){
    showing = <span>The table is empty</span>

  }
  else{
    showing = <span>
                  Showing {(props.page*15)+1} to {props.count > ((props.page+1)*15) ?  ((props.page+1)*15) : props.count} of {props.count} entries
              </span>
    current = <li className="paginate_button page-item active">
                <div //href=""
                  //onClick={props.first}
                  aria-controls="datatables"
                  data-dt-idx="0"
                  tabIndex="0"
                  className="page-link"
                  style={{backgroundColor: '#9c27b0', borderColor: '#9c27b0', color: '#fff'}}>
                  {props.page +1}
                </div>
              </li>
  }

  return (
    <div style={{display: "block", maxWidth: "50%"}}>
      <div className="row">
        <div className="col-sm-12 col-md-5">
          <div className="dataTables_info" id="datatables_info" role="status" aria-live="polite">
            {showing}
          </div>
        </div>

        <div className="col-sm-12 col-md-7">
          <div className="dataTables_paginate paging_full_numbers" id="datatables_paginate">
            <ul className="pagination">
              {first}
              {previous}
              {current}
              <li className="paginate_button page-item ">
                <div aria-controls="datatables"
                     data-dt-idx="3" tabIndex="0"
                     className="page-link">
                  ...
                </div>
              </li>
              {next}
              {last}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
};

DataTableFooter.propTypes = {
  count: PropTypes.number,
  next: PropTypes.number,
  previous: PropTypes.number
};
