import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Box, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CRM_CONTACT } from "../../graphql/queries";
import { UPDATE_CRM_CONTACT } from "../../graphql/mutations";
import { crmData } from "../../../../utils/data/sandboxes";
import { selectList } from "../../../../utils/data/addContactData";
import SandboxForm from '../../../../common/components/SandboxForm/SandboxForm';
import SandboxFormField from '../../../../common/components/SandboxForm/SandboxFormField/SandboxFormField';
import SandboxFormSubmit from '../../../../common/components/SandboxForm/SandboxFormSubmit/SandboxFormSubmit';
import SandboxLayout from "../../../../hoc/SandboxLayout/SandboxLayout";
import SandboxHeader from "../../../../common/components/SandboxHeader/SandboxHeader";
import SandboxDialog from '../../../../common/components/Dialogs/SandboxDialog/SandboxDialog';
import LoadingSpinner from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const validationSchema = yup.object({
  name: yup
    .string('Enter your name')
    .required('Name is required'),
  surname: yup
    .string('Enter your surname')
    .required('Surname is required'),
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  age: yup
    .number('Enter your age')
    .typeError('Age must be a number')
    .min(13)
    .max(99)
    .required('Age is required'),
  country: yup
    .string('Enter your country')
    .required('Country is required'),
});

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 620,
    margin: '0 auto',
  },
  goBackBtn: {
    marginBottom:10,
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.common.gamsPetroleumBlue,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  alert: {
    marginBottom:18,
    border: `solid 1px ${theme.palette.error.light}`
  }
}));

const CrmContactEdit = props => {
  const classes = useStyles();
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [updateCrmContact] = useMutation(UPDATE_CRM_CONTACT);
  const { loading, error, data } = useQuery(GET_CRM_CONTACT, {
    variables: { id: props.match.params.id }
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.crmContact.name || '',
      surname: data?.crmContact.surname || '',
      email: data?.crmContact.email || '',
      age: data?.crmContact.age || '',
      country: data?.crmContact.country || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateCrmContact({
        variables: {
          id: data.crmContact._id,
          input: {
            name: values.name,
            surname: values.surname,
            email: values.email,
            age: Number(values.age),
            country: values.country,
          }}
      })
      .then(({ data }) => {
        setHasError(false);
        setSuccessDialogOpen(true);
      })
      .catch(error => {
        setSuccessDialogOpen(false);
        setHasError(true);
        setErrorMsg(error.toString());
      });
    },
  });

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  useEffect(() => {
    if (successDialogOpen === true) {
      const delay = setTimeout(() => {
        setSuccessDialogOpen(false);
        props.history.push("/crm/contacts");
      }, 2000);
      return () => clearTimeout(delay);
    }
  }, [successDialogOpen, props.history]);

  return (
    <SandboxLayout layoutData={crmData}>

      <Container maxWidth={false} className={classes.container} disableGutters>
        <SandboxHeader
          title="Edit contact"
          subTitle="Edit information about a created contact."
          extraStyles={{ marginBottom: 30 }}
        />

        {loading ? (
          <LoadingSpinner boxed={true} styles={{ paddingTop: 70}}/>
        ) : error ? (
          <div>Error! {error.message}</div>
        ) : (
          <>
            { hasError ?
              <Alert className={classes.alert} severity="error">
                {errorMsg}
              </Alert>
            : null }
            <SandboxForm
              formHandleSubmit={formik.handleSubmit}
            >
              <SandboxFormField
                fieldType={'text'}
                fieldGrid={12}
                fieldName={'name'}
                fieldLabel={'Name'}
                fieldValue={formik.values.name}
                fieldHandleChange={formik.handleChange}
                fieldError={formik.touched.name && Boolean(formik.errors.name)}
                fieldHelperText={formik.touched.name && formik.errors.name}
              />
              <SandboxFormField
                fieldType={'text'}
                fieldGrid={12}
                fieldName={'surname'}
                fieldLabel={'Surname'}
                fieldValue={formik.values.surname}
                fieldHandleChange={formik.handleChange}
                fieldError={formik.touched.surname && Boolean(formik.errors.surname)}
                fieldHelperText={formik.touched.surname && formik.errors.surname}
              />
              <SandboxFormField
                fieldType={'text'}
                fieldGrid={12}
                fieldName={'email'}
                fieldLabel={'Email'}
                fieldValue={formik.values.email}
                fieldHandleChange={formik.handleChange}
                fieldError={formik.touched.email && Boolean(formik.errors.email)}
                fieldHelperText={formik.touched.email && formik.errors.email}
              />
              <SandboxFormField
                fieldType={'text'}
                fieldGrid={12}
                fieldName={'age'}
                fieldLabel={'Age'}
                fieldValue={formik.values.age}
                fieldHandleChange={formik.handleChange}
                fieldError={formik.touched.age && Boolean(formik.errors.age)}
                fieldHelperText={formik.touched.age && formik.errors.age}
              />
              <SandboxFormField
                fieldType={'select'}
                fieldGrid={12}
                fieldName={'country'}
                fieldLabel={'Country'}
                fieldSelectList={selectList}
                fieldValue={formik.values.country}
                fieldHandleChange={formik.handleChange}
                fieldError={formik.touched.country && Boolean(formik.errors.country)}
                fieldHelperText={formik.touched.country && formik.errors.country}
              />
              <SandboxFormSubmit
                textSubmit={'Save contact'}
                btnSubmitFullWidth={true}
              />
            </SandboxForm>
          </>
        )}
        <Box textAlign="right" pt="30px" mr="-8px">
          <Button
            startIcon={<ArrowBackIcon />}
            component={RouterLink}
            to={'/crm/contacts'}
            className={classes.goBackBtn}
          >Back to Contacts</Button>
        </Box>
      </Container>

      <SandboxDialog
        id="successEditDialog"
        successIcon={true}
        dialogClose={handleSuccessDialogClose}
        dialogOpen={successDialogOpen}
        dialogTitle={'Contact updated'}
        dialogMessage={
          <Box textAlign="center" pb="42px">
            <Typography variant="body1" component="p">You will be redirected to the contacts page in a few seconds.</Typography>
          </Box>
        }
      />

    </SandboxLayout>
  )
}

export default CrmContactEdit;
